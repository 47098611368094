import {Column} from "./column";

export class Preset<T> {
    public Id: string = null;
    public Name: string = "";
    public SearchTerm: string = null;
    public IsSystemPreset: boolean = false;
    public Columns: Partial<Column<T>>[] = [];
    public RememberColumns: boolean = false;
    public RememberOrder: boolean = false;
    public RememberColumnFilters: boolean = false;
    public RememberSearchTerm: boolean = false;

    constructor(obj?: Partial<Preset<T>>) {
        ctor(this, obj, {
            Collections: {
                Columns: Column,
            },
        });
    }
}
