import {ShippingProductService} from "../shipping-product-service";
import {IdentAgeCheckEnum} from "@app/app/shipping/core/entity/service/ident-age-check.enum";

export class IdentService extends ShippingProductService {
    public FirstName: string = null;
    public LastName: string = null;
    public DateOfBirth: Date = null;
    public MinimumAge: IdentAgeCheckEnum = IdentAgeCheckEnum.A0;

    constructor(obj?: Partial<IdentService>) {
        super(obj);
        ctor(this, obj, {
            DateFields: ["DateOfBirth"],
        });
    }

}
