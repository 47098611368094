import {Component} from "@angular/core";
import {AbstractLayoutComponent, AbstractLayoutType} from "@mintware-de/form-builder";
import {ExpansionPanelOptions} from "@app/app/common/form/layout/expansion-panel/expansion-panel.options";

@Component({
    selector: "bb-form-expansion-panel",
    template: `
        <mat-expansion-panel [(expanded)]="mwFieldType.options.expanded">
            <mat-expansion-panel-header>
                <mat-panel-title>{{  mwFieldType.options.title | translate }}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let kv of mwFieldType.options.model | keyvalue:orderAsGiven"
                          mwFormField
                          [mwFieldType]="kv.value"
                          [mwIndex]="indexFromParent"
                          [mwFormGroup]="mwFormGroup"
                          [mwPath]="fieldPaths[kv.key]"
                          [mwElement]="elements[kv.key]">
            </ng-container>
        </mat-expansion-panel>

    `,
})
export class ExpansionPanelComponent extends AbstractLayoutComponent<AbstractLayoutType<ExpansionPanelOptions>> {

}
