import { Injectable } from "@angular/core";
import {
    ClientStorageService,
    ErrorHandlerService,
    Message,
    MessagingService,
    NavigationService,
    TranslationService,
} from "@bb-core/service";
import { SingleShipPresenter } from "./presenter";
import { ShipmentRepository } from "../../repository";
import { CloudStorage, InsuranceService, OrderToShip, PackageType, ShippingProvider, ShippingStationOptions } from "../../entity";
import { SelectShippingProviderRequest, SelectShippingProviderUseCase } from "../select-shipping-provider.use-case";
import { ShipSingleOrderRequest, ShipSingleOrderUseCase } from "./ship-single-order.use-case";
import { ApplyWeightRequest, ApplyWeightUseCase } from "../apply-weight.use-case";
import { ShippingDependenciesService } from "../../service";
import { ShippingProductServiceDto } from "@app/app/shipping/data/shipping-product-service.dto";

export class LoadOrderToShipRequest {
    public OrderId: number = null;

    constructor(obj?: Partial<LoadOrderToShipRequest>) {
        ctor(this, obj, {
            NumberFields: ["OrderId"],
        });
    }
}

/**
 * @deprecated try to replace instead of using this class
 */
@Injectable({ providedIn: "root" })
export class LoadOrderToShipUseCase implements IUseCase<LoadOrderToShipRequest, SingleShipPresenter> {

    constructor(private readonly translator: TranslationService,
        private readonly messaging: MessagingService,
        private readonly shippingRepo: ShipmentRepository,
        private readonly selectProviderUseCase: SelectShippingProviderUseCase,
        private readonly navigationService: NavigationService,
        private readonly shipSingleOrderUseCase: ShipSingleOrderUseCase,
        private readonly clientStorageService: ClientStorageService,
        private readonly applyWeightUseCase: ApplyWeightUseCase,
        private readonly errorHandler: ErrorHandlerService,
        private readonly shippingDependenciesService: ShippingDependenciesService,
    ) {
    }

    public async execute(request: LoadOrderToShipRequest,
        presenter?: SingleShipPresenter,
    ): Promise<void> {

        if (!this._validateRequest(request)) {
            return;
        }

        const order = await this._getOrder(request.OrderId);
        if (order == null) {
            await this.navigationService.navigateToShipping();
            return;
        }

        if (order.AlreadyShipped) {
            this.messaging.showMessage(Message.transient({
                title: this.translator.translate("title.already_shipped"),
                message: this.translator.translate("text.order_is_already_shipped"),
            })).then();
        }

        const [cloudStorages, providers, packageTypes] = await this._fetchData();
        this._setDefaults(order, cloudStorages, packageTypes, providers);
        this._markPositionsAsPacked(order);


        const options = await this.shippingRepo.getShippingStationOptions();

        await this._checkAndPerformAutoShip(options, order, packageTypes, providers);

        await this._setProviderAndProduct(order, providers, presenter);
        this._setCloudStorages(order, cloudStorages);
        this._setPackageType(order, packageTypes);

        await this.applyWeightUseCase.execute(new ApplyWeightRequest({
            Order: order,
            PackageTypes: packageTypes,
            Providers: providers,
            Type: order.DefaultWeightCalculation,
        }), presenter);

        presenter?.displayPositionsHaveToBeMarkedAsPacked(options.PositionsHaveToBeMarkedAsPacked);
        presenter?.displayOrderToShip(order);
        presenter?.displayAvailableCloudStorages(cloudStorages);
        presenter?.displayAvailableCloudStoragesForExportDocs(cloudStorages);
        presenter?.displayAvailableCloudStoragesForRetoureLabels(cloudStorages);
        presenter?.displayAvailableShippingProviders(providers);
        presenter?.displayAvailablePackageTypes(packageTypes);
        presenter?.displayCurrency(order.Currency, order.CurrencySymbol);
    }

    private async _fetchData(): Promise<[CloudStorage[], ShippingProvider[], PackageType[]]> {
        return await Promise.all([
            this._loadCloudStorages(),
            this.shippingDependenciesService.loadShippingProviders(),
            this.shippingDependenciesService.loadPackageTypes(),
        ]) as [CloudStorage[], ShippingProvider[], PackageType[]];
    }

    private _getOrder(orderId: number): Promise<OrderToShip> {
        try {
            return this.shippingRepo.getOrderForSingleShip(orderId);
        } catch (e) {
            this.errorHandler.handleException(e, "text.load_order_failed", true).then();
            return null;
        }
    }

    private _validateRequest(request: LoadOrderToShipRequest): boolean {
        if (request?.OrderId == null || request.OrderId <= 0
        ) {
            const message = this.translator.translate("flash.no_order_was_given");
            this.messaging.showError(Message.blocking({ message })).then();
            return false;
        }
        return true;
    }


    private async _loadCloudStorages(): Promise<CloudStorage[]> {
        try {
            return await this.shippingRepo.getCloudStorages();
        } catch (e) {
            const message = this.translator.translate("flash.load_cloud_devices_failed");
            this.messaging.showMessage(Message.transient({ message })).then();
            throw e;
        }
    }

    private async _checkAndPerformAutoShip(options: ShippingStationOptions,
        order: OrderToShip,
        packageTypes: PackageType[],
        providers: ShippingProvider[],
        presenter?: SingleShipPresenter,
    ): Promise<void> {
        const packageType = packageTypes.find(p => p.Id === order.ShippingPackageId);
        const allProducts = providers.reduce(
            (products, provider) => [...products, ...provider.AttachedProducts],
            [],
        );
        const product = allProducts.find(p => p.Id === order.ShippingProductId);

        const autoShipPossible = options.AutoSendIfPossible && !order.AlreadyShipped && product != null && packageType != null;
        if (!autoShipPossible) {
            return;
        }

        if (product?.ProductServices.find(x => x.RequiresUserInput) != null) {
            const title = this.translator.translate("title.auto_ship_failed");
            const message = this.translator.translate("flash.service_requires_input");
            await this.messaging.showMessage(Message.transient({ title, message }));
            return;
        }

        let services = product?.ProductServices || [];

        services = services.map((service: ShippingProductServiceDto) => {
            if (service instanceof InsuranceService && service.AutoSetShippingValue && service.InsuranceValue === 0) {
                service.InsuranceValue = order.OrderValue;
            }
            return service;
        })

        this.shipSingleOrderUseCase.execute(new ShipSingleOrderRequest({
            OrderToShip: order,
            ProductServices: services,
            ShippingDate: order.ShippingDate,
        }), presenter).then();
    }

    private _setCloudStorages(order: OrderToShip, cloudStorages: CloudStorage[]): void {
        const defaultCloudPrinter = this.clientStorageService.get<number>("shipping.defaultCloudPrinter");
        order.CloudStorageId = order.CloudStorageId ?? cloudStorages.find(x => x.Id === defaultCloudPrinter)?.Id;
        
        const defaultCloudPrinterForExportDocs = this.clientStorageService.get<number>("shipping.defaultCloudPrinterForExportDocs");
        order.CloudStorageIdForExportDocs = order.CloudStorageIdForExportDocs ?? cloudStorages.find(x => x.Id === defaultCloudPrinterForExportDocs)?.Id;

        const defaultCloudPrinterForRetoureLabels = this.clientStorageService.get<number>("shipping.defaultCloudPrinterForRetoureLabels");
        order.CloudStorageIdForRetoureLabels = order.CloudStorageIdForRetoureLabels ?? cloudStorages.find(x => x.Id === defaultCloudPrinterForRetoureLabels)?.Id;
    }

    private _setPackageType(order: OrderToShip,
        packageTypes: PackageType[],
    ): void {
        const defaultShippingPackage = this.clientStorageService.get<number>("shipping.defaultPackageId");
        order.ShippingPackageId = packageTypes.find(x => x.Id === order.ShippingPackageId)?.Id
            ?? packageTypes.find(x => x.Id === defaultShippingPackage)?.Id
            ?? packageTypes.sort((a,b) => a.Priority - b.Priority)[0]?.Id
            ?? null;
    }

    private async _setProviderAndProduct(order: OrderToShip,
        shippingProviders: ShippingProvider[],
        presenter?: SingleShipPresenter,
    ): Promise<void> {
        const options = await this.shippingRepo.getShippingOptions();
        const defaultShippingProvider = this.clientStorageService.get<number>("shipping.defaultProviderId");
        const defaultProductId = options.DefaultShippingProductId;
        const shippingProvider = shippingProviders.find(x => x.Id === order.ShippingProviderId)
            ?? shippingProviders.find(x => x.Id === defaultShippingProvider)
            ?? shippingProviders.find(x => x.AttachedProducts.find(y => y.Id === defaultProductId) != null)
            ?? shippingProviders.find(_ => true);
        order.ShippingProviderId = shippingProvider?.Id;
        await this._preselectShippingProvider(order, shippingProvider, presenter, defaultProductId);
    }

    private async _preselectShippingProvider(order: OrderToShip,
        provider: ShippingProvider,
        presenter?: SingleShipPresenter,
        defaultProductId: number = null,
    ): Promise<void> {
        if (provider == null) {
            return;
        }

        await this.selectProviderUseCase.execute(new SelectShippingProviderRequest({
            Order: order,
            Provider: provider,
            DefaultShippingProductId: defaultProductId,
        }), presenter);
    }

    private _markPositionsAsPacked(order: OrderToShip): void {
        const packedPositions = this.clientStorageService.get<number[]>("shipping_station_packed_items", []);

        for (const item of order.OrderDetails) {
            item.IsPacked = packedPositions.includes(item.Id);
        }
    }

    private _setDefaults(
        order: OrderToShip,
        cloudStorages: CloudStorage[],
        packageTypes: PackageType[],
        providers: ShippingProvider[],
    ): void {
        order.CloudStorageId = cloudStorages.find(x => x.Id === order.CloudStorageId)?.Id;
        order.ShippingPackageId = packageTypes.find(x => x.Id === order.ShippingPackageId)?.Id;
        order.ShippingProduct = providers.find(x => x.AttachedProducts.find(y => y.Id == order.ShippingProductId))?.AttachedProducts.find(x => x.Id == order.ShippingProductId);
    }
}
