import {Injectable} from "@angular/core";
import {ComponentType} from "@angular/cdk/portal";
import {MatDialog} from "@angular/material/dialog";
import {Request2FaDialogComponent} from "@app/app/account/presentation/request-2fa-dialog/request2-fa-dialog.component";
import { Add2FaDialogComponent } from "@app/app/account/presentation/add-2fa-dialog/add2-fa-dialog.component";

@Injectable({providedIn: "root"})
export class OtpService implements IOtpService {
    constructor(
        private readonly matDialog: MatDialog,
    ) {
    }

    public requestAndVerifyOtp(): Promise<boolean> {
        return this._showDialog(Request2FaDialogComponent, false, {
            withVerify: true,
        });
    }

    public requestOtp(): Promise<string> {
        return this._showDialog(Request2FaDialogComponent, null, {
            withVerify: false,
        });
    }

    public showInitializeDialog(): Promise<boolean> {
        return this._showDialog(Add2FaDialogComponent, false);
    }

    private _showDialog<T, T2>(dialog: ComponentType<T>, defaultValue: T2, data: any = {}) {
        return new Promise<T2>(resolve => {
            const dialogRef = this.matDialog.open(dialog, {
                data,
                disableClose: true,
            });

            dialogRef.afterClosed().subscribe(result => {
                resolve(result ?? defaultValue);
            });
        });
    }
}
