import {CustomerMetaInformation} from "@app/app/customer/core";
import {FormModel} from "@mintware-de/form-builder";
import {HiddenField} from "@app/app/common/form/text-field/hidden-field.type";
import {TextField} from "@app/app/common/form/text-field/text-field.type";

export class EditMetaDataViewModel {
    public metaData: CustomerMetaInformation = null;
    public formModel: FormModel<CustomerMetaInformation> = {
        Id: new HiddenField(),
        Type: new HiddenField(),
        Name: new HiddenField(),
        SubType: new HiddenField(),
        Value: new TextField({
            label: "",
        }),
    };

}
