import {Injectable} from "@angular/core";
import {CloudDeviceEnum} from "@app/app/settings/cloud/core/enum";
import * as ngx from "@ngx-resource/core";
import {ResourceActionReturnType, ResourceRequestMethod} from "@ngx-resource/core";
import {Observable} from "rxjs";
import * as core from "../../core";
import {ConnectionTypeEnum} from "@shared/models/connection-type.enum";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/oauth",
})
export class OAuthService extends ngx.Resource implements core.OAuthService {

    @ngx.ResourceAction({
        path: "/{type}/{api}/auth",
        method: ResourceRequestMethod.Post,
        returnAs: ResourceActionReturnType.Observable,
    })
    private doGetAuthorizeUrl: ngx.IResourceMethodObservable<{ Id?: number, ClientState?: { [key: string]: string } }, string>;

    private getAuthUrlInternal(type: string,
                               api: string,
                               id?: number,
                               clientState?: { [key: string]: string }): Promise<string> {
        return this.getAuthUrlInternal$(type, api, id, clientState).toPromise();
    }

    private getAuthUrlInternal$(type: string,
                                api: string,
                                id?: number,
                                clientState?: { [key: string]: string }): Observable<string> {
        return this.doGetAuthorizeUrl({
            ClientState: clientState,
            Id: id,
        }, {}, {
            type: type,
            api: api,
        });
    }

    public getBookkeepingAuthorizeUrl(api: string,
                                      id?: number,
                                      clientState?: { [key: string]: string }): Observable<string> {
        return this.getAuthUrlInternal$(ConnectionTypeEnum.Bookkeeping, api, id, clientState);
    }

    public async getChannelAuthorizeUrl(api: string,
                                        id?: number,
                                        clientState?: { [key: string]: string }): Promise<string> {
        return this.getAuthUrlInternal(ConnectionTypeEnum.Channel, api, id, clientState);
    }

    public async getCloudDeviceAuthorizeUrl(api: CloudDeviceEnum,
                                            id: number = null): Promise<string> {
        return this.getAuthUrlInternal(ConnectionTypeEnum.Cloud, CloudDeviceEnum[api], id);

    }

    public async getPaymentAuthorizeUrl(api: string,
                                        id?: number,
                                        clientState?: { [key: string]: string }): Promise<string> {
        return this.getAuthUrlInternal(ConnectionTypeEnum.Payment, api, id, clientState);
    }

    public getShippingAuthorizeUrl(api: string,
                                   id: number = null,
                                   clientState?: { [key: string]: string }): Promise<string> {
        return this.getAuthUrlInternal(ConnectionTypeEnum.Shipping, api, id, clientState);
    }

    @ngx.ResourceAction({
        path: "/{type}/{api}/access-token",
    })
    private getAccessToken: ngx.IResourceMethod<{ type: string, api: string, code: string, state?: string }, core.AccessToken>;

    private _mapAccessToken(response: any): core.AccessToken {
        return new core.AccessToken({
            Token: response.access_token,
            TokenType: response.token_type,
            ExpiresIn: response.expires_in,
            RefreshToken: response.refresh_token,
            Scope: response.scope,
            UserId: response.user_id,
            ValidUntil: response.valid_until,
        });
    }

    public async getBookkeepingAccessToken(req: { api: string, code: string, state?: string }): Promise<core.AccessToken> {
        const response = await this.getAccessToken({
            type: ConnectionTypeEnum.Bookkeeping,
            ...req,
        });
        return this._mapAccessToken(response);
    }

    public async getChannelAccessToken(req: { api: string, code: string, config?: string, state?: string }): Promise<core.AccessToken> {
        const response = await this.getAccessToken({
            type: ConnectionTypeEnum.Channel,
            ...req,
        });
        return this._mapAccessToken(response);
    }

    public async getAccessTokenForCloudDevice(api: CloudDeviceEnum, code: string, state: string): Promise<core.AccessToken> {
        const rawToken = await this.getAccessToken({
            type: ConnectionTypeEnum.Cloud,
            api: CloudDeviceEnum[api],
            code,
            state,
        });
        return this._mapAccessToken(rawToken);
    }

    public async getPaymentAccessToken(req: { api: string, code: string, state?: string }): Promise<core.AccessToken> {
        const response = await this.getAccessToken({
            type: ConnectionTypeEnum.Payment,
            ...req,
        });
        return this._mapAccessToken(response);
    }

    public async getShippingAccessToken(req: { api: string, code: string, state?: string }): Promise<core.AccessToken> {
        const response = await this.getAccessToken({
            type: ConnectionTypeEnum.Shipping,
            ...req,
        });
        return this._mapAccessToken(response);
    }

    @ngx.ResourceAction({
        path: "/{type}/{api}/revoke/{id}",
        method: ResourceRequestMethod.Get,
        returnAs: ResourceActionReturnType.Observable,
    })
    private doRevokeAuthorization: ngx.IResourceMethodObservable<{type: string, api: string, id: number}, void>;

    public revokeBookkeepingAuthorization(api: string, id: number): Observable<void> {
        return this.doRevokeAuthorization({type: ConnectionTypeEnum.Bookkeeping, api, id});
    }

    public revokeChannelAuthorization(api: string, id: number): Observable<void> {
        return this.doRevokeAuthorization({type: ConnectionTypeEnum.Channel, api, id});
    }

    public revokeCloudDeviceAuthorization(api: string, id: number): Observable<void> {
        return this.doRevokeAuthorization({type: ConnectionTypeEnum.Cloud, api, id});
    }

    public revokePaymentAuthorization(api: string, id: number): Observable<void> {
        return this.doRevokeAuthorization({type: ConnectionTypeEnum.Payment, api, id});
    }

    public revokeShippingAuthorization(api: string, id: number): Observable<void> {
        return this.doRevokeAuthorization({type: ConnectionTypeEnum.Shipping, api, id});
    }

    @ngx.ResourceAction({
        path: "/verify-state",
        method: ResourceRequestMethod.Post,
    })
    public verifyState: ngx.IResourceMethod<{ type?: string, api?: string, state: string }, { [key: string]: string }>;

    @ngx.ResourceAction({
        path: "/patch-state",
        method: ResourceRequestMethod.Post,
    })
    public patchState: ngx.IResourceMethodObservable<{ stateKey: string, statePatch: { [key: string]: string }}, void>;

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }
}
