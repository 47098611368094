import {AbstractLayoutType, Constructor, IGroupTypeOptions} from "@mintware-de/form-builder";
import {PlainTextOptions} from "./plain-text.options";
import {PlainTextComponent} from "./plain-text.component";

export class PlainText extends AbstractLayoutType<PlainTextOptions & IGroupTypeOptions> {
    public readonly component: Constructor = PlainTextComponent;

    constructor(opts: PlainTextOptions) {
        super({
            model: {},
            interpolationParams: {},
            ...opts,
        });
    }

    public set text(text: string) {
        if (this.options.text !== text) {
            this.options.text = text;
        }
    }
}
