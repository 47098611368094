import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {RouterModule} from "@angular/router";
import {
    EmptyComponent,
    EmptyWithChildrenComponent,
    MissingRouteComponent,
} from "@app/app/common/component/empty.component";
import {MvcComponent} from "@app/app/common/component/mvc.component";
import {NamedContentComponent} from "@app/app/common/component/named-content/named-content.component";
import {IncludeDirective} from "@app/app/common/directive/include-host.directive";
import {SelectFontComponent} from "@app/app/common/directive/selectFont.component";
import {NavigationComponent} from "@app/app/common/navigation/navigation.component";
import {NotificationsItemComponent} from "@app/app/common/navigation/notifications-item/notifications-item.component";
import {ColorPickerModule} from "ngx-color-picker";
import {SignalrHandlerService, SnackbarComponent} from "@app/app/common/service/signalr-handler.service";
import {FormModule} from "@app/app/common/form/form.module";
import {SharedModule} from "@app/app/common/module/shared.module";
import {StatusItemComponent} from "@app/app/common/navigation/status-item/status-item.component";

@NgModule({
    declarations: [
        EmptyComponent,
        EmptyWithChildrenComponent,
        MissingRouteComponent,
        SelectFontComponent,
        NamedContentComponent,
        IncludeDirective,
        NavigationComponent,
        NotificationsItemComponent,
        SnackbarComponent,
        MvcComponent,
        StatusItemComponent,
    ],
    exports: [
        RouterModule,
        ColorPickerModule,
        CommonModule,
        FlexLayoutModule,
        FormModule,
        SharedModule,
        EmptyComponent,
        EmptyWithChildrenComponent,
        MissingRouteComponent,
        SelectFontComponent,
        NamedContentComponent,
        IncludeDirective,
        NavigationComponent,
        NotificationsItemComponent,
        SnackbarComponent,
        MvcComponent,
    ],
    imports: [
        RouterModule,
        ColorPickerModule,
        CommonModule,
        FlexLayoutModule,
        SharedModule,
        FormModule,
    ],
    providers: [
        SignalrHandlerService,
    ],
})
export class BbModule {
}
