import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {AuthDto} from "./auth.dto";

@Injectable({
	providedIn: "root",
})
export class AuthRepository {
	constructor(private readonly httpClient: HttpClient) {}

	getAuth(): Observable<AuthDto> {
		return this.httpClient.get<AuthDto>(`${environment.baseUrl}/auth`);
	}
}
