import {SelectFieldOptions} from "./select-field.options";
import {SelectField} from "@app/app/common/form/select-field/select-field.type";

export class CountryField extends SelectField {
    public static options: { [key: string]: string } = null;

    constructor(options: Omit<SelectFieldOptions, "options">) {
        super(Object.assign({
            expanded: false,
            options: CountryField.options,
        } as SelectFieldOptions, options));
    }

}
