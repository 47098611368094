import {Injectable} from "@angular/core";
import * as ngx from "@ngx-resource/core";
import {JavascriptSnippetContainer} from "../../model/javascript-snippet";

@Injectable({ providedIn: "root" })
@ngx.ResourceParams({
    pathPrefix: "/api/angular/jssnippet",
})
export class JavascriptSnippetService extends ngx.Resource {

    @ngx.ResourceAction({
        path: "/snippets",
    })
    public getJsSnippets: ngx.IResourceMethod<{}, JavascriptSnippetContainer>;

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }
}
