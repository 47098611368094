export class CustomerComment {
    public Id: number = null;
    public CreatedBy: string = "";
    public CreatedAt: Date = null;
    public Text: string = "";
    public IsInternalMessage: boolean = false;

    constructor(obj?: Partial<CustomerComment>) {
        ctor(this, obj, {
            NumberFields: ["Id"],
            DateFields: ["CreatedAt"],
        });
    }
}
