import {AbstractLayoutType, Constructor} from "@mintware-de/form-builder";
import {CardComponent} from "./card.component";
import {CardOptions} from "./card.options";

export class Card<T = any> extends AbstractLayoutType<CardOptions<T>, T> {
    public readonly component: Constructor = CardComponent;

    constructor(options: Omit<CardOptions<T>, "bodyModel">) {
        super({
            title: null,
            visible: true,
            bodyModel: options.model,
            footerModel: null,
            ...options,
            model: {
                ...options.model,
                ...options.footerModel,
            },
        });
    }

    public get visible(): boolean {
        return this.options.visible || true;
    }

    public set visible(value: boolean) {
        if (this.options.visible !== value) {
            this.options.visible = value;

            this.control?.updateValueAndValidity();
        }
    }

}
