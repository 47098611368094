<div fxFill fxLayout="column" *ngIf="viewModel.dependenciesLoaded">
    <mat-toolbar fxFlex="none">
        <mat-toolbar-row fxLayout="row" fxLayoutGap="8px">
            <button type="button" mat-icon-button (click)="sidebar.toggleVisibility()"
                    matBadge="!" [matBadgeHidden]="(sidebar.showSidebar | async) || !(sidebar.filtersApplied$ | async)"
                    [matBadgeOverlap]="true" matBadgeSize="small"
                    [matTooltip]="(sidebar.showSidebar.value ? 'tooltip.hide_filter' : 'tooltip.show_filter') | translate">
                <mat-icon fontIcon="fa-filter"></mat-icon>
            </button>
            <button type="button" mat-button [matMenuTriggerFor]="presetMenu">
                <ng-container *ngIf="sidebar.viewModel.preset == null; else presetNameTemplate">
                    {{ 'button.presets' | translate }}
                </ng-container>
                <ng-template #presetNameTemplate>
                    {{ sidebar.viewModel.preset.Name }}
                </ng-template>
            </button>
            <mat-divider [vertical]="true"            
                         [hidden]="!viewModel.canCreate && !viewModel.canMerge && !viewModel.canCreateOrder && !viewModel.canDelete"
                         style="height: 32px"></mat-divider>
            <button type="button"
                    mat-icon-button
                    routerLink="./add"
                    [hidden]="!viewModel.canCreate"
                    [matTooltip]="'tooltip.add_customer' | translate">
                <mat-icon fontIcon="fa-plus"></mat-icon>
            </button>
            <button type="button"
                    mat-icon-button
                    [disabled]="!(grid.multipleSelected | async)"
                    [hidden]="!viewModel.canMerge"
                    (click)="mergeCustomers(grid.selectedIds.value)"
                    [matTooltip]="'tooltip.merge_customers' | translate">
                <mat-icon fontIcon="fa-link"></mat-icon>
            </button>
            <button type="button"
                    mat-icon-button
                    [disabled]="!(grid.oneSelected | async)"
                    [hidden]="!viewModel.canCreateOrder"
                    [matTooltip]="'tooltip.create_order_for_customer' | translate"
                    (click)="onCreateOrderClicked(grid.selectedIds.value[0])">
                <mat-icon fontIcon="fa-cart-plus"></mat-icon>
            </button>
            <button type="button"
                    mat-icon-button
                    [matTooltip]="'tooltip.delete' | translate"
                    [hidden]="!viewModel.canDelete"
                    [disabled]="grid.noneSelected | async"
                    (click)="deleteCustomers(grid.selectedIds.value)">
                <mat-icon fontIcon="fa-trash"></mat-icon>
            </button>
            <mat-divider [vertical]="true"
                         [hidden]="!viewModel.canExport && !viewModel.canImport"
                         style="height: 32px" ></mat-divider>
            <button type="button"
                    mat-icon-button
                    (click)="onImportClicked()"
                    [hidden]="!viewModel.canImport"
                    [matTooltip]="'tooltip.import_customers' | translate">
                <mat-icon fontIcon="fa-cloud-download"></mat-icon>
            </button>
            <button type="button"
                    mat-icon-button
                    [hidden]="!viewModel.canExport"
                    (click)="onExportClicked(grid.selectedIds.value, sidebar.getCurrentPreset(null, false))"
                    [matTooltip]="'tooltip.export_customers' | translate">
                <mat-icon fontIcon="fa-cloud-upload"></mat-icon>
            </button>
            <mat-menu #presetMenu="matMenu">
                <button *ngFor="let preset of sidebar.presets$ | async; index as index"
                        type="button"
                        mat-menu-item
                        (click)="sidebar.applyPreset(preset)">
                    <mat-icon matListIcon fontIcon="fa-lock" *ngIf="preset.IsSystemPreset"></mat-icon>
                    {{ preset.Name }}
                </button>
            </mat-menu>
            <mat-divider [vertical]="true" class="toolbar-divider"></mat-divider>
            <mat-form-field *ngIf="sidebar && sidebar.viewModel && sidebar.viewModel.preset"
                            floatLabel="never"
                            class="search-field">
                <input type="text"
                       matInput
                       [placeholder]="'label.search_term' | translate"
                       [(ngModel)]="sidebar.viewModel.preset.SearchTerm"
                       (keyup.enter)="sidebar.searchTermChanged()">
            </mat-form-field>
            <span fxFlex></span>
            <span class="small-text">{{ 'text.records_found' | translate:{'count': viewModel.totalCount} }}</span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div fxFlex fxLayout="row">
        <div [fxHide]="!(sidebar.showSidebar | async)" fxFlex="20" class="mat-padding">
            <bb-grid-sidebar #sidebar
                             [grid]="grid"
                             [presetRepository]="customerRepo"
            ></bb-grid-sidebar>
        </div>
        <div [fxFlex]="(sidebar.showSidebar | async) ? 80 : 100">
            <bb-grid #grid
                     [options]="viewModel.gridOptions"
                     [repository]="customerRepo"
                     (totalCountChanged)="viewModel.totalCount = $event"
                     (rowClick)="openCustomer($event.Id)">
            </bb-grid>
        </div>
    </div>
</div>