import {AbstractLayoutComponent, AbstractLayoutType} from "@mintware-de/form-builder";
import {ComponentWrapperOptions} from "@app/app/common/form/layout/component-wrapper/component-wrapper.options";
import {AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ViewContainerRef} from "@angular/core";

@Component({
    selector: "bb-form-component-wrapper",
    template: ``,
})
export class ComponentWrapperComponent
    extends AbstractLayoutComponent<AbstractLayoutType<ComponentWrapperOptions<any>>>
    implements AfterViewInit {
    constructor(private readonly vcr: ViewContainerRef,
                cfr: ComponentFactoryResolver,
                cdr: ChangeDetectorRef,
    ) {
        super(cfr, cdr);
    }

    public ngAfterViewInit(): void {
        const factory = this.cfr.resolveComponentFactory(this.mwFieldType.options.component);
        const component = this.vcr.createComponent(factory);
        if (this.mwFieldType.options.inputs) {
            Object.keys(this.mwFieldType.options.inputs).forEach((k) => {
                component.instance[k] = this.mwFieldType.options.inputs[k];
            });
        }
        component.changeDetectorRef.detectChanges();
    }


}
