import { HttpClient } from "@angular/common/http";
import { Component, Inject, Input, ViewChild } from "@angular/core";
import {
    Button,
    Divider,
    ExpansionPanel,
    HiddenField,
    NumberField,
    RowLayout,
    Switch,
    TableCollection,
    TextField,
    Text,
    SelectField,
} from "@app/app/common/form/types";
import { Order, TestmailConfig } from "@app/app/common/model";
import { FormBuilderComponent, FormModel, IFormBuilder } from "@mintware-de/form-builder";
import { MessageTemplate } from "@app/app/settings/automation/core";
import { GeneralSettingsService } from "@app/app/common/service/da/general-settings.service";
import { ApiTestMailReceiverRepository } from "@app/app/customer/data/repository/api-testmail-receiver.repository";

@Component({
    selector: "bb-testmail-config",
    templateUrl: "testmail-config.component.html",
})
export class TestmailConfigComponent {
    @ViewChild("settingsForm")
    public settingsForm: FormBuilderComponent<TestmailConfig>;

    private receiverEmailAddress: SelectField;
    private replacePlaceholders: Switch;
    private searchTerm: TextField;
    private searchResultField: TableCollection<Order>;
    private expansionPanelField: ExpansionPanel;
    private orderIdField: NumberField;

    public formModel: FormModel<TestmailConfig>;
    public settings: TestmailConfig = new TestmailConfig();

    @Input() preSubmit: () => {
        template: MessageTemplate,
        currentLanguage: string,
    };

    constructor(
        private readonly http: HttpClient,
        @Inject("$alert") private alert: AlertService,
        @Inject("$translate") private $translate: ng.translate.ITranslateService,
        @Inject("accountResource") private accountResource: IAccountResource,
        private settingsService: GeneralSettingsService,
    ) {
        this._loadData();
        this.formModel = {
            _legacyOptions: this.expansionPanelField = new ExpansionPanel({
                title: "Test E-Mail",
                model: {
                    ReceiverEmailAddress: this.receiverEmailAddress = new SelectField({
                        label: "Senden an",
                        options: {},
                        dataProvider: ApiTestMailReceiverRepository,
                        hint: "Wird hier keine E-Mail Adresse angegeben, wird bei ausgewählter Bestellung die E-Mail aus " +
                            "der im Shop hinterlegten SMTP-Konfiguration verwendet, " +
                            "ansonsten die Standard SMTP-Konfiguration bzw. Billbee Account E-Mail-Adresse.",
                        required: true,
                    }),
                    _divider: new Divider(),
                    ReplacePlaceholders: this.replacePlaceholders = new Switch({
                        label: "Platzhalter ersetzen",
                    }),
                    OrderId: this.orderIdField = new NumberField({
                        label: "Order ID",
                        required: false,
                        visible: this.settings.OrderId != null,
                    }),
                    _row: new RowLayout({
                        flex: ["flex", "nogrow"],
                        model: {
                            SearchTerm: this.searchTerm = new TextField({
                                label: "Suchen nach Bestellnummer, Rechnungsnummer, Name, ...",
                                required: false,
                                onKeyUp: ($event) => {
                                    if ($event.key === "Enter") {
                                        this._triggerSearchOrders().then();
                                    }
                                },
                            }),
                            _button: new Button({
                                text: "Suchen",
                                type: "button",
                                onClick: () => {
                                    this._triggerSearchOrders().then();
                                },
                            }),
                        },
                    }),
                    SearchResult: this.searchResultField = new TableCollection<Order>({
                        allowAdd: false,
                        allowDelete: false,
                        noDataText: "text.no_order_searched",
                        selectMode: "single",
                        selectionChanged: (indexes, rows) => {
                            this.orderIdField.control.patchValue(rows[0].Id);
                        },
                        entrySettings: {
                            columns: [
                                {
                                    title: "",
                                    field: "Checked",
                                    content: new HiddenField(),
                                    isHidden: true,
                                },
                                {
                                    title: "Id",
                                    field: "Id",
                                    content: new Text({}),
                                },
                                {
                                    title: "Bestelldatum",
                                    field: "OrderDate",
                                    content: new Text({}),
                                },
                                {
                                    title: "Bestellnummer",
                                    field: "ExternalReference",
                                    content: new Text({}),
                                },
                                {
                                    title: "Rechnungsnummer",
                                    field: "FullInvoiceNumber",
                                    content: new Text({}),
                                },
                                {
                                    title: "Rechnungsdatum",
                                    field: "BillDate",
                                    content: new Text({}),
                                },
                            ],
                        },
                    }),
                    _rowSubmit: new RowLayout({
                        flex: ["nogrow"],
                        alignMainAxis: "end",
                        model: {
                            _submitButton: new Button({
                                text: "Test E-Mail senden",
                                appearance: "raised",
                                color: "primary",
                                type: "button",
                                onClick: () => this.sendTestMail(this.settingsForm.submit()),
                            }),
                        },
                    }),
                },
            }),
        };
    }

    public async searchOrders(): Promise<Order[]> {
        return await (this.http.post<Order[]>(
            "/api/angular/settings/setting/searchOrders",
            { queryString: this.searchTerm.control.value }).toPromise());
    }

    public async sendTestMail(formData: TestmailConfig): Promise<void> {
        if (formData == null || this.settingsForm.group.disabled) {
            return;
        }
        this.settings.ReplacePlaceholders = this.replacePlaceholders.control.value;
        this.settings.ReceiverEmailAddress = this.receiverEmailAddress.control.value;

        localStorage.setItem("testMailSettings", JSON.stringify({
            orderId: this.settings.OrderId,
            replacePlaceholders: this.settings.ReplacePlaceholders,
            receiverEmailAddress: this.settings.ReceiverEmailAddress,
        }));
        try {
            this.settingsForm.group.disable();

            const { template: messageTemplate, currentLanguage } = this.preSubmit();
            const config = new TestmailConfig({
                ...formData,
                CurrentLanguage: currentLanguage,
                MessageTemplate: messageTemplate,
            });

            const toMail = await this.settingsService.sendTestMail(config);
            const title = this.$translate.instant("Bitte Postfach überprüfen");
            const message = this.$translate.instant(`Test Email wird in Kürze an ${toMail} gesendet`);

            this.alert.success(title, message);
        } catch (e) {
            this.alert.failureFromHttpResult(e, "Ein Fehler ist aufgetreten");
        } finally {
            this.settingsForm.group.enable();
        }
    }

    private _loadData(): void {
        const testMailSettings = JSON.parse(localStorage.getItem("testMailSettings"));
        if (testMailSettings !== null) {
            this.settings.OrderId = testMailSettings.orderId;
            this.settings.ReplacePlaceholders = testMailSettings.replacePlaceholders;
            this.settings.ReceiverEmailAddress = testMailSettings.receiverEmailAddress;
        }
    }

    private async _triggerSearchOrders(): Promise<void> {
        try {
            const orders = await this.searchOrders();
            this.searchResultField.control.clear();
            for (const order of orders) {
                this.searchResultField.componentInstance.addEntry();
                this.searchResultField.control.get([this.searchResultField.control.length - 1]).patchValue(order);
            }
            this.settingsForm.group.get("OrderId").patchValue(null);
        } catch (error) {
            this.alert.failureFromHttpResult(
                error,
                this.$translate.instant("Fehler beim Suchen von Bestellungen"),
            );
        }
    }
}
