import {ShippingProductService} from "../shipping-product-service";

export class DayOfDeliveryService extends ShippingProductService {
    public DayOfDelivery: Date = null;

    constructor(obj?: Partial<DayOfDeliveryService>) {
        super(obj);
        ctor(this, obj, {
            DateFields: ["DayOfDelivery"],
        });
    }
}
