import {Pipe, PipeTransform} from "@angular/core";
import {Label, LabelPart} from "@app/app/common/form/base/base.options";
import {TranslationService} from "@bb-core/service";
import {TranslationKey} from "@bb-core/entity";

@Pipe({
    name: "translateLabel",
})
export class TranslateLabelPipe implements PipeTransform {

    constructor(private readonly translator: TranslationService) {
    }

    public transform(label: Label, params?: { [key: string]: string | number | Date }): string {
        if (Array.isArray(label)) {
            return LabelPart.combineHtml(label.map((lp: LabelPart) => new LabelPart({
                ...lp,
                text: lp.text ? this.translator.translate(lp.text as TranslationKey, params) : "",
            })));
        } else {
            return label ? this.translator.translate(label as TranslationKey, params) : "";
        }
    }

}
