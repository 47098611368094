import * as core from "@app/app/oauth/core";
import { CloudDeviceEnum } from "@app/app/settings/cloud/core/enum";
import { BookkeepingPartnerKey } from "@partners/bookkeeping/model/bookkeeping-partner-key";
import { Observable } from "rxjs";

export abstract class OAuthService {

    public abstract getShippingAuthorizeUrl(
        api: string,
        id: number,
        clientState?: { [key: string]: string },
    ): Promise<string>;

    public abstract getChannelAuthorizeUrl(
        api: string,
        id?: number,
        clientState?: { [key: string]: string },
    ): Promise<string>;

    public abstract getPaymentAuthorizeUrl(
        api: string,
        id?: number,
        clientState?: { [key: string]: string },
    ): Promise<string>;

    public abstract getShippingAccessToken(
        req: { api: string, code: string, state?: string },
    ): Promise<core.AccessToken>;

    public abstract getPaymentAccessToken(
        req: { api: string, code: string, state?: string },
    ): Promise<core.AccessToken>;

    public abstract getChannelAccessToken(
        req: { api: string, code: string, config?: string, state?: string },
    ): Promise<core.AccessToken>;

    public abstract getAccessTokenForCloudDevice(
        api: CloudDeviceEnum,
        code: string,
        state: string,
    ): Promise<core.AccessToken>;

    public abstract getCloudDeviceAuthorizeUrl(
        api: CloudDeviceEnum,
        id: number,
    ): Promise<string>;

    public abstract getBookkeepingAuthorizeUrl(
        api: BookkeepingPartnerKey,
        id?: number,
        clientState?: { [key: string]: string },
    ): Observable<string>;

    public abstract getBookkeepingAccessToken(
        req: { api: string, code: string, state?: string },
    ): Promise<core.AccessToken>;
	
	public abstract revokeBookkeepingAuthorization(api: BookkeepingPartnerKey, id: number): Observable<void>;

    public abstract verifyState(
        obj: { type?: string, api?: string, state: string },
    ): Promise<{ [key: string]: string }>;

    public abstract patchState(
        obj: { stateKey: string, statePatch: {[key: string]: string} },
    ): Observable<void>;
}
