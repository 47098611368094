import {ChangeDetectionStrategy, Component} from "@angular/core";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantIsOverseasInvoiceNetCorrectComponent} from "../is-overseas-invoice-net-correct/is-overseas-invoice-net-correct.component";
import {OnboardingAssistantUseIglTaskComponent} from "../use-igl/use-igl.component";

@Component({
    selector: "bb-onboarding-assistant-overseas-invoice-net",
    templateUrl: "./overseas-invoice-net.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantOverseasInvoiceNetComponent {
    constructor(private readonly onboardingStore: OnboardingAssistantComponentStore) {}

    public next(detectThirdCountry: boolean): void {
        this.onboardingStore.setData({detectThirdCountry});
        this.onboardingStore.nextTask(
            detectThirdCountry
                ? OnboardingAssistantIsOverseasInvoiceNetCorrectComponent
                : OnboardingAssistantUseIglTaskComponent,
        );
    }
}
