import {AbstractType} from "@mintware-de/form-builder";
import {BaseOptions} from "./base.options";
import {ValidatorFn, Validators} from "@angular/forms";

export abstract class BaseType<T extends BaseOptions> extends AbstractType<T> {

    public autoRebuild: boolean = true;

    protected constructor(options: T) {
        super(Object.assign({
            required: true,
            visible: true,
        } as T, options));
    }

    public get validators(): ValidatorFn[] {
        const validators = this.buildValidators();

        if (this.options == null) {
            return validators;
        }

        if (this.options.required === true) {
            validators.push(Validators.required);
        }

        return validators;
    }

    protected buildValidators(): ValidatorFn[] {
        return [];
    }

    public get disabled(): boolean {
        return this.options.disabled || false;
    }

    public set disabled(value: boolean) {
        const needsRebuild = value !== this.disabled;
        this.options.disabled = value;

        if (needsRebuild) {
            if (this.control != null) {
                if (value) {
                    this.control.disable();
                } else {
                    this.control.enable();
                }
            } else if (this.builderInstance != null) {
                this.rebuildForm();
            }
        }
    }

    public get visible(): boolean {
        return this.options.visible || true;
    }

    public set visible(value: boolean) {
        if (this.options.visible !== value) {
            this.options.visible = value;

            this.control?.updateValueAndValidity();
        }
    }

    public set required(required: boolean) {
        if (this.options.required !== required) {
            this.options.required = required;
            this.builderInstance.rebuildForm();
        }
    }

    public get required(): boolean {
        return this.options.required || false;
    }

    protected rebuildForm(force: boolean = false): void {
        if (!this.autoRebuild && !force) {
            return;
        }

        this.autoRebuild = true;

        if (!this.builderInstance) {
            return;
        }

        this.builderInstance.rebuildForm();
    }
}
