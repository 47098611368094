<bb-list-item-card
    [imgSrc]="shop.logo || null"
    [border]="null"
    (onClick)="cardClicked.emit()"
>
    <div content>
        <h6>
            {{
                (shop.type === ShopType.Marketplace
                    ? "label.marketplace"
                    : shop.type === ShopType.Shop
                    ? "label.shop"
                    : "label.others"
                ) | translate
            }}
        </h6>
        <h4>{{ shop.name }}</h4>
    </div>
</bb-list-item-card>
