import {inject, Injectable} from "@angular/core";
import {from, Observable, switchMap} from "rxjs";
import {PopupType} from "../../../../shared/model/popup-type.enum";
import {PopupService} from "../../../../shared/service/popup.service";
import {ShopViewModelDto} from "../../data/shop-view-model.dto";
import {ShopsRepository} from "../../data/shops.repository";
import {Shop} from "../../model/shop";
import {ShopOauthPopupMessage} from "../../model/shop-oauth-popup-message";
import {ShopType} from "../../model/shop-type.enum";
import {OauthShopAddCommand} from "../../service/command/oauth-shop-add.command";
import {ShopsService} from "../../service/shops.service";

@Injectable({
    providedIn: "root",
})
export class EbayShop implements Shop {
    public readonly key = "ebay";
    public readonly name = "eBay";
    public readonly type = ShopType.Marketplace;
	public readonly logo = `assets/images/logo/shops/${this.key}.svg`;
	public readonly featured = true;


    private readonly repository = inject(ShopsRepository);
	private readonly addCommand = inject(OauthShopAddCommand);
	private readonly shopsService = inject(ShopsService);
	private readonly popupService = inject(PopupService);

    add(): Observable<never> {
        return this.addCommand.execute(from(this.repository.getAuthorizeUrl(this.key)), this.key, this.logo);
    }

	reAuth(shop: ShopViewModelDto): Observable<ShopViewModelDto> {
		return this.shopsService
            .showShopLoadingDialogForRequest(
                from(this.repository.getAuthorizeUrl(this.key, {shopId: shop.Id})),
                this.logo,
                "title.reauth_shop",
                "text.fetching_partner_url",
            )
            .pipe(
                switchMap((url) =>
                    this.shopsService.showShopLoadingDialogForRequest(
                        this.popupService.getMessage<ShopOauthPopupMessage>(url, PopupType.ShopReAuth),
                        this.logo,
                        "title.reauth_shop",
                        "text.add_shop_description",
                    ),
                ),
                switchMap(({SessionId, AccountId}) => {
                    const req$ = from(
                        this.repository.getAuth(this.key, {sessionId: SessionId, accountId: AccountId}),
                    );

                    return this.shopsService.showShopLoadingDialogForRequest(
                        req$,
                        this.logo,
                        "title.reauth_shop",
                        "text.fetching_shop_auth",
					);
				}),
                switchMap(({AccessToken}) =>
                    this.shopsService.showShopLoadingDialogForRequest(
                        this.repository.updateShop({...shop, EbayAccessToken: AccessToken}),
                        this.logo,
                        "title.reauth_shop",
                        "text.updating_shop",
                    ),
                ),
            );
    }
}
