/** Describes a service to store data on client side */
import {Injectable} from "@angular/core";
import {ClientStorage} from "@bb-core/storage";

@Injectable({providedIn: "root"})
export class ClientStorageService {

    constructor(private readonly clientStorage: ClientStorage) {
    }

    public get<T>(key: string, fallback: T = null, type: new (obj: Partial<T>) => T = null): T {
        const fromStorage = this.clientStorage.get(key);
        const plain = (fromStorage != null ? JSON.parse(fromStorage) : fallback) as T;
        if (type == null) {
            return plain;
        }
        return new type(plain);
    }

    public set<T>(key: string, value: T): void {
        const json = value != null ? JSON.stringify(value) : null;
        this.clientStorage.set(key, json);
    }
}
