import {AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component} from "@angular/core";
import {AbstractFormFieldComponent, AbstractType} from "@mintware-de/form-builder";
import {TextOptions} from "./text.options";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "bb-form-text",
    template: `<span [innerHTML]="calculatedValue | translate"></span>`,
})
export class TextComponent extends AbstractFormFieldComponent<AbstractType<TextOptions>> implements AfterContentInit {

    public calculatedValue: string;

    constructor(private readonly cdr: ChangeDetectorRef) {
        super();
    }

    public ngAfterContentInit(): void {
        this.updateCalculatedValue();
        this.mwElement.valueChanges.subscribe(() => {
            this.updateCalculatedValue();
            this.cdr.detectChanges();
        });
    }

    private updateCalculatedValue(): void {
        this.calculatedValue = this.mwFieldType.options.builder(this.mwElement.value);
    }
}
