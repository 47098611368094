import {FormModel} from "@mintware-de/form-builder";
import {CustomerSource} from "@app/app/customer/core";
import {HiddenField, SelectField, TextField} from "@app/app/common/form/types";



export class EditCustomerSourceViewModel {
    public selectField: SelectField = new SelectField({
        label: "label.source",
        options: {
        },
    });
    public formModel: FormModel<CustomerSource> = {
        Id: new HiddenField(),
        Source: this.selectField,
        SourceId: new TextField({
            label: "label.source_id",
            tooltip: "tooltip.source_id",
        }),
        ApiAccountName: new HiddenField(),
        ApiAccountId: new HiddenField(),
    };
    public source: CustomerSource = new CustomerSource();
}
