import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {OAuthService} from "@app/app/oauth/infrastructure";
import {Subscription} from "rxjs";
import {PopupType} from "../shared/model/popup-type.enum";
import {PopupService} from "../shared/service/popup.service";

@Component({
    template: "",
})
export class CallbackComponent implements OnInit, OnDestroy {

    private paramsSubscription: Subscription;
    private queryParamsSubscription: Subscription;
    
    private additionalParams = [
        {paramName: "id_token", stateKey: "openid_id_token"},
        {paramName: "error", stateKey: "openid_error"},
    ];

    constructor(private route: ActivatedRoute, private router: Router,
		private readonly oauthService: OAuthService,
				private readonly popupService: PopupService,
    ) {

    }

    public ngOnInit(): void {
        this.getParameters();
    }

    private async getParameters(): Promise<any> {
        const params = await Promise.all<any>([
            new Promise<any>((resolve) => {
                this.paramsSubscription = this.route.params.subscribe((p) => resolve(p));
            }),
            new Promise<any>((resolve) => {
                this.queryParamsSubscription = this.route.queryParams.subscribe((p) => resolve(p));
            }),
        ]);

        const allParams = Object.assign({}, ...params);
        this.handleCustomParameters(allParams);
        let oAuthState: any = {};
        //Amazon liefert auch einen "state" parameter der aber nichts mit dem hier gemeinten state zu tun hat.
        if (("state" in allParams && allParams.api != "amazonpay") || allParams.api == "UpsRest") {

            const objState = await this.oauthService.verifyState({api: allParams.api, type: allParams.type, state: allParams.state});

            if (objState != null) {
                oAuthState = objState;
            } else {
                let state = allParams.state;
                if (state.includes("_")) {
                    state = state.replace(/_/g, "=");
                }
                try {
                    const decoded = JSON.parse(atob(state));
                    if (decoded != null && typeof decoded === "object") {
                        oAuthState = decoded;
                    }
                } catch (e) {
                    // ignored
                }
            }
        }
        const oAuthStateString = encodeURIComponent(btoa(JSON.stringify(oAuthState)));

        let targetUrl = "/";
        if (allParams.type === "shipping") {
            if ("id" in oAuthState) {
                targetUrl = `/settings/shipping/provider/${oAuthState.id}?type=${allParams.api}&code=${allParams.code}`;
            } else {
                targetUrl = `/settings/shipping/provider/add/${allParams.api}?code=${allParams.code}`;
            }
            if ("shop" in allParams) {
                targetUrl += `&shop=${allParams.shop}`;
            }
            targetUrl += `&state=${allParams.state}`;

		} else if (allParams.type === "channel") {
			if ("id" in oAuthState || ("AccountId" in allParams && allParams.AccountId !== "0")) {
				this.popupService.emitMessage(PopupType.ShopReAuth, allParams);
                targetUrl = `/settings/shops/${oAuthState.id}?code=${allParams.code}`;
			} else {
				this.popupService.emitMessage(PopupType.ConnectShopOauth, allParams);
				targetUrl = `/settings/shops/oauth/add/${allParams.api}?code=${allParams.code}`;
				if ("shop" in allParams) {
					targetUrl += `&shop=${allParams.shop}`;
				}
            }
            targetUrl += `&state=${allParams.state}`;
        } else if (allParams.type === "cloud") {
            if (allParams.code == null || allParams.code.trim() == "") {
                targetUrl = "/settings/cloud/devices";
            } else {
                if ("id" in oAuthState) {
                    targetUrl = `/settings/cloud/devices/${oAuthState.id}?type=${allParams.api}&code=${allParams.code}`;
                } else {
                    targetUrl = `/settings/cloud/devices/add/${allParams.api}?code=${allParams.code}`;
                }
                targetUrl += `&state=${allParams.state}`;
            }
        } else if (allParams.type === "payment") {
            if ("id" in oAuthState) {
                targetUrl = `/settings/payment-import/edit/${oAuthState.id}?code=${allParams.code}`;
            } else {
                targetUrl = `/settings/payment-import/edit/new?type=${allParams.api}&code=${allParams.code}`;
                if ("shop" in allParams) {
                    targetUrl += `&shop=${allParams.shop}`;
                }
            }
            targetUrl += `&state=${allParams.state}`;
        } else if (allParams.type === "bookkeeping") {
            if ("id" in oAuthState) {
                targetUrl = `/settings/bookkeeping/${oAuthState.id}?code=${allParams.code}`;
            } else {
                targetUrl = `/settings/bookkeeping/add/${allParams.api}?code=${allParams.code}`;
            }
            targetUrl += `&state=${allParams.state}`;
            
        }

        // Store all additional parameters (non-OAuth-standard/extensions) in state
        this.patchStateWithAdditionalParams(allParams);

        await this.router.navigateByUrl(targetUrl);
    }

    private handleCustomParameters(allParams): void {
        if (allParams.api == "amazon" || allParams.api == "AmazonFBA" || allParams.api == "AMZ_MWS_Merchant") {
            if ("spapi_oauth_code" in allParams && !("code" in allParams)) {
                allParams.code = allParams.spapi_oauth_code;
            }
            if ("selling_partner_id" in allParams && !("shop" in allParams)) {
                allParams.shop = allParams.selling_partner_id;
            }
        }
    }

    private patchStateWithAdditionalParams(allParams) {

        if (!("state" in allParams)) {
            return;
        }
        
        const stateKey = allParams.state;
        const statePatch = {};
        
        this.additionalParams.forEach(param => {
            if (param.paramName in allParams) {
                statePatch[param.stateKey] = allParams[param.paramName];
            }
        });
        
        if (Object.keys(statePatch).length <= 0) {
            return;
        }

        this.oauthService.patchState({
            "stateKey": stateKey,
            "statePatch": statePatch,
        });
    }

    public ngOnDestroy(): void {
        if (this.paramsSubscription != null) {
            this.paramsSubscription.unsubscribe();
        }
        if (this.queryParamsSubscription != null) {
            this.queryParamsSubscription.unsubscribe();
        }
    }
}
