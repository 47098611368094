import {ChangeDetectionStrategy, Component} from "@angular/core";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantShippingOverseasTaskComponent} from "../shipping-overseas/shipping-overseas.component";

@Component({
    selector: "bb-onboarding-assistant-vat-mode",
    templateUrl: "./vat-mode.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantVatModeTaskComponent {
    
    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
    ) {}

	public next(vatMode: number): void {
		this.onboardingStore.setData({vatMode});
		this.onboardingStore.nextTask(OnboardingAssistantShippingOverseasTaskComponent);
    }
}
