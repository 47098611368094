import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TranslateModule} from "@ngx-translate/core";
import {BbDatePickerComponent} from "../../shared/ui/form/bb-datepicker/bb-datepicker.component";
import {BbInputComponent} from "../../shared/ui/form/bb-input/bb-input.component";
import {BbSelectComponent} from "../../shared/ui/form/bb-select/bb-select.component";
import {OnboardingAssistantBeforeSavingTaskComponent} from "../tasks/before-saving/before-saving.component";
import {OnboardingAssistantEnterFurtherCountriesVatIdsTaskComponent} from "../tasks/enter-further-country-vat-ids/enter-further-country-vat-ids.component";
import {OnboardingAssistantFurtherCountriesVatIdsTaskComponent} from "../tasks/further-country-vat-ids/further-country-vat-ids.component";
import {OnboardingAssistantIglNetCorrectTaskComponent} from "../tasks/igl-net-correct/igl-net-correct.component";
import {OnboardingAssistantIsOverseasInvoiceNetCorrectComponent} from "../tasks/is-overseas-invoice-net-correct/is-overseas-invoice-net-correct.component";
import {OnboardingAssistantOverseasInvoiceNetComponent} from "../tasks/overseas-invoice-net/overseas-invoice-net.component";
import {OnboardingAssistantSavingTaskComponent} from "../tasks/saving/saving.component";
import {OnboardingAssistantShippingOverseasTaskComponent} from "../tasks/shipping-overseas/shipping-overseas.component";
import {OnboardingAssistantTaxRateTargetCountryTaskComponent} from "../tasks/tax-rate-target-country/tax-rate-target-country.component";
import {OnboardingAssistantUseIglTaskComponent} from "../tasks/use-igl/use-igl.component";
import {OnboardingAssistantUseOssTaskComponent} from "../tasks/use-oss/use-oss.component";
import {OnboardingAssistantVatIdTaskComponent} from "../tasks/vat-id/vat-id.component";
import {OnboardingAssistantVatModeTaskComponent} from "../tasks/vat-mode/vat-mode.component";
import {ONBOARDING_ASSISTANT_STEPS} from "./steps.token";

const STEP_TASK_COMPONENTS = [
    OnboardingAssistantVatIdTaskComponent,
    OnboardingAssistantVatModeTaskComponent,
    OnboardingAssistantShippingOverseasTaskComponent,
    OnboardingAssistantFurtherCountriesVatIdsTaskComponent,
    OnboardingAssistantEnterFurtherCountriesVatIdsTaskComponent,
    OnboardingAssistantTaxRateTargetCountryTaskComponent,
    OnboardingAssistantUseOssTaskComponent,
    OnboardingAssistantOverseasInvoiceNetComponent,
    OnboardingAssistantIsOverseasInvoiceNetCorrectComponent,
    OnboardingAssistantUseIglTaskComponent,
    OnboardingAssistantIglNetCorrectTaskComponent,
    OnboardingAssistantBeforeSavingTaskComponent,
    OnboardingAssistantSavingTaskComponent,
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        BbInputComponent,
        MatButtonModule,
        BbSelectComponent,
		ReactiveFormsModule,
		MatIconModule,
		MatProgressSpinnerModule,
		BbDatePickerComponent,
    ],
    declarations: STEP_TASK_COMPONENTS,
    providers: [
        {
            provide: ONBOARDING_ASSISTANT_STEPS,
            useValue: {
                title: "title.tax_data",
                subtitle: "text.tax_data_description",
                tasks: STEP_TASK_COMPONENTS,
            },
            multi: true,
        },
    ],
})
export class OnboardingAssistantTaxDataStepModule {}
