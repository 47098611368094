/* eslint-disable */
import {PresetRepository, GridRepository, Preset} from "@app/app/grid/core";
import {
    Customer,
    CustomerAddress,
    CustomerExportSettings,
    CustomerImportSettings,
    CustomerMetaInformation,
    FileFormat,
} from "../entity";

/**
 * @deprecated use CustomerRepository in same directory and extend it if necessary
 */
export abstract class LegacyCustomerRepository extends GridRepository<Customer> implements PresetRepository<Customer> {
    public abstract get uniqueIdentifier(): string;

    /** Load a single customer by its id. */
    public abstract getCustomerById(id: number): Promise<Customer>;

    /** Load the the addresses for a customer by its id. */
    public abstract getAddresses(customerId: number): Promise<CustomerAddress[]>;

    /** Create a new customer or update an existing customer. */
    public abstract save(customer: Customer): Promise<Customer>;

    /** Delete selected customers. */
    public abstract deleteCustomers(customerIds: number[]): Promise<void>;

    public abstract getPresets(): Promise<Preset<Customer>[]>;

    public abstract savePresets(filters: Preset<Customer>[]): Promise<Preset<Customer>[]>;

    public abstract getFileFormats(): Promise<FileFormat[]>;

    public abstract startExport(settings: CustomerExportSettings): Promise<void>;

    public abstract startImport(exportSettings: CustomerImportSettings): Promise<void>;

    public abstract getMultipleCustomers(customerIds: number[]): Promise<Customer[]>;

    public abstract mergeCustomers(customerIdsToMerge: number[],
                                   targetCustomer: Customer,
    ): Promise<void>;

    public abstract updateMetaDataValue(metaData: CustomerMetaInformation,
    ): Promise<CustomerMetaInformation>;
}
