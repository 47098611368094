import {ChangeDetectionStrategy, Component} from "@angular/core";
import {AbstractLayoutComponent, AbstractLayoutType, IGroupTypeOptions} from "@mintware-de/form-builder";
import {DividerOptions} from "./divider.options";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "[bb-form-divider]",
    template: `<div [ngClass]="{'padding-v': mwFieldType.options.withPadding}"><mat-divider [vertical]="mwFieldType.options.vertical"></mat-divider></div>`,
})
export class DividerComponent extends AbstractLayoutComponent<AbstractLayoutType<DividerOptions & IGroupTypeOptions>> {
}
