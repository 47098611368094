import {Injectable} from "@angular/core";
import {Message, MessagingService, NavigationService, TranslationService} from "@bb-core/service";
import {SearchOrderResult} from "../entity";
import {ShipmentRepository} from "../repository";

export class SearchOrdersRequest {
    public Term: string = null;

    public constructor(obj?: Partial<SearchOrdersRequest>) {
        ctor(this, obj);
    }
}

export abstract class SearchOrdersPresenter {
    /** Is called when the search returns no result */
    public abstract displayEmptyResult(): void;

    /** Is called when the search returns a non-empty result */
    public abstract displayResult(result: SearchOrderResult[]): void;

    /** Is called when the loading state was changed. */
    public abstract setLoadingState(isLoading: boolean): void;
}

@Injectable({providedIn: "root"})
export class SearchOrdersUseCase implements IUseCase<SearchOrdersRequest, SearchOrdersPresenter> {

    constructor(private readonly shipmentRepository: ShipmentRepository,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
                private readonly navigationService: NavigationService,
    ) {
    }

    public async execute(request: SearchOrdersRequest,
                         presenter?: SearchOrdersPresenter,
    ): Promise<void> {
        if (!this.validateRequest(request)) {
            return;
        }

        const searchResult = await this.findOrders(request, presenter);
        if (searchResult.length === 0) {
            presenter?.displayEmptyResult();
        } else {
            if (searchResult.length === 1) {
                await this.navigationService.navigateToShipSingle(searchResult[0].OrderId);
            }
            presenter?.displayResult(searchResult);
        }
    }

    private async findOrders(request: SearchOrdersRequest,
                             presenter?: SearchOrdersPresenter): Promise<SearchOrderResult[]> {
        try {
            presenter?.setLoadingState(true);
            return await this.shipmentRepository.searchOrders(request.Term);
        } catch (e) {
            const title = this.translator.translate("title.search_failed");
            const message = this.translator.translate("text.error_with_message", {
                "message": e.message ?? "",
            });
            await this.messaging.showError(Message.blocking({title, message}));

            return [];
        } finally {
            presenter?.setLoadingState(false);
        }
    }

    private validateRequest(request: SearchOrdersRequest,
    ): boolean {
        if (request.Term == null || request.Term.trim() === "") {
            const message = this.translator.translate("flash.enter_search_term");
            this.messaging.showError(Message.transient({message})).then();
            return false;
        }
        return true;
    }
}
