<mat-card>
    <bb-loading-card *ngIf="isLoading$ | async"></bb-loading-card>
    <form [formGroup]="formGroup">
        <bb-slide-toggle
            formControlName="UseEmailForLookup"
            [label]="'label.use_email_for_lookup' | translate"
        ></bb-slide-toggle>
        <bb-slide-toggle
            formControlName="UseAddressForLookup"
            [label]="'label.use_address_for_lookup' | translate"
        ></bb-slide-toggle>
        <bb-select
            formControlName="ImportInternalNoteTarget"
            [label]="'label.internal_note_only_for_me' | translate"
            [options]="internalNoteTargetOptions"
        ></bb-select>
        <bb-slide-toggle
            formControlName="AddAutomaticPaymentOnImportPayed"
            [label]="'label.add_automatic_payment_on_imported_payed' | translate"
            [matTooltip]="'tooltip.add_automatic_payment_on_imported_payed' | translate"
        ></bb-slide-toggle>
        <bb-slide-toggle
            formControlName="ParseInternalNoteForTags"
            [label]="'label.read_tags' | translate"
            [matTooltip]="'tooltip.parse_internal_note_for_tags' | translate"
        ></bb-slide-toggle>
        <bb-slide-toggle
            formControlName="AutoConvertOrdersWhileImporting"
            [label]="'label.auto_recalc_orders' | translate"
            [matTooltip]="'tooltip.auto_convert_orders_while_importing' | translate"
        ></bb-slide-toggle>
    </form>
    <mat-card-actions align="end">
        <button mat-raised-button color="primary" type="button" (click)="saveSettings()">
            {{ "button.save" | translate }}
        </button>
    </mat-card-actions>
</mat-card>
