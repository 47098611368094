import {Component, Inject} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";

@Component({
    selector: "bb-snackbar-container",
    template: `<div [innerHtml]="data"></div>`,
})
export class SnackbarContainerComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) { }
}
