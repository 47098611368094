export class ColorRule {
    public Id: number;
    public Conditions: ColorRuleCondition[];
    public Color: string;
}

export class ColorRuleCondition {
    public Id: number;
    public ConditionType: string;
    public Parameter1: string;
}
