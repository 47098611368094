import {
    AfterViewInit,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import {
    BB_GRID_OPTIONS,
    BB_GRID_REPO,
    ColumnFilter,
    ColumnSort,
    FilterOperation,
    GridOptions,
    GridRepository,
    NumberFilterOperation,
    StringFilterOperation,
} from "../core";
import {BbApiStore} from "@app/app/grid/data/bb-api.store";
import {DxDataGridComponent} from "devextreme-angular";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: "bb-grid",
    templateUrl: "bb-grid.component.html",
})
export class BbGridComponent<T = any> implements OnInit, OnChanges, AfterViewInit {

    @Input()
    public options: GridOptions<T>;

    @Input()
    public repository: GridRepository<T>;

    @Output()
    public onOptionsChanged: EventEmitter<GridOptions> = new EventEmitter<GridOptions>();

    @Output()
    public totalCountChanged: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    public rowClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onSelectionChange: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("grid", {static: false})
    public grid: DxDataGridComponent;

    public selectedIds: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
    public anySelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public noneSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public multipleSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public oneSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public dataSource: BbApiStore;
    public selectedKeys: number[] = [];
    public clickEdit: (e: any) => void;

    private filterOperationMap: { [key: string]: FilterOperation | StringFilterOperation | NumberFilterOperation } = {
        "=": "Equals",
        "<": "Lt",
        ">": "Gt",
        "<=": "LtE",
        ">=": "GtE",
        "between": "Between",
        "contains": "Contains",
        "<>": "NotEquals",
        "notcontains": "NotContains",
        "startswith": "StartsWith",
        "endswith": "EndsWith",
    };
    private viewIsInitialized: boolean = false;
    private isRefreshing: boolean = false;

    constructor(private readonly injector: Injector,
    ) {
        this.clickEdit = (e) => this.rowClick.emit(e.row.data);
    }

    // region Lifecycle hooks
    public ngOnInit(): void {
        const injector = Injector.create({
            providers: [
                {provide: BB_GRID_REPO, useValue: this.repository},
                {provide: BB_GRID_OPTIONS, useValue: this.options},
            ],
            parent: this.injector,
        });
        this.dataSource = injector.get(BbApiStore);
        this.dataSource.totalCount$.subscribe((c) => this.totalCountChanged.emit(c));
    }

    public ngAfterViewInit(): void {
        this.viewIsInitialized = true;
        this.updateColumns().then();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ("options" in changes) {
            this.updateColumns().then();
        }
    }

    public async refresh(): Promise<void> {
        if (!this.viewIsInitialized || this.isRefreshing) {
            return;
        }
        this.isRefreshing = true;
        try {
            await this.grid.instance.refresh();
            this.onSelectionChanged();
        } finally {
            this.isRefreshing = false;
        }
    }

    public async updateColumns(): Promise<void> {
        if (!(this.grid && this.grid.instance)) {
            return;
        }
        const reversedMap = Object.keys(this.filterOperationMap).reduce((r, key) => ({
            ...r,
            [this.filterOperationMap[key]]: key,
        }), {});
        this.beginGridUpdate();
        for (const col of this.options.Columns) {
            const colName = col.Name as string;

            let filterValue: any = null;
            let filterOperation: any = null;
            if (col.Filter != null) {
                filterValue = col.Filter.Value;
                filterOperation = reversedMap[col.Filter.Operation];
            }

            let sortIndex = null;
            let sortOrder = null;
            if (col.Sort != null) {
                sortIndex = col.Sort.Index;
                sortOrder = col.Sort.Direction === "Desc" ? "desc" : "asc";
            }

            const currentOptions = this.grid.instance.columnOption(colName);
            const options: any = {};
            options.sortIndex = sortIndex;
            options.sortOrder = sortOrder;
            options.visibleIndex = col.VisibleIndex != null ? col.VisibleIndex + 1 : null;
            options.width = col.Width;
            options.filterValue = filterValue;
            options.selectedFilterOperation = filterOperation;
            for (const k of Object.keys(options)) {
                if (currentOptions[k] == options[k]) {
                    delete options[k];
                }
            }
            if (Object.keys(options).length > 0) {
                this.grid.instance.columnOption(colName, options);
            }
        }
        this.updateGridOptions(true);
        this.endGridUpdate();
    }

    private isUpdating: boolean = false;

    private endGridUpdate(): void {
        this.isUpdating = false;
        this.grid.instance.endUpdate();
    }

    private beginGridUpdate(): void {
        this.isUpdating = true;
        this.grid.instance.beginUpdate();
    }

// endregion

    public onOptionChanged($event: any): void {
        if ($event.fullName.endsWith(".sortOrder")
            || $event.fullName.endsWith(".filterValue")
            || $event.fullName.endsWith(".selectedFilterOperation")
            || $event.fullName.endsWith(".width")
            || $event.fullName.endsWith(".visibleIndex")
        ) {
            this.updateGridOptions();
        }
    }

    private mapFilterOperation(filterOperation: string): FilterOperation | StringFilterOperation | NumberFilterOperation {
        return filterOperation in this.filterOperationMap ? this.filterOperationMap[filterOperation] : null;
    }

    private updateGridOptions(force: boolean = false): void {
        if (!force && this.isUpdating) {
            return;
        }
        for (const column of this.grid.columns) {
            const dataField = (column as any).dataField;
            const columnOption = this.grid.instance.columnOption(dataField);
            const oColumn = this.options.Columns.find(c => c.Name === dataField);
            if (oColumn) {
                // VisibleIndex
                const position = columnOption.visibleIndex;
                if (position != null) {
                    oColumn.VisibleIndex = position - 1;
                }

                // Spaltenbreite
                const width = columnOption.width;
                if (width) {
                    oColumn.Width = width === "auto" ? null : Math.round(Number(width));
                }

                // Sortierung
                const sortIndex = columnOption.sortIndex;
                const sortOrder = columnOption.sortOrder === "asc" ? "Asc" : "Desc";
                oColumn.Sort = sortIndex != null && sortOrder != null ? new ColumnSort({
                    Index: sortIndex,
                    Direction: sortOrder,
                }) : null;


                // Filter
                const filterValue = columnOption.filterValue;
                const filterOperation = this.mapFilterOperation(
                    columnOption.selectedFilterOperation ?? columnOption.defaultFilterOperation,
                );
                oColumn.Filter = filterValue != null
                && filterOperation != null
                && (!Array.isArray(filterValue) || filterValue.filter(x => x != null).length) ? new ColumnFilter({
                    Operation: filterOperation,
                    Value: filterValue,
                }) : null;
            }
        }
        this.onOptionsChanged.emit(this.options);
    }

    public onSelectionChanged(): void {
        const selectedIds = this.grid.instance.getSelectedRowKeys();
        this.selectedIds.next(selectedIds);
        this.anySelected.next(selectedIds.length > 0);
        this.noneSelected.next(selectedIds.length === 0);
        this.multipleSelected.next(selectedIds.length > 1);
        this.oneSelected.next(selectedIds.length === 1);
    }

    public colName(index: number, item: any): string {
        return item.colName;
    }
}
