import {FormModel} from "@mintware-de/form-builder";
import {CustomerImportSettings} from "@app/app/customer/core";
import {FileField, SelectField} from "@app/app/common/form/types";

export class ImportCustomersViewModel {
    public fileFormatField: SelectField = null;
    public fileField: FileField = null;

    public formModel: FormModel<CustomerImportSettings> = {
        FileFormat: this.fileFormatField = new SelectField({
            label: "label.format",
            options: {},
        }),
        File: this.fileField = new FileField({
            label: "label.file",
        }),
    };

    public importSettings: CustomerImportSettings = new CustomerImportSettings();
}
