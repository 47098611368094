import {Inject, Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {hasFlag, PreSaveOrderRequest, VatDetectionFlagsEnum} from "@app/app/order";
import {OrderRepository} from "@app/app/order/data/repository/order.repository";
import {InconsistentVatDetailsDialogComponent} from "../component/dialog/inconsistent-vat-details-dialog/inconsistent-vat-details-dialog.component";
import {InconsistentVatDetailsCheckModeEnum} from "@app/app/common/model";
import {Auth} from "@shared/models";
import {CustomerFormComponent} from "@app/app/customer/presentation";
import {CustomerMetaInformation} from "@app/app/customer/core";
import {
    EditCustomerMetaDataRequest,
    EditCustomerMetaDataUseCase,
} from "@app/app/customer/core/use-case/edit-customer-meta-data.use-case";
import {Message, MessagingService} from "@bb-core/service";

@Injectable({providedIn: "root"})
export class LegacyBridgeService {

    private auth: Auth;

    constructor(private readonly matDialog: MatDialog,
                private readonly orderRepo: OrderRepository,
                private readonly editMetaDataUseCase: EditCustomerMetaDataUseCase,
                private readonly messaging: MessagingService,
                @Inject("authService") public authService: AuthService,
    ) {
        window.addEventListener("legacy-oss-check", this._legacyOssCheck.bind(this));
        window.addEventListener("legacy-create-customer", this._legacyCreateCustomer.bind(this));
        window.addEventListener("edit-customer-meta-data", this._legacyEditCustomerMetaData.bind(this));
        window.addEventListener("legacy-alert", this._legacyAlert.bind(this));
    }

    private async _legacyOssCheck(event: CustomEvent<{
        Promise: Promise<void>,
        Request: PreSaveOrderRequest,
        OrderData: {
            Vat1Rate: string,
            Vat2Rate: string,
            MerchantVatId: string,
        },
    }>): Promise<void> {
        if (this.auth == null) {
            this.auth = await this.authService.userPromise;
        }
        event.detail.Promise = new Promise<any>(async (resolve) => {
            const request = new PreSaveOrderRequest(event.detail.Request);

            const changeSet: any = {};
            if (!hasFlag(request.VatDetectionFlags ?? 0, VatDetectionFlagsEnum.IsDirty)) {
                const response = await this.orderRepo.preSaveOrderCheck(request);

                if (response.VatRates) {
                    const regular = (response.VatRates.VatRateRegular?.toFixed(2)?.toString() ?? "").replace(/\./g, ",");
                    if (regular !== event.detail.OrderData.Vat1Rate) {
                        changeSet.Vat1Rate = {
                            client: event.detail.OrderData.Vat1Rate,
                            server: regular,
                        };
                    }
                    const reduced = (response.VatRates.VatRateReduced?.toFixed(2)?.toString() ?? "").replace(/\./g, ",");
                    if (reduced !== event.detail.OrderData.Vat2Rate) {
                        changeSet.Vat2Rate = {
                            client: event.detail.OrderData.Vat2Rate,
                            server: reduced,
                        };
                    }
                }
                if (response.MerchantVatId == null) {
                    response.MerchantVatId = "";
                }
                if (response.MerchantVatId !== "" && response.MerchantVatId != event.detail.OrderData.MerchantVatId) {
                    changeSet.MerchantVatId = {
                        client: event.detail.OrderData.MerchantVatId,
                        server: response.MerchantVatId,
                    };
                }
                if (response.CustomerVatId == null) {
                    response.CustomerVatId = "";
                }
                if (response.CustomerVatId !== "" && response.CustomerVatId != request.CustomerVatId) {
                    changeSet.CustomerVatId = {client: request.CustomerVatId, server: response.CustomerVatId};
                }
            }

            if (Object.keys(changeSet).length) {
                const {Mode: mode} = await this.orderRepo.getInconsistentVatCheckConfig();
                if (mode === InconsistentVatDetailsCheckModeEnum.ShowDialog) {
                    const dialog = this.matDialog.open(InconsistentVatDetailsDialogComponent, {
                        data: {changeSet, isMasterUser: this.auth.isMasterUser},
                        disableClose: true,
                    });
                    dialog.afterClosed().subscribe(resolve);
                } else if (mode === InconsistentVatDetailsCheckModeEnum.HideDialogAndUseBillbeeCalculation) {
                    resolve(Object.keys(changeSet).reduce((o, k) => ({...o, [k]: changeSet[k].server}), {}));
                } else {
                    resolve({});
                }
            } else {
                resolve({});
            }
        });
    }

    private async _legacyCreateCustomer(event: CustomEvent<{
        Promise: Promise<any>,
    }>): Promise<void> {
        event.detail.Promise = new Promise<any>(resolve => {
            const dialogRef = this.matDialog.open(CustomerFormComponent, {
                panelClass: "no-padding",
                minHeight: "80%",
                minWidth: "80%",
            });

            dialogRef.afterClosed().subscribe((result) => {
                console.log("Legacy Create Customer");
                resolve(result);
            });

        });
    }

    private _legacyEditCustomerMetaData(event: CustomEvent<{
        Promise: Promise<void>,
        MetaData: CustomerMetaInformation,
    }>): void {
        event.detail.Promise = new Promise<any>(async (resolve) => {
            await this.editMetaDataUseCase.execute(new EditCustomerMetaDataRequest({
                MetaData: new CustomerMetaInformation(event.detail.MetaData),
            }), {
                displayUpdatedMetaData(metaData: CustomerMetaInformation): void {
                    resolve(metaData);
                },
            });
            
            resolve(null);
        });
    }
    
    private _legacyAlert(event: CustomEvent<{
        title: string,
        message: string,
    }>): void {
        this.messaging.showMessage(Message.transient({...event.detail, isBlocking: false}));
    }
}
