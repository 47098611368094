import {InconsistentVatDetailsCheckModeEnum} from "./inconsistent-vat-details-check-mode.enum";

export class VatSettings {
    public DefaultVatMode: number = 0;
    public DefaultVatIndex: number = 1;
    public VatRate1: number = 19;
    public VatRate2: number = 7;
    public DefaultCurrency: string = "EUR";
    public InconsistentVatDetailsCheckMode: InconsistentVatDetailsCheckModeEnum = InconsistentVatDetailsCheckModeEnum.ShowDialog;

    constructor(obj?: Partial<VatSettings>) {
        ctor(this, obj);
    }
}
