import {DirectionEnum} from "./direction.enum";

export class FileFormat {
    public FileFormatDirection: DirectionEnum = DirectionEnum.Import;
    public Name: string = null;
    public Value: string = null;

    constructor(obj?: Partial<FileFormat>) {
        ctor(this, obj);
    }
}
