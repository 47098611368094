import {Injectable, inject} from "@angular/core";
import {OAuthService} from "@app/app/oauth/core";
import {Observable, from, map, switchMap, zip} from "rxjs";
import {PopupType} from "../../../../shared/model/popup-type.enum";
import {PopupService} from "../../../../shared/service/popup.service";
import {ShopViewModelDto} from "../../data/shop-view-model.dto";
import {ShopsRepository} from "../../data/shops.repository";
import {ShopKey} from "../../model/shop-key";
import {ShopOauthPopupMessage} from "../../model/shop-oauth-popup-message";
import {ShopsService} from "../shops.service";

@Injectable({
    providedIn: "root",
})
export class ReauthShopCommand {
    private readonly oauthService = inject(OAuthService);
    private readonly popupService = inject(PopupService);
    private readonly shopsService = inject(ShopsService);
    private readonly shopsRepository = inject(ShopsRepository);

    public execute(
        url$: Observable<string>,
        key: ShopKey,
        logo: string,
        shop: ShopViewModelDto,
    ): Observable<ShopViewModelDto> {
        return this.shopsService
            .showShopLoadingDialogForRequest(url$, logo, "title.reauth_shop", "text.fetching_partner_url")
            .pipe(
                switchMap((url) =>
                    this.shopsService.showShopLoadingDialogForRequest(
                        this.popupService.getMessage<ShopOauthPopupMessage>(url, PopupType.ShopReAuth),
                        logo,
                        "title.reauth_shop",
                        "text.add_shop_description",
                    ),
                ),
                switchMap(({code, state}) => {
                    const accessToken$ = from(
                        this.oauthService.getChannelAccessToken({
                            api: key,
                            code,
                            state,
                        }),
                    );
                    const verifyState$ = from(this.oauthService.verifyState({state}));
                    const req$ = state
                        ? zip(accessToken$, verifyState$).pipe(map(([token, state]) => ({...token, ...state})))
                        : accessToken$;
                    return this.shopsService.showShopLoadingDialogForRequest(
                        req$,
                        logo,
                        "title.reauth_shop",
                        "text.fetching_shop_auth",
                    );
                }),
                switchMap((oauth) =>
                    this.shopsService.showShopLoadingDialogForRequest(
                        this.shopsRepository.updateShop({
                            ...shop,
                            OAuthAccessToken: oauth,
                            OAuthToken: oauth,
                            ...oauth,
                        } as ShopViewModelDto),
                        logo,
                        "title.reauth_shop",
                        "text.updating_shop",
                    ),
                ),
            );
    }
}
