export class ArticleSource {
    public Id: number = 0;
    public Source: string = null;
    public SourceId: string = null;
    public ApiAccountName: string = null;
    public SourcesList: any = null;
    public SourceOriginal: string = null;
    public SourceWithAccountId: any = null;
    public ApiAccountId: number = null;
    public ExportFactor?: number = null;
    public StockSyncInactive: boolean = false;
    public StockSyncMin?: number = null;
    public StockSyncMax?: number = null;
    public UnitsPerItem?: number = null;
    public Custom: any = null;
    public EnableListing: boolean = false;

    constructor(obj?: Partial<ArticleSource>) {
        ctor(this, obj);
    }
}
