<ng-container *ngIf="fromDialog; else cardTemplate">
    <div fxLayout="row" fxLayoutAlign="center center">
        <h3 *ngIf="viewModel.messageTemplate?.Id != null" fxFlex mat-dialog-title>{{ viewModel.messageTemplate?.Name }}</h3>
        <h3 *ngIf="viewModel.messageTemplate?.Id == null" fxFlex mat-dialog-title>{{ 'Vorlage anlegen' | translate }}</h3>
        <ng-container *ngTemplateOutlet="languageDropdownTemplate"></ng-container>
    </div>
    <mat-dialog-content>
        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <ng-container *ngTemplateOutlet="buttonsTemplate"></ng-container>
    </mat-dialog-actions>
</ng-container>
<ng-template #cardTemplate>
    <mat-card>
        <div fxLayout="row" fxLayoutAlign="center center">
            <h3 style="margin-top: 0;" *ngIf="viewModel.messageTemplate?.Id != null" fxFlex mat-card-title>{{ viewModel.messageTemplate?.Name }}</h3>
            <h3 style="margin-top: 0;" *ngIf="viewModel.messageTemplate?.Id == null" fxFlex mat-card-title>{{ 'Vorlage anlegen' | translate }}</h3>
            <ng-container *ngTemplateOutlet="languageDropdownTemplate"></ng-container>
        </div>
        <mat-card-content>
            <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
        </mat-card-content>
        <mat-card-actions align="end">
            <ng-container *ngTemplateOutlet="buttonsTemplate"></ng-container>
        </mat-card-actions>
    </mat-card>
</ng-template>

<ng-template #formTemplate>
    <mw-form-builder #messageTemplateForm
                     [mwFormModel]="viewModel.formModel"
                     [mwFormData]="viewModel.localizedMessageTemplate"
                     (mwFormSubmit)="submit($event)"
    ></mw-form-builder>

    <br>
    <mat-divider></mat-divider>
    <br>
    <bb-testmail-config [preSubmit]="preSendTestMail.bind(this)"></bb-testmail-config>
    <br>
</ng-template>

<ng-template #buttonsTemplate>
    <button type="button" mat-button (click)="cancel()">
        {{ 'Abbrechen' | translate }}
    </button>
    <button type="button" mat-raised-button color="primary" (click)="messageTemplateForm.submit()">
        {{ 'Speichern' | translate }}
    </button>
</ng-template>

<ng-template #languageDropdownTemplate>
    <div fxFlex="none" style="width: 60px">
        <mat-select [value]="viewModel.currentLanguage"
                    (valueChange)="flipLanguage($event)">
            <mat-option *ngFor="let language of viewModel.allLanguages"
                        [value]="language">
                {{ language}}
            </mat-option>
        </mat-select>
    </div>
</ng-template>