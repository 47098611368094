export class LabelPaper {
    public Id: number = 0;
    public Name: string = null;
    public Description: string = null;
    public NumColumnsPerPage: number = null;
    public NumRowsPerPage: number = null;
    public PageWidthInMM: number = 210;
    public PageHeightInMM: number = 297;
    public LabelWidthMM: number = null;
    public LabelHeightMM: number = null;
    public LeftOffsetMM: number = 5;
    public TopOffsetMM: number = 5;
    public Selected: boolean = false; // virtual, only for view

    public constructor(obj?: Partial<LabelPaper>) {
        if (!obj) {
            return;
        }
        Object.keys(this).forEach((k) => {
            if (k in obj) {
                this[k] = obj[k];
            }
        });
    }
}
