import {OrderToShip, PackageType, ShippingProvider} from "../entity";
import {OrderWeightType} from "../enum";

/**
 * This class provides weight calculation logic.
 */
export class WeightCalculationService {

    /**
     * Recalculate the weight of the order and updates the weight in the order object.
     * @param order The order.
     * @param packageTypes A list of package types which contain at least the package type that is used in the order.
     * @param shippingProviders A list of shipping providers which contain at least the used shipping provider.
     */
    public static recalculateWeight(order: OrderToShip,
                                    packageTypes: PackageType[],
                                    shippingProviders: ShippingProvider[],
    ): void {
        order.Weight = WeightCalculationService.calculateWeight(order, packageTypes, shippingProviders);
    }

    private static calculateWeight(order: OrderToShip,
                                   packageTypes: PackageType[],
                                   shippingProviders: ShippingProvider[],
    ): number {
        if (order.DefaultWeightCalculation === OrderWeightType.OrderWeight) {
            return order.OrderWeight;
        }
        if (order.DefaultWeightCalculation === OrderWeightType.Calculated) {
            return WeightCalculationService.CalculatedWeight(order, packageTypes, shippingProviders);
        }
        if (order.DefaultWeightCalculation === OrderWeightType.Provider) {
            return WeightCalculationService.ProviderWeight(order, shippingProviders);
        }
        if (order.DefaultWeightCalculation === OrderWeightType.ManualThenCalculated) {
            if (order.OrderWeight) {
                return order.OrderWeight;
            } else {
                return WeightCalculationService.CalculatedWeight(order, packageTypes, shippingProviders);
            }
        }
        return null;
    }

    /**
     * Calculate the calculated weight of the order.
     * @param order The order.
     * @param packageTypes A list of package types which contain at least the package type that is used in the order.
     * @param shippingProviders A list of shipping providers which contain at least the used shipping provider.
     * @return The calculated weight.
     */
    public static CalculatedWeight(order: OrderToShip,
                                   packageTypes: PackageType[],
                                   shippingProviders: ShippingProvider[],
    ): number {
        const packageType = packageTypes.find(p => p.Id === order.ShippingPackageId);
        const provider = shippingProviders.find(p => p.Id === order.ShippingProviderId);
        let weight = order.CalculatedWeight;
        if (!packageType) {
            return null;
        }
        if (packageType?.WeightGram != null) {
            weight += packageType.WeightGram / 1000;
        } else {
            if (provider?.PackageWeightGram != null) {
                weight += provider.PackageWeightGram / 1000;
            }
        }
        return weight;
    }

    /**
     * Calculate the provider weight of the order.
     * @param order The order.
     * @param shippingProviders A list of shipping providers which contain at least the used shipping provider.
     * @return number The provider weight.
     */
    public static ProviderWeight(order: OrderToShip,
                                 shippingProviders: ShippingProvider[]): number {
        const provider = shippingProviders.find(p => p.Id === order.ShippingProviderId);
        if (provider?.DefaultWeightGram != null) {
            return provider.DefaultWeightGram / 1000;
        }
        return null;
    }
}
