import {KeyValuePair} from "@bb-core/entity/key-value-pair";

export class ShippingProductService {
    public DisplayName: string = null;
    public DisplayValue: string = null;
    public RequiresUserInput: boolean = false;
    public ServiceName: string = null;
    public typeName: string = null;
    public CanBeConfigured: boolean = false;
    public PossibleValueLists: Array<KeyValuePair<string, Array<KeyValuePair<number, string>>>> = [];
    public AlsoInOrder: boolean = false;
    public OnlyInOrder: boolean = false;

    constructor(obj?: Partial<ShippingProductService>) {
        ctor(this, obj);
    }
}
