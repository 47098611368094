import {AbstractLayoutType, Constructor, IGroupTypeOptions} from "@mintware-de/form-builder";
import {DividerComponent} from "./divider.component";
import {DividerOptions} from "./divider.options";

export class Divider extends AbstractLayoutType<IGroupTypeOptions> {
    public readonly component: Constructor = DividerComponent;

    constructor(opts: DividerOptions = {}) {
        super({
            withPadding: true,
            ...opts,
            model: {},
        });
    }
}
