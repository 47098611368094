export class Message {
    public title?: string = "";
    public message: string = "";
    public isBlocking: boolean = false;

    constructor(obj?: Partial<Message>) {
        ctor(this, obj);
    }

    public static blocking(obj: Partial<Message>): Message {
        return new Message(Object.assign({}, obj, {
            "isBlocking": true,
        }));
    }

    public static transient(obj: Partial<Message>): Message {
        return new Message(Object.assign({}, obj, {
            "isBlocking": false,
        }));
    }
}
