import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {BbFormErrorPipe} from "./bb-error.pipe";
import {BbFormErrorStateMatcher} from "./bb-form-error-state-matcher";

/** Only for Form-Elements. Do not import outside of form/ */
@NgModule({
	declarations: [BbFormErrorPipe],
	providers: [BbFormErrorStateMatcher],
    imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule],
    exports: [BbFormErrorPipe, CommonModule, MatFormFieldModule, ReactiveFormsModule],
})
export class BbFormModule implements NgModule {}
