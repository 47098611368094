import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MAT_MOMENT_DATE_FORMATS, MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDatetimepickerModule } from "@mat-datetimepicker/core";
import { MatMomentDatetimeModule } from "@mat-datetimepicker/moment";

@NgModule({
    exports: [
        MatBadgeModule,
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatListModule,
        MatSelectModule,
        MatCheckboxModule,
        MatTabsModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatGridListModule,
        MatSnackBarModule,
        FlexLayoutModule,
        MatDatepickerModule,
        MatMomentDatetimeModule,
        MatDatetimepickerModule,
        MatChipsModule,
        MatStepperModule,
    ],
    imports: [
        MatBadgeModule,
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatListModule,
        MatSelectModule,
        MatCheckboxModule,
        MatTabsModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatGridListModule,
        MatSnackBarModule,
        FlexLayoutModule,
        MatDatepickerModule,
        MatMomentDatetimeModule,
        MatDatetimepickerModule,
        MatChipsModule,
        MatStepperModule,
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "de-DE" },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "outline" } },
    ],
})
export class MaterialModule {
    public constructor(public matIconRegistry: MatIconRegistry) {
        matIconRegistry.setDefaultFontSetClass("fal");
        matIconRegistry.registerFontClassAlias("fab", "fab");
    }
}
