import {AfterViewInit, Component, OnDestroy} from "@angular/core";
import {AbstractFormFieldComponent, AbstractType} from "@mintware-de/form-builder";
import {Subscription} from "rxjs";
import {NumberFieldOptions} from "./number-field.options";
import {TextFieldOptions} from "./text-field.options";

@Component({
    selector: "bb-form-text-field",
    template: `
        <ng-container *ngIf="mwFieldType.options.type == 'hidden'; else visibleField">
            <input [type]="mwFieldType.options.type"
                   [name]="mwPath"
                   [required]="mwFieldType.options.required == true"
                   [formControl]="mwElement">
        </ng-container>
        <ng-template #visibleField>
            <mat-form-field [matTooltip]="mwFieldType.options.tooltip | translate" class="mat-block"
                            [hidden]="!mwFieldType.options.visible">
                <mat-label
                    *ngIf="mwFieldType.options.label">{{ mwFieldType.options.label | translateLabel }}</mat-label>
                <input [autofocus]="mwFieldType.options.autofocus"
                       [autocomplete]="mwFieldType.options.type == 'password' ? 'new-password' : null"
                       matInput
                       [type]="mwFieldType.options.type"
                       [placeholder]="mwFieldType.options.placeholder == null ? null : (mwFieldType.options.placeholder | translate)"
                       [name]="mwPath"
                       [required]="mwFieldType.options.required == true"
                       [min]="mwFieldType.options.min"
                       [max]="mwFieldType.options.max"
                       [minlength]="mwFieldType.options.minLength ? mwFieldType.options.minLength.toString() : null"
                       [maxlength]="mwFieldType.options.maxLength ? mwFieldType.options.maxLength.toString() : null"
                       [readonly]="mwFieldType.options.readonly"
                       [step]="step"
                       [formControl]="mwElement"
                       (keyup)="onKeyUp($event)">
                <mat-hint>{{ mwFieldType.options.hint | translate }}</mat-hint>
                <mat-hint align="end" *ngIf="mwElement && mwFieldType.options.maxLength > 0">
                    {{ (mwElement.value != null ? mwElement.value.length : 0) }} / {{ mwFieldType.options.maxLength }}
                </mat-hint>
                <span matSuffix *ngIf="mwFieldType.options.suffix">{{mwFieldType.options.suffix}}</span>
                <mat-error>
                    <bb-form-errors [formField]="mwElement" [fieldType]="mwFieldType"></bb-form-errors>
                </mat-error>
            </mat-form-field>
        </ng-template>
    `,
})
export class TextFieldComponent
    extends AbstractFormFieldComponent<AbstractType<TextFieldOptions & NumberFieldOptions>> implements AfterViewInit, OnDestroy {
    public step: number = null;

    private subscriptions: Subscription[] = [];

    public ngAfterViewInit(): void {
        if (this.mwFieldType.options.type === "number" && this.mwFieldType.options.decimals > 0) {
            this.step = 1 / Math.pow(10, this.mwFieldType.options.decimals);
            this.subscriptions.push(this.mwElement.valueChanges.subscribe(v => {
                if (!Number.isInteger(v)) {
                    return;
                }

                const reformatted = this._reformatDecimal(v);
                if (reformatted !== v) {
                    this.mwElement.patchValue(reformatted);
                }
            }));
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }


    private _reformatDecimal(v: any): string {
        const n = parseFloat(v);
        return n.toFixed(this.mwFieldType.options.decimals);
    }

    public onKeyUp(event: KeyboardEvent): void {
        this.mwFieldType.options.onKeyUp?.call(this.mwFieldType.options.onKeyUp, event);
    }
}

