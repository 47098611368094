import {ComponentStatus} from "./types";

export class IncidentComponent {
    public name: string = null;
    public status: ComponentStatus = null;
    public description: string = null;
    public created_at: Date = null;

    constructor(obj?: Partial<IncidentComponent>) {
        ctor(this, obj, {
            DateFields: ["created_at"],
        });
    }
}
