import {OrderToShipDetail} from "./order-to-ship-detail";
import {OrderToShipMessage} from "./order-to-ship-message";
import {Address} from "./address";
import {ShippingProviderProduct} from "./shipping-provider-product";
import {OrderWeightType} from "../enum";
import {ShippingProductService} from "./shipping-product-service";
import {ShippingProductServiceFactory} from "../factory/shipping-product-service.factory";
import {ShippingResult} from "./shipping-result";
import {WeightSet} from "@app/app/shipping/core/entity/weight-set";

export class OrderToShip {
    public OrderId: number = null;
    public ShippingProviderId: number = null;
    public ShippingProductId: number = null;
    public CloudStorageId: number = null;
    public CloudStorageIdForExportDocs: number = null;
    public CloudStorageIdForRetoureLabels: number = null;
    public ShippingPackageId: number = null;
    public ShopName: string = null;
    public Weight: number = null;
    public OrderWeight: number = null;
    public CalculatedWeight: number = null;
    public ShippingCost: number = null;
    public ShippingAddressId: string = null;
    public DefaultWeightCalculation: OrderWeightType = OrderWeightType.OrderWeight;
    public Content: string = null;
    public SelectedPackage: string = null;
    public SaveSelectionOfDefaults: boolean = false;
    public ShippingProduct: ShippingProviderProduct = null;
    public ShippingId: string = null;
    public ShipAddressUsageCount: number = 0;
    public Selected: boolean = false;
    public OrderNumber: string = null;
    public BillingNumber: string = null;
    public MarkStatus: boolean = false;
    public SignalRConnectionId: string = null;
    public OrderValue: number = null;
    public AlreadyShipped: boolean = false;
    public InternalNote: string = null;
    public AdditionalComment: string = null;
    public ExternalReference: string = null;
    public Currency: string = null;
    public CurrencySymbol: string = null;
    public Tags: string[] = [];
    public ShippingDate: Date = null;
    public OrderDate: Date = null;
    public PayDate: Date = null;
    public ShippingAddress: Address = new Address();
    public OrderDetails: OrderToShipDetail[] = [];
    public Messages: OrderToShipMessage[] = [];
    public OrderRelatedShippingServices: ShippingProductService[] = [];
    public Result: ShippingResult = null;
    public HasCustomerMessages: boolean = false;
    public Weights: WeightSet = new WeightSet();
    public OrderIsArchived: boolean = false;

    constructor(obj?: Partial<OrderToShip>) {
        ctor(this, obj, {
            DateFields: ["ShippingDate", "OrderDate", "PayDate"],
            Relations: {
                "ShippingAddress": Address,
                "ShippingProduct": ShippingProviderProduct,
                "Result": ShippingResult,
                "Weights": WeightSet,
            },
            Collections: {
                "OrderDetails": OrderToShipDetail,
                "Messages": OrderToShipMessage,
                "Tags": null,
            },
        });

        if (!obj) {
            return;
        }

        this.OrderRelatedShippingServices = (obj.OrderRelatedShippingServices ?? [])
            .map(e => e instanceof ShippingProductService ? e : ShippingProductServiceFactory.resolve(e));

        this.HasCustomerMessages = this.Messages.find((m) => m.IsCustomerMessage) != null;
    }
}
