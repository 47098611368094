import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";

/**
 * @deprecated 
 * use MultiSelectService instead
 */
@Injectable()
export class LegacyMultiSelectService {
	public selected$: BehaviorSubject<{ [key: number]: boolean }> = new BehaviorSubject({});

	public selectedIds$: Observable<number[]> = this.selected$.pipe(
        map(selected => Object.keys(selected).filter(k => selected[k]).map(k => Number(k))),
    );

    public isNothingSelected$: Observable<boolean> = this.selectedIds$.pipe(map(selected => selected.length === 0));
    public isOneSelected$: Observable<boolean> = this.selectedIds$.pipe(map(selected => selected.length === 1));
    public isAnySelected$: Observable<boolean> = this.selectedIds$.pipe(map(selected => selected.length > 0));
    public areManySelected$: Observable<boolean> = this.selectedIds$.pipe(map(selected => selected.length > 1));

    public toggle(key: number): void {
		const currentSelection = {...this.selected$.value};
        currentSelection[key] = !(key in currentSelection) || !currentSelection[key];
		this.selected$.next(currentSelection);
    }

    public reset(): void {
        this.setAll(false);
    }

    public getSelected(): number[] {
		const currentSelection = {...this.selected$.value};
        return Object.keys(currentSelection).filter(k => currentSelection[k]).map(Number);
    }

    public updatePossibleKeys(keys: number[]): void {
		const currentSelection = {...this.selected$.value};
        for (const key of Object.keys(currentSelection).map(Number)) {
            if (!keys.includes(key)) {
                delete currentSelection[key];
            }
        }

        this.selected$.next(keys.reduce((obj, k) => ({...obj, [k]: k in currentSelection && currentSelection[k]}), {}));
    }

    public selectAll(): void {
        this.setAll(true);
    }

    private setAll(value: boolean): void {
		const currentSelection = {...this.selected$.value};
        for (const key of Object.keys(currentSelection)) {
            currentSelection[key] = value;
        }
        this.selected$.next(currentSelection);
    }
}
