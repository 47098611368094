/**
 * This class describes a service for general user interaction such as confirmations.
 */
import {TranslationKey} from "@bb-core/entity";
import {Observable} from "rxjs";

export abstract class InteractionService {
    /** Show a message to the user to confirm an action. */
    public abstract confirm(
        title: string,
        message: string,
        options?: {
            yesText?: TranslationKey;
            noText?: TranslationKey;
            isDangerousToProceed?: boolean;
        },
    ): Promise<boolean>;

    public abstract confirm$(
        title: string,
        message: string,
        options?: {
            yesText?: TranslationKey;
            noText?: TranslationKey;
            isDangerousToProceed?: boolean;
        },
    ): Observable<boolean>;
}
