import {ChangeDetectionStrategy, Component} from "@angular/core";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantEnterFurtherCountriesVatIdsTaskComponent} from "../enter-further-country-vat-ids/enter-further-country-vat-ids.component";
import {OnboardingAssistantTaxRateTargetCountryTaskComponent} from "../tax-rate-target-country/tax-rate-target-country.component";

@Component({
    selector: "bb-onboarding-assistant-further-country-vat-ids",
    templateUrl: "./further-country-vat-ids.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantFurtherCountriesVatIdsTaskComponent {
    
    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
    ) {}

	public next(enterVatIds: boolean): void {
		this.onboardingStore.nextTask(
            enterVatIds
                ? OnboardingAssistantEnterFurtherCountriesVatIdsTaskComponent
                : OnboardingAssistantTaxRateTargetCountryTaskComponent,
        );
    }
}
