import {AbstractGroupType, Constructor, FormModel, IGroupTypeOptions} from "@mintware-de/form-builder";
import {TableRowComponent} from "./table-row.component";
import {TableRowOptions} from "@app/app/common/form/collection/table-collection/table-row.options";

export class TableRow<T> extends AbstractGroupType<IGroupTypeOptions & TableRowOptions<T>> {
    public readonly component: Constructor = TableRowComponent;

    constructor(obj?: TableRowOptions<T>) {
        super(Object.assign({
            model: obj.columns.reduce((res, col, idx) => ({
                isHidden: false,
                ...res,
                [col.field ?? ("anon_" + idx)]: col.content,
            }), {}),
        }, obj));
    }
}
