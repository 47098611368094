import {Constructor} from "@mintware-de/form-builder";
import {HiddenFieldComponent} from "./hidden-field.component";
import {InputField} from "./input.type";

export class HiddenField extends InputField<any> {

    public readonly component: Constructor = HiddenFieldComponent;

    constructor() {
        super({
            required: false,
            type: "hidden",
            label: "",
        });
    }
}
