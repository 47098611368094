import {Observable} from "rxjs";

export abstract class PlatformChannelService {

    /// Subscribes a hub event and returns a teardown function to unsubscribe.
    public abstract subscribe(hub: string, event: string, onMessage: (...args: any[]) => void): VoidFunction;

    /// Subscribes a hub event
    public abstract listenToEvent<T extends (...args: any[]) => any>(hub: string, event: string): Observable<Parameters<T>>;
}
