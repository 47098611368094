import {CustomerPaymentRepository, Payment, CustomerPaymentListItem} from "@app/app/customer/core";
import {Injectable} from "@angular/core";
import {Column, PageResult} from "@app/app/grid/core";
import * as ngx from "@ngx-resource/core";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/customer",
})
export class ApiCustomerPaymentRepository extends ngx.Resource implements CustomerPaymentRepository {

    @ngx.ResourceAction({
        path: "/{!customerId}/payments",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doQueryPage: ngx.IResourceMethod<any, { Data: Payment[], TotalCount: number }>;

    public async queryPage(page: number,
                           pageSize: number,
                           visibleColumns: Column<Payment>[],
                           searchTerm: string,
                           additionalArguments: any = null,
    ): Promise<PageResult<CustomerPaymentListItem>> {
        const data = await this.doQueryPage({
            Page: page,
            PageSize: pageSize + 1,
            VisibleColumns: visibleColumns,
        }, null, additionalArguments);

        return new PageResult<CustomerPaymentListItem>({
            Data: data.Data.slice(0, pageSize).map(x => new CustomerPaymentListItem(x)),
            Page: page,
            PageSize: pageSize,
            TotalCount: data.TotalCount,
        });
    }

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }

    public updateProperty(id: number, changes: Partial<CustomerPaymentListItem>): Promise<CustomerPaymentListItem> {
        // noop
        return Promise.resolve(null);
    }
}
