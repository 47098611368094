import {ShippingProductService} from "../shipping-product-service";
import {PersonalDeliverySpecificationsEnum} from "@app/app/shipping/core/entity/service/personal-delivery-specifications.enum";
import {AgeCheckEnum, IdentAgeCheckEnum} from "@app/app/shipping/core";

export class DhlPersonalDeliverySpecificationsService extends ShippingProductService {
    public PersonalDeliverySpecifications: PersonalDeliverySpecificationsEnum = PersonalDeliverySpecificationsEnum.NamedPersonOnly;
    public FirstName: string = null;
    public LastName: string = null;
    public DateOfBirth: Date = null;
    public MinimumAge: IdentAgeCheckEnum = IdentAgeCheckEnum.A0;
    public SelectedAgeValue: AgeCheckEnum = AgeCheckEnum.A16; 

    constructor(obj?: Partial<DhlPersonalDeliverySpecificationsService>) {
        super(obj);
        ctor(this, obj, {
            DateFields: ["DateOfBirth"],
        });
    }

}
