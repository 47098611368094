import {ShippingProductService} from "../shipping-product-service";

export class ShopReturnService extends ShippingProductService {
    public name1: string = null;
    public name2: string = null;
    public name3: string = null;
    
    public street: string = null;
    public zip: string = null;
    public city: string = null;
    public countryCode: string = null;
    
    public email: string = null;
    public mobile: string = null;
    public phone: string = null;

    constructor(obj?: Partial<ShopReturnService>) {
        super(obj);
        ctor(this, obj);
    }
}
