import {AbstractLayoutComponent, AbstractLayoutType, IGroupTypeOptions} from "@mintware-de/form-builder";
import {RawTextOptions} from "./raw-text.options";
import {AfterContentInit, ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "bb-form-raw-text",
    template: `<span [innerHTML]="calculatedValue | translate"></span>`,
})
export class RawTextComponent
    extends AbstractLayoutComponent<AbstractLayoutType<RawTextOptions<any> & IGroupTypeOptions>> implements AfterContentInit {

    public calculatedValue: string = "";

    public ngAfterContentInit(): void {
        this.mwElement.valueChanges.subscribe(() => {
            this.updateCalculatedValue();
        });
        this.updateCalculatedValue();
    }

    private updateCalculatedValue(): void {
        if (this.mwFieldType.builderInstance) {
            this.calculatedValue = this.mwFieldType.options.builder(this.mwFieldType.builderInstance.mwFormData, this);
            this.cdr.detectChanges();
        }
    }
}
