import {Constructor} from "@mintware-de/form-builder";
import {TextFieldComponent} from "./text-field.component";
import {TextFieldOptions} from "./text-field.options";
import {ValidatorFn, Validators} from "@angular/forms";
import {InputField} from "./input.type";


export class TextField extends InputField<TextFieldOptions> {

    public readonly component: Constructor = TextFieldComponent;

    constructor(options: TextFieldOptions) {
        super(Object.assign({
            required: true,
            type: "text",
        }, options));
    }

    protected buildValidators(): ValidatorFn[] {
        const validators: ValidatorFn[] = [];
        if (this.options.minLength != null) {
            validators.push(Validators.minLength(this.options.minLength));
        }

        if (this.options.maxLength) {
            validators.push(Validators.maxLength(this.options.maxLength));
        }
        return validators;
    }
}
