import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { ShippingProduct } from "@app/app/article/data/shipping-product.model";
import { Observable, map } from "rxjs";
import { environment } from "../../environments/environment";
import { PaymentType } from "../model/payment-type";

@Injectable({
    providedIn: "root",
})
export class SettingsRepository {
    private readonly httpClient = inject(HttpClient);

    private readonly baseUrl = `${environment.baseUrl}/settings`;

    getOrderStates(): Observable<{ Id: number; Name: string }[]> {
        return this.httpClient.get<{ Id: number; Name: string }[]>(`${this.baseUrl}/setting/orderstates`);
    }

    getPaymentTypes(onlyExisting = false): Observable<PaymentType[]> {
        let params = new HttpParams();
        if (onlyExisting) {
            params = new HttpParams().set("onlyExisting", "true");
        }
        return this.httpClient.get<PaymentType[]>(`${this.baseUrl}/enums/paymentTypes`, { params });
    }

    getSmtpSettings(): Observable<{ DisplayName: string }[]> {
        const params = new HttpParams().set("module", "general_smtp");
        return this.httpClient
            .get<{ list: { DisplayName: string }[] }>(`${this.baseUrl}/setting`, { params })
            .pipe(map(response => response?.list ?? []));
    }

    getShippingProducts(): Observable<ShippingProduct[]> {
        return this.httpClient.get<ShippingProduct[]>(`${this.baseUrl}/shippingproduct`);
    }

    getHelpscoutBeaconsSetting(): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseUrl}/account/showHelpscoutBeaconsOnNavigation`);
    }

    setHelpscoutBeaconsSetting(isEnabled: boolean): Observable<void> {
        return this.httpClient.patch<void>(`${this.baseUrl}/account/showHelpscoutBeaconsOnNavigation`, isEnabled);
    }
}
