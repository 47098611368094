import {ArticleStock} from "@shared/models/article-stock";

export class SubArticle {
    public ToArticleId: number = null;
    public ToArticleSku: string = null;
    public ToArticleText: string = null;
    public Amount: number = null;
    public FromArticleId: number = 0;
    public Id: number = null;
    public ToArticleDataRowId: number = null;
    public ToArticleIsDeactivated: boolean = false;
    public ToArticleStocks: ArticleStock[] = [];

    constructor(obj?: Partial<SubArticle>) {
        ctor(this, obj);
    }
}
