import {Subscription} from "rxjs";

export abstract class EventsService {

    /// Subscribe to an event
    public abstract subscribe<T extends object>(type: new (...args: any[]) => T, handler: (event: T) => void): Subscription;

    /// Dispatch an event
    public abstract dispatch<T extends object>(event: T): void;
}
