export class PageResult<T> {
    public Data: T[] = [];
    public Page: number = null;
    public PageSize: number = null;
    public TotalCount: number = null;

    constructor(obj?: Partial<PageResult<T>>) {
        ctor(this, obj);
    }
}
