import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {BbModule} from "@app/app/common/module/bb.module";
import {CallbackComponent} from "@app/app/oauth/callback.component";
import {AmazonScpnAuthDialogComponent} from "@app/app/oauth/extensions/amazon/";
import {AmazonScpnAuthComponent} from "@app/app/oauth/extensions/amazon/amazon-scpn-auth.component";
import * as core from "./core";
import * as infra from "./infrastructure";
import {ShopifyTypeSelectionComponent} from "./shopify/shopify-type-selection.component";

@NgModule({
    declarations: [
        AmazonScpnAuthComponent,
        AmazonScpnAuthDialogComponent,
        CallbackComponent,
        ShopifyTypeSelectionComponent,
    ],
    imports: [
        BbModule,
        CommonModule,
        RouterModule.forChild([
            {
                path: "druckerwolke/authcallback",
                redirectTo: "oauth/cloud/DruckerWolkePrinter",
            },
            {
                component: AmazonScpnAuthComponent,
                path: "amazon/scpnauth",
            },
            {
                component: CallbackComponent,
                path: "oauth/:type/:api",
            },
            {
                path: "shopify/type-selection",
                component: ShopifyTypeSelectionComponent,
            },
        ]),
    ],
    providers: [{provide: core.OAuthService, useClass: infra.OAuthService}],
})
export class OAuthModule {}
