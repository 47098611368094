import {Inject, Injectable} from "@angular/core";
import {PlatformChannelService} from "@bb-core/service";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class SignalRPlatformChannelService implements PlatformChannelService {
    constructor(@Inject("SignalRService") private readonly sigR: SignalRService,
    ) {
    }

    public subscribe(hub: string, event: string, onMessage: (...args: any[]) => void): () => void {
        this.sigR.on(hub, event, onMessage);
        return () => this.sigR.off(hub, event, onMessage);
    }

    public listenToEvent<T = unknown[]>(hub: string, event: string): Observable<T> {
        return new Observable((subscriber) => {
            const onMessage = (...args: any[]) => subscriber.next(args as any);
            return this.subscribe(hub, event, onMessage);
        });
    }
}
