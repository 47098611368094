import {AbstractLayoutType, Constructor, IGroupTypeOptions} from "@mintware-de/form-builder";
import {HeaderOptions} from "./header.options";
import {HeaderComponent} from "./header.component";

export class Header extends AbstractLayoutType<HeaderOptions & IGroupTypeOptions> {
    public readonly component: Constructor = HeaderComponent;

    constructor(obj: HeaderOptions) {
        super({
            ...obj,
            model: {},
        });
    }
}
