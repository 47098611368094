import {Injectable} from "@angular/core";
import {DownloadService, ErrorHandlerService, Message, MessagingService, TranslationService} from "@bb-core/service";
import {ShipmentRepository} from "../repository";
import {ShipmentPdfLocation} from "../entity";

export enum ShipmentPdfType {
    label,
    exportDocuments,
    retoureLabel,
}

export class DownloadShipmentPdfRequest {
    public ProviderId: number = null;
    public BatchId: string = null;
    public PdfType: ShipmentPdfType = ShipmentPdfType.label;

    constructor(obj?: Partial<DownloadShipmentPdfRequest>) {
        ctor(this, obj, {NumberFields: ["ProviderId"]});
    }
}

@Injectable({providedIn: "root"})
export class DownloadShipmentPdfUseCase implements IUseCase<DownloadShipmentPdfRequest, void> {

    constructor(private readonly shipmentRepository: ShipmentRepository,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
                private readonly downloadService: DownloadService,
                private readonly errorHandler: ErrorHandlerService,
    ) {
    }

    public async execute(request: DownloadShipmentPdfRequest, presenter?: void): Promise<void> {
        if (!(await this.validateRequest(request))) {
            return;
        }
        const pdfLocation = await this.getPdfLocation(request);
        if (pdfLocation == null) {
            return;
        }

        await this.downloadFile(pdfLocation.Key);
    }

    private async getPdfLocation(request: DownloadShipmentPdfRequest): Promise<ShipmentPdfLocation> {
        let pdfLocation: ShipmentPdfLocation;
        try {
            switch (request.PdfType) {
                case ShipmentPdfType.label:
                    pdfLocation = await this.shipmentRepository.getLabelsForBatchAndProvider(request.BatchId, request.ProviderId);
                    break;
                case ShipmentPdfType.exportDocuments:
                    pdfLocation = await this.shipmentRepository.getExportDocumentsForBatchAndProvider(request.BatchId, request.ProviderId);
                    break;
                case ShipmentPdfType.retoureLabel:
                    pdfLocation = await this.shipmentRepository.getReturnLabelsForBatchAndProvider(request.BatchId, request.ProviderId);
                    break;

            }
        } catch (e) {
            await this.errorHandler.handleException(e, null, true);
            return;
        }

        if (!pdfLocation.LabelDataAvailable) {
            const title = this.translator.translate("title.general_error");
            const message = this.translator.translate("text.requested_label_not_found");
            await this.messaging.showError(Message.blocking({title, message}));
            return null;
        }
        return pdfLocation;
    }

    private async validateRequest(request: DownloadShipmentPdfRequest): Promise<boolean> {

        if (request.BatchId == null || request.BatchId === "") {
            const message = this.translator.translate("flash.invalid_shipping_batch");
            await this.messaging.showError(Message.transient({message}));
            return false;
        }

        if (request.ProviderId == null || request.ProviderId <= 0) {
            const message = this.translator.translate("flash.invalid_shipping_provider");
            await this.messaging.showError(Message.transient({message}));
            return false;
        }

        return true;
    }

    private async downloadFile(fileKey: string): Promise<void> {
        try {
            await this.downloadService.downloadFileByKey(fileKey);
        } catch (e) {
            const message = this.translator.translate("flash.error_while_file_download");
            await this.messaging.showError(Message.transient({message}));
        }
    }
}
