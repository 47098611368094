import {Constructor} from "@mintware-de/form-builder";
import {TextAreaComponent} from "./text-area.component";
import {TextAreaOptions} from "./text-area.options";
import {BaseType} from "../base/base.type";

export class TextArea extends BaseType<TextAreaOptions> {

    public readonly component: Constructor = TextAreaComponent;

    constructor(options: TextAreaOptions) {
        super(Object.assign({
            rows: 8,
        }, options));
    }
}
