import {ShippingCarrierEnum} from "@app/app/shipping/core/enum";
import {ShippingProductService} from "./shipping-product-service";
import {ShippingProductServiceFactory} from "../factory/shipping-product-service.factory";

export class ShippingProviderProduct {
    public Id: number = null;
    public ProductName: string = null;
    public DisplayName: string = null;
    public ShippingProviderId: number = null;
    public ShippingCarrier: ShippingCarrierEnum = null;
    public ExportTypeOther?: string = null;
    public TermsOfTrade?: string = null;
    public ExportType?: string = null;
    public ProductServices: ShippingProductService[] = [];
    public IsExport: boolean = false;
    public UniqueProductName: string = null;
    public ExportInfo: any = null;

    constructor(obj?: Partial<ShippingProviderProduct>) {
        ctor(this, obj);
        if (!obj) {
            return;
        }

        this.ProductServices = (obj.ProductServices ?? [])
            .map(e => e instanceof ShippingProductService
                ? e
                : ShippingProductServiceFactory.resolve(e),
            );
    }
}
