<button class="close-button" mat-icon-button (click)="closeAssistant()">
	<mat-icon fontIcon="fa-times"></mat-icon>
</button>
<div class="wrapper">
    <div class="steps-wrapper">
        <div class="step" *ngFor="let step of steps; let i = index" [class.active]="(activeStep$ | async) === i">
            <div class="indicator">
				<ng-container *ngIf="((activeStep$ | async) || 0) > i; else indexDisplay">
					<mat-icon fontIcon="fa-check"></mat-icon>

				</ng-container>
				<ng-template #indexDisplay>
					{{ i + 1 }}
				</ng-template>
            </div>
            <div class="text">
                <div class="title">
                    {{ step.title | translate }}
                </div>
                <div class="subtitle">
                    {{ step.subtitle | translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="content-wrapper">
		<mat-progress-bar mode="determinate" [value]="progress$ | async"></mat-progress-bar>
		<div class="header">
			<img src="assets/images/bee.svg" />
			<h1>{{ "title.welcome_to_billbee" | translate }}</h1>
			<h3>{{ "title.first_implementation_steps" | translate }}</h3>
		</div>
        <div class="content" [@openClose]="openClose$ | async">
			<ng-container *ngComponentOutlet="taskComponent$ | async"></ng-container>
        </div>
        <div class="buttons">
            <button *ngIf="hasBackButton$ | async" mat-button color="primary" (click)="onClickPrev()">
                {{ "button.back" | translate }}
            </button>
        </div>
    </div>
</div>
