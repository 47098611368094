import {AfterContentInit, ChangeDetectionStrategy, Component} from "@angular/core";
import {AbstractLayoutComponent, AbstractLayoutType} from "@mintware-de/form-builder";
import {CardOptions} from "@app/app/common/form/layout/card/card.options";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "[bb-form-card]",
    template: `
        <mat-card>
            <div style="margin-bottom: 16px" *ngIf="mwFieldType.options.title">{{ mwFieldType.options.title | translate }}</div>
            <div style="margin-bottom: 16px" *ngIf="calculatedValue">{{ calculatedValue }}</div>
            <mat-card-content>
                <ng-container *ngFor="let e of mwFieldType.options.bodyModel | keyvalue:orderAsGiven; index as index">
                    <div *ngIf="e.value.options.visible == null || e.value.options.visible"
                         mwFormField
                         [mwFormGroup]="mwElement"
                         [mwElement]="elements[e.key]"
                         [mwFieldType]="e.value"
                         [mwPath]="fieldPaths[e.key]"
                         [mwIndex]="indexFromParent"></div>
                </ng-container>
            </mat-card-content>
            <mat-card-actions *ngIf="mwFieldType.options.footerModel" align="end">
                <ng-container *ngFor="let e of mwFieldType.options.footerModel | keyvalue:orderAsGiven; index as index">
                    <ng-container *ngIf="e.value.options.visible == null || e.value.options.visible"
                         mwFormField
                         [mwFormGroup]="mwElement"
                         [mwElement]="elements[e.key]"
                         [mwFieldType]="e.value"
                         [mwPath]="fieldPaths[e.key]"
                         [mwIndex]="indexFromParent"></ng-container>
                </ng-container>
            </mat-card-actions>
        </mat-card>
    `,
})
export class CardComponent
    extends AbstractLayoutComponent<AbstractLayoutType<CardOptions>>
    implements AfterContentInit {

    public calculatedValue: string = null;

    public ngAfterContentInit(): void {
        if (this.mwFieldType.options.titleBuilder) {
            this.mwFormGroup.valueChanges.subscribe(() => {
                if (this.mwFieldType.builderInstance && this.mwFieldType.options.titleBuilder) {
                    this.calculatedValue = this.mwFieldType.options.titleBuilder(
                        this.mwFieldType.builderInstance.mwFormData,
                        this,
                    );
                }
            });
        }
    }
}
