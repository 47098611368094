export class AccessToken {
    public Token: string = null;
    public TokenType: string = null;
    public ExpiresIn: number = null;
    public RefreshToken: string = null;
    public Scope: string = null;
    public UserId: string = null;
    public ValidUntil?: Date = null;

    constructor(obj?: Partial<AccessToken>) {
        ctor(this, obj, {
            DateFields: ["ValidUntil"],
        });
    }
}
