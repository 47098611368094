import { DatePipe, NgIf } from "@angular/common";
import { Component, EventEmitter, Output, input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { TranslateModule } from "@ngx-translate/core";
import { ShopViewModelDto } from "../../../../partners/shops/data/shop-view-model.dto";
import { ListItemCardComponent } from "../../../../shared/ui/list-item-card/list-item-card.component";
import { IconFromSyncStatePipe } from "../../container/shop-list/icon-from-sync-state.pipe";
import { StatusColorFromShopPipe } from "../../container/shop-list/status-color-from-shop.pipe";
import { ShopFromPartnerKeyPipe } from "../shopFromPartnerKey.pipe";

@Component({
    selector: "bb-shop-card",
    templateUrl: "./shop-card.component.html",
    styleUrls: ["./shop-card.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        ListItemCardComponent,
        TranslateModule,
		MatMenuModule,
		MatDividerModule,
        MatIconModule,
        MatButtonModule,
        DatePipe,
        StatusColorFromShopPipe,
        ShopFromPartnerKeyPipe,
        IconFromSyncStatePipe,
    ],
})
export class ShopCardComponent {
    shop = input.required<ShopViewModelDto & { selected: boolean }>();
    loading = input<boolean>(true);

    @Output() clickShop = new EventEmitter<void>();
    @Output() selectionChange = new EventEmitter<void>();
    @Output() clickEdit = new EventEmitter<void>();
    @Output() clickReAuth = new EventEmitter<void>();
    @Output() clickDelete = new EventEmitter<void>();
    @Output() clickSwitchToManual = new EventEmitter<void>();
}
