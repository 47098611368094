import {Order} from "./order";

export class StockChange {
    public CreatedStr: string = null;
    public Change: number = null;
    public NewValue: number = null;
    public Reason: string = null;
    public Comment: string = null;
    public StockName: string = null;
    public Order: Order = null;
    public SerialNumber: string = null;

    constructor(obj?: Partial<StockChange>) {
        ctor(this, obj, {
            Relations: {
                "Order": Order,
            },
        });
    }
}
