import {ChangeDetectionStrategy, Component} from "@angular/core";
import {AbstractFormFieldComponent, AbstractType} from "@mintware-de/form-builder";
import {DateFieldOptions} from "./date-field.options";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "bb-form-date-field",
    template: `
        <ng-container *ngIf="mwFieldType.options.withTime; else dateOnly">
            <mat-form-field>
                <mat-label *ngIf="mwFieldType.options.label">{{ mwFieldType.options.label | translateLabel }}</mat-label>
                <mat-datetimepicker-toggle [for]="datetimePicker" matSuffix>
                    <mat-icon fontIcon="fa-calendar" matDatepickerToggleIcon></mat-icon>
                </mat-datetimepicker-toggle>
                <mat-datetimepicker
                        #datetimePicker
                        [disabled]="mwElement.disabled || mwFieldType.options.readonly"
                        type="datetime"
                        openOnFocus="true"
                        timeInterval="1">
                </mat-datetimepicker>
                <input [min]="mwFieldType.options.minDate"
                       [max]="mwFieldType.options.maxDate"
                       [readonly]="mwFieldType.options.readonly"
                       [formControl]="mwElement"
                       [matDatetimepicker]="datetimePicker"
                       [required]="mwFieldType.options.required == true"
                       (dateChange)="mwFieldType.options.onChange != null && mwFieldType.options.onChange($event, mwFieldType.builderInstance.group)"
                       matInput
                       placeholder="{{mwFieldType.options.label | translateLabel}}">
            </mat-form-field>
        </ng-container>
        <ng-template #dateOnly>
            <mat-form-field [matTooltip]="mwFieldType.options.tooltip" class="mat-block"
                            [hidden]="!mwFieldType.options.visible">
                <mat-label *ngIf="mwFieldType.options.label">{{ mwFieldType.options.label | translateLabel }}</mat-label>

                <mat-datepicker #picker
                                [disabled]="mwElement.disabled || mwFieldType.options.readonly"
                                [startAt]="mwFieldType.options.startDate"
                                [startView]="mwFieldType.options.startView"></mat-datepicker>
                <input [min]="mwFieldType.options.minDate"
                       [max]="mwFieldType.options.maxDate"
                       [readonly]="mwFieldType.options.readonly"
                       [formControl]="mwElement"
                       [matDatepicker]="picker"
                       [required]="mwFieldType.options.required == true"
                       (dateChange)="mwFieldType.options.onChange != null && mwFieldType.options.onChange($event, mwFieldType.builderInstance.group)"
                       matInput
                       placeholder="{{mwFieldType.options.label | translateLabel}}"
                       (focus)="picker.open()">
                <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon fontIcon="fa-calendar" matDatepickerToggleIcon></mat-icon>
                </mat-datepicker-toggle>

                <mat-hint>{{ mwFieldType.options.hint | translate }}</mat-hint>
                <mat-error>
                    <bb-form-errors [formField]="mwElement" [fieldType]="mwFieldType"></bb-form-errors>
                </mat-error>
            </mat-form-field>

        </ng-template>
    `,
})
export class DateFieldComponent extends AbstractFormFieldComponent<AbstractType<DateFieldOptions>> {
}
