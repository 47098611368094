import {ChangeDetectionStrategy, Component} from "@angular/core";
import {
    AbstractFormGroupComponent,
    AbstractGroupType,
    IGroupTypeOptions,
} from "@mintware-de/form-builder";
import {TableRowOptions} from "./table-row.options";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "tr[bb-form-table-row]",
    template: "",
})
export class TableRowComponent extends AbstractFormGroupComponent<AbstractGroupType<IGroupTypeOptions & TableRowOptions<any>>> {
}

