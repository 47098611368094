import {inject, Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {OAuthService} from "@app/app/oauth/core";
import moment from "moment";
import {from, Observable} from "rxjs";
import {filter, map, switchMap} from "rxjs/operators";
import {ShopViewModelDto} from "../../data/shop-view-model.dto";
import {ShopsRepository} from "../../data/shops.repository";
import {Shop} from "../../model/shop";
import {ShopType} from "../../model/shop-type.enum";
import {GetOAuthViewModelCommand} from "../../service/command/get-oauth-view-model.command";
import {OauthShopAddCommand} from "../../service/command/oauth-shop-add.command";
import {ReauthShopCommand} from "../../service/command/reauth-shop.command";
import {ShopsService} from "../../service/shops.service";
import {AmazonShopChooseRegionDialogComponent} from "./choose-region-dialog/choose-region-dialog.component";

@Injectable({
    providedIn: "root",
})
export class AmazonShop implements Shop {
    public readonly key = "amazon";
    public readonly name = "amazon";
    public readonly type = ShopType.Marketplace;
    public readonly featured = true;
    public readonly logo = `assets/images/logo/shops/${this.key}.png`;

    private readonly shopsRepository = inject(ShopsRepository);
    private readonly matDialog = inject(MatDialog);
    private readonly oauthService = inject(OAuthService);
    private readonly getOauthViewModel = inject(GetOAuthViewModelCommand);
    private readonly shopsService = inject(ShopsService);
    private readonly addCommand = inject(OauthShopAddCommand);
    private readonly reauthCommand = inject(ReauthShopCommand);

    public add(): Observable<never> {
        return this.matDialog
            .open(AmazonShopChooseRegionDialogComponent)
            .afterClosed()
            .pipe(
                filter((region: string) => !!region),
                switchMap((region) =>
                    this.addCommand.execute(
                        from(this.oauthService.getChannelAuthorizeUrl(this.key, undefined, {Region: region})),
						this.key,
						this.logo,
                    ),
                ),
            );
    }

    public reAuth(shop: ShopViewModelDto): Observable<ShopViewModelDto> {
        const url$ = from(
            this.oauthService.getChannelAuthorizeUrl(this.key, shop.Id || 0, {
                ShopName: shop.ShopName || "",
                Region: shop.AmzRegion || "",
            }),
        );
        return this.reauthCommand.execute(url$, this.key,this.logo, shop);
    }

    public saveShopWithNecessarySettings(): Observable<ShopViewModelDto> {
        return this.matDialog
            .open(AmazonShopChooseRegionDialogComponent)
            .afterClosed()
            .pipe(
                filter((region: string) => !!region),
                switchMap((region) =>
                    from(this.oauthService.getChannelAuthorizeUrl(this.key, undefined, {Region: region})).pipe(
                        map((url) => ({url, region})),
                    ),
                ),
                switchMap(({url, region}) =>
                    this.getOauthViewModel
                        .execute(this.key, this.logo, this.name, url, moment(Date.now()).add(-90, "d").toDate())
                        .pipe(map((viewModel) => ({viewModel, region}))),
                ),
                map(({viewModel, region}) => ({
                    ...viewModel,
                    SpApiRefreshToken: viewModel.OAuthAccessToken.RefreshToken,
                    SpApiAccessToken: viewModel.OAuthAccessToken.Token,
                    MerchantId: viewModel.OAuthAccessToken.shop || null,
                    AmzRegion: region,
                    UploadInvoices: true,
                    TagAmzMarketplace: true,
                })),
                switchMap((model) =>
                    this.shopsRepository
                        .getAmazonAvailableMarketPlaceIds(model.SpApiAccessToken, model.AmzRegion)
                        .pipe(map((ids) => ({...model, AmazonAvailableMarketplaceIds: ids}))),
                ),
                switchMap((model) =>
                    this.shopsService.showShopLoadingDialogForRequest(
                        this.shopsRepository.saveShop(model),
                        this.logo,
                        "title.saving",
                        "text.saving_shop",
                    ),
                ),
            );
    }
}
