import {ChangeDetectionStrategy, Component, Inject} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialogModule} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TranslateModule} from "@ngx-translate/core";

interface ShopLoadingDialogData {
	logo: string;
	title: string;
	text: string;
}

@Component({
    selector: "bb-shop-loading-dialog",
    templateUrl: "./shop-loading-dialog.component.html",
    styleUrls: ["./shop-loading-dialog.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatDialogModule, TranslateModule, MatProgressSpinnerModule, MatButtonModule],
})
export class ShopLoadingDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public readonly data: ShopLoadingDialogData) {}
}
