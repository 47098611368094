import {AbstractCollectionComponent} from "@mintware-de/form-builder";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {TableCollectionOptions} from "./table-collection.options";
import {TableRowOptions} from "./table-row.options";
import {InteractionService, TranslationService} from "@bb-core/service";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "bb-form-table-collection",
    template: `
        <table class="material" *ngIf="mwElement.controls.length; else noData">
            <thead>
            <tr>
                <th *ngIf="mwFieldType.options.selectMode != 'none'"></th>
                <th *ngIf="mwFieldType.options.allowEdit"></th>
                <ng-container *ngFor="let column of mwFieldType.options.entrySettings.columns">
                    <th *ngIf="!column.isHidden">
                        <ng-container *ngIf="column['selectAllCallback'] != null; else columnCaption">
                        <mat-slide-toggle (change)="column['selectAllCallback'](column['field'], $event.checked, mwFormGroup)">
                            <ng-container *ngTemplateOutlet="columnCaption"></ng-container>
                        </mat-slide-toggle>
                    </ng-container>
                    <ng-template #columnCaption>
                        {{column['title'] | translate}}
                    </ng-template>
                    </th>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let child of mwElement.controls; let i=index">
                <tr>
                    <td *ngIf="mwFieldType.options.selectMode != 'none'" style="width: 48px">
                        <mat-checkbox *ngIf="mwFieldType.options.selectMode == 'multiple'"
                                      [checked]="selectedRows[i]"
                                      (change)="selectRow(i)"></mat-checkbox>
                        <mat-radio-button *ngIf="mwFieldType.options.selectMode == 'single'"
                                          [checked]="selectedRows[i]"
                                          (change)="selectRow(i)"></mat-radio-button>
                    </td>
                    <td *ngIf="mwFieldType.options.allowEdit" style="width: 48px">
                        <button *ngIf="mwFieldType.options.allowEdit"
                                mat-icon-button
                                type="button"
                                (click)="editEntry(i)">
                            <mat-icon fontIcon="fa-pencil"></mat-icon>
                        </button>
                    </td>
                    <ng-container *ngFor="let column of mwFieldType.options.entrySettings.columns">
                        <td *ngIf="!column.isHidden">
                            <ng-container
                                *ngIf="mwFieldType.options.showCellCallback == null || mwFieldType.options.showCellCallback(i, column['field']) == true">
                            <ng-container *ngIf="!column['isActionsColumn']; else actionField"
                                          mwFormField
                                          [mwFormGroup]="mwFormGroup"
                                          [mwElement]="child['controls'][column['field'] ?? ('anon_' + i)] || child"
                                          [mwFieldType]="column['content']"
                                          [mwPath]="mwPath + '_' + i + '_' + (column['field'] ?? ('anon_' + i))"
                                          [mwIndex]="i"
                            ></ng-container>
                            <ng-template #actionField>
                                <div fxLayout="row" fxLayoutGap="4px">
                                    <button *ngIf="mwFieldType.options.allowDelete"
                                            mat-icon-button
                                            type="button"
                                            (click)="removeEntry(i)">
                                        <mat-icon fontIcon="fa-trash"></mat-icon>
                                    </button>
                                </div>
                            </ng-template>
                        </ng-container>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
            </tbody>
        </table>
        <ng-template #noData>
            <bb-no-data-container [noDataText]="mwFieldType.options.noDataText"></bb-no-data-container>
        </ng-template>
        <br>
        <ng-container *ngIf="mwFieldType.options.allowAdd">
            <ng-container *ngIf="mwFieldType.options.addButtonText; else iconButton">
                <button mat-button
                        (click)="addEntry()"
                        type="button"
                        class="mat-elevation-z1">
                    <mat-icon fontIcon="fa-plus"></mat-icon>
                    {{mwFieldType.options.addButtonText|translate}}
                </button>
            </ng-container>
            <ng-template #iconButton>
                <button mat-icon-button
                        (click)="addEntry()"
                        type="button"
                        class="mat-elevation-z1">
                    <mat-icon fontIcon="fa-plus"></mat-icon>
                </button>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="mwElement && mwElement.errors">
            <span style="color: red" [hidden]="!mwElement.errors.required || (mwFormGroup.untouched)">
                {{'error.required_any' | translate }}
            </span>
            <span style="color: red" [hidden]="mwElement.errors == null || mwElement.errors.custom == null">
                {{mwElement.errors.custom | translate }}
            </span>
        </ng-container>
        <br>
    `,
})
export class TableCollectionComponent
    extends AbstractCollectionComponent<TableRowOptions<any>, TableCollectionOptions<TableRowOptions<any>>>
    implements OnInit {

    public readonly selectedRows: { [key: number]: boolean } = {};

    constructor(private readonly cdr: ChangeDetectorRef,
                private readonly translator: TranslationService,
                private readonly interaction: InteractionService,
    ) {
        super();
    }

    public ngOnInit(): void {
        (this.mwFieldType as any).componentInstance = this;
        this.mwElement.valueChanges.subscribe((s) => {
            this.cdr.detectChanges();
        });
        (this.mwFieldType as any).componentInstance = this;
    }


    public addEntry(): void {
        super.addEntry();
        this.mwElement.markAsDirty();
    }

    public editEntry(i: number): void {
        const handler = this.mwFieldType.options.onClickEdit;
        if (handler) {
            handler(i, this.mwElement.get([i]).value);
        }
    }

    public removeEntry(index: number, force: boolean = false): void {
        (new Promise<void>(async (resolve) => {
            if (force || (await this.confirmDelete())) {
                super.removeEntry(index);
                this.mwElement.markAsTouched();
                this.mwElement.markAllAsTouched();

                if (index in this.selectedRows && this.selectedRows[index]) {
                    delete this.selectedRows[index];
                    this.cdr.detectChanges();
                }
            }
            resolve();
        })).then();
    }

    private confirmDelete(): Promise<boolean> {
        const title = this.translator.translate("title.confirm_delete_record");
        const message = this.translator.translate("text.confirm_delete_record");
        return this.interaction.confirm(title, message);
    }

    public resetSelection(): void {
        for (const key of Object.keys(this.selectedRows)) {
            delete this.selectedRows[key];
        }
        this.emitSelectionChange();
    }

    public selectRow(rowIndex: number): void {
        if (this.mwFieldType.options.selectMode === "none") {
            return;
        }
        if (this.mwFieldType.options.selectMode === "multiple") {
            if (!(rowIndex in this.selectedRows)) {
                this.selectedRows[rowIndex] = false;
            }
            this.selectedRows[rowIndex] = !this.selectedRows[rowIndex];
            if (!this.selectedRows[rowIndex]) {
                delete this.selectedRows[rowIndex];
            }
        } else if (this.mwFieldType.options.selectMode === "single") {
            Object.keys(this.selectedRows).forEach(k => delete this.selectedRows[k]);
            this.selectedRows[rowIndex] = true;
        }
        this.emitSelectionChange();
    }

    private emitSelectionChange(): void {
        const handler = this.mwFieldType.options.selectionChanged;
        if (handler) {
            const indexes = Object.keys(this.selectedRows).map(Number);
            handler(indexes, indexes.map(index => this.mwElement.get([index]).value));
        }
    }
}
