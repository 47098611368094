import {Pipe, PipeTransform} from "@angular/core";
import {LocalizedString} from "@shared/models/localized-string";

@Pipe({name: "defaultLanguage"})
export class DefaultLanguagePipe implements PipeTransform {
    public transform(strings: LocalizedString) {
        return strings[0].Text;
    }
}

