import {SelectableKeyValuePair} from "@bb-core/entity";

export class RecipientOptions {

    public DefaultMailAddresses: SelectableKeyValuePair[] = [];
    public CommercialMailAddresses: SelectableKeyValuePair[] = [];
    public StatusUpdatesMailAddresses: SelectableKeyValuePair[] = [];
    public Phone1Numbers: SelectableKeyValuePair[] = [];
    public Phone2Numbers: SelectableKeyValuePair[] = [];
    public FaxNumbers: SelectableKeyValuePair[] = [];

    constructor(obj?: Partial<RecipientOptions>) {
        ctor(this, obj, {
            Collections: {
                DefaultMailAddresses: SelectableKeyValuePair,
                CommercialMailAddresses: SelectableKeyValuePair,
                StatusUpdatesMailAddresses: SelectableKeyValuePair,
                Phone1Numbers: SelectableKeyValuePair,
                Phone2Numbers: SelectableKeyValuePair,
                FaxNumbers: SelectableKeyValuePair,
            },
        });
    }
}
