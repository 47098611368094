import {Injectable} from "@angular/core";
import {
    Address,
    EditAddressRequest,
    EditAddressUseCase,
    PickAddressRequest,
    PickAddressUseCase,
} from "@app/app/shipping/core";

@Injectable({providedIn: "root"})
export class ShippingHelperService implements IShippingHelperService {

    constructor(private readonly editAddressUseCase: EditAddressUseCase,
                private readonly pickAddressUseCase: PickAddressUseCase,
    ) {
    }

    public async editAddress(orderId: number, addressId: number): Promise<Address> {
        let updatedAddress = null;
        await this.editAddressUseCase.execute(new EditAddressRequest({
            AddressId: addressId,
            OrderId: orderId,
        }), {
            displayUpdatedAddress(address: Address): void {
                updatedAddress = address;
            },
        });
        return updatedAddress;
    }
    public async pickAddress(customerId: number, orderId: number, currentAddressId: number): Promise<Address> {
        let pickedAddress = null;
        await this.pickAddressUseCase.execute(new PickAddressRequest({
            CustomerId: customerId,
            OrderId: orderId,
            CurrentAddressId: currentAddressId,
        }), {
            displayPickedAddress(address: Address): void {
                pickedAddress = address;
            },
        });
        return pickedAddress;
    }
}
