import { Incident } from "./incident";
import {SystemStatus } from "./system-status";
import { SystemStatusComponent } from "./system-status-component";

export class SystemStatusSummary {
    public status: SystemStatus = null;
    public components: SystemStatusComponent[] = [];
    public incidents: Incident[] = [];

    constructor(obj?: Partial<SystemStatusSummary>) {
        ctor(this, obj, {
            Relations: {
                status: SystemStatus,
            },
            Collections: {
                components: SystemStatusComponent,
                incidents: Incident,
            },
        });
    }
}
