import {ChangeDetectionStrategy, Component} from "@angular/core";
import {map} from "rxjs/operators";
import {UtilsRepository} from "../../../shared/data/utils.repository";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantVatIdTaskComponent} from "../vat-id/vat-id.component";

@Component({
    selector: "bb-onboarding-assistant-country",
    templateUrl: "./country.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantCountryTaskComponent {
    public readonly options$ = this.utilsRepository.getCountries().pipe(
        map((countries) =>
            countries
                .filter(({HasVatRates}) => !!HasVatRates)
                .map((country) => ({Key: country.Name, Value: country.ISO2}))
                .filter(({Value}) => Value !== "DE"),
        ),
    );

    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
        private readonly utilsRepository: UtilsRepository,
    ) {}

    public next(country: string): void {
        this.onboardingStore.setData({country});
        this.onboardingStore.nextTask(OnboardingAssistantVatIdTaskComponent);
    }
}
