import {ColumnFilter} from "./column-filter";
import {ColumnSort} from "./column-sort";
import {TranslationKey} from "@bb-core/entity";

export type ColumnType = "string" | "number" | "decimal" | "date";

export class Column<T> {
    public Caption: TranslationKey | "" = null;
    public Name: keyof T | string = null;
    public IsVisible: boolean = true;
    public VisibleIndex: number = null;
    public Width?: number = null;
    public Sort: ColumnSort = null;
    public Filter: ColumnFilter = null;
    public AllowSorting: boolean = true;
    public AllowFiltering: boolean = true;
    public AllowEditing: boolean = false;
    public Type: ColumnType | (`array<${ColumnType}>`) = "string";
    public DisplayTransformer?: (value: T) => any = null;
    public ValueTransformer?: (value: string) => any = null;
    public CellTemplateName: "htmlCellTemplate" | "arrayCellTemplate" = null;
    public LookupValues: Array<{ value: any, text: any }> = null;

    constructor(obj?: Partial<Column<T>>) {
        ctor(this, obj, {
            Relations: {
                Sort: ColumnSort,
                Filter: ColumnFilter,
            },
        });
    }
}
