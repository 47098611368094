import {Pipe, PipeTransform} from "@angular/core";
import {TranslationService} from "@bb-core/service";
import {TranslationKey} from "@bb-core/entity";

@Pipe({
    name: "translate",
})
export class TranslatePipe implements PipeTransform {

    constructor(private readonly translator: TranslationService) {
    }

    public transform(label: TranslationKey | string, params?: { [key: string]: string | number | Date }): string {
        return this.translator.translate(label as TranslationKey, params);
    }
}
