import {OwnCategory} from "./own-category";
import {ArticleComment} from "./article-comment";
import {Country} from "./country";
import {ArticleSource} from "./article-source";
import {ArticleImageRelation} from "./article-image-relation";
import {ScaledPrice} from "./scaled-price";
import {ArticleFile} from "./article-file";
import {SubArticle} from "./sub-article";
import {StockChange} from "./stock-change";
import {CustomFieldValue} from "@shared/models/custom-field-value";
import {ArticleStock} from "./article-stock";
import {ArticleCommonProperties} from "@shared/models/article-common-properties";

export class Article {
    public Id: number = null;
    public Priority: number = null;
    public Title: string = null;
    public Shorttext: string = null;
    public Description: string = null;
    public ShortDescription: string = null;
    public EAN: string = null;
    public SKU: string = null;
    public Comments: ArticleComment[] = [];
    public Type: number = null;
    public UnitAsEnum: string = null;
    public UnitsPerItem: number = null;
    public Manufacturer: string = null;
    public Condition: number = null;
    public SoldSumGross: number = null;
    public SoldAmount: number = null;
    public IsDigital: boolean = false;
    public IsCustomizable: boolean = false;
    public IsPhotovoltaic: boolean = false;
    public Categories: OwnCategory[] = [];
    public Category1: OwnCategory = null;
    public Category2: OwnCategory = null;
    public Category3: OwnCategory = null;
    public Recipient: number = null;
    public Occasion: number = null;
    public TaricNumber: string = null;
    public Weight: number = null;
    public WeightNet: number = null;
    public CountryOfOrigin: string = null;
    public CountryOfOriginAsObject: Country = null;
    public ShippingProductId: number = null;
    public DeliveryTime: number = null;
    public Vat1Rate: number = null;
    public Vat2Rate: number = null;
    public PriceGroups: any[] = [];
    public VatIndex: number = null;
    public Sources: ArticleSource[] = [];
    public Stocks: ArticleStock[] = [];
    public Images: ArticleImageRelation[] = [];
    public PriceNet: number = null;
    public CostPriceNet: number = null;
    public Price: number = null;
    public CostPrice: number = null;
    public ScaledPrices: ScaledPrice[] = [];
    public Files: ArticleFile[] = [];
    public Texts: any = null;
    public SubArticles: SubArticle[] = [];
    public HeightCm: number = null;
    public WidthCm: number = null;
    public LengthCm: number = null;
    public StockChanges: StockChange[] = [];
    public CustomFieldValues: CustomFieldValue[] = [];
    public ResolveParts: boolean = false;
    public SplitSetPrice: boolean = false;
    public Materials: string = null;
    public Tags: string = null;
    public InvoiceText: string = null;
    public ExportDescription: string = null;
    public BasicAttributes: string = null;
    public CommonProperties: ArticleCommonProperties = null;
    public DeliveryTimeInDays: number = null;

    constructor(obj?: Partial<Article>) {
        ctor(this, obj, {
            Collections: {
                Comments: ArticleComment,
                Categories: OwnCategory,
                Sources: ArticleSource,
                Stocks: ArticleStock,
                Images: ArticleImageRelation,
                ScaledPrices: ScaledPrice,
                Files: ArticleFile,
                SubArticles: SubArticle,
                StockChanges: StockChange,
                CustomFieldValues: CustomFieldValue,
            },
            Relations: {
                Category1: OwnCategory,
                Category2: OwnCategory,
                Category3: OwnCategory,
                CommonProperties: ArticleCommonProperties,
            },
        });
    }
}
