import {OrderToShip, ShippingProductService, ShippingProviderProduct} from "../entity";
import {Injectable} from "@angular/core";

export class SelectShippingProductRequest {
    public Order: OrderToShip = null;
    public Product: ShippingProviderProduct = null;
    public WithPreselect: boolean = false;

    constructor(obj?: Partial<SelectShippingProductRequest>) {
        ctor(this, obj);
    }
}

export abstract class SelectShippingProductPresenter {
    /** Preselect a shipping product */
    public abstract preselectShippingProduct(product: ShippingProviderProduct,
    ): void;

    /** Display the available shipping product services */
    public abstract displayAvailableShippingProductServices(order: OrderToShip,
                                                            services: ShippingProductService[],
    ): void;
}

@Injectable({providedIn: "root"})
export class SelectShippingProductUseCase implements IUseCase<SelectShippingProductRequest, SelectShippingProductPresenter> {
    public async execute(request: SelectShippingProductRequest,
                         presenter?: SelectShippingProductPresenter,
    ): Promise<void> {
        const {Order: order} = request;
        if (!order) {
            throw  new Error("A order is required");
        }
        const productServices = [...(request.Product?.ProductServices ?? [])];
        if (request.Product == null){
            order.ShippingProduct = null;
            order.ShippingProductId = null;
        } else {

            order.ShippingProduct = new ShippingProviderProduct(request.Product);
            order.ShippingProductId = order.ShippingProduct?.Id ?? request.Product.Id;
            const notAppliedOrderServices = order.OrderRelatedShippingServices.filter(
                x => !order.ShippingProduct.ProductServices.find(y => y.ServiceName === x.ServiceName),
            ).map(x => new ShippingProductService({...x, OnlyInOrder: true}));
            for (const service of productServices) {
                if (order.OrderRelatedShippingServices.find(s => s.ServiceName === service.ServiceName)) {
                    service.AlsoInOrder = true;
                }
            }

            if (notAppliedOrderServices.length) {
                productServices.push(...notAppliedOrderServices);
            }
            order.ShippingProduct.ProductServices = productServices;
        }
        presenter?.displayAvailableShippingProductServices(order, productServices);

        if (request.WithPreselect) {
            presenter?.preselectShippingProduct(request.Product);
        }
    }
}
