import {AbstractCollectionType, Constructor} from "@mintware-de/form-builder";
import {ValidatorFn, Validators} from "@angular/forms";
import {TableCollectionComponent} from "./table-collection.component";
import {TableCollectionOptions} from "./table-collection.options";
import {TableRowOptions} from "./table-row.options";
import {TableRow} from "./table-row.type";

export class TableCollection<TConfig>
    extends AbstractCollectionType<TableRowOptions<TConfig>, TableCollectionOptions<TableRowOptions<TConfig>>> {
    public readonly component: Constructor = TableCollectionComponent;
    public componentInstance: TableCollectionComponent;

    constructor(options: TableCollectionOptions<TableRowOptions<TConfig>, TConfig>) {
        super(TableRow, Object.assign({
            allowAdd: true,
            allowDelete: true,
            allowEdit: false,
            selectMode: "none",
            selectProperty: null,
            required: false,
        }, options));
    }

    public get validators(): ValidatorFn[] {
        const validators = [];
        if (this.options.required) {
            validators.push(Validators.required);
        }
        return validators;
    }
}
