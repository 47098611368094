import {ChangeDetectionStrategy, Component} from "@angular/core";
import {MatCardModule} from "@angular/material/card";

@Component({
    selector: "bb-loading-card",
    template: ` <mat-card> </mat-card> `,
    styleUrls: ["./bb-loading-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatCardModule],
})
export class BbLoadingCardComponent {}
