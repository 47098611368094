import {Component, Input, Inject} from "@angular/core";
import {AdminUserRepository} from "@app/app/common/service/da/admin-user.repository";

@Component({
    selector: "bb-external-logins-list",
    templateUrl: "external-logins-list.component.html",
})
export class ExternalLoginsListComponent {
    @Input() public user: IBillbeeUser = null;

    public readonly providerNames: { [key: string]: string } = {
        "facebook": "Facebook",
        "google": "Google",
        "totp": "2FA (TOTP)",
    };

    constructor(
        @Inject("$alert") private readonly alertService: AlertService,
        private readonly adminUserRepo: AdminUserRepository,
    ) {
    }

    public confirmDeleteExternalLogin(index: number): void {
        this.alertService.askYesNo(
            "Soll der Login wirklich gelöscht werden?",
            "Externen Login löschen",
            () => this.deleteExternalLogin(index));
    }

    private async deleteExternalLogin(index: number): Promise<void> {
        const login = this.user.ExternalLogins[index];

        try {
            await this.adminUserRepo.deleteExternalLogin({
                Id: login.Id,
                UserName: this.user.Username,
            });
            this.alertService.warning("Externen Login gelöscht");
            this.user.ExternalLogins.splice(index, 1);
        } catch (e) {
            this.alertService.failureFromHttpResult(e, "Fehler beim Löschen");
        }
    }
}
