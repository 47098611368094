import {AbstractLayoutComponent, AbstractLayoutType} from "@mintware-de/form-builder";
import {ToolbarOptions} from "./toolbar.options";
import {Component} from "@angular/core";

@Component({
    selector: "[bb-form-toolbar]",
    template: `
        <mat-toolbar>
            <mat-toolbar-row>
                <div fxLayout="row" fxLayoutGap="8px">
                    <ng-container
                        *ngFor="let e of mwFieldType.options.model | keyvalue:orderAsGiven; index as index"
                        mwFormField
                        [mwFormGroup]="mwElement"
                        [mwElement]="elements[e.key]"
                        [mwFieldType]="e.value"
                        [mwPath]="fieldPaths[e.key]"
                        [mwIndex]="indexFromParent">
                    </ng-container>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
    `,
})
export class ToolbarComponent extends AbstractLayoutComponent<AbstractLayoutType<ToolbarOptions>> {

}
