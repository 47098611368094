<h2 mat-dialog-title>{{ data.title | translate }}</h2>
<mat-dialog-content>
	<div class="spinner-container">
		<mat-progress-spinner color="primary" mode="indeterminate" strokeWidth="2"></mat-progress-spinner>
		<img [src]="data.logo">
	</div>
	<div class="fallback" [innerHTML]="data.text | translate">
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-stroked-button mat-dialog-close>{{ "button.cancel" | translate }}</button>
</mat-dialog-actions>