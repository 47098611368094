import {OrderState} from "@app/app/order/core";
import {OrderWeightType, ShippingPositionTextSource} from "@app/app/shipping/core/enum";

export class ShippingOptions {
    public MarkAsShippedByDefault: boolean = true;
    public ShowCustomerMessagesInBulkShipDlg: boolean = false;
    public ShowArticles: boolean = false;
    public ShowArticleProperties: boolean = false;
    public ArticleTextSource: ShippingPositionTextSource = ShippingPositionTextSource.invoiceText;
    public OrderState?: OrderState = null;
    public DefaultShippingProductId?: number = null;
    public DefaultWeight: OrderWeightType = OrderWeightType.ManualThenCalculated;
    public TransferAllTrackingNumbers: boolean = false;
    public TransferEmail: boolean = true;
    public TransferPhone: boolean = true;
    public ObfuscateEmail: boolean = false;
    public MapShippingProfileFromSoldArticles: boolean = false;
    public UseBulkshipV2: boolean = null;

    constructor(obj?: Partial<ShippingOptions>) {
        ctor(this, obj, {});
    }
}
