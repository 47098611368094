<select class="form-control" [(ngModel)]="font.FontName" (change)="Changed()">
    <option value="Arial" style="font-family:Arial,Arial,Helvetica,sans-serif">Arial</option>
    <option value="Arial Black" style="font-family:Arial Black,Arial Black,Gadget,sans-serif">Arial Black</option>
    <option value="Bauhaus 93" style="font-family:'Bauhaus 93'">Bauhaus 93</option>
    <option value="Berlin Sans FB" style="font-family:Berlin Sans FB">Berlin Sans FB</option>
    <option value="Calibri" style="font-family:Calibri">Calibri</option>
    <option value="Comic Sans MS" style="font-family:Comic Sans MS,Comic Sans MS,cursive">Comic Sans MS</option>
    <option value="Courier New" style="font-family:Courier New,Courier New,Courier,monospace">Courier New</option>
    <option value="Estrangelo Edessa" style="font-family:Estrangelo Edessa">Estrangelo Edessa</option>
    <option value="Franklin Gothic Medium" style="font-family:Franklin Gothic Medium">Franklin Gothic Medium</option>
    <option value="Gautami" style="font-family:Gautami">Gautami</option>
    <option value="Georgia" style="font-family:Georgia,Georgia,serif">Georgia</option>
    <option value="Impact" style="font-family:Impact,Charcoal,sans-serif">Impact</option>
    <option value="Leelawadee" style="font-family:Leelawadee">Leelawadee</option>
    <option value="Lovelo Black" style="font-family:Lovelo Black">Lovelo Black</option>
    <option value="Lovelo Line Bold" style="font-family:Lovelo Line Bold">Lovelo Line Bold</option>
    <option value="Lovelo Line Light" style="font-family:Lovelo Line Light">Lovelo Line Light</option>
    <option value="Lucida Console" style="font-family:Lucida Console,Monaco,monospace">Lucida Console</option>
    <option value="Lucida Sans Unicode" style="font-family:Lucida Sans Unicode,Lucida Grande,sans-serif">Lucida Sans Unicode</option>
    <option value="Open Sans" style="font-family:Open Sans">Open Sans</option>
    <option value="Open Sans Light" style="font-family:Open Sans Light">Open Sans Light</option>
    <option value="Open Sans Semibold" style="font-family:Open Sans Semibold">Open Sans Semibold</option>
    <option value="Palatino Linotype" style="font-family:Palatino Linotype,Book Antiqua,Palatino,serif">Palatino Linotype</option>
    <option value="Tahoma" style="font-family:Tahoma,Geneva,sans-serif">Tahoma</option>
    <option value="Times New Roman" style="font-family:Times New Roman,Times,serif">Times New Roman</option>
    <option value="Trebuchet MS" style="font-family:Trebuchet MS,Helvetica,sans-serif">Trebuchet MS</option>
    <option value="Verdana" style="font-family:Verdana,Geneva,sans-serif">Verdana</option>
    <option *ngFor="let uFont of userFonts" value="{{uFont}}" style="font-family: '{{uFont}}'">{{uFont}}</option>
</select>