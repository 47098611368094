import {AbstractLayoutType, Constructor, IGroupTypeOptions} from "@mintware-de/form-builder";
import {RawTextOptions} from "@app/app/common/form/layout/raw-text/raw-text.options";
import {RawTextComponent} from "@app/app/common/form/layout/raw-text/raw-text.component";

export class RawText<T> extends AbstractLayoutType<RawTextOptions<T> & IGroupTypeOptions<T>, T> {
    public readonly component: Constructor = RawTextComponent;

    constructor(options: RawTextOptions<T>) {
        super({
            model: {},
            ...options,
        });
    }
}
