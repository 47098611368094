import {SimpleCollection} from "@app/app/common/form/collection/simple-collection/simple-collection.type";
import {IGroupTypeOptions} from "@mintware-de/form-builder";
import {Customer, CustomerMetaInformation, CustomerMetaType} from "@app/app/customer/core";
import {
    Button,
    ColumnLayout,
    FormGroup,
    Header,
    HiddenField,
    IconCheckbox,
    RowLayout,
    SelectField,
    TextField,
} from "@app/app/common/form/types";

export class PhoneNumberCollection extends ColumnLayout<Customer> {
    private readonly phoneNumbers: SimpleCollection<any>;

    private defaultMapping: { [key: string]: number } = {};

    constructor() {
        super({model: {}});
        this.phoneNumbers = new SimpleCollection<IGroupTypeOptions<CustomerMetaInformation>>(FormGroup, {
            noDataText: "text.no_phone_numbers",
            showAddButton: false,
            afterInit: () => {
                for (let i = 0; i < this.phoneNumbers.control.controls.length; i++) {
                    const row = this.phoneNumbers.control.controls[i];
                    const defaults = (row as any).get("IsDefaultFor").value as string[];
                    for (const d of defaults) {
                        if(!(d in this.defaultMapping)){
                            this.defaultMapping[d] = i;
                        }
                    }
                }
            },
            entrySettings: {
                model: {
                    Id: new HiddenField(),
                    Name: new HiddenField(),
                    Type: new HiddenField(),
                    _row: new RowLayout<CustomerMetaInformation>({
                        flex: ["auto", 45],
                        alignCrossAxis: "baseline",
                        model: {
                            Value: new TextField({label: ""}),
                            IsDefaultFor: new SelectField({
                                label: "label.use_phone_number_as",
                                options: {
                                    "phone1": "label.phone1",
                                    "phone2": "label.phone2",
                                    "fax": "label.fax",
                                },
                                multiple: true,
                                required: false,
                                isOptionAvailable: (index: number, option: string) =>
                                    !(option in this.defaultMapping) || this.defaultMapping[option] === index,
                                onChange: (_, __, component) => this._updateDefault(component),
                            }),
                        },
                    }),
                },
            },
        });
        Object.assign(this.options.model, {
            _phoneNumbersTitle: new Header({
                icon: "fa-phone-alt",
                text: "label.phone_numbers",
            }),
            PhoneNumbers: this.phoneNumbers,
            _addButton: new Button({
                text: "button.add_new_phone_number",
                appearance: "raised",
                onClick: () => {
                    this.phoneNumbers.componentInstance.addEntry();
                    const lastIndex = this.phoneNumbers.control.length - 1;
                    this.phoneNumbers.control.get([lastIndex]).patchValue(new CustomerMetaInformation({
                        Type: CustomerMetaType.PhoneNumber,
                    }));
                },
                type: "button",
            }),
        });
    }

    private _updateDefault(component): void {
        for (const key of Object.keys(this.defaultMapping)) {
            if (this.defaultMapping[key] == component.mwIndex) {
                delete this.defaultMapping[key];
            }
        }

        for (const selection of component.mwElement.value) {
            if (!(selection in this.defaultMapping)) {
                this.defaultMapping[selection] = component.mwIndex;
            }
        }
    }
}
