export class OrderToShipMessage {
    public Creationdate: Date = null;
    public Creator: string = null;
    public Message: string = null;
    public IsCustomerMessage: boolean = false;

    constructor(obj?: Partial<OrderToShipMessage>) {
        ctor(this, obj, {
            DateFields: ["Creationdate"],
        });
    }
}
