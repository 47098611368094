export class OrderToShipDetail {
    public Id: number = null;
    public Shorttext: string = null;
    public ArticleSKU: string = null;
    public ArticleEAN: string = null;
    public Amount: number = null;
    public MainImageUrl: string = null;
    public Weight: number = null;
    public PropertiesAsHtmlString: string = null;
    public Dimensions: string = null;
    public ArticleId: string = null;
    public IsPacked: boolean = false;
    public IsDeactivated: boolean = false;

    constructor(obj?: Partial<OrderToShipDetail>) {
        ctor(this, obj);

    }
}
