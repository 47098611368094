export class EuDeliveryThreshold {
    public SrcCountryCode: string = null;
    public CountryCode: string = null;
    public FromDate: Date = new Date();

    constructor(obj?: Partial<EuDeliveryThreshold>) {
        if (!obj) {
            return;
        }
        Object.keys(this).forEach(k => {
            if (k in obj) {
                this[k] = obj[k];
            }
        });

        this.FromDate = new Date(this.FromDate);
    }
}
