import {CountryField, HiddenField, RowLayout, SelectField, TextField} from "@app/app/common/form/types";
import {AbstractType} from "@mintware-de/form-builder";
import {AddressTypeEnum} from "@app/app/shipping/core";

export class CustomerAddressFormModel {
    Id = new HiddenField();
    IsUsedInOrders = new HiddenField();
    AddressType: AbstractType<any> = null;
    CustomerId = new HiddenField();
    FullName = new HiddenField();
    PhoneNumbers = new HiddenField();
    MailAddresses = new HiddenField();
    Company = new TextField({
        label: "label.company",
        tooltip: "tooltip.company",
        required: false,
    });
    Salutation = new SelectField({
        label: "label.salutation",
        tooltip: "tooltip.salutation",
        options: {
            0: "-",
            1: "label.salutation_mr",
            2: "label.salutation_mrs",
            3: "label.salutation_married_couple",
            4: "label.salutation_company",
        },
        required: null,
    });
    Title = new TextField({
        label: "label.title",
        tooltip: "tooltip.title",
        required: false,
    });
    Name2 = new TextField({
        label: "label.name2",
        tooltip: "tooltip.recipient_name2",
        required: false,
    });
    nameRow = new RowLayout({
        model: {
            FirstName: new TextField({
                label: "label.first_name",
                tooltip: "tooltip.recipient_first_name",
                required: false,
            }),
            LastName: new TextField({
                label: "label.last_name",
                tooltip: "tooltip.recipient_last_name",
                required: false,
            }),
        },
    });
    streetRow = new RowLayout({
        flex: [70, 30],
        model: {
            Street: new TextField({
                label: "label.street",
                tooltip: "tooltip.recipient_street",
            }),
            Housenumber: new TextField({
                label: "label.house_number",
                tooltip: "tooltip.recipient_house_number",
                required: false,
            }),
        },
    });
    AddressAddition = new TextField({
        label: "label.address_addition",
        tooltip: "tooltip.recipient_address_addition",
        required: false,
    });
    cityRow = new RowLayout({
        flex: [30, 70],
        model: {
            Zip: new TextField({
                label: "label.zip_code",
                tooltip: "tooltip.recipient_zip_code",
                required: false,
            }),
            City: new TextField({
                label: "label.city",
                tooltip: "tooltip.recipient_city",
            }),
        },
    });
    State = new TextField({
        label: "label.state",
        tooltip: "tooltip.recipient_state",
        required: false,
    });
    CountryCode = new CountryField({
        label: "label.country",
        tooltip: "tooltip.recipient_country",
    });

    constructor(showAddressTypeField: boolean = false) {
        this.AddressType = showAddressTypeField ?
            new SelectField({
                label: "label.address_type",
                options: {
                    [AddressTypeEnum.Invoice]: "label.address_type_invoice",
                    [AddressTypeEnum.Ship]: "label.address_type_ship",
                },
            })
            : new HiddenField();
    }
}
