import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {ListItemCardComponent} from "../list-item-card/list-item-card.component";

@Component({
    selector: "bb-new-list-item-card",
    templateUrl: "./new-list-item-card.component.html",
    styleUrls: ["./new-list-item-card.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ListItemCardComponent, TranslateModule, MatIconModule],
})
export class BbNewListItemCardComponent {
	@Input() label = "label.new";
	@Output() onClick = new EventEmitter<void>();
}
