import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {NavigationService} from "@bb-core/service";

@Injectable({providedIn: "root"})
export class RouterNavigationService implements NavigationService {
    constructor(private readonly router: Router) {
    }

    public navigateToShipping(): Promise<boolean> {
        return this.router.navigateByUrl("/shipping");
    }

    public navigateToShipSingle(orderId: number): Promise<boolean> {
        return this.router.navigateByUrl(`/shipping/single/${orderId}`);
    }

    public navigateToCloudStorages(): Promise<boolean> {
        return this.router.navigateByUrl("/settings/cloud/devices");
    }

    public navigateToCustomerList(): Promise<boolean> {
        return this.router.navigateByUrl("/customer");
    }

    public navigateToEditCustomer(customerId: number): Promise<boolean> {
        return this.router.navigateByUrl(`/customer/${customerId}`);
    }

    public async navigateToBillbeeAccountDeleted(hintText: string): Promise<boolean> {
        const encodedHintText = encodeURIComponent(hintText);
        document.location.href = `/app_v2/account-deleted?hinttext=${encodedHintText}`;
        return true;
    }
}
