import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {PopupType} from "../../shared/model/popup-type.enum";
import {PopupService} from "../../shared/service/popup.service";

@UntilDestroy()
@Component({
    selector: "bb-shopify-type-selection",
	templateUrl: "shopify-type-selection.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopifyTypeSelectionComponent implements OnInit {
    private shop: string = "";
    private state: string = "";

    constructor(private readonly route: ActivatedRoute, private readonly popupService: PopupService) {}

    public ngOnInit(): void {
		this.route.queryParams.pipe(untilDestroyed(this)).subscribe((p) => {
			this.popupService.emitMessage(PopupType.ShopifyShopName, p);
			this.shop = p.shop;
            this.state = p.state;
        });
    }

    public openLink(type: string): void {
        window.location.href = `/sync/shopifyauth?shop=${this.shop}&state=${this.state}&connectionType=${type}`;
    }
}
