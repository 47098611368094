export class ScaledPrice {
    public Id: number = 0;
    public MaxAmount: number = null;
    public Price: number = null;
    public PriceGroups: any[] = [];

    constructor(obj?: Partial<ScaledPrice>) {
        ctor(this, obj);
    }
}
