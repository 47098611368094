import {Injectable} from "@angular/core";
import {Shop} from "../../model/shop";
import {ShopType} from "../../model/shop-type.enum";

@Injectable({
    providedIn: "root",
})
export class RealShop implements Shop {
    public readonly key = "real";
    public readonly name = "real";
    public readonly type = ShopType.Marketplace;
}
