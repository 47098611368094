import { AsyncPipe, NgIf } from "@angular/common";
import { Component, DestroyRef, EventEmitter, OnInit, Output, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, NonNullableFormBuilder, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatTooltipModule } from "@angular/material/tooltip";
import * as model from "@app/app/common/model/general-settings";
import { GeneralSettingsService } from "@app/app/common/service/da/general-settings.service";
import { BbSlideToggleComponent } from "@app/app/shared/ui/form/bb-slide-toggle/bb-slide-toggle.component";
import { BbLoadingCardComponent } from "@app/app/ui/bb-loading-card/bb-loading-card.component";
import { TranslateModule } from "@ngx-translate/core";
import { BehaviorSubject, from, take } from "rxjs";
import { ErrorHandlerService, MessagingService } from "../../../../shared/core/service";
import { BbSelectComponent } from "../../../../shared/ui/form/bb-select/bb-select.component";

@Component({
    selector: "bb-general-shop-settings",
    templateUrl: "./general-shop-settings.component.html",
    styleUrls: ["./general-shop-settings.component.scss"],
    standalone: true,
    imports: [
        MatCardModule,
        TranslateModule,
        MatButtonModule,
        BbSelectComponent,
        BbSlideToggleComponent,
        ReactiveFormsModule,
        MatTooltipModule,
        BbLoadingCardComponent,
        AsyncPipe,
        NgIf,
    ],
})
export class GeneralShopSettingsComponent implements OnInit {
    @Output() onClickSave = new EventEmitter<void>();

    private readonly settingsService = inject(GeneralSettingsService);
    private readonly errorHandlerService = inject(ErrorHandlerService);
    private readonly messagingService = inject(MessagingService);
    private readonly formBuilder = inject(NonNullableFormBuilder);
    private readonly destroyRef = inject(DestroyRef);

    public readonly formGroup = this.formBuilder.group({
        UseEmailForLookup: new FormControl(false),
        UseAddressForLookup: new FormControl(false),
        ImportInternalNoteTarget: new FormControl("InternalNote"),
        AddAutomaticPaymentOnImportPayed: new FormControl(false),
        ParseInternalNoteForTags: new FormControl(false),
        AutoConvertOrdersWhileImporting: new FormControl(false),
    });

    public readonly internalNoteTargetOptions: KeyValuePair<string, string>[] = [
        { Key: "label.own_notes_for_order", Value: "InternalNote" },
        { Key: "label.individual_text_for_invoice", Value: "CustomInvoiceNote" },
    ];

    public isLoading$ = new BehaviorSubject<boolean>(true);

    public async ngOnInit() {
        from(this.settingsService.getShopSettings())
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(settings => {
                this.formGroup.patchValue(settings);
                this.isLoading$.next(false);
            });
    }

    public async saveSettings() {
        this.onClickSave.emit();
        from(this.settingsService.saveShopSettings(this.formGroup.value as model.ShopSettings))
            .pipe(take(1))
            .subscribe({
                next: () => this.messagingService.showSnackBar("flash.settings_saved"),
                error: e => this.errorHandlerService.handleException(e),
            });
    }
}
