import { Injectable } from "@angular/core";
import * as ngx from "@ngx-resource/core";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
     pathPrefix: "/api/angular/admin/user",
})
export class AdminUserRepository extends ngx.Resource {

    @ngx.ResourceAction({
        path: "/delete-external-login/{Id}/{UserName}",
        method: ngx.ResourceRequestMethod.Delete,
    })
    public deleteExternalLogin: ngx.IResourceMethod<{ Id: number, UserName: string }, void>;

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }
}
