import {CommonModule} from "@angular/common";
import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {MatDividerModule} from "@angular/material/divider";

@Component({
    selector: "bb-text-divider",
    templateUrl: "./bb-text-divider.component.html",
    styleUrls: ["./bb-text-divider.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MatDividerModule],
})
export class BbTextDividerComponent {
    @Input() public highlight = false;
}
