import { Component, Inject } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { TextField } from "@app/app/common/form/types";
import { AccountService } from "@app/app/common/service/da/account.service";
import { FormBuilderModule, FormModel } from "@mintware-de/form-builder";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    selector: "bb-request-2fa-dialog",
    template: `
        <h3 mat-dialog-title>{{ "Zwei-Faktor Bestätigung" | translate }}</h3>
        <mat-dialog-content>
            <p>{{ "Bitte gib den Code ein, der auf deinem Authenticator angezeigt wird." | translate }}</p>
            <mw-form-builder
                #form
                [mwFormModel]="formModel"
                [mwFormData]="formData"
                (mwFormSubmit)="submitForm($event)"
            ></mw-form-builder>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{ "Abbrechen" | translate }}</button>
            <button mat-button (click)="form.submit()">
                {{ "Bestätigen" | translate }}
            </button>
        </mat-dialog-actions>
    `,
    standalone: true,
    imports: [
        TranslateModule,
        MatButtonModule,
        TranslateModule,
        FormBuilderModule,
        MatDialogModule,
        ReactiveFormsModule,
    ],
})
export class Request2FaDialogComponent {
    public formData = { Otp: "" };
    public formModel: FormModel = {
        Otp: new TextField({
            label: "Code",
        }),
    };

    public constructor(
        private readonly dialogRef: MatDialogRef<Request2FaDialogComponent>,
        private readonly accountService: AccountService,
        @Inject("$alert") private readonly alertService: AlertService,
        @Inject(MAT_DIALOG_DATA) private readonly data: { withVerify: boolean },
    ) {}

    public async submitForm($event: {Otp: string}): Promise<void> {
        try {
            if (this.data == null || this.data.withVerify) {
                await this.accountService.verifyOtp($event);
                this.dialogRef.close(true);
            } else {
                this.dialogRef.close($event.Otp);
            }
        } catch (e) {
            this.alertService.failureFromHttpResult(e, "Es ist ein Fehler aufgetreten", true);
        }
    }
}
