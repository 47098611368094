import {Pipe, PipeTransform} from "@angular/core";
import {ValidationErrors} from "@angular/forms";
import {TranslationService} from "@bb-core/service";

@Pipe({
    name: "bbErrors",
})
export class BbFormErrorPipe implements PipeTransform {
    private readonly defaultErrors: ValidationErrors = {
        required: "error.required",
		min: "error.min",
		max: "error.max",
        email: "error.email",
    };

    constructor(private readonly translator: TranslationService,
    ) {
    }

    public transform(controlErrors: ValidationErrors | null, customErrors: ValidationErrors): string[] {
        const errors = controlErrors !== null ? Object.keys(controlErrors) : [];

        if (errors.length === 0) {
            return [];
        }

        const allErrorMessages = {...this.defaultErrors, ...customErrors};
        return Object.keys(allErrorMessages)
            .filter(e => errors.includes(e))
            .map(key => this.translator.translate(allErrorMessages[key], controlErrors ? controlErrors[key] : undefined));
    }
}
