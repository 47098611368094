import {Customer, CustomerExportSelection} from "@app/app/customer/core";
import {Column} from "@app/app/grid/core";

export class CustomerExportSettings {
    public Selection: CustomerExportSelection = CustomerExportSelection.all;
    public FileFormat: string = "";
    public SelectedIds: number[] = null;
    public Columns: Column<Customer>[] = null;
    public SignalRClientId: string = "";

    constructor(obj?: Partial<CustomerExportSettings>) {
        ctor(this, obj);
    }
}
