import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Incident, SystemStatusString, SystemStatusSummary} from "./models";

@Injectable({providedIn: "root"})
export class SystemStatusService {

    public readonly status$: BehaviorSubject<SystemStatusSummary> = new BehaviorSubject<SystemStatusSummary>(new SystemStatusSummary());
    public readonly statusAsString$: BehaviorSubject<SystemStatusString> = new BehaviorSubject<SystemStatusString>("ok");
    public readonly statusIcon$: BehaviorSubject<string> = new BehaviorSubject<string>("fa-check-circle");
    public readonly statusColorClass$: BehaviorSubject<string> = new BehaviorSubject<string>("fa-check-circle");
    public readonly incidents$: BehaviorSubject<Incident[]> = new BehaviorSubject<Incident[]>([]);

    constructor(private readonly httpClient: HttpClient,
    ) {
        const oneMinute = 60 * 1000;
        window.setInterval(this._updateStatus.bind(this), oneMinute);
        this._updateStatus().then();
    }

    private async _updateStatus(): Promise<void> {
        const result = await (this.httpClient.get("https://status.billbee.io/api/v2/summary.json").toPromise()) as any;
        const summary = new SystemStatusSummary(result);
        this.status$.next(summary);
        this.incidents$.next(summary.incidents);
        switch (summary.status.indicator) {
            case "minor":
                this.statusAsString$.next("warning");
                this.statusIcon$.next("fa-exclamation-circle");
                this.statusColorClass$.next("fill-warning");
                break;
            case "major":
            case "critical":
                this.statusAsString$.next("error");
                this.statusIcon$.next("fa-times-circle");
                this.statusColorClass$.next("fill-error");
                break;
            case "maintenance":
                this.statusAsString$.next("maintenance");
                this.statusIcon$.next("fa-tools");
                this.statusColorClass$.next("fill-info");
                break;
            default:
                this.statusAsString$.next("ok");
                this.statusIcon$.next("fa-check-circle");
                this.statusColorClass$.next("");
                break;
        }
    }
}
