import {HttpClient} from "@angular/common/http";
import {ChangeDetectionStrategy, Component, Inject, OnInit} from "@angular/core";
import {SHOPS} from "@app/app/partners/shops/shops.token";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ShopViewModelDto} from "@partners/shops/data/shop-view-model.dto";
import {Shop} from "@partners/shops/model/shop";
import {ShopKey} from "@partners/shops/model/shop-key";
import {BehaviorSubject, EMPTY, Observable, Subject, merge} from "rxjs";
import {map, scan, tap} from "rxjs/operators";
import {MessagingService} from "../../../shared/core/service";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantFinishTaskComponent} from "../finish/finish.component";

@UntilDestroy()
@Component({
    selector: "bb-onboarding-assistant-select-channel",
    templateUrl: "./select-channel.component.html",
    styleUrls: ["../tasks.scss", "./select-channel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantSelectChannelTaskComponent implements OnInit {
	private readonly addedShop$ = new Subject<ShopKey[]>();
	
	public readonly hasAddedShops$ = new BehaviorSubject<boolean>(false);

    public shops$: Observable<{shop: Shop; disabled: boolean}[]> = EMPTY;

    constructor(
        @Inject(SHOPS) private readonly shops: Shop[],
        private readonly httpClient: HttpClient,
		private readonly onboardingStore: OnboardingAssistantComponentStore,
		private readonly messagingService: MessagingService,
    ) {}

    public ngOnInit(): void {
        this.shops$ = merge(
            this.httpClient
                .get<{data: ShopViewModelDto[]}>(`/api/angular/settings/shop?displayMode=5`)
                .pipe(map(({data}) => data.map(({Partner}) => Partner as ShopKey))),
            this.addedShop$,
        ).pipe(
            scan((total, newKeys) => total.concat(newKeys)),
            map((addedShopKeys) =>
                this.shops
                    .filter(({saveShopWithNecessarySettings}) => !!saveShopWithNecessarySettings)
                    .map((shop) => ({shop, disabled: addedShopKeys.includes(shop.key)})),
            ),
            tap((shops) => this.hasAddedShops$.next(shops.filter(({disabled}) => !!disabled).length > 0)),
        );
    }

    public next(): void {
        this.onboardingStore.nextTask(OnboardingAssistantFinishTaskComponent);
    }

    public add(shop: Shop) {
		shop.saveShopWithNecessarySettings?.().pipe(untilDestroyed(this)).subscribe(() => {
			this.addedShop$.next([shop.key]);
			this.messagingService.showSnackBar("flash.added_shop");
		});
    }
}
