export class OwnCategory {
    public Id: number = null;
    public Name: string = null;
    public ParentId: number = null;
    public Children: OwnCategory[] = [];

    constructor(obj?: Partial<OwnCategory>) {
        ctor(this, obj, {
            Collections: {
                Children: OwnCategory,
            },
        });
    }
}
