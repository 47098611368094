import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { BbInputComponent } from "@app/app/shared/ui/form/bb-input/bb-input.component";
import { ErrorHandlerService, MessagingService } from "@bb-core/service";
import { FormBuilderModule } from "@mintware-de/form-builder";
import { tapResponse } from "@ngrx/operators";
import { TranslateModule } from "@ngx-translate/core";
import { AccountRepository } from "../../data/account.repository";

@Component({
    selector: "bb-add-2fa-dialog",
    templateUrl: "add2-fa-dialog.component.html",
    styleUrl: "add2-fa-dialog.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslateModule,
        MatDialogModule,
        NgIf,
        FormBuilderModule,
        MatButtonModule,
        BbInputComponent,
        ReactiveFormsModule,
        MatProgressSpinner,
    ],
})
export class Add2FaDialogComponent {
    private readonly dialogRef = inject(MatDialogRef);
    private readonly accountRepository = inject(AccountRepository);
    private readonly messagingService = inject(MessagingService);
    private readonly errorHandlerService = inject(ErrorHandlerService);

    protected initModel = toSignal(this.accountRepository.getInitial2FaData(), { initialValue: null });
    protected codeControl = new FormControl("", {validators: [Validators.required], nonNullable: true});

    public activate2Fa(): void {
        const initModel = this.initModel();
        if (this.codeControl.invalid || !initModel) {
            this.codeControl.markAsTouched();
            return;
        }

        this.accountRepository
            .activate2Fa({
                Otp: this.codeControl.value,
                Period: initModel.Period,
                SecretKey: initModel.SecretKey,
            })
            .pipe(
                tapResponse(
                    _ => {
                        this.messagingService.showSnackBar("account_settings.activated_2fa");
                        this.dialogRef.close(true);
                    },
                    e => this.errorHandlerService.handleException(e),
                ),
            )
            .subscribe();
    }
}
