import {Inject, Injectable} from "@angular/core";
import {BB_GRID_REPO, GridRepository} from "@app/app/grid/core";
import {ErrorHandlerService, Message, MessagingService, TranslationService} from "@bb-core/service";


export class EditCellContentRequest<T> {
    public Id: number = 0;
    public Changes: Partial<T> = {};

    constructor(obj?: Partial<EditCellContentRequest<T>>) {
        ctor(this, obj);
    }
}


@Injectable({providedIn: "any"})
export class EditCellContentUseCase implements IUseCase<EditCellContentRequest<any>, void> {
    constructor(@Inject(BB_GRID_REPO) private readonly repo: GridRepository<any>,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
                private readonly errorHandler: ErrorHandlerService,
    ) {
    }

    public async execute<T>(request: EditCellContentRequest<T>, presenter?: void): Promise<void> {
        try {
            await this.repo.updateProperty(request.Id, request.Changes);
            const message = this.translator.translate("flash.row_edited");
            await this.messaging.showMessage(Message.transient({message}));
        } catch (e) {
            await this.errorHandler.handleException(e);
        }
    }

}
