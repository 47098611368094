import {AbstractType, Constructor} from "@mintware-de/form-builder";
import {AddressGroupOptions} from "@app/app/common/form/group/address-group/address-group.options";
import {AddressGroupComponent} from "@app/app/common/form/group/address-group/address-group.component";

export class AddressGroup extends AbstractType<AddressGroupOptions> {
    public readonly component: Constructor = AddressGroupComponent;

    constructor(obj: AddressGroupOptions) {
        super({
            ...obj,
        });
    }
}
