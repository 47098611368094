import {InjectionToken, inject} from "@angular/core";
import {Shop} from "./model/shop";
import {AfterbuyShop} from "./providers/afterbuy/afterbuy.shop";
import {AmazonShop} from "./providers/amazon/amazon.shop";
import {AvocadostoreShop} from "./providers/avocadostore/avocadostore.shop";
import {AzooShop} from "./providers/azoo/azoo.shop";
import {BigCartelShop} from "./providers/bigcartel/bigcartel.shop";
import {BillbeeShop} from "./providers/billbee/billbee.shop";
import {CdiscountShop} from "./providers/cdiscount/cdiscount.shop";
import {CommerceSeoShop} from "./providers/commerceseo/commerceseo.shop";
import {CsCartShop} from "./providers/cscart/cscart";
import {EbayShop} from "./providers/ebay/ebay.shop";
import {EcwidShop} from "./providers/ecwid/ecwid.shop";
import {EPagesShop} from "./providers/epages/epages.shop";
import {EtsyShop} from "./providers/etsy/etsy.shop";
import {FairfoxShop} from "./providers/fairfox/fairfox.shop";
import {FlowShop} from "./providers/flow/flow.shop";
import {GambioGx3Shop} from "./providers/gambio-gx3/gambio-gx3.shop";
import {GambioGx4hop} from "./providers/gambio-gx4/gambio-gx4.shop";
import {HoodShop} from "./providers/hood/hood.shop";
import {InteraktivShopShop} from "./providers/interaktivshop/interaktivshop.shop";
import {JimdoShop} from "./providers/jimdo/jimdo.shop";
import {KasuwaShop} from "./providers/kasuwa/kasuwa.shop";
import {KauflandShop} from "./providers/kaufland/kaufland.shop";
import {KayamoShop} from "./providers/kayamo/kayamo.shop";
import {LenaShop} from "./providers/lena/lena.shop";
import {LightspeedShop} from "./providers/lightspeed/lightspeed.shop";
import {LimangoShop} from "./providers/limango/limango.shop";
import {MagentoShop} from "./providers/magento/magento.shop";
import {Magento2Shop} from "./providers/magento2/magento2.shop";
import {ManualShop} from "./providers/manual/manual.shop";
import {NothsShop} from "./providers/noths/noths.shop";
import {OttoShop} from "./providers/otto/otto.shop";
import {OxidShop} from "./providers/oxid/oxid.shop";
import {PayPalShop} from "./providers/paypal/paypal.shop";
import {PrestaShop} from "./providers/presta/presta.shop";
import {ProductswithloveShop} from "./providers/productswithlove/productswithlove.shop";
import {RakutenShop} from "./providers/rakuten/rakuten.shop";
import {RealShop} from "./providers/real/real.shop";
import {SelekktShop} from "./providers/selekkt/selekkt.shop";
import {ServerspotShop} from "./providers/serverspot/serverspot.shop";
import {ShopifyShop} from "./providers/shopify/shopify.shop";
import {ShopwareShop} from "./providers/shopware/shopware.shop";
import {Shopware6Shop} from "./providers/shopware6/shopware6.shop";
import {ShorePosShop} from "./providers/shorepos/shorepos.shop";
import {SleekshopShop} from "./providers/sleekshop/sleekshop.shop";
import {SquarespaceShop} from "./providers/squarespace/squarespace.shop";
import {SugarTrendsShop} from "./providers/sugartrends/sugartrends.shop";
import {VersaCommerceShop} from "./providers/versacommerce/versacommerce.shop";
import {WerkyShop} from "./providers/werky/werky.shop";
import {WishShop} from "./providers/wish/wish.shop";
import {WixShop} from "./providers/wix/wix.shop";
import {WooCommerceShop} from "./providers/woocommerce/woocommerce.shop";
import {XtCommerceShop} from "./providers/xtcommerce/xtcommerce.shop";
import {ZalandoShop} from "./providers/zalando/zalando.shop";

export const SHOPS = new InjectionToken<Shop[]>("SHOPS", {
    factory: () =>
        [
            inject(AmazonShop),
            inject(EtsyShop),
            inject(WooCommerceShop),
            inject(ShopifyShop),
            inject(EbayShop),
            inject(KauflandShop),
            inject(JimdoShop),
            inject(OttoShop),
            inject(AvocadostoreShop),
            inject(CdiscountShop),
            inject(FairfoxShop),
            inject(HoodShop),
            inject(KasuwaShop),
            inject(KayamoShop),
            inject(LenaShop),
            inject(LimangoShop),
            inject(NothsShop),
            inject(ProductswithloveShop),
            inject(RakutenShop),
            inject(SelekktShop),
            inject(SleekshopShop),
            inject(SugarTrendsShop),
            inject(WerkyShop),
            inject(WishShop),
            inject(ZalandoShop),
            inject(AzooShop),
            inject(BigCartelShop),
            inject(CommerceSeoShop),
            inject(CsCartShop),
            inject(EcwidShop),
            inject(EPagesShop),
            inject(FlowShop),
            inject(GambioGx3Shop),
            inject(GambioGx4hop),
            inject(InteraktivShopShop),
            inject(LightspeedShop),
            inject(MagentoShop),
            inject(Magento2Shop),
            inject(OxidShop),
            inject(PayPalShop),
            inject(PrestaShop),
            inject(ServerspotShop),
            inject(ShopwareShop),
            inject(Shopware6Shop),
            inject(ShorePosShop),
            inject(SquarespaceShop),
            inject(VersaCommerceShop),
            inject(WixShop),
            inject(XtCommerceShop),
            inject(AfterbuyShop),
            inject(BillbeeShop),
            inject(ManualShop),
			inject(RealShop),
        ].sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0,
        ),
});
