<h3 mat-dialog-title>{{ "account_settings.2fa_confirmation" | translate }}</h3>
@if (initModel()) {
    <mat-dialog-content>
        <p>{{ "account_settings.scan_2fa_qr_code" | translate }}</p>
        <div class="text-center">
            <img [src]="initModel()?.QrCodeDataUrl" alt="2FA QR Code" />
        </div>
        <bb-input [formControl]="codeControl" [label]="'general.code' | translate" required></bb-input>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{ "general.cancel" | translate }}</button>
        <button mat-flat-button color="primary" (click)="activate2Fa()">
            {{ "general.connect" | translate }}
        </button>
    </mat-dialog-actions>
} @else {
    <mat-dialog-content>
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </mat-dialog-content>
}
