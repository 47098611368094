import {InputField} from "./input.type";
import {TextFieldOptions} from "./text-field.options";
import {Constructor} from "@mintware-de/form-builder";
import {TextFieldComponent} from "./text-field.component";
import {ValidatorFn, Validators} from "@angular/forms";

export class PasswordField extends InputField<TextFieldOptions> {

    public readonly component: Constructor = TextFieldComponent;

    constructor(options: TextFieldOptions) {
        super(Object.assign({
            required: true,
            type: "password",
        }, options));
    }

    protected buildValidators(): ValidatorFn[] {
        const validators: ValidatorFn[] = [];
        if (this.options.minLength != null) {
            validators.push(Validators.minLength(this.options.minLength));
        }

        if (this.options.maxLength) {
            validators.push(Validators.maxLength(this.options.maxLength));
        }
        return validators;
    }

    public set required(required: boolean) {
        if (this.options.required !== required) {
            this.options.required = required;
            this.builderInstance.rebuildForm();
        }
    }

    public get required(): boolean {
        return this.options.required || false;
    }
}
