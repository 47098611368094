import {Cloud} from "./cloud";

export class ArticleFile {
    public Id: number = 0;
    public ArticleId: number = 0;
    public DisplayName: string = null;
    public Path: string = null;
    public Active: boolean = false;
    public DisplayPath: string = null;
    public CloudStorageId: number = null;
    public CloudStorageName: string = null;
    public Source: Cloud = null;

    constructor(obj?: Partial<ArticleFile>) {
        ctor(this, obj, {
            Relations: {
                Source: Cloud,
            },
        });
    }
}
