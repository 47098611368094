import {
    RecipientOptions,
    CloudStorage,
    OrderToShip,
    PackageType,
    SearchOrderResult, ShipmentPdfLocation, ShippingOptions,
    ShippingProvider,
    ShippingStationOptions, RecipientDetails,
} from "../entity";

/**
 * Describes a data source for shipments.
 */
export abstract class ShipmentRepository {

    /**
     * Search shippable orders by the search term.
     * @param term The search term.
     */
    public abstract searchOrders(term: string,
    ): Promise<SearchOrderResult[]>;

    /**
     * Returns a single order for shipping.
     * @param orderId The order id.
     */
    public abstract getOrderForSingleShip(orderId: number,
    ): Promise<OrderToShip>;

    /** Returns the available shipping providers. */
    public abstract getShippingProviders(): Promise<ShippingProvider[]>;

    /** Returns view options. */
    public abstract getShippingStationOptions(): Promise<ShippingStationOptions>;

    /** Returns the available package types. */
    public abstract getPackageTypes(): Promise<PackageType[]>;

    /** Returns the available cloud storages. */
    public abstract getCloudStorages(): Promise<CloudStorage[]> ;

    /**
     * Starts the shipping process for a single order.
     * @param orderToShip The order to ship.
     */
    public abstract shipSingleOrder(orderToShip: OrderToShip,
    ): Promise<void>;

    /**
     * Queries the location of the pdf labels for the batch id.
     * @param batchId The shipping batch id.
     * @param providerId The id of the used shipping provider.
     */
    public abstract getLabelsForBatchAndProvider(batchId: string,
                                                 providerId: number,
    ): Promise<ShipmentPdfLocation> ;

    /**
     * Queries the location of the pdf export documents for the batch id.
     * @param batchId The shipping batch id.
     * @param providerId The id of the used shipping provider.
     */
    public abstract getExportDocumentsForBatchAndProvider(batchId: string,
                                                          providerId: number,
    ): Promise<ShipmentPdfLocation>;

    /**
     * Queries the location of the pdf retoure labels for the batch id.
     * @param batchId The shipping batch id.
     * @param providerId The id of the used shipping provider.
     */
    public abstract getReturnLabelsForBatchAndProvider(batchId: string,
                                                          providerId: number,
    ): Promise<ShipmentPdfLocation>;

    /**
     * Queries the list of orders for the bulk ship.
     * @param orderIds The order ids.
     */
    public abstract getOrdersForBulkShip(orderIds: number[]): Promise<OrderToShip[]>;

    /** Queries the list of orders that are ready for ship. */
    public abstract getReadyForShip(): Promise<OrderToShip[]> ;

    /** Queries the shipping options. */
    public abstract getShippingOptions(): Promise<ShippingOptions> ;

    /** Queries options for the recipient details */
    public abstract getRecipientOptions(orderId: number,
    ): Promise<RecipientOptions>;

    public abstract updateRecipientDetails(orderId: number,
                                           recipientDetails: RecipientDetails,
    ): Promise<RecipientDetails> ;
}
