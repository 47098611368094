import * as coreSvc from "@bb-core/service";
import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Injectable({providedIn: "root"})
export class TranslationService implements coreSvc.TranslationService {

    constructor(private readonly svc: TranslateService) {
    }

    public translate(messageId: string,
                     params: { [p: string]: string | number | Date } = {},
    ): string {
        for (const key of Object.keys(params)) {
            const parts = key.split(":");
            if (parts.length > 1) {
                const format = parts[1].toLowerCase();
                let regexResult: RegExpMatchArray;
                switch (true) {
                    case (regexResult = format.match(/^n(?<decimals>\d*)/)) != null:
                        params[key] = this._formatNumberFromRegex(regexResult, params[key] as any);
                        break;
                    case format === "date" || params[key] instanceof Date:
                        params[key] = this._formatDate(params[key] as any);
                        break;
                }
                params[parts[0]] = params[key];
                delete params[key];
            }
        }
        return this.svc.instant(messageId, params);
    }

    private _formatNumberFromRegex(regexResult: RegExpMatchArray, value: number | string): string {
        let decimals = null;
        if ("decimals" in regexResult.groups && regexResult.groups.decimals.length > 0) {
            decimals = Number(regexResult.groups.decimals);
        }

        return this.formatNumber(value, decimals);
    }

    public formatNumber(value: number | string, decimals: number = null): any {
        return Intl.NumberFormat(undefined, {
            minimumFractionDigits: decimals ?? undefined,
            maximumFractionDigits: decimals ?? undefined,
        }).format(value as number);
    }

    private _formatDate(date: Date): string {
        return Intl.DateTimeFormat(window.navigator.language, {
            dateStyle: "medium",
            timeStyle: "medium",
        } as any).format(date);
    }
}
