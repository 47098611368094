import * as core from "../../core/repository";
import * as ngx from "@ngx-resource/core";
import {Injectable} from "@angular/core";
import {RestService} from "@bb-data/service/rest.service";
import {Address} from "@app/app/shipping/core/entity";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/addresses",
})
export class AddressRepository extends RestService implements core.AddressRepository {

    @ngx.ResourceAction({
        path: "/{Id}",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetSingle: ngx.IResourceMethod<{ Id: number }, Address>;

    @ngx.ResourceAction({
        path: "/{Id}/update-or-copy",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doUpdateOrCopy: ngx.IResourceMethod<Address, Address>;

    @ngx.ResourceAction({
        path: "/byCustomer/{CustomerId}",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetByCustomer: ngx.IResourceMethod<{ CustomerId: number }, Address[]>;

    @ngx.ResourceAction({
        path: "/",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doCreate: ngx.IResourceMethod<Address, Address>;

    public async getSingle(id: number): Promise<Address> {
        return new Address(await this.doGetSingle({Id: id}));
    }

    public async updateOrCopy(address: Address): Promise<Address> {
        return new Address(await this.doUpdateOrCopy(address));
    }

    public async getForCustomer(customerId: number): Promise<Address[]> {
        return (await this.doGetByCustomer({CustomerId: customerId})).map((a) => new Address(a));
    }

    public async create(address: Address): Promise<Address> {
        return new Address(await this.doCreate(address));
    }

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }
}
