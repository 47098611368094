import {InputField} from "./input.type";
import {NumberFieldOptions} from "./number-field.options";
import {Constructor} from "@mintware-de/form-builder";
import {TextFieldComponent} from "./text-field.component";
import {ValidatorFn, Validators} from "@angular/forms";

export class NumberField extends InputField<NumberFieldOptions> {

    public readonly component: Constructor = TextFieldComponent;

    constructor(options: NumberFieldOptions) {
        super(Object.assign({
            required: true,
            type: "number",
            min: -2147483648,
            max: 2147483647,
        }, options));
    }

    protected buildValidators(): ValidatorFn[] {
        const validators: ValidatorFn[] = [];
        if (this.options.min != null) {
            validators.push(Validators.min(this.options.min));
        }

        if (this.options.max) {
            validators.push(Validators.max(this.options.max));
        }
        return validators;
    }
}
