import {enableProdMode, NgModuleRef} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";

import {AppModule} from "./app.module";
import {environment} from "./environments/environment";
import {ResourceActionReturnType, ResourceGlobalConfig} from "@ngx-resource/core";

if (environment.production) {
    enableProdMode();
}

ResourceGlobalConfig.returnAs = ResourceActionReturnType.Promise;

(window as any).loadDone = new Promise((resolve) => {
    window.onload = resolve;
});
window.localStorage.setItem("dx-theme", "dx-billbee");

platformBrowserDynamic().bootstrapModule(AppModule).then((_: NgModuleRef<AppModule>) => {

});
