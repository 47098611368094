import { Injectable, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NEVER, Observable } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { ShopViewModelDto } from "../../data/shop-view-model.dto";
import { ShopsRepository } from "../../data/shops.repository";
import { Shop } from "../../model/shop";
import { ShopType } from "../../model/shop-type.enum";
import { DefaultShopAddCommand } from "../../service/command/default-shop-add.command";
import { ShopsService } from "../../service/shops.service";
import { WooCommerceConnectionDetailsDialogComponent } from "./connection-details-dialog/connection-details-dialog.component";

@Injectable({
    providedIn: "root",
})
export class WooCommerceShop implements Shop {
    private readonly shopsService = inject(ShopsService);
    private readonly shopsRepository = inject(ShopsRepository);
    private readonly matDialog = inject(MatDialog);
    private readonly addCommand = inject(DefaultShopAddCommand);

    readonly key = "woocommercerest";
    readonly name = "WooCommerce";
    readonly type = ShopType.Shop;
    readonly logo = `assets/images/logo/shops/${this.key}.svg`;
    readonly featured = true;

    add(): Observable<never> {
        this.addCommand.execute(this.key);
        return NEVER;
    }

    saveShopWithNecessarySettings(): Observable<ShopViewModelDto> {
        return this.shopsService.getBaseDetails(this.key, this.name, this.logo).pipe(
            switchMap(viewModel => this.getConnectionDetails(viewModel)),
            switchMap(viewModel =>
                this.shopsService.showShopLoadingDialogForRequest(
                    this.shopsRepository.saveShop(viewModel),
                    this.key,
                    "title.saving",
                    "text.saving_shop",
                ),
            ),
        );
    }

    private getConnectionDetails(viewModel: ShopViewModelDto, hasError = false): Observable<ShopViewModelDto> {
        return this.matDialog
            .open(WooCommerceConnectionDetailsDialogComponent, { width: "400px", data: { hasError, viewModel } })
            .afterClosed()
            .pipe(
                filter(res => !!res),
                map(res => ({
                    ...viewModel,
                    ...res,
                })),
                switchMap(mappedModel =>
                    this.shopsService
                        .showShopLoadingDialogForRequest(
                            this.shopsRepository.testConnection(mappedModel),
                            this.logo,
                            "title.test_connection",
                            "text.testing_connection",
                        )
                        .pipe(catchError(() => this.getConnectionDetails(mappedModel, true))),
                ),
            );
    }
}
