import {AbstractFormFieldComponent, AbstractType} from "@mintware-de/form-builder";
import {HtmlInputOptions} from "./html-input.options";
import {Component} from "@angular/core";
import {UntypedFormControl} from "@angular/forms";


@Component({
    selector: "bb-html-input",
    template: `
        <ng-container *ngIf="mwFieldType.options.useHtml && mwFieldType.options.useEditor; else textAreaTemplate">
            <mat-label>{{mwFieldType.options.label | translateLabel}}</mat-label>
            <quill-editor [formControl]="mwElement"></quill-editor>
            <br>
        </ng-container>
        <ng-template #textAreaTemplate>
            <mat-form-field [matTooltip]="mwFieldType.options.tooltip" class="mat-block"
                            [hidden]="!mwFieldType.options.visible">
           <textarea [placeholder]="mwFieldType.options.label | translateLabel"
                     matInput
                     [type]="mwFieldType.options.type"
                     [name]="mwPath"
                     [required]="mwFieldType.options.required == true"
                     [rows]="mwFieldType.options.rows"
                     [cols]="mwFieldType.options.cols"
                     [minlength]="mwFieldType.options.minLength"
                     [maxlength]="mwFieldType.options.maxLength"
                     [formControl]="mwElement"
                     [readonly]="mwFieldType.options.readonly"></textarea>
                <mat-hint>{{ mwFieldType.options.hint | translate }}</mat-hint>
                <mat-hint align="end" *ngIf="mwElement && mwElement.value != null && mwFieldType.options.maxLength > 0">
                    {{ mwElement.value.length }} / {{ mwFieldType.options.maxLength }}
                </mat-hint>
                <mat-error>
                    <bb-form-errors [formField]="mwElement" [fieldType]="mwFieldType"></bb-form-errors>
                </mat-error>
            </mat-form-field>
        </ng-template>
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <button type="button" mat-raised-button helpLink="placeholders">{{'Platzhalter Dokumentation öffnen' | translate }}</button>
            <span fxFlex></span>
            <mat-slide-toggle *ngIf="mwFieldType.options.useHtml" [(ngModel)]="mwFieldType.options.useEditor">{{ 'Editor verwenden' | translate }}</mat-slide-toggle>
            <mat-slide-toggle [(ngModel)]="mwFieldType.options.useHtml">{{ 'Ist HTML Code' | translate }}</mat-slide-toggle>
        </div>
    `,
})
export class HtmlInputComponent extends AbstractFormFieldComponent<AbstractType<HtmlInputOptions>> {
    mwElement: UntypedFormControl;
}
