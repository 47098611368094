<div class="description">
	{{ "text.based_which_country" | translate }}
</div>
<div class="choose-button-wrapper">
	<button mat-stroked-button color="primary" (click)="next('DE')">{{ "country_de" | translate }}</button>
	<button mat-stroked-button color="primary" [matMenuTriggerFor]="selectionMenu">{{ "Andere" | translate }}</button>
</div>

<mat-menu #selectionMenu="matMenu">
	<button mat-menu-item *ngFor="let country of options$ | async" (click)="next(country.Value)">
		{{ country.Key }}
	</button>
</mat-menu>
            