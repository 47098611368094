import {ChangeDetectionStrategy, Component} from "@angular/core";
import {AbstractLayoutComponent, AbstractLayoutType, IGroupTypeOptions} from "@mintware-de/form-builder";
import {HeaderOptions} from "@app/app/common/form/layout/header/header.options";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "[bb-form-header]",
    template: `
        <div fxLayout="row" fxLayoutGap="8px">
            <div fxFlex="none" *ngIf="mwFieldType.options.icon">
                <mat-icon [inline]="true" [fontIcon]="mwFieldType.options.icon"></mat-icon>
            </div>
            <div fxFlex>{{ mwFieldType.options.text | translate }}</div>
        </div>
    `,
})
export class HeaderComponent extends AbstractLayoutComponent<AbstractLayoutType<HeaderOptions & IGroupTypeOptions>> {

}
