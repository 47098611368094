import {ChangeDetectionStrategy, Component} from "@angular/core";
import {take} from "rxjs/operators";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantBeforeSavingTaskComponent} from "../before-saving/before-saving.component";
import {OnboardingAssistantOverseasInvoiceNetComponent} from "../overseas-invoice-net/overseas-invoice-net.component";

@Component({
    selector: "bb-onboarding-assistant-use-oss",
    templateUrl: "./use-oss.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantUseOssTaskComponent {
    
    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
    ) {}

	public next(useOss: boolean): void {
		this.onboardingStore.setData({useOss});
		
		this.onboardingStore.data$.pipe(take(1)).subscribe(({vatMode}) => {
            if (vatMode === 0) {
                this.onboardingStore.nextTask(OnboardingAssistantOverseasInvoiceNetComponent);
            } else {
                this.onboardingStore.nextTask(OnboardingAssistantBeforeSavingTaskComponent);
            }
        });
    }
}
