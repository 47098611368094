<mat-form-field [subscriptSizing]="subscriptSizing">
    @if (label) {
        <mat-label>{{ label }}</mat-label>
    }
    <mat-select [formControl]="control" [required]="required" [multiple]="multiple" [matTooltip]="tooltip">
        <mat-option *ngFor="let option of options" [value]="option.Value">
            {{ option.Key | translate }}
        </mat-option>
    </mat-select>
    <mat-error *ngFor="let error of control.errors | bbErrors: customErrors">{{ error }}</mat-error>
</mat-form-field>
