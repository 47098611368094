import {ChangeDetectionStrategy, Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {UntilDestroy} from "@ngneat/until-destroy";
import {BehaviorSubject} from "rxjs";
import {BbFormErrorStateMatcher} from "../bb-form-error-state-matcher";
import {BbFormModule} from "../bb-form.module";

@UntilDestroy()
@Component({
    selector: "bb-slide-toggle",
    templateUrl: "./bb-slide-toggle.component.html",
    styleUrls: ["../bb-form.styles.scss", "./bb-slide-toggle.component.scss"],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BbSlideToggleComponent),
            multi: true,
        },
        {
            provide: ErrorStateMatcher,
            useClass: BbFormErrorStateMatcher,
        },
    ],
    standalone: true,
    imports: [BbFormModule, MatSlideToggleModule],
})
export class BbSlideToggleComponent implements ControlValueAccessor {
    @Input() label: string = "";

    public readonly value$ = new BehaviorSubject<boolean>(false);
    public readonly disabled$ = new BehaviorSubject<boolean>(false);

    private onChange = (v: any) => {};
    private onTouched = () => {};

    public changeValue(value: boolean): void {
        this.onChange(value);
        this.onTouched();
        this.value$.next(value);
    }

    public writeValue(value: boolean): void {
        this.value$.next(value);
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled$.next(isDisabled);
    }
}
