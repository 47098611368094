<h2 mat-dialog-title>
    <img [src]="data.logoUrl" alt="Shop-Logo" />
    {{ "title.add_shop" | translate }}
</h2>
<mat-dialog-content>
    <form [formGroup]="form">
        <h6>{{ "label.which_name_for_shop" | translate }}</h6>
        <bb-input formControlName="name" [label]="'label.name' | translate"></bb-input>

        @if (data.showStartDateSelection) {
            <h6>{{ "label.channel_sync_start_date" | translate }}</h6>
            <bb-select
                formControlName="syncMode"
                [label]="'label.synchronization' | translate"
                [options]="syncOptions"
            ></bb-select>
            @if (form.value.syncMode === ShopSyncMode.Custom) {
                <bb-datepicker
                    formControlName="customDate"
                    [label]="'label.date' | translate"
                    [min]="data.earliestStartDate"
                    [required]="form.value.syncMode === ShopSyncMode.Custom"
                ></bb-datepicker>
            }
        }
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>{{ "button.cancel" | translate }}</button>
    <button mat-flat-button color="primary" (click)="save()">{{ "button.save" | translate }}</button>
</mat-dialog-actions>
