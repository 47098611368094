import {
    ChangeDetectionStrategy,
    Component,
    effect,
    ElementRef,
    inject,
    Injector,
    NgZone,
    runInInjectionContext,
    signal,
    viewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { JavascriptSnippetHelperService, LegacyBridgeService } from "@app/app/common/service";
import { SignalrHandlerService } from "@app/app/common/service/signalr-handler.service";
import { Store } from "@ngrx/store";
import { combineLatest, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, take } from "rxjs/operators";
import { SegmentService } from "./shared/service/segment.service";
import { selectHelpscoutBeaconSetting } from "./states/selectors";

@Component({
    selector: "bb-app",
    template: `
        <div #outer>
            <div ui-view></div>
        </div>
        @if (isInitialized()) {
            <bb-navigation fxFlexFill>
                <div #realUiView></div>
                @if (canNavigate()) {
                    <router-outlet></router-outlet>
                }
            </bb-navigation>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    private readonly javascriptSnippetHelperService = inject(JavascriptSnippetHelperService);
    private readonly zone = inject(NgZone);
    private readonly router = inject(Router);
    private readonly injector = inject(Injector);
    private readonly store = inject(Store);

    protected readonly outer = viewChild<ElementRef<HTMLDivElement>>("outer");
    protected readonly realUiView = viewChild<ElementRef<HTMLDivElement>>("realUiView");
    protected readonly parentReplaceChild = effect(() => {
        const outer = this.outer();
        const realUiView = this.realUiView();

        if (outer && realUiView) {
            this.realUiView()?.nativeElement.parentElement?.replaceChild(outer.nativeElement, realUiView.nativeElement);
        }
    });

    protected readonly isInitialized = signal(false);
    protected readonly canNavigate = signal(false);

    public bootstrapped(): void {
        this.isInitialized.set(true);
        this.canNavigate.set(true);

        runInInjectionContext(this.injector, () => {
            // Init the SignalR Handler and Segment/Analytics
            inject(SignalrHandlerService);
            inject(SegmentService).init();
        });

        const initJsSnippetsLoaded$ = new Subject<void>();

        combineLatest([
            initJsSnippetsLoaded$,
            this.store.select(selectHelpscoutBeaconSetting),
            this.router.events.pipe(
                filter((e): e is NavigationEnd => e instanceof NavigationEnd),
                map(e => e.url),
                distinctUntilChanged(),
            ),
        ])
            .pipe(
                filter(([_, helpscoutBeaconSetting, _u]) => helpscoutBeaconSetting === true),
                map(([_, _s, url]) => url),
            )
            .subscribe(url =>
                this.zone.runOutsideAngular(
                    async () => await this.javascriptSnippetHelperService.loadAndExecuteJsSnippets(url, false),
                ),
            );

        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                take(1),
            )
            .subscribe(() => {
                runInInjectionContext(this.injector, () => {
                    inject(LegacyBridgeService);
                });
                this.zone.runOutsideAngular(async () => {
                    await this.javascriptSnippetHelperService.loadAndExecuteJsSnippets("", true);
                    initJsSnippetsLoaded$.next();
                });
            });
    }
}
