import {BaseType} from "../base/base.type";
import {Constructor} from "@mintware-de/form-builder";
import {SelectFieldOptions, SelectOption} from "./select-field.options";
import {SelectFieldComponent} from "./select-field.component";

export class SelectField extends BaseType<SelectFieldOptions> {
    public readonly component: Constructor = SelectFieldComponent;
    public componentInstance: SelectFieldComponent;

    constructor(options: SelectFieldOptions) {
        super(Object.assign({
            expanded: false,
            orderByText: false,
        } as SelectFieldOptions, options));
    }

    public reportChanges(options: SelectOption[]) {
        this.options.options = {};
        for (const option of options) {
            this.options.options[option.value] = option.text;
        }
        if (this.componentInstance) {
            this.componentInstance.detectChanges();
        }
    }
}
