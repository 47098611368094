import {ChangeDetectorRef, Component, Injector, Input, OnInit} from "@angular/core";
import {BbGridComponent} from "@app/app/grid/presentation/bb-grid.component";
import {
    ApplyPresetPresenter,
    ApplyPresetRequest,
    ApplyPresetUseCase,
    BB_PRESET_REPO,
    Column,
    DeletePresetPresenter,
    DeletePresetRequest,
    DeletePresetUseCase,
    InitPresetSectionPresenter,
    InitPresetSectionUseCase,
    Preset,
    PresetRepository,
    SaveAsNewPresetUseCase,
    SavePresetPresenter,
    SavePresetRequest,
    SavePresetUseCase, SetPresetPanelVisibilityUseCase,
} from "@app/app/grid/core";
import {BehaviorSubject} from "rxjs";
import {BbGridSidebarViewModel} from "./bb-grid-sidebar.view-model";
import {ClientStorageService} from "@bb-core/service";

@Component({
    selector: "bb-grid-sidebar",
    templateUrl: "bb-grid-sidebar.component.html",
})
export class BBGridSidebarComponent<T = any> implements OnInit,
    InitPresetSectionPresenter<T>,
    SavePresetPresenter<T>,
    DeletePresetPresenter<T>,
    ApplyPresetPresenter<T> {

    @Input()
    public grid: BbGridComponent<T>;

    @Input()
    public presetRepository: PresetRepository<T>;

    public showSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public readonly viewModel: BbGridSidebarViewModel<T> = new BbGridSidebarViewModel<T>();
    public readonly presets$: BehaviorSubject<Preset<any>[]> = new BehaviorSubject<Preset<any>[]>([]);
    public readonly filtersApplied$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private initPresetSectionUseCase: InitPresetSectionUseCase;
    private savePresetUseCase: SavePresetUseCase;
    private saveAsNewPresetUseCase: SaveAsNewPresetUseCase;
    private deletePresetUseCase: DeletePresetUseCase;
    private applyPresetUseCase: ApplyPresetUseCase;
    private setPresetPanelVisibilityUseCase: SetPresetPanelVisibilityUseCase;

    constructor(private readonly injector: Injector,
                private readonly cdr: ChangeDetectorRef,
                private readonly clientStorage: ClientStorageService,
    ) {
    }

    // region Life cycle hooks
    public ngOnInit(): void {
        const injector = Injector.create([
            {provide: BB_PRESET_REPO, useValue: this.presetRepository},
        ], this.injector);

        this.initPresetSectionUseCase = injector.get(InitPresetSectionUseCase);
        this.savePresetUseCase = injector.get(SavePresetUseCase);
        this.deletePresetUseCase = injector.get(DeletePresetUseCase);
        this.applyPresetUseCase = injector.get(ApplyPresetUseCase);
        this.saveAsNewPresetUseCase = injector.get(SaveAsNewPresetUseCase);
        this.setPresetPanelVisibilityUseCase = injector.get(SetPresetPanelVisibilityUseCase);

        this.initPresetSectionUseCase.execute(null, this).then();

        this.viewModel.expandFilterPanel$.next(
            this.clientStorage.get<boolean>(this.getPanelSettingsName("filter"), false),
        );

        this.viewModel.expandColumnsPanel$.next(
            this.clientStorage.get<boolean>(this.getPanelSettingsName("columns"), false),
        );
    }

    // endregion

    public async applyPreset(preset: Preset<T>): Promise<void> {
        await this.applyPresetUseCase.execute(new ApplyPresetRequest<T>({
            preset,
            realColumns: this.getColumnsWorkingCopy(),
        }), this);
    }

    private getColumnsWorkingCopy(): Column<T>[] {
        return [...this.grid.options.Columns.map(col => new Column(col))];
    }

    public setColumnVisibility(column: Column<any>,
                               isVisible: boolean,
    ): void {
        column.IsVisible = isVisible;
        this.grid.updateColumns().then();
        if (isVisible) {
            this.grid.refresh().then();
        }
    }

    // region InitPresetSectionPresenter, SavePresetPresenter, DeletePresetPresenter

    public displayPresets(presets: Preset<T>[]): void {
        this.presets$.next(presets);
    }

    public selectPreset(preset: Preset<T>): void {
        this.applyPreset(preset).then();
    }

    // endregion

    // region GeneralPresetPresenter
    public setFilterPanelVisibility(visible: boolean): void {
        this.showSidebar.next(visible);
    }

    // endregion
    // region ApplyPresetPresenter

    public displayAppliedPreset(preset: Preset<T>) {
        this.viewModel.preset = preset;
    }

    public displayNewColumnOptions(columns: Column<T>[]) {
        this.grid.options.Columns = [...columns];
        this.cdr.detectChanges();
        this.grid.updateColumns().then();
    }

    public resetPreset(): void {
        this.grid.grid.instance.clearSelection();
        this.grid.grid.instance.clearSorting();
        this.grid.grid.instance.clearFilter();
        this.grid.grid.instance.clearGrouping();
    }

    // endregion

    public async savePreset(formData: any): Promise<void> {
        const preset = new Preset<any>({...this.getCurrentPreset({...this.viewModel.preset, ...formData}), ...formData});
        await this.savePresetUseCase.execute(new SavePresetRequest({
            preset,
            allPresets: this.presets$.value,
        }), this);
        this.viewModel.preset = preset;
    }

    public async saveAsNewPreset(formData: any): Promise<void> {
        const preset = new Preset<any>({...this.getCurrentPreset({...this.viewModel.preset, ...formData}), ...formData});
        await this.saveAsNewPresetUseCase.execute(new SavePresetRequest({
            preset,
            allPresets: this.presets$.value,
        }), this);
        this.viewModel.preset = preset;
    }

    public async deleteSelectedPreset(): Promise<void> {
        await this.deletePresetUseCase.execute(new DeletePresetRequest({
            preset: this.viewModel.preset,
            allPresets: this.presets$.value,
        }), this);
    }

    public getCurrentPreset(preset?: Preset<T>, forPersisting: boolean = true): Preset<T> {
        preset ??= this.viewModel.preset;
        return new Preset<T>({
            ...preset,
            Columns: this.grid.options.Columns
                .filter(c => c.IsVisible && (!forPersisting || (preset.RememberColumns || preset.RememberColumnFilters || preset.RememberOrder)))
                .map(c => new Column<T>({
                    ...c,
                    Filter: (!forPersisting || preset.RememberColumnFilters) ? c.Filter : null,
                    Sort: (!forPersisting || preset.RememberOrder) ? c.Sort : null,
                    VisibleIndex: (!forPersisting || preset.RememberColumns) ? c.VisibleIndex : null,
                    Width: (!forPersisting || preset.RememberColumns) ? c.Width : null,
                })),
        });
    }

    public async toggleVisibility(): Promise<void> {
        await this.setPresetPanelVisibilityUseCase.execute(!this.showSidebar.value, this);
    }

    public setSearchTerm(term: string): void {
        this.viewModel.preset.SearchTerm = term;
        this.searchTermChanged();
    }

    public async searchTermChanged(): Promise<void> {
        this.grid.options.SearchTerm = this.viewModel.preset.SearchTerm;
        await this.grid.refresh();
        this.updateFiltersApplied();
    }

    public storeExpanded(field: string, expanded: boolean): void {
        this.clientStorage.set(this.getPanelSettingsName(field), expanded);
    }

    private getPanelSettingsName(field: string): string {
        return `grid.preset.${this.presetRepository.uniqueIdentifier}.${field}_panel_expanded`;
    }

    private updateFiltersApplied(): void {
        this.filtersApplied$.next(false);
    }
}
