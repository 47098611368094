import {AfterContentInit, ChangeDetectionStrategy, Component} from "@angular/core";
import {AbstractLayoutComponent, AbstractLayoutType, IGroupTypeOptions} from "@mintware-de/form-builder";
import {FlexLayoutOptions} from "./flex-layout.options";

@Component({
    selector: "bb-form-flex-layout",
    template: `
        <div #flex *ngIf="mwFieldType && mwFieldType.options"
             [fxLayout]=" mwFieldType.options.direction + (mwFieldType.options.wrap ? ' wrap'  : '')"
             [fxLayoutGap]="mwFieldType.options.dense ? '' : '16px' + (mwFieldType.options.wrap ? ' grid'  : '')"
             [fxLayoutAlign]="(mwFieldType.options.alignMainAxis + ' ' + mwFieldType.options.alignCrossAxis).trim()">
            <ng-container *ngFor="let e of mwFieldType.options.model | keyvalue:orderAsGiven; index as index">
                <div [fxHide]="e.value.options.visible != null && !e.value.options.visible"
                     [fxFlex]="mwFieldType.options.flex ? mwFieldType.options.flex[index] : null"
                     [fxFlex.lt-xl]="mwFieldType.options.flexLtXl ? mwFieldType.options.flexLtXl[index] : null"
                     [fxFlex.lt-lg]="mwFieldType.options.flexLtLg ? mwFieldType.options.flexLtLg[index] : null"
                     [fxFlex.lt-md]="mwFieldType.options.flexLtMd ? mwFieldType.options.flexLtMd[index] : null"
                     [fxFlex.lt-sm]="mwFieldType.options.flexLtSm ? mwFieldType.options.flexLtSm[index] : null"
                     mwFormField
                     [mwFormGroup]="mwElement"
                     [mwElement]="elements[e.key]"
                     [mwFieldType]="e.value"
                     [mwPath]="fieldPaths[e.key]"
                     [mwIndex]="indexFromParent"></div>
            </ng-container>
        </div>
    `,
})
export class FlexLayoutComponent
    extends AbstractLayoutComponent<AbstractLayoutType<FlexLayoutOptions & IGroupTypeOptions>>
    implements AfterContentInit {
    public ngAfterContentInit(): void {
        this.mwElement.valueChanges.subscribe(() => {
            this.cdr.markForCheck();

        });
    }

}
