import {ImpactType, IncidentStatus} from "./types";
import {IncidentComponent} from "./incident-component";

export class Incident {
    public name: string = null;
    public status: IncidentStatus = "investigating";
    public impact: ImpactType = null;
    public created_at: Date = null;
    public components: IncidentComponent[] = [];

    constructor(obj: Partial<Incident>) {
        ctor(this, obj, {
            DateFields: ["created_at"],
            Collections: {
                components: IncidentComponent,
            },
        });
    }
}
