import { Injectable } from "@angular/core";
import { SelectOption } from "@app/app/common/form/select-field/select-field.options";
import { EmployeeService } from "@app/app/common/service/da/employee.service";
import { selectAccount } from "@bb/states/selectors";
import { Store } from "@ngrx/store";
import { Observable, firstValueFrom, from, zip } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class ApiTestMailReceiverRepository {
    private readonly baseUrl = `${environment.baseUrl}/employee`;

    constructor(private readonly employeeService: EmployeeService,
        private readonly store: Store) { }

    private getTestMailRecipients(): Observable<string[]> {
        return from(this.employeeService.getTestMailRecipients()).pipe(map((employees) => employees.map((e) => e.Email)));
    }

    public async fetchSelectOptions(): Promise<SelectOption[]> {
        return firstValueFrom(zip(this.store.select(selectAccount).pipe(map((account) => account.MailAddress)),
            this.getTestMailRecipients()).pipe(map(([mailAddress, employees]) => ([mailAddress, ...employees].map((mail) => ({ value: mail, text: mail }))))));
    }
}


