import {Address} from "../entity";

/**
 * Describes a data source for addresses.
 */
export abstract class AddressRepository {
    /**
     * Queries a single address by its id.
     * @param id The id.
     */
    public abstract getSingle(id: number): Promise<Address> ;

    /**
     * Updates an address and returns the result.
     * @param address The address to update.
     */
    public abstract updateOrCopy(address: Address): Promise<Address>;

    /**
     * Queries all addresses for a customer.
     * @param customerId The customer id.
     */
    public abstract getForCustomer(customerId: number): Promise<Address[]>;

    /**
     * Creates a new address.
     * @param address The address to create.
     */
    public abstract create(address: Address): Promise<Address> ;
}
