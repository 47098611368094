import {CustomerAddress} from "@app/app/customer/core";
import {FormModel} from "@mintware-de/form-builder";
import {CustomerAddressFormModel} from "../edit-customer-address/customer-address.form-model";
import {CustomerAddressMergeFormModelHelper} from "./customer-address-merge.form-model";

export class MergeCustomerAddressesViewModel {
    public addresses: CustomerAddress[] = [];
    public newAddressFormModel: FormModel<CustomerAddress> = new CustomerAddressFormModel();
    public mergeAddressFormModel: { [key: number]: CustomerAddressMergeFormModelHelper } = {};
    public newAddress: CustomerAddress = new CustomerAddress();
}
