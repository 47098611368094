import {MessageTemplate} from "@app/app/settings/automation/core";
import {Order} from "@shared/models";

export class TestmailConfig {
    public MessageTemplate: MessageTemplate = null;
    public ReplacePlaceholders: boolean = false;
    public ReceiverEmailAddress?: string = null;
    public OrderId?: number = null;
    public SearchTerm?: string = null;
    public SearchResult: Order[] = [];
    public CurrentLanguage: string = null;

    constructor(obj?: Partial<TestmailConfig>) {
        ctor(this, obj, {
            Relations: {
                "MessageTemplate": MessageTemplate,
            },
            Collections: {
                SearchResult: Order,
            },
        });
    }
}
