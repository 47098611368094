import {Injectable} from "@angular/core";
import {EventsService} from "@bb-core/service";
import {Subscription} from "rxjs";

@Injectable({providedIn: "root"})
export class AppEventsService implements EventsService {
    private subscriptions: { [key: string]: ((event: any) => void)[] } = {};

    public subscribe<T extends object>(type: new (...args: any[]) => T, handler: (event: T) => void): Subscription {
        if (!(type.name in this.subscriptions)) {
            this.subscriptions[type.name] = [];
        }
        this.subscriptions[type.name].push(handler);
        return new Subscription(() => this.subscriptions[type.name].splice(this.subscriptions[type.name].indexOf(handler), 1));
    }

    public dispatch<T extends object>(event: T): void {
        const eventName = event.constructor.name;
        if (!(eventName in this.subscriptions)) {
            return;
        }
        this.subscriptions[eventName].forEach((s) => s(event));
    }
}
