import {AbstractLayoutComponent, AbstractLayoutType, IGroupTypeOptions} from "@mintware-de/form-builder";
import {SubheadingOptions} from "./subheading.options";
import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "bb-form-subheading",
    template: `
        <div class="mat-subtitle-1" style="margin-top: 8px">
            {{ mwFieldType.options.text | translate}}
            <small *ngIf="mwFieldType.options.subtitle">
                {{ mwFieldType.options.subtitle | translate }}
            </small>
        </div>
    `,
})
export class SubheadingComponent
    extends AbstractLayoutComponent<AbstractLayoutType<SubheadingOptions & IGroupTypeOptions>> {
}
