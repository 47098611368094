import {OrderToShip} from "@app/app/shipping/core/entity";
import {Injectable} from "@angular/core";

type OrderSort = "OrderDate ASC" |
    "PayDate ASC" |
    "ShipDate ASC" |
    "ExternalReference ASC" |
    "OrderNumber ASC" |
    "ShipAddressLastName ASC" |
    "ShopName ASC";

export class SortOrdersRequest {
    public Orders: OrderToShip[] = [];
    public By: OrderSort | string = "OrderDate ASC";

    constructor(obj?: Partial<SortOrdersRequest>) {
        ctor(this, obj, {
            Collections: {
                Orders: OrderToShip,
            },
        });
    }
}

export abstract class SortOrdersPresenter {

    /** Display the sorted orders */
    public abstract displaySortedOrders(orders: OrderToShip[]): void;
}

@Injectable({providedIn: "root"})
export class SortOrdersUseCase implements IUseCase<SortOrdersRequest, SortOrdersPresenter> {
    public async execute(request: SortOrdersRequest,
                         presenter?: SortOrdersPresenter,
    ): Promise<void> {
        if (request.Orders.length === 0) {
            return;
        }
        let getter: (e: OrderToShip) => any = null;
        switch (request.By) {
            case "OrderDate ASC":
                getter = x => x.OrderDate != null ? x.OrderDate.toString() : null;
                break;
            case "PayDate ASC":
                getter = x => x.PayDate != null ? x.PayDate.toString() : null;
                break;
            case "ShipDate ASC":
                getter = x => x.ShippingDate != null ? x.ShippingDate.toString() : null;
                break;
            case "ExternalReference ASC":
                getter = x => x.ExternalReference;
                break;
            case "OrderNumber ASC":
                getter = x => x.OrderNumber;
                break;
            case "ShipAddressLastName ASC":
                getter = x => x.ShippingAddress != null ? x.ShippingAddress.LastName : null;
                break;
            case "ShopName ASC":
                getter = x => x.ShopName;
                break;
        }

        if (getter != null) {
            const sorted = this._sortBy([...request.Orders], getter);
            presenter?.displaySortedOrders(sorted);
        }
    }

    private _sortBy<T>(orders: T[], getter: (e: T) => string): T[] {
        return orders.sort((a, b) => {
            const aVal = a != null ? getter(a) : null;
            const bVal = b != null ? getter(b) : null;
            if (!aVal || !bVal) {
                return 0;
            }
            const valueA = aVal.toUpperCase();
            const valueB = bVal.toUpperCase();
            return valueA < valueB ? -1
                : valueA > valueB ? 1 : 0;
        });
    }
}
