<div class="description">
    {{ "text.enter_further_country_vat_ids" | translate }}
</div>
<form *ngFor="let group of formArray.controls; let i = index" [formGroup]="group">
    <bb-select
        formControlName="country"
        [label]="'label.country' | translate"
        [options]="options$ | async"
        required
    ></bb-select>
    <bb-input formControlName="vatId" [label]="'label.vat_id' | translate" required></bb-input>
    <bb-datepicker formControlName="validFrom" [label]="'label.valid_from' | translate" required></bb-datepicker>
    <button mat-icon-button color="warn" (click)="deleteCountry(i)">
        <mat-icon fontIcon="fa-trash"></mat-icon>
    </button>
</form>
<button mat-stroked-button color="primary" (click)="addCountry()">
    {{ "button.add_country" | translate }}
</button>
<button mat-flat-button color="primary" (click)="next()">
    {{ "button.next" | translate }}
</button>
