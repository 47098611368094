import {AbstractFormGroupComponent, AbstractGroupType, IGroupTypeOptions} from "@mintware-de/form-builder";
import {Component} from "@angular/core";

@Component({
    selector: "bb-form-group",
    template: `
        <ng-container *ngFor="let e of mwFieldType.options.model | keyvalue:orderAsGiven; index as index">
            <div *ngIf="e.value.options.visible == null || e.value.options.visible"
                 mwFormField
                 [mwPath]="fieldPaths[e.key]"
                 [mwElement]="elements[e.key]"
                 [mwFieldType]="mwFieldType.options.model[e.key]"
                 [mwFormGroup]="mwElement"
                 [mwIndex]="mwIndex"></div>
        </ng-container>
    `,
})
export class GroupComponent extends AbstractFormGroupComponent<AbstractGroupType<IGroupTypeOptions>> {

}
