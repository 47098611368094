<mat-card (click)="onClick.emit()">
    <div *ngIf="loading" class="loading-overlay">
        <mat-progress-spinner mode="indeterminate" [diameter]="50"></mat-progress-spinner>
    </div>
    <div class="border" [ngClass]="border" *ngIf="border !== null"></div>
    <mat-card-title>
        <div *ngIf="index !== null && index !== undefined" class="title-index">{{index}}.</div>
        <mat-checkbox
            *ngIf="checked !== null"
            [checked]="checked"
            (change)="selectionChange.emit($event)"
            (click)="$event.stopPropagation()"
        ></mat-checkbox>
        <div class="title-wrapper">
            <span class="title" *ngIf="!imgSrc">{{ title }}</span>
            <img *ngIf="imgSrc" [src]="imgSrc" />
            <div class="additional-header">
                <ng-content select="[additionalHeader]"></ng-content>
            </div>
        </div>
        <div>
            <button
                *ngIf="matMenuItems.length > 0"
                mat-icon-button
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()"
            >
                <mat-icon fontIcon="fa-ellipsis-v"></mat-icon>
            </button>
            <mat-menu #menu="matMenu" (click)="$event.stopPropagation()">
                <button
                    mat-menu-item
                    *ngFor="let item of matMenuItems"
                    [disabled]="item?.disabled"
                    (click)="item?._getHostElement().click()"
                >
                    <span>{{ item?.getLabel() }}</span>
                </button>
            </mat-menu>
        </div>
    </mat-card-title>
	<div class="content-wrapper">
		<div class="content-wrapper-body">
			<ng-content select="[content]"></ng-content>
		</div>
		<ng-content select="[footer]"></ng-content>
	</div>
</mat-card>
