export class Payment {
    public Id: number = null;
    public ExtId: string = null;
    public Created: Date = null;
    public SourceTechnology: string = null;
    public SourceText: string = null;
    public PayDate: Date = null;
    public PayValue: number = null;
    public OrderId: number = null;
    public TransactionId: string = null;
    public Fee: number = null;
    public PaymentType: number = null;
    public Purpose: string = null;
    public Name: string = null;
    public PayerAccountNumber: string = null;

    constructor(obj?: Partial<Payment>) {
        ctor(this, obj, {
            DateFields: ["Created", "PayDate"],
            NumberFields: ["Id", "PayValue", "OrderId", "PaymentType", "Fee"],
        });
    }
}
