<h3 mat-dialog-title>{{ "title.reset_account" | translate }}</h3>
<mat-dialog-content>
    <p>{{ "text.select_data_to_reset" | translate }}</p>
    <form [formGroup]="formGroup">
        <p>
            <bb-checkbox formControlName="DeleteOrders" [label]="'label.account.delete_orders' | translate">
            </bb-checkbox>
        </p>
        <p>
            <bb-checkbox formControlName="DeleteCustomers" [label]="'label.account.delete_customers' | translate">
            </bb-checkbox>
        </p>
        <p>
            <bb-checkbox formControlName="DeleteProducts" [label]="'label.account.delete_products' | translate">
            </bb-checkbox>
        </p>
        <p>
            <bb-checkbox formControlName="DeletePayments" [label]="'label.account.delete_payments' | translate">
            </bb-checkbox>
        </p>
        <p>
            <bb-checkbox
                formControlName="ResetRules"
                [label]="'label.account.reset_default_rules' | translate"
                [matTooltip]="'label.account.reset_default_rules_tooltip' | translate"
            >
            </bb-checkbox>
        </p>
    </form>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
    <button mat-button type="button" [mat-dialog-close]="null">{{ "button.cancel" | translate }}</button>
    <button mat-flat-button color="warn" type="button" [mat-dialog-close]="formGroup.getRawValue()">
        {{ "button.delete" | translate }}
    </button>
</mat-dialog-actions>
