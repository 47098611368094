import {Injectable, inject} from "@angular/core";
import {Router} from "@angular/router";

@Injectable({
	providedIn: "root",
})
export class DefaultShopAddCommand {
	private readonly router = inject(Router);

    public execute(key: string, queryParams?: any): void {
        this.router.navigate([`/settings/shops/add/${key}`], {queryParams});
    }
}
