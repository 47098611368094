<mat-card fxFlex="33" [fxFlex.lt-md]="50" [fxFlex.lt-sm]="100">
    <h3 mat-dialog-title>{{ "title.select_shopify_connection_type" | translate }}</h3>
    <mat-card-content>
        <div fxLayout="column" fxLayoutGap="16px">
            <div style="width: 100%" [matTooltip]="'tooltip.shopify_shop_only_via_channel_settings' | translate">
                <button style="width: 100%" type="button" mat-flat-button color="primary" disabled>
                    {{ "label.sales_channel" | translate }}
                </button>
            </div>
            <button type="button" mat-flat-button color="primary" (click)="openLink('payment')">
                {{ "button.payment_import" | translate }}
            </button>
        </div>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="end">
        <button type="button" mat-flat-button routerLink="/">
            {{ "button.cancel" | translate }}
        </button>
    </mat-card-actions>
</mat-card>
