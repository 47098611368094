/* eslint-disable */
import {Injectable} from "@angular/core";
import {Customer, CustomerInteractionService, LegacyCustomerRepository} from "@app/app/customer/core";
import {ErrorHandlerService, InteractionService, Message, MessagingService, TranslationService} from "@bb-core/service";

export class MergeCustomersRequest {
    public CustomerIds: number[] = [];

    constructor(obj?: Partial<MergeCustomersRequest>) {
        ctor(this, obj);
    }
}
export abstract class MergeCustomersPresenter {
    public abstract removeMergedCustomers(customers: Customer[]): void;
}

/**
 * @deprecated
 */
@Injectable({providedIn: "root"})
export class MergeCustomersUseCase implements IUseCase<MergeCustomersRequest, MergeCustomersPresenter> {
    constructor(private readonly customerRepository: LegacyCustomerRepository,
                private readonly customerInteraction: CustomerInteractionService,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
                private readonly errorHandler: ErrorHandlerService,
                private readonly interactionService: InteractionService,
    ) {
    }

    public async execute(request: MergeCustomersRequest,
                         presenter?: MergeCustomersPresenter,
    ): Promise<void> {
        if (!this.validateRequest(request)) {
            return;
        }
        const customers = await this.getCustomers(request.CustomerIds);

        const targetCustomer = await this.customerInteraction.mergeCustomers(customers);
        if (targetCustomer == null) {
            return;
        }

        if (await this.confirmMerge()) {
            await this.mergeCustomers(customers.map(x => x.Id), targetCustomer);
            presenter?.removeMergedCustomers(customers.filter(x => x.Id !== targetCustomer.Id));
        }
    }

    private validateRequest(request: MergeCustomersRequest): boolean {
        if (request.CustomerIds == null || request.CustomerIds.length <= 0) {
            const title = this.translator.translate("title.select_customers");
            const message = this.translator.translate("text.select_at_least_two_customers_to_merge");
            this.messaging.showMessage(Message.blocking({title, message})).then();
            return false;
        }
        return true;
    }

    private async getCustomers(customerIds: number[]): Promise<Customer[]> {
        let customers: Customer[];
        try {
            customers = await this.customerRepository.getMultipleCustomers(customerIds);
        } catch (e) {
            await this.errorHandler.handleException(e);
            throw e;
        }
        if (customers.length !== customerIds.length) {
            const title = this.translator.translate("title.missing_records");
            const message = this.translator.translate("text.not_all_customers_were_found");
            this.messaging.showMessage(Message.blocking({title, message})).then();
        }
        return customers;
    }

    private async mergeCustomers(customerIdsToMerge: number[],
                                 targetCustomer: Customer,
    ): Promise<void> {
        try {
            await this.customerRepository.mergeCustomers(customerIdsToMerge, targetCustomer);
            const message = this.translator.translate("flash.customers_merged");
            await this.messaging.showMessage(Message.transient({message}));
        } catch (e) {
            await this.errorHandler.handleException(e, null, false);
            throw e;
        }
    }

    private async confirmMerge(): Promise<boolean> {
        const title = this.translator.translate("title.merge_customers");
        const message = this.translator.translate("text.confirm_merge_customers");
        return this.interactionService.confirm(title, message);
    }
}
