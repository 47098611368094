import {Injectable} from "@angular/core";
import * as abs from "@bb-core/service";
import {Message} from "@bb-core/service";
import {MessagingService} from "./messaging.service";
import {TranslationService} from "./translation.service";

@Injectable({
    providedIn: "root",
})
export class ClipboardService implements abs.ClipboardService {
    constructor(private readonly translator: TranslationService, private readonly messaging: MessagingService) {
    }

    public async copy(text: string): Promise<void> {
        await navigator.clipboard.writeText(text);
        const message = this.translator.translate("flash.copied_to_clipboard");
        await this.messaging.showMessage(Message.transient({message}));
    }
}
