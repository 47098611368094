import {AddressTypeEnum} from "../enum/address-type.enum";

export class Address {
    public Id: number = 0;
    public AddressType: AddressTypeEnum = AddressTypeEnum.Both;
    public CustomerId: number = null;
    public Company: string = null;
    public FirstName: string = null;
    public LastName: string = null;
    public Name2: string = null;
    public AddressAddition: string = null;
    public Street: string = null;
    public Housenumber: string = null;
    public Zip: string = null;
    public City: string = null;
    public State: string = null;
    public CountryCode: string = null;
    public Email: string = null;
    public Tel1: string = null;
    public Tel2: string = null;
    public Fax: string = null;
    public FullName: string = null;
    public FullStreet: string = null;

    public constructor(obj?: Partial<Address>) {
        ctor(this, obj);
    }

    public toString(): string {
        return `${this.FullName} ${this.FullStreet}`.trim();
    }

    public equals(other: Address): boolean {
        return this.Id === other.Id
            && this.AddressType === other.AddressType
            && this.CustomerId === other.CustomerId
            && this.Company === other.Company
            && this.FirstName === other.FirstName
            && this.LastName === other.LastName
            && this.Name2 === other.Name2
            && this.AddressAddition === other.AddressAddition
            && this.Street === other.Street
            && this.Housenumber === other.Housenumber
            && this.Zip === other.Zip
            && this.City === other.City
            && this.State === other.State
            && this.CountryCode === other.CountryCode
            && this.FullName === other.FullName
            && this.FullStreet === other.FullStreet
            ;
    }

}
