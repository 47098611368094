import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {filter, map, take} from "rxjs/operators";
import {UtilsRepository} from "../../../shared/data/utils.repository";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantTaxRateTargetCountryTaskComponent} from "../tax-rate-target-country/tax-rate-target-country.component";

@Component({
    selector: "bb-onboarding-assistant-enter-further-country-vat-ids",
    templateUrl: "./enter-further-country-vat-ids.component.html",
    styleUrls: ["../tasks.scss", "./enter-further-country-vat-ids.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantEnterFurtherCountriesVatIdsTaskComponent implements OnInit {
    public readonly formArray = new FormArray<FormGroup>([this.generateNewFormGroup()]);
    public readonly options$ = this.utilsRepository
        .getCountries()
        .pipe(
            map((countries) =>
                countries
                    .filter(({HasVatRates}) => !!HasVatRates)
                    .map((country) => ({Key: country.Name, Value: country.ISO2})),
            ),
        );

    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
        private readonly utilsRepository: UtilsRepository,
    ) {}

    public ngOnInit(): void {
        this.onboardingStore.data$
            .pipe(
                take(1),
                filter(({furtherVatIds}) => furtherVatIds.length > 0),
            )
			.subscribe(({furtherVatIds}) => {
				this.formArray.clear();
				furtherVatIds.forEach(furtherVatId => {
					const group = this.generateNewFormGroup();
					group.patchValue(furtherVatId);
					this.formArray.push(group);
				});
				this.formArray.updateValueAndValidity();
			});
    }

    public next(): void {
        if (this.formArray.invalid && this.formArray.controls.length > 0) {
            this.formArray.markAllAsTouched();
            return;
        }
        const furtherVatIds = this.formArray.value.filter((v) => v.country !== "" && v.vatid !== "");
        this.onboardingStore.setData({furtherVatIds});
        this.onboardingStore.nextTask(OnboardingAssistantTaxRateTargetCountryTaskComponent);
    }

    public addCountry(): void {
        this.formArray.push(this.generateNewFormGroup());
    }

    public deleteCountry(index: number): void {
        this.formArray.controls.splice(index, 1);
    }

    private generateNewFormGroup(): FormGroup {
        return new FormGroup({
            country: new FormControl("", Validators.required),
            vatId: new FormControl("", Validators.required),
            validFrom: new FormControl(new Date(), Validators.required),
        });
    }
}
