// noinspection ES6PreferShortImport
import {ShippingProductService} from "../entity/shipping-product-service";
import * as svc from "../entity/service";
import {TimeDefiniteService} from "../entity/service";

/**
 * This factory is used to construct a concrete ShippingService by its type.
 */
export class ShippingProductServiceFactory {

    private static serviceMapping = {
        "BulkfreightService": svc.BulkfreightService,
        "CODService": svc.CodService,
        "CustomerNotificationService": svc.CustomerNotificationService,
        "DHLEndorsementService": svc.DhlEndorsementService,
        "DHLDeliveryTypeService": svc.DhlDeliveryTypeService,
        "DHLRetoureLocationsService": svc.DhlRetoureLocationsService,
        "DHLFilialRoutingService": svc.DhlFilialRoutingService,
        "DHLIndividualTeilnahmeService": svc.DhlIndividualTeilnahmeService,
        "DHLPersonalDeliverySpecificationsService": svc.DhlPersonalDeliverySpecificationsService,
        "DHLPreferredTimeFrameService": svc.DhlPreferredTimeFrameService,
        "DHLPremiumService": svc.DhlPremiumService,
        "DHLTimeFrameService": svc.DhlTimeFrameService,
        "DayOfDeliveryService": svc.DayOfDeliveryService,
        "DryIceService": svc.DryIceService,
        "EarlyDeliveryService": svc.EarlyDeliveryService,
        "EveningDeliveryService": svc.EveningDeliveryService,
        "GreenService": svc.GreenService,
        "HermesExpressService": svc.HermesExpressService,
        "HermesPreferredTimeWindowService": svc.HermesPreferredTimeWindowService,
        "HolidayDeliveryService": svc.HolidayDeliveryService,
        "IdentService": svc.IdentService,
        "InsuranceService": svc.InsuranceService,
        "LQService": svc.LQService,
        "LetterboxService": svc.LetterboxService,
        "NoNeighbourService": svc.NoNeighbourService,
        "NoticeOfNonDeliverabilityService": svc.NoticeOfNonDeliverabilityService,
        "PackagingReturnService": svc.PackagingReturnService,
        "PerishablesService": svc.PerishablesService,
        "PersonallyService": svc.PersonallyService,
        "PinAuthenticationService": svc.PinAuthenticationService,
        "PreferredDayOfDeliveryService": svc.PreferredDayOfDeliveryService,
        "PreferredLocationService": svc.PreferredLocationService,
        "PreferredNeighbourService": svc.PreferredNeighbourService,
        "ReturnLabelService": svc.ReturnLabelService,
        "SaturdayDeliveryService": svc.SaturdayDeliveryService,
        "ShopDeliveryService": svc.ShopDeliveryService,
        "ShopReturnService": svc.ShopReturnService,
        "VisualAgeCheckService": svc.VisualAgeCheckService,
        "TimeDefiniteService": svc.TimeDefiniteService,
    };

    /**
     * Resolve a concrete ShippingProductService by its type.
     * If the type is unknown, a new instance of the base class will be returned.
     * @param untyped The untyped service
     */
    public static resolve<T extends ShippingProductService>(untyped: ShippingProductService): T {
        const service = untyped.ServiceName in ShippingProductServiceFactory.serviceMapping
            ? ShippingProductServiceFactory.serviceMapping[untyped.ServiceName]
            : ShippingProductService;

        return new service(untyped);
    }
}

