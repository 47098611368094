import {ShippingProductService} from "../shipping-product-service";

export class InsuranceService extends ShippingProductService {
    public InsuranceValue: number = null;
    public InsuranceTriggerLimit: number = null;
    public AutoSetShippingValue: boolean = true;

    constructor(obj?: Partial<InsuranceService>) {
        super(obj);
        ctor(this, obj, {
            NumberFields: [
                "InsuranceValue",
                "InsuranceTriggerLimit",
            ],
        });
    }
}
