
export type FilterOperation = "Equals" | "NotEquals";
export type StringFilterOperation = FilterOperation | "Contains" | "NotContains" | "StartsWith" | "EndsWith";
export type NumberFilterOperation = FilterOperation | "Lt" | "Gt" | "LtE" | "GtE" | "Between";

export class ColumnFilter {
    public Operation: FilterOperation | StringFilterOperation | NumberFilterOperation = null;
    public Value: any = null;

    constructor(obj?: Partial<ColumnFilter>) {
        ctor(this, obj);
    }
}
