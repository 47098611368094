import {NgIf} from "@angular/common";
import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {TranslateModule} from "@ngx-translate/core";
import {BbSelectComponent} from "../../../../shared/ui/form/bb-select/bb-select.component";
import {BbSlideToggleComponent} from "../../../../shared/ui/form/bb-slide-toggle/bb-slide-toggle.component";

@Component({
    selector: "app-delete-shop-dialog",
    templateUrl: "./delete-shop-dialog.component.html",
    styleUrls: ["./delete-shop-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        ReactiveFormsModule,
        BbSelectComponent,
        TranslateModule,
        BbSlideToggleComponent,
        MatButtonModule,
        MatFormFieldModule,
        NgIf,
    ],
})
export class DeleteShopDialogComponent {
    private readonly data = inject(MAT_DIALOG_DATA);
    private readonly dialogRef = inject(MatDialogRef);

    public readonly formGroup = new FormGroup({
        deleteMode: new FormControl(this.data.allManualShops ? "transferOrders" : "convert", Validators.required),
        targetShop: new FormControl(this.data.targetShopOptions[0].Value, Validators.required),
        confirmDelete: new FormControl(false),
    });

    public readonly deleteModeOptions: KeyValuePair<string, string>[] = [
        ...(this.data.allManualShops ? [] : [{Key: "label.switch_to_manual_shop", Value: "convert"}]),
        {Key: "label.delete_shop_move_orders", Value: "transferOrders"},
        {Key: "label.delete_shop_completely", Value: "delete"},
    ];

    public readonly targetShopOptions = this.data.targetShopOptions;

    public confirm(): void {
        const isValid =
            this.formGroup.value.deleteMode === "convert" ||
            (this.formGroup.value.deleteMode === "transferOrders" && this.formGroup.value.targetShop !== null) ||
            (this.formGroup.value.deleteMode === "delete" && this.formGroup.value.confirmDelete === true);

        if (!isValid) {
            return;
        }

        this.dialogRef.close({...this.formGroup.value, isValid});
    }
}
