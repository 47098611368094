import {Inject, Injectable, Type} from "@angular/core";
import {ComponentStore} from "@ngrx/component-store";
import {OnboardingAssistantStep} from "../../model/step";
import {ONBOARDING_ASSISTANT_STEPS} from "../../steps/steps.token";
import {OnboardingAssistantSavingTaskComponent} from "../../tasks/saving/saving.component";
import {OnboardingAssistantWelcomeTaskComponent} from "../../tasks/welcome/welcome.component";

interface TaskValue {
    current: Type<unknown>;
    previous?: TaskValue;
}

interface OnboardingAssistantComponentState {
    activeStepIndex: number;
    progress: number;
    task: TaskValue;
    data: {
        country: string;
		vatId: string;
		vatIdValidFrom: Nullable<Date>;
        vatMode: number;
        furtherVatIds: {country: string; vatId: string, validFrom: Date}[];
        useTargetCountryTaxRate: boolean;
		useOss: boolean;
		useIgl: boolean;
        detectThirdCountry: boolean;
		thirdCountryNetCorrect: boolean;
		iglVatMode: string;
    };
}

@Injectable()
export class OnboardingAssistantComponentStore extends ComponentStore<OnboardingAssistantComponentState> {
    public readonly activeStepIndex$ = this.select(({activeStepIndex: activeStep}) => activeStep);
    public readonly taskComponent$ = this.select(({task}) => task.current);
    public readonly hasBackButton$ = this.select(({task}) => task.previous !== undefined);
    public readonly progress$ = this.select(({progress}) => progress);
    public readonly data$ = this.select(({data}) => data);

    public readonly setData = this.updater((state, data: Object) => ({...state, data: {...state.data, ...data}}));

    public readonly nextTask = this.updater((state, activeTask: Type<unknown>) => {
        const {activeStepIndex, progress} = this.getStepIndexAndProgressFromTask(activeTask);
        if (activeStepIndex < 0 || progress < 0) {
            return state;
        }

        return {
            ...state,
            activeStepIndex,
            progress,
            task: {
                current: activeTask,
                previous: state.task,
            },
        };
    });

    public readonly previousTask = this.updater((state, _) => {
		let previousTask = state.task.previous;

		if (previousTask?.current === OnboardingAssistantSavingTaskComponent) {
			previousTask = state.task.previous?.previous;
		}
		
        if (!previousTask) {
            return state;
        }

        const {activeStepIndex, progress} = this.getStepIndexAndProgressFromTask(previousTask.current);
        if (activeStepIndex < 0 || progress < 0) {
            return state;
        }

        return {
            ...state,
            activeStepIndex,
            progress,
            task: {
                current: previousTask.current,
                previous: previousTask.previous,
            },
        };
    });

	constructor(@Inject(ONBOARDING_ASSISTANT_STEPS) private readonly steps: OnboardingAssistantStep[]) {
        super({
            activeStepIndex: 0,
            task: {
                current: OnboardingAssistantWelcomeTaskComponent,
            },
            progress: 100 / steps.reduce((total, step) => (total += step.tasks.length), 0),
            data: {
                country: "",
				vatId: "",
				vatIdValidFrom: null,
                vatMode: 0,
                furtherVatIds: [],
                useTargetCountryTaxRate: false,
				useOss: false,
				useIgl: false,
                detectThirdCountry: false,
				thirdCountryNetCorrect: false,
				iglVatMode: "",
            },
        });
    }

    private getStepIndexAndProgressFromTask(task: Type<unknown>): {
        activeStepIndex: number;
        progress: number;
    } {
        const activeStepIndex = this.steps.findIndex((step) => step.tasks.includes(task));
		const taskIndex = this.steps[activeStepIndex].tasks.findIndex((t) => t === task);
		
        const totalTaskAmount = this.steps.reduce((total, step) => (total += step.tasks.length), 0);
        let totalTaskIndex = 0;
        for (let i = 0; i < activeStepIndex; i++) {
            totalTaskIndex += this.steps[i].tasks.length;
        }
        totalTaskIndex += taskIndex + 1;
        const progress = (totalTaskIndex / totalTaskAmount) * 100;

        return {
            activeStepIndex,
            progress,
        };
    }
}
