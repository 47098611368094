import {inject, Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {filter, ignoreElements, Observable, tap} from "rxjs";
import {Shop} from "../../model/shop";
import {ShopType} from "../../model/shop-type.enum";
import {DefaultShopAddCommand} from "../../service/command/default-shop-add.command";
import {KauflandApiKeyDialogComponent} from "./kaufland-api-key-dialog/kaufland-api-key-dialog.component";

@Injectable({
    providedIn: "root",
})
export class KauflandShop implements Shop {
    public readonly key = "kaufland";
    public readonly name = "Kaufland.de";
	public readonly type = ShopType.Marketplace;
	public readonly logo = `assets/images/logo/shops/${this.key}.svg`;
    public readonly featured = true;

    private readonly addCommand = inject(DefaultShopAddCommand);
	private readonly matDialog = inject(MatDialog);

	add(): Observable<never> {
		return this.matDialog.open(KauflandApiKeyDialogComponent).afterClosed().pipe(
			filter(res => !!res),
			tap((res) => this.addCommand.execute(this.key, res)),
			ignoreElements(),
		);
    }
}
