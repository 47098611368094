import {Inject, Injectable} from "@angular/core";
import {BB_PRESET_REPO, Column, Preset, PresetRepository} from "@app/app/grid/core";
import {ClientStorageService} from "@bb-core/service";

export class ApplyPresetRequest<T> {
    public preset: Preset<T> = null;
    public realColumns: Column<T>[] = [];

    constructor(obj?: Partial<ApplyPresetRequest<T>>) {
        ctor(this, obj);
    }
}

export abstract class ApplyPresetPresenter<T> {
    public abstract displayAppliedPreset(preset: Preset<T>);

    public abstract displayNewColumnOptions(columns: Column<T>[]);

    public abstract resetPreset(): void;

    public abstract setSearchTerm(term: string): void;
}


@Injectable({providedIn: "any"})
export class ApplyPresetUseCase<T = any> implements IUseCase<ApplyPresetRequest<T>, ApplyPresetPresenter<T>> {

    constructor(@Inject(BB_PRESET_REPO) private readonly repository: PresetRepository<T>,
                private readonly clientStorage: ClientStorageService,
    ) {
    }

    public async execute(request: ApplyPresetRequest<T>,
                         presenter?: ApplyPresetPresenter<T>,
    ): Promise<void> {
        const {preset, realColumns} = request;

        for (const realColumn of realColumns) {
            const column = preset?.Columns.find(c => c.Name === realColumn.Name);

            Object.assign(realColumn, {
                IsVisible: preset == null || !preset.RememberColumns || (column != null && column.IsVisible),
                Sort: preset != null && preset.RememberOrder ? column?.Sort : null,
                Filter: preset != null && preset.RememberColumnFilters ? column?.Filter : null,
                VisibleIndex: preset != null && preset.RememberColumns ? column?.VisibleIndex : null,
                Width: preset != null && preset.RememberColumns ? column?.Width : null,
            });
        }

        presenter?.displayAppliedPreset(new Preset<T>(preset));
        presenter?.displayNewColumnOptions(realColumns);

        if (preset == null) {
            presenter?.resetPreset();
        }

        if (preset != null
            && preset.RememberSearchTerm
            && preset.SearchTerm != null
            && preset.SearchTerm !== "") {
            presenter?.setSearchTerm(preset.SearchTerm);
        } else {
            presenter?.setSearchTerm(null);
        }

        this.clientStorage.set(`grid.preset.${this.repository.uniqueIdentifier}.selected`, preset?.Id);
    }
}
