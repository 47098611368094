import {ChangeDetectionStrategy, Component} from "@angular/core";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantCountryTaskComponent} from "../country/country.component";

@Component({
    selector: "bb-welcome",
    templateUrl: "./welcome.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantWelcomeTaskComponent {
	constructor(private readonly onboardingStore: OnboardingAssistantComponentStore) {}
	
	public next(): void {
		this.onboardingStore.nextTask(OnboardingAssistantCountryTaskComponent);
	}
}
