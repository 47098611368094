import {Injectable} from "@angular/core";
import {CustomerListPermissions} from "@app/app/customer/core";
import {AuthenticationService} from "@bb-core/service";

export abstract class ShowCustomerListPresenter {
    public abstract applyPermissions(permissions: CustomerListPermissions,
    ): void;
}

@Injectable({providedIn: "root"})
export class ShowCustomerListUseCase implements IUseCase<void, ShowCustomerListPresenter> {

    constructor(private readonly authService: AuthenticationService,
    ) {
    }

    public async execute(request: void,
                         presenter?: ShowCustomerListPresenter,
    ): Promise<void> {
        const permissions = await this.getPermissions();
        presenter?.applyPermissions(permissions);
    }

    private async getPermissions(): Promise<CustomerListPermissions> {
        const userRoles = await this.authService.getRoles();
        return new CustomerListPermissions({
            canEdit: userRoles.includes("kunden - bearbeiten"),
            canCreate: userRoles.includes("kunden - bearbeiten"),
            canDelete: userRoles.includes("kunden - bearbeiten"),
            canImport: userRoles.includes("kunden - bearbeiten"),
            canExport: userRoles.includes("kunden - datenexport"),
            canMerge: userRoles.includes("kunden - kunden zusammenfassen"),
            canList: userRoles.includes("kunden - liste anzeigen"),
            canCreateOrder: userRoles.includes("auftrag - neuer auftrag"),
        });
    }
}
