import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {SelectOption} from "@app/app/common/form/select-field/select-field.options";
import {Language, PriceGroup} from "@app/app/customer/core";
import * as ngx from "@ngx-resource/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: "root"})
export class ApiPriceGroupRepository {
    private readonly baseUrl = `${environment.baseUrl}/pricegroup`;

    constructor(private readonly httpClient: HttpClient) {}

    public getAll(): Observable<PriceGroup[]> {
		return this.httpClient.get<{data: PriceGroup[]}>(this.baseUrl).pipe(map(({data}) => data));
    }

    public async fetchSelectOptions(): Promise<SelectOption[]> {
        const result = await this.getAll().toPromise();
        return result.map((p) => new PriceGroup(p)).map((p) => ({value: p.Id, text: p.toString()}));
    }
}

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/language",
})
export class ApiLanguageRepository extends ngx.Resource {

    @ngx.ResourceAction({
        path: "/getall",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetAllLanguages: ngx.IResourceMethod<any, Language[]>;

    public async fetchSelectOptions(): Promise<SelectOption[]> {
        return (await this.doGetAllLanguages()).map((p) => new Language(p)).map(p => ({
            value: p.Id.toString(),
            text: p.toString(),
        }));
    }

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }
}
