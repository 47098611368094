import {Injectable, NgZone} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: "root",
})
export class BusyService {
    private jobsBackingField: number = 0;

    private set jobs(newVal: number) {
        this.jobsBackingField = newVal;
        this.isBusy$.next(this.jobsBackingField > 0);
    }

    private get jobs(): number {
        return this.jobsBackingField;
    }

    public isBusy$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private readonly zone: NgZone) {
        zone.runOutsideAngular(() => {
            const oldOpen = XMLHttpRequest.prototype.open;
            const increase = this.increase.bind(this);
            const decrease = this.decrease.bind(this);
            const OPENED = 1;
            const DONE = 4;
            // override the native send()
            XMLHttpRequest.prototype.open = function() {
                if (!arguments[1].includes("signalr") && arguments[1].indexOf("//") !== 0 && arguments[1].indexOf("http") !== 0) {
                    let timeoutHandle = null;
                    this.addEventListener("readystatechange", (evt) => {
                        if (this.readyState === OPENED) {
                            timeoutHandle = window.setTimeout(() => {
                                timeoutHandle = null;
                                increase();
                            }, 1000);
                        } else if (this.readyState === DONE) {
                            if (timeoutHandle != null) {
                                window.clearTimeout(timeoutHandle);
                            } else {
                                decrease();
                            }
                        }
                    });
                }

                oldOpen.apply(this, arguments);
            };
        });
    }

    public increase(): void {
        this.jobs++;
    }

    public decrease(): void {
        if (this.jobs > 0) {
            this.jobs--;
        }
    }
}
