import {Column} from "./column";
import {TranslationKey} from "@bb-core/entity";

export class GridOptions<T = any> {
    public Columns: Column<T>[] = [];
    public PageSize: number = null;
    public HideWhenNoResults: boolean = true;
    public SearchTerm: string = null;
    public AdditionalArguments: any = null;
    public ShowHeaderFilter: boolean = true;
    public AllowColumnResizing: boolean = true;
    public ShowEditButton: boolean = false;
    public NoDataText: TranslationKey = "text.no_data";
    public SelectionMode: "none" | "multiple" = "none";

    constructor(obj?: Partial<GridOptions<T>>) {
        ctor(this, obj, {
            Collections: {
                Columns: Column,
            },
        });
    }
}
