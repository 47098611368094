import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Country} from "../../../shared/models";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class UtilsRepository {
    constructor(private readonly httpClient: HttpClient) {}

    getCountries(): Observable<Country[]> {
        return this.httpClient
            .get<Country[]>(`${environment.baseUrl}/country`)
            .pipe(map((countries) => countries.filter((country) => !!country.ISO2)));
    }
}
