import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TranslationKey} from "@bb-core/entity";

@Component({
    selector: "bb-ok-dialog",
    template: `
        <h4 mat-dialog-title>{{ title }}</h4>
        <mat-dialog-content [innerHTML]="message">
        </mat-dialog-content>
        <mat-dialog-actions align="end" *ngIf="showActions">
            <button mat-button [matDialogClose]="false">{{ buttonText | translate }}</button>
        </mat-dialog-actions>
    `,
})
export class OkDialogComponent {

    public title: string = "";
    public message: string = "";
    public buttonText: TranslationKey = "button.close";
    public showActions: boolean = true;

    constructor(@Inject(MAT_DIALOG_DATA) data: {
        title: string,
        message: string,
        buttonText?: string,
        showActions?: boolean,

    }) {
        this.title = data.title;
        this.message = data.message;
        this.buttonText = (data.buttonText as TranslationKey) ?? this.buttonText;
        this.showActions = data.showActions ?? this.showActions;
    }

}
