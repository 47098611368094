<mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel [expanded]="viewModel.expandFilterPanel$ | async"
                         (expandedChange)="storeExpanded('filter', $event)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'panel_title.filter' | translate }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mw-form-builder #presetForm
                         [mwFormModel]="viewModel.formModel"
                         [mwFormData]="viewModel.preset"></mw-form-builder>
        <div fxLayout="column" fxLayoutGap="8px">
            <button fxFlex="none" type="button" mat-raised-button color="primary"
                    *ngIf="viewModel.preset != null && !viewModel.preset.IsSystemPreset"
                    (click)="savePreset(presetForm.submit())">
                {{ 'button.save' | translate }}
            </button>
            <button fxFlex="none"
                    type="button"
                    mat-raised-button
                    (click)="saveAsNewPreset(presetForm.submit())">
                {{ 'button.save_copy' | translate }}
            </button>
            <ng-container
                    *ngIf="viewModel.preset != null && viewModel.preset.Id != null && !viewModel.preset.IsSystemPreset">
                <mat-divider></mat-divider>
                <button fxFlex="none" type="button" mat-raised-button color="warn"
                        (click)="deleteSelectedPreset()">
                    {{ 'button.delete' | translate }}
                </button>
            </ng-container>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="viewModel.expandColumnsPanel$ | async"
                         (expandedChange)="storeExpanded('columns',$event)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'panel_title.columns' | translate }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list>
            <mat-list-item *ngFor="let item of grid.options.Columns">
                <mat-slide-toggle
                        [checked]="item.IsVisible"
                        (change)="setColumnVisibility(item, $event.checked)"
                >
                    {{ item.Caption | translate}}
                </mat-slide-toggle>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>
</mat-accordion>