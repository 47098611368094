import { Injectable } from "@angular/core";
import * as ngx from "@ngx-resource/core";

export interface IEmployee {
    ShopsAsId: number[];
    Shops: any[];
    RolesAsId: string[];
    Roles: IEmployeeRole[];
    Password: string;
    IsLockedOut: boolean;
    UserId: string;
    Name: string;
    Created: Date;
    UserName: string;
    Email: string;
    Selected: boolean; // virtual, only for view
}

export interface IEmployeeRole {
    RoleName: string;
    RoleId: string;
    Description: string;
    Granted: boolean;
}

export interface IEmployeeLockState {
    LockState: boolean;
    EmployeeIds: any[];
}

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
        pathPrefix: "/api/angular/employee",
})
export class EmployeeService extends ngx.Resource {

    @ngx.ResourceAction({
        path: "/",
    })
    public getEmployees: ngx.IResourceMethod<void, IEmployee[]>;

    @ngx.ResourceAction({
        path: "/test-mail-recipients",
    })
    public getTestMailRecipients: ngx.IResourceMethod<void, IEmployee[]>;

    @ngx.ResourceAction({
        path: "/roles",
    })
    public getRoles: ngx.IResourceMethod<void, IEmployeeRole[]>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/",
    })
    public save: ngx.IResourceMethod<IEmployee, IEmployee>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/delete-many",
    })
    public deleteMany: ngx.IResourceMethod<string[], void>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/setlockstate",
    })
    public setLockState: ngx.IResourceMethod<IEmployeeLockState, IEmployeeLockState>;

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }
}
