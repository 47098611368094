import {ShippingProductService} from "../shipping-product-service";

export class CodService extends ShippingProductService {
    public CodFeeToAdd: number = 0.0;
    public UseDefaultFee: boolean = true;

    constructor(obj?: Partial<CodService>) {
        super(obj);
        ctor(this, obj);
    }
}
