import { Injectable, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { AccountResetSettingsDto } from "../../account/data/account-reset-settings.dto";
import { AccountSyncSettings } from "../../account/model/account-sync-settings.model";
import { EnterAccountSyncSettingsDialogComponent } from "../../account/presentation/enter-account-sync-settings-dialog/enter-account-sync-settings-dialog.component";
import { EnterResetAccountSettingsDialogComponent } from "../../account/presentation/enter-reset-account-settings-dialog/enter-reset-account-settings-dialog.component";

@Injectable({
	providedIn: "root",
})
export class AccountService {
    private readonly dialog = inject(MatDialog);

	public openEnterAccountResetSettingsDialog(): Observable<AccountResetSettingsDto | undefined> {
		return this.dialog
            .open(EnterResetAccountSettingsDialogComponent)
            .afterClosed();
	}

	public openEnterAccountSyncSettingsDialog(): Observable<AccountSyncSettings | undefined> {
		return this.dialog
            .open(EnterAccountSyncSettingsDialogComponent)
            .afterClosed();
	}
}
