import {ShippingProductService} from "../shipping-product-service";

export class ReturnLabelService extends ShippingProductService {
    public UseSenderAddress: boolean = true;

    public name1: string = null;
    public name2: string = null;
    public name3: string = null;

    public street: string = null;
    public streetNumber: string = null;
    public zip: string = null;
    public city: string = null;
    public countryCode: string = null;

    public telephone: string = null;
    public email: string = null;
    public contactPerson: string = null;

    constructor(obj?: Partial<ReturnLabelService>) {
        super(obj);
        ctor(this, obj);
    }
}
