import {Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AccessToken} from "@app/app/common/model/oauth/access-token";
import {OAuthService} from "@app/app/oauth/core";
import {AmazonScpnAuthResult} from "./auxiliaries/AmazonScpnAuthResult";
import {AmazonScpnAuthData} from "./auxiliaries/AmazonScpnAuthData";
import {
    connectionTypes,
    SellerCentrals,
    AmazonMarketplaces,
    ReautResultStates,
    States,
    AmazonMarketplace,
} from "./auxiliaries/consts";


@Component({
    selector    : "bb-amazon-scpn-auth-dialog",
    templateUrl : "./amazon-scpn-auth-dialog.component.html",
    styleUrls   : ["./amazon-scpn-auth-dialog.component.scss"],
    encapsulation : ViewEncapsulation.None, // Why is this necessary??
})
export class AmazonScpnAuthDialogComponent implements OnInit {

    merchantId: string = "";
    
    States = States;
    state: States = States.START;

    connectionTypes = connectionTypes;
    connectionType: string = this.connectionTypes[0].value;

    ReautResultStates = ReautResultStates;
    reauthResultState: ReautResultStates = ReautResultStates.NO_CONNECTIONS;
    
    reauthProgressMessage: string = "";
    reauthResult: AmazonScpnAuthResult;

    oAuthAccessToken: AccessToken;
    oAuthState: { [key: string]: string };
    
    amazonMarketplaces: AmazonMarketplace[] = AmazonMarketplaces.getList();
    amazonMarketplace: string = this.amazonMarketplaces[0].id;

    constructor(
        @Inject(MAT_DIALOG_DATA) public amazonScpnAuthData: AmazonScpnAuthData,
        private readonly oAuthService: OAuthService,
        public dialogRef: MatDialogRef<AmazonScpnAuthDialogComponent>,
        @Inject("shopResource") private shopResource: IShopResource,
    ) {
        // Prevent user from accidentally closing the dialog by clicking in the background
        dialogRef.disableClose = true; 
    }

    /******************************************************
     ****** Dialog UI logic *******************************
     ******************************************************/

    public ngOnInit(): void {
        if ("amazon_callback_uri" in this.amazonScpnAuthData) {

            // We just arrived from "Authorize now" button in seller central
            this.state = States.START;
            this.merchantId = this.amazonScpnAuthData.selling_partner_id;

        } else if ("spapi_oauth_code" in this.amazonScpnAuthData) {

            // We re-arrived from Amazon and re-authorize all connections
            this.state = States.REAUTHORIZE_IN_PROGRESS;
            this.reauthorizeConnections();

        }
    }
    
    changeState(id: States) {
        this.state = id;
    }

    close() {
        this.dialogRef.close();
    }

    /******************************************************
     ****** Re-Authorization ******************************
     ******************************************************/
    
    async reauthorize() {

        try {

            await this._redirectToAmazon("channel", "amazon", "https://app.billbee.io/app_v2/amazon/scpnauth", { MerchantId : this.merchantId });

        } catch (e) {

            // ERROR /////
            this.reauthResultState = ReautResultStates.FAILED;
            this.state = States.REAUTHORIZE_RESULT;

        }
    }

    async reauthorizeConnections() {

        try {

            this.oAuthAccessToken = await this._getChannelAccessToken();
            this.oAuthState = await this._verifyState();

            this.reauthResult = await this.shopResource.amazonScpnAuth({
                SpApiRefreshToken : this.oAuthAccessToken.RefreshToken,
                MerchantId        : this.oAuthState.MerchantId,
            }).$promise;

            // Check if any of connection was updated
            if (this._connectionWereUpdated()) {
                // At least one connection was updated -> Show updated connections to the user
                this.reauthResultState = ReautResultStates.SUCCESS;
            }
            else {
                // No connection was updated -> There seem to be not connections matching the merchant ID
                this.reauthResultState = ReautResultStates.NO_CONNECTIONS;
            }

            this.state = States.REAUTHORIZE_RESULT;

        } catch (e) {

            // ERROR /////
            this.reauthResultState = ReautResultStates.FAILED;
            this.state = States.REAUTHORIZE_RESULT;

        }
    }

    /******************************************************
     ****** Connection creation ***************************
     ******************************************************/

    createConnectionAfterConnectionTypeSelection() {

        if ("shop" == this.connectionType) {
            // Shop -> Derive region from seller centrel URL and redirect to Amazon immediately
            this._redirectToAmazon("channel", "amazon", "https://app.billbee.io/app_v2/oauth/channel/amazon",
                { Region : this._getRegionFromCallbackUri() });
        } else {
            // Fulfillment, payment import -> Let user select marketplace first
            this.changeState(States.SELECT_MARKETPLACE);
        }
    }
    
    createConnectionAfterMarketplaceSelection() {
        const [type, api] = this._getCallbackTypeAndApi();
        const ourRedirectUri = `https://app.billbee.io/app_v2/oauth/${type}/${api}`;
        this._redirectToAmazon(type, api, ourRedirectUri, { MarketplaceId : this.amazonMarketplace });
    }

    /******************************************************
     ****** Auxiliary methods *****************************
     ******************************************************/

    private _getRegionFromCallbackUri(): string {
        const amazonCallbackUri = this.amazonScpnAuthData.amazon_callback_uri;
        const domain = new URL(amazonCallbackUri);
        const hostname = domain.hostname;
        return SellerCentrals.getSellerCentralByHostname(hostname);
    }

    private _getCallbackTypeAndApi(): [string, string] {
        switch (this.connectionType) {
            case "shop":
                return ["channel", "amazon"];
            case "fulfillment_fba":
                return ["shipping", "AmazonFBA"];
            case "fulfillment_sfp":
                return ["shipping", "AMZ_MWS_Merchant"];
            case "payment_import":
                return ["payment", "amazon"];
        }
    }

    private async _redirectToAmazon(type: string, api: string, ourRedirectUri: string, ourState: { [key: string]: string }) {

        // Implicitly create state by creating an auth URL, don't use URL since it does not fit SCPN flow
        const url = await this._getAuthorizeUrl(type, api, ourState);
        if (null == url
            || 0 >= url.length) {
            throw new Error("Generation of auth URL failed.");
        }

        // Get our state key
        const params = new URLSearchParams(url);
        const ourStateKey = params.get("state");
        if (null == ourStateKey
            || 0 >= ourStateKey.length) {
            throw new Error("Could not derive state parameter.");
        }

        // Create our redirect URI
        const amazonCallbackUri = this.amazonScpnAuthData.amazon_callback_uri;
        const amazonState = this.amazonScpnAuthData.amazon_state;

        document.location.href = `${amazonCallbackUri}?redirect_uri=${ourRedirectUri}&amazon_state=${amazonState}&state=${ourStateKey}`;
    }

    private async _getAuthorizeUrl(type, api, ourState): Promise<string> {
        switch (type) {
            case "channel":
                return await this.oAuthService.getChannelAuthorizeUrl(api, null, ourState);
            case "shipping":
                return await this.oAuthService.getShippingAuthorizeUrl(api, null, ourState);
            case "payment":
                return await this.oAuthService.getPaymentAuthorizeUrl(api, null, ourState);
        }
    }

    private async _getChannelAccessToken() {
        const oAuthAccessToken = await this.oAuthService.getChannelAccessToken({
            api    : "amazon",
            code   : this.amazonScpnAuthData.spapi_oauth_code,
            state  : this.amazonScpnAuthData.state,
            config : null,
        });
        if (!("RefreshToken" in oAuthAccessToken)
            || 0 >= oAuthAccessToken.RefreshToken.length) {
            throw new Error("Refresh Token is empty.");
        }

        // DEBUG
        // const oAuthAccessToken = new AccessToken();
        // oAuthAccessToken.RefreshToken = "Atzr|IwEBIEzmah0D4G8PxCu1-UN2RfgYQw4135ZsmkCpRDbf2GOz4SW88OzcvKKl9_y_lNm-wH03oNCppjOEyHRaTLykd6fdZh_Pj-eY4xiybYLS3Gh_xo7sfTetK-Qg3Z96yYJJJ3_VFSssUomxlcbjAxx4JqlDnDrLnG3p-WrtGolg-C3vG1QE2v5QNN0TXjoGkQ1f4PRgA4otKXwYpWfDzdFhIQQjzZbUQcGu-mUOhkWKnFLIlTdfb21ouM2RhQ52XIuw0HG7SobDfJ5BgIc55y5jvfblI7gh5-pK0N5r-_9WEdm-n8qs0YHwGohMKoEuHVLZRf8";

        return oAuthAccessToken;
    }

    private async _verifyState() {
        const oAuthState = await this.oAuthService.verifyState({ state : this.amazonScpnAuthData.state });
        if (!("MerchantId" in oAuthState)
            || 0 >= oAuthState.MerchantId.length) {
            throw new Error("Merchant ID is empty.");
        }

        // DEBUG
        // const oAuthState: { [key: string]: string } = {};
        // oAuthState.MerchantId = "AQQ78VVU0E0SU";

        return oAuthState;
    }

    private _connectionWereUpdated() {
        return 0 < this.reauthResult.ReauthorizedShopConnections.length
            || 0 < this.reauthResult.ReauthorizedFulfillmentConnections.length
            || 0 < this.reauthResult.ReauthorizedPaymentImportConnections.length;
    }
}
