<h3 mat-dialog-title>{{ "title.delete_shops" | translate }}</h3>
<mat-dialog-content>
    <form [formGroup]="formGroup">
        <bb-select
            formControlName="deleteMode"
            [options]="deleteModeOptions"
            [label]="'label.what_to_do_with_shops' | translate"
        ></bb-select>

        <bb-select
            formControlName="targetShop"
            [options]="targetShopOptions"
            [label]="'label.move_orders_to_which_shop' | translate"
            [hidden]="formGroup.value.deleteMode !== 'transferOrders'"
        ></bb-select>

        <bb-slide-toggle
            formControlName="confirmDelete"
            [label]="'label.confirm_delete' | translate"
            [hidden]="formGroup.value.deleteMode !== 'delete'"
        ></bb-slide-toggle>

        <mat-error *ngIf="formGroup.value.deleteMode === 'delete'">
            {{ "text.delete_shop_completely_description" | translate }}<br />
            <strong>{{ "text.process_can_not_be_undone" | translate }}</strong>
        </mat-error>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>{{ "button.cancel" | translate }}</button>
    <button mat-flat-button color="warn" (click)="confirm()">
        {{ (formGroup.value.deleteMode === "convert" ? "button.transform" : "button.delete") | translate }}
    </button>
</mat-dialog-actions>
