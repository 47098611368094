<h3 mat-dialog-title>{{ "account_settings.in_app_help_settings" | translate }}</h3>
<mat-dialog-content>
    <span>
        {{ "account_settings.show_helpscout_beacons_description" | translate }}
    </span>
    <bb-slide-toggle
        [formControl]="enableHelpscoutBeaconSetting"
        [label]="'account_settings.show_helpscout_beacons' | translate"
    ></bb-slide-toggle>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="undefined">{{ "general.cancel" | translate }}</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="enableHelpscoutBeaconSetting.value">{{ "general.save" | translate }}</button>
</mat-dialog-actions>