import { DestroyRef, Injectable, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "@app/app/environments/environment";
import { selectAccount } from "@bb/states/selectors";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Analytics } from "@segment/analytics-node";
import { distinctUntilChanged, filter, map, switchMap, tap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class SegmentService {
    private readonly actions = inject(Actions);
    private readonly store = inject(Store);
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);

    private readonly _analytics: Analytics = new Analytics({ writeKey: environment.segmentWriteKey });

    init() {
        this.store
            .select(selectAccount)
            .pipe(
                tap((account) => {
                    this._analytics.identify({
                        userId: account.Id,
                        traits: {
                            name: account.FullName,
                            email: account.MailAddress,
                            child: !account.IsMasterUser,
                            parentuser: account.MasterUserId,
                            instanceid: account.InstanceId,
                        },
                    });

                    this._analytics.group({
                        userId: account.Id,
                        groupId: account.MasterUserId || "",
                    });
                }),
                switchMap((account) =>
                    this.router.events.pipe(
                        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
                        map(e => e.url),
                        distinctUntilChanged(),
                        map(() => account),
                    ),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(account => this._analytics.page({ userId: account.Id }));
    }
}
