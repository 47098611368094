import {Injectable} from "@angular/core";
import * as model from "@app/app/common/model";
import {ActivateTwoFactorAuthentication} from "@app/app/common/model/account/activate-two-factor-authentication";
import {InitializeTwoFactorAuthentication} from "@app/app/common/model/account/initialize-two-factor-authentication";
import {TextValuePair} from "@bb-core/entity";
import * as ngx from "@ngx-resource/core";

/**
 * @deprecated use account/data/account.repository.ts
 */
@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/account",
})
export class AccountService extends ngx.Resource {
    @ngx.ResourceAction({
        path: "/details",
    })
    public getDetails: ngx.IResourceMethod<void, model.UserProfile>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Patch,
        path: "/profile",
    })
    public patchProfile: ngx.IResourceMethod<model.UserProfile, model.UserProfile>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/change-password",
    })
    public changePassword: ngx.IResourceMethod<model.AccountSecurity, model.UserProfile>;

    @ngx.ResourceAction({
        path: "/invoices",
    })
    public getInvoices: ngx.IResourceMethod<void, model.Invoice[]>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        path: "/invoice",
    })
    public getCurrentInvoice: ngx.IResourceMethod<void, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        path: "/invoice/{id}",
    })
    public getInvoice: ngx.IResourceMethod<{id: number}, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        path: "/invoice/last",
    })
    public getLastInvoice: ngx.IResourceMethod<void, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/invoice-address",
    })
    public updateInvoiceAddress: ngx.IResourceMethod<model.Invoice, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/invoice-address-country",
    })
    public updateInvoiceAddressCountry: ngx.IResourceMethod<{country: string}, string>;

    @ngx.ResourceAction({
        path: "/countries",
    })
    public getCountryList: ngx.IResourceMethod<void, TextValuePair<string>[]>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/payment-information",
    })
    public updatePaymentInformation: ngx.IResourceMethod<model.InvoicePayment, model.InvoicePayment>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        path: "/external-logins",
    })
    public getExternalLogins: ngx.IResourceMethod<model.InvoicePayment[], model.ExternalLogin[]>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Delete,
        path: "/external-logins/{Id}",
    })
    public removeExternalLogin: ngx.IResourceMethod<model.ExternalLogin, void>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/delete",
    })
    public deleteAccount: ngx.IResourceMethod<model.Invoice, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/reset",
    })
    public resetAccount: ngx.IResourceMethod<model.Invoice, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        path: "/dpa",
    })
    public getDpa: ngx.IResourceMethod<model.Invoice, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/accept-dpa",
    })
    public acceptDpa: ngx.IResourceMethod<model.Invoice, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/agree-legal",
    })
    public agreeLegal: ngx.IResourceMethod<model.Invoice, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/dpa",
    })
    public getDpaAsFile: ngx.IResourceMethod<model.Invoice, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        path: "/currentmonthfees",
    })
    public getCurrentMonthFees: ngx.IResourceMethod<model.Invoice, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/paypalcreatepayment",
    })
    public createPayPalPayment: ngx.IResourceMethod<model.Invoice, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/paypalexecutepayment",
    })
    public executePayPalPayment: ngx.IResourceMethod<model.Invoice, model.Invoice>;
    // Will have to check model from its controller

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Get,
        path: "/initial-2fa-qr-code",
    })
    public getInitial2FAQrCodeUrl: ngx.IResourceMethod<void, InitializeTwoFactorAuthentication>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/activate-2fa",
    })
    public activateTwoFactorAuthentication: ngx.IResourceMethod<ActivateTwoFactorAuthentication, void>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/verify-2fa",
    })
    public verifyOtp: ngx.IResourceMethod<{Otp: string}, void>;

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }
}
