export class ShippingResult {
    public LabelDownloadUrl: string = null;
    public ExportDocDownloadUrl: string = null;
    public ShippingId: string = null;
    public Message: string = null;
    public Error: boolean = false;

    constructor(obj?: Partial<ShippingResult>) {
        ctor(this, obj);
    }
}
