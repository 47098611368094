import {ChangeDetectionStrategy, Component} from "@angular/core";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantUseIglTaskComponent} from "../use-igl/use-igl.component";

@Component({
    selector: "bb-onboarding-assistant-is-overseas-invoice-net-correct",
    templateUrl: "./is-overseas-invoice-net-correct.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantIsOverseasInvoiceNetCorrectComponent {
    constructor(private readonly onboardingStore: OnboardingAssistantComponentStore) {}

    public next(thirdCountryNetCorrect: boolean): void {
        this.onboardingStore.setData({thirdCountryNetCorrect});
        this.onboardingStore.nextTask(OnboardingAssistantUseIglTaskComponent);
    }
}
