export class Language {
    public Id: number = null;
    public Name: string = null;
    public Iso6391Code: string = null;

    constructor(obj?: Partial<Language>) {
        ctor(this, obj);
    }

    public toString(): string {
        return `${this.Name} (${this.Iso6391Code})`;
    }
}
