import {Component, EventEmitter, Input, Output} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {Shop} from "../../../../partners/shops/model/shop";
import {ShopType} from "../../../../partners/shops/model/shop-type.enum";
import {ListItemCardComponent} from "../../../../shared/ui/list-item-card/list-item-card.component";

@Component({
    selector: "bb-select-shop-card",
    templateUrl: "./select-shop-card.component.html",
    styleUrls: ["./select-shop-card.component.scss"],
    standalone: true,
    imports: [ListItemCardComponent, TranslateModule],
})
export class SelectShopCardComponent {
    @Input() public shop!: Shop;
    @Output() public cardClicked = new EventEmitter<void>();

    public readonly ShopType = ShopType;
}
