import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {BusyService} from "@app/app/common/service/busy.service";

@Component({
    selector: "bb-mvc",
    template: `
        <iframe #iframe *ngIf="src" [src]="src | safeContent" frameborder="0" class="mvc_holder" [ngClass]="extraClasses"></iframe>`,
})
export class MvcComponent implements OnInit, OnDestroy {

    public src: string;
    private readonly subscriptions: Subscription[] = [];

    @ViewChild("iframe", {static: false})
    public iframe: ElementRef<HTMLIFrameElement>;

    @Input()
    public extraClasses: string = "";

    constructor(private readonly route: ActivatedRoute,
                private readonly router: Router,
                private cdf: ChangeDetectorRef,
                public readonly busyService: BusyService,
    ) {
    }

    public ngOnInit(): void {
        let current = this.route.snapshot;
        const parts: string[] = [];

        do {
            parts.unshift(current.url.join("/"));
        } while ((current = current.parent) != null);

        const thisRoute = "/" + parts.filter((p) => p !== "").join("/");
        let iframeUrl: string = "";
        this.subscriptions.push(this.route.url.subscribe(async (url) => {
            const params = (await this.route.queryParams as any)._value;
            iframeUrl = "/partial/de/" + thisRoute;
            if (Object.keys(params).length > 0) {
                iframeUrl += "?" + Object.keys(params).map((k) => k + "=" + encodeURIComponent( params[k])).join("&");
            }
            iframeUrl = iframeUrl
                .replace(/~([0-9a-f]{2})/ig, "%$1") // ~2F -> %2F
                .replace(/(^|[^:])\/{2,}(.*)/gi, "$1/$2") // http://foo.bar//baz -> http://foo.bar/baz
            ;
            this.src = iframeUrl;
            this.cdf.detectChanges();
            const iframe = this.iframe.nativeElement;

            iframe.addEventListener("loadStatusChanged", (rawEvent: any) => {
                if (rawEvent.detail === "start") {
                    this.busyService.increase();
                } else {
                    this.busyService.decrease();
                }
            });

            iframe.addEventListener("navigate", (rawEvent: any) => {
                const event: { href: string, wasHandled: boolean } = rawEvent.detail;

                const [path, query] = event.href.split("?");
                let loweredPath = path.toLowerCase();
                if (loweredPath.indexOf("/de/report/") >= 0) {
                    loweredPath = loweredPath.replace("/de/report/", "/app_v2/report/");
                } else if (loweredPath.indexOf("/de/webdav/") >= 0) {
                    loweredPath = loweredPath.replace("/de/webdav/", "/app_v2/webdav/");
                } else if (loweredPath.indexOf("/de/ftp/") >= 0) {
                    loweredPath = loweredPath.replace("/de/ftp/", "/app_v2/ftp/");
                } else if (loweredPath.indexOf("/de/amazons3/") >= 0) {
                    loweredPath = loweredPath.replace("/de/amazons3/", "/app_v2/amazons3/");
                } else if (loweredPath.indexOf("/partial/de/") >= 0) {
                    loweredPath = loweredPath.replace("/partial/de/", "/app_v2/");
                } else if (loweredPath.indexOf("/de/customer") === 0) {
                    loweredPath = loweredPath.replace("/de/", "/app_v2/");
                }

                if (loweredPath.indexOf("app_v2") >= 0) {
                    if (query != null && query.length > 0) {
                        loweredPath += `?${query}`;
                    }
                    event.wasHandled = true;
                    const newRoute = loweredPath.split("app_v2")[1];
                    if (newRoute === thisRoute) {
                        iframe.contentWindow.location.href = iframeUrl;
                    } else {
                        this.router.navigateByUrl(newRoute);
                    }
                }
            });
        }));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

}
