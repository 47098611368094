import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { environment } from "@app/app/environments/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ShopifyRepository {
    private readonly httpClient = inject(HttpClient);

    getStockLocations(accessToken: string, shopName: string): Observable<{ Id: string; name: string }[]> {
        const params = new HttpParams().set("accessToken", accessToken).set("shopName", shopName);
        return this.httpClient.get<{ Id: string; name: string }[]>(
            `${environment.baseUrl}/settings/shop/shopify-stocklocations`,
            { params },
        );
    }
}
