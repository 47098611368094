import {Pipe, PipeTransform, inject} from "@angular/core";
import {Shop} from "../../../partners/shops/model/shop";
import {SHOPS} from "../../../partners/shops/shops.token";

@Pipe({
    name: "bbShopFromPartnerKey",
    standalone: true,
})
export class ShopFromPartnerKeyPipe implements PipeTransform {
    private readonly shops = inject(SHOPS);

    transform(value: string): Shop | undefined {
        return this.shops.find(({key}) => key === value);
    }
}
