import {VatDetectionFlagsEnum} from "@app/app/order";

export class PreSaveOrderRequest {
    public OrderDate: Date = null;
    public InvoiceAddressId: number = null;
    public ShipToAddressId: number = null;
    public SourceCountry: string = null;
    public CustomerId: number = null;
    public CustomerVatId: string = null;
    public VatDetectionFlags: VatDetectionFlagsEnum = VatDetectionFlagsEnum.IsDirty;
    public CreditForOrderId?: number = null;
    public VatMode?: number = null;

    constructor(obj?: Partial<PreSaveOrderRequest>) {
        ctor(this, obj, {
            DateFields: ["OrderDate"],
        });
    }
}

