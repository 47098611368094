import {AddressTypeEnum} from "@app/app/shipping/core";
import {CustomerMetaInformation, CustomerMetaType} from "@app/app/customer/core";

export class CustomerAddress {
    public Id: number = null;
    public IsUsedInOrders: boolean = false;
    public AddressType: AddressTypeEnum = AddressTypeEnum.Ship;
    public CustomerId: number = null;
    public Company: string = null;
    public FirstName: string = null;
    public LastName: string = null;
    public FullName: string = null;
    public Name2: string = null;
    public Street: string = null;
    public Housenumber: string = null;
    public AddressAddition: string = null;
    public Zip: string = null;
    public City: string = null;
    public State: string = null;
    public CountryCode: string = "DE";
    public Title: string = null;
    public Salutation: number = 0;
    public PhoneNumbers: CustomerMetaInformation[] = [];
    public MailAddresses: CustomerMetaInformation[] = [];
    public IsArchived: boolean = false;

    constructor(obj?: Partial<CustomerAddress>) {
        ctor(this, obj, {
            Collections: {
                MailAddresses: CustomerMetaInformation,
                PhoneNumbers: CustomerMetaInformation,
            },
            NumberFields: [
                "Id",
                "AddressType",
                "CustomerId",
                "Salutation",
            ],
        });

        this.MailAddresses = this.MailAddresses.filter(x => x.Value != null && x.Value.length > 0);
        for (const mailAddress of this.MailAddresses) {
            mailAddress.Type = CustomerMetaType.MailAddress;
        }

        this.PhoneNumbers = this.PhoneNumbers.filter(x => x.Value != null && x.Value.length > 0);
        for (const phoneNumber of this.PhoneNumbers) {
            phoneNumber.Type = CustomerMetaType.PhoneNumber;
        }
    }

    public get FullStreet(): string {
        return [this.Street, this.Housenumber].join(" ").trim();
    }
}
