import {TranslationKey} from "@bb-core/entity";

export type Label = string | TranslationKey | ILabelPart[];

export interface BaseOptions {
    label: Label;
    hint?: string | TranslationKey;
    tooltip?: string | TranslationKey;
    required?: boolean;
    readonly?: boolean;
    visible?: boolean;
    disabled?: boolean;
    floatLabel?: string;
}

export type ILabelPart = LabelPart | string;

export class LabelPart {
    public text: string | TranslationKey = "";
    public link?: string = null;
    public openInNewTab?: boolean = false;

    constructor(obj?: Partial<LabelPart>) {
        ctor(this, obj);
    }

    public toHtml(): string {
        if (this.link == null) {
            return this.text;
        }
        const target = this.openInNewTab ? "_blank" : "_self";
        return `<a href="${this.link}" target="${target}" class="primary test" onclick="event.cancelBubble() && event.stopPropagation()">${this.text}</a>`;
    }

    public static combineHtml(parts: ILabelPart[]): string {
        return parts.map(p => {
            if (p instanceof LabelPart) {
                return p.toHtml();
            }
            return p;

        }).join(" ");
    }
}

