export class Cloud {
    public Id: number = null;
    public Name: string = null;
    public CanStorage: boolean = false;
    public CanPrinter: boolean = false;

    constructor(obj?: Partial<Cloud>) {
        ctor(this, obj);
    }
}
