import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlContainer, FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { formViewProvider } from "@app/app/shared/provider/form-view.provider";
import { BbInputComponent } from "@app/app/shared/ui/form/bb-input/bb-input.component";
import { TranslateModule } from "@ngx-translate/core";
import { ShopifyShop } from "../shopify.shop";

@Component({
    selector: "bb-shopify-connection-form",
    templateUrl: "./shopify-connection-form.component.html",
    styleUrl: "./shopify-connection-form.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [formViewProvider],
    standalone: true,
    imports: [BbInputComponent, FormsModule, TranslateModule, MatButtonModule, MatIconModule],
})
export class ShopifyConnectionFormComponent {
    private readonly shopify = inject(ShopifyShop);
    private readonly controlContainer = inject(ControlContainer);
    private readonly destroyRef = inject(DestroyRef);

    readonly apiTokenFieldType = signal("password");

    onClickReAuth() {
        this.shopify
            .reAuth(this.controlContainer.control?.getRawValue())
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }

    toggleApiTokenFieldType() {
        this.apiTokenFieldType.update((type) => (type === "password" ? "text" : "password"));
    }
}
