import {Injectable} from "@angular/core";
import * as model from "@app/app/common/model";
import {PageableResourceService} from "@app/app/common/service/da/pageable-resource.service";
import * as ngx from "@ngx-resource/core";
import {ResourceRequestMethod} from "@ngx-resource/core";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/article",
})
export class ArticleService extends ngx.Resource implements PageableResourceService<model.Article> {

    @ngx.ResourceAction({
        path: "/{id}",
    })
    public getArticle: ngx.IResourceMethod<void, model.Article>;

    @ngx.ResourceAction({
        method: ResourceRequestMethod.Post,
        path: "/list",
    })
    public queryPaged: ngx.IResourceMethod<model.PagedQuery, model.PagedResult<model.Article>>;

    @ngx.ResourceAction({
        method: ResourceRequestMethod.Post,
        path: "/details-for-manual-stock-sync",
    })
    public getDetailsForManualStockSync: ngx.IResourceMethod<number[], Array<{
        Id: number, Name: string, Sources: Array<{
            Id: number,
            SourceApiAccountId: number,
            SourceName: string
            IsActive: boolean,
        }>,
    }>>;
    @ngx.ResourceAction({
        method: ResourceRequestMethod.Post,
        path: "/start-manual-stock-sync",
    })
    public startManualStockSync: ngx.IResourceMethod<number[], void>;

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }

}
