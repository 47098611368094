import { Injectable } from "@angular/core";
import * as model from "@app/app/common/model";
import * as ngx from "@ngx-resource/core";
import {OrderRepository} from "@app/app/order/data/repository/order.repository";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/settings/setting",
})
export class GeneralSettingsService extends ngx.Resource {
    // item-sync
    @ngx.ResourceAction({
        path: "/getItemSyncSettings",
    })
    public getItemSyncSettings: ngx.IResourceMethod<void, model.ItemSyncSettings>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/saveItemSyncSettings",
    })
    public saveItemSyncSettings: ngx.IResourceMethod<model.ItemSyncSettings, model.ItemSyncSettings>;

    // stock-calc
    @ngx.ResourceAction({
        path: "/getStockCalcSettings",
    })
    public getStockCalcSettings: ngx.IResourceMethod<void, model.StockCalcSettings>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/saveStockCalcSettings",
    })
    public saveStockCalcSettings: ngx.IResourceMethod<model.StockCalcSettings, model.StockCalcSettings>;

    // customer
    @ngx.ResourceAction({
        path: "/getShopSettings",
    })
    public getShopSettings: ngx.IResourceMethod<void, model.ShopSettings>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/saveShopSettings",
    })
    public saveShopSettings: ngx.IResourceMethod<model.ShopSettings, model.ShopSettings>;

    // search
    @ngx.ResourceAction({
        path: "/getSearchSettings",
    })
    public getSearchSettings: ngx.IResourceMethod<void, model.SearchSettings>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/saveSearchSettings",
    })
    public saveSearchSettings: ngx.IResourceMethod<model.SearchSettings, model.SearchSettings>;

    // notification
    @ngx.ResourceAction({
        path: "/getNotificationSettings",
    })
    public getNotificationSettings: ngx.IResourceMethod<void, model.NotificationSettings>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/saveNotificationSettings",
    })
    public saveNotificationSettings: ngx.IResourceMethod<model.NotificationSettings, model.NotificationSettings>;

    // stmp
    @ngx.ResourceAction({
        path: "/getSmtpList",
    })
    public getStmpList: ngx.IResourceMethod<void, model.Smtp[]>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/testsmtp",
    })
    public testsmtp: ngx.IResourceMethod<model.Smtp, model.Smtp>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/updateSmtpSettings",
    })
    public updateStmpSettings: ngx.IResourceMethod<model.Smtp[], model.Smtp[]>;

    // dashboard
    @ngx.ResourceAction({
        path: "/getDashboardSettings",
    })
    public getDashboardSettings: ngx.IResourceMethod<void, model.DashboardSettings>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/saveDashboardSettings",
    })
    public saveDashboardSettings: ngx.IResourceMethod<model.DashboardSettings, model.DashboardSettings>;

    // Order documents
    @ngx.ResourceAction({
        path: "/getOrderDocumentsSettings",
    })
    public getOrderDocumentsSettings: ngx.IResourceMethod<void, model.OrderDocumentsSettings>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/saveOrderDocumentsSettings",
    })
    public saveOrderDocumentsSettings: ngx.IResourceMethod<model.OrderDocumentsSettings, model.OrderDocumentsSettings>;

    // Vat setings
    @ngx.ResourceAction({
        path: "/getVatSettings",
    })
    public getVatSettings: ngx.IResourceMethod<void, model.VatSettings>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/saveVatSettings",
    })
    private doSaveVatSettings: ngx.IResourceMethod<model.VatSettings, model.VatSettings>;

    public saveVatSettings(settings: model.VatSettings): Promise<model.VatSettings> {
        OrderRepository.cachedVatCheckDetails = null;
        return this.doSaveVatSettings(settings);
    }

    @ngx.ResourceAction({
        path: "/orderstates",
    })
    public getOrderStates: ngx.IResourceMethod<void, model.KeyValueOption[]>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/sendTestMail",
    })
    public sendTestMail: ngx.IResourceMethod<model.TestmailConfig, string>;

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }
}
