import {Order} from "@shared/models";
import {CustomerAddress, CustomerComment, CustomerSource} from "@app/app/customer/core";

export enum CustomerType {
    // eslint-disable-next-line
    Customer = 0,
    Business = 1,
}

export enum CustomerMetaType {
    MailAddress = 1,
    PhoneNumber = 2,
}

export enum ArchiveStateEnum {
    NotArchived = 0,
    Archived = 1,
    RestoreRequested = 2,
}

export class CustomerMetaInformation {
    public Id: number = null;
    public Name: string = null;
    public Type: CustomerMetaType = CustomerMetaType.MailAddress;
    public Value: string = null;
    public IsDefaultFor: string[] = [];
    public SubType: string = "";

    constructor(obj?: Partial<CustomerMetaInformation>) {
        ctor(this, obj);
    }
}

export type CustomerNote = string;

export class Customer {
    public Id: number = null;
    public Number: number = 0;
    public Type: CustomerType = CustomerType.Customer;
    public Name: string = null;
    public Addresses: CustomerAddress[] = [];
    public MailAddresses: CustomerMetaInformation[] = [];
    public PhoneNumbers: CustomerMetaInformation[] = [];
    public Notes: CustomerNote[] = [];
    public LanguageId: number = null;
    public Sources: CustomerSource[] = [];
    public Comments: CustomerComment[] = [];
    public PriceGroupId: number = null;
    public LastOrder: Order = null;
    public VatId: string = null;
    public EoriNumber: string = null;
    public IsArchived: boolean = false;
    public IsRestoreRequested: boolean = false;
    public ArchiveState: ArchiveStateEnum = ArchiveStateEnum.NotArchived;

    constructor(obj?: Partial<Customer>) {
        ctor(this, obj, {
            Relations: {
                "LastOrder": Order,
            },
            Collections: {
                MailAddresses: CustomerMetaInformation,
                PhoneNumbers: CustomerMetaInformation,
                Addresses: CustomerAddress,
                Sources: CustomerSource,
                Comments: CustomerComment,
            },
            NumberFields: ["LanguageId", "PriceGroupId"],
        });

        this.MailAddresses = this.MailAddresses.filter(x => x.Value != null && x.Value.length > 0);
        for (const mailAddress of this.MailAddresses) {
            mailAddress.Type = CustomerMetaType.MailAddress;
        }

        this.PhoneNumbers = this.PhoneNumbers.filter(x => x.Value != null && x.Value.length > 0);
        for (const phoneNumber of this.PhoneNumbers) {
            phoneNumber.Type = CustomerMetaType.PhoneNumber;
        }
    }
}
