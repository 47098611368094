import {Injectable} from "@angular/core";
import {CustomerInteractionService, CustomerSource, ShopRepository} from "@app/app/customer/core";

export class EditCustomerSourceRequest {
    public Source: CustomerSource = null;

    constructor(obj?: Partial<EditCustomerSourceRequest>) {
        ctor(this, obj);
    }
}

export abstract class EditCustomerSourcePresenter {
    public abstract displayNewSource(Source: CustomerSource): void;
}

@Injectable({providedIn: "root"})
export class EditCustomerSourceUseCase implements IUseCase<EditCustomerSourceRequest, EditCustomerSourcePresenter> {

    constructor(private readonly customerInteractionService: CustomerInteractionService,
                private readonly shopRepository: ShopRepository,
    ) {
    }

    public async execute(request: EditCustomerSourceRequest,
                         presenter?: EditCustomerSourcePresenter,
    ): Promise<void> {
        const shopOptions = await this.shopRepository.getShopListSelectOptions();
        const newSource = await this.customerInteractionService.editSource(new CustomerSource(request.Source), shopOptions);
        if (newSource == null) {
            return;
        }

        presenter?.displayNewSource(newSource);
    }
}
