<bb-data-page [title]="'title.shop_connections' | translate" [multiSelect]="multiSelect">
    <ng-container headerRight>
        <button
            mat-icon-button
            (click)="settingsOverlayOpened = !settingsOverlayOpened"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
        >
            <mat-icon fontIcon="fa-gear"></mat-icon>
        </button>
        <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="settingsOverlayOpened"
            [cdkConnectedOverlayHasBackdrop]="true"
            (backdropClick)="settingsOverlayOpened = false"
        >
            <bb-general-shop-settings (onClickSave)="settingsOverlayOpened = false"></bb-general-shop-settings>
        </ng-template>
    </ng-container>
    <mat-card class="multiactions-wrapper">
        <mat-checkbox
            (change)="$event ? toggleAllRows() : null"
            [checked]="multiSelect.areAllSelected$ | async"
            [indeterminate]="(multiSelect.isAnySelected$ | async) && (multiSelect.areAllSelected$ | async) === false"
        >
        </mat-checkbox>
        <ng-container *ngIf="multiSelect.selectedIds$ | async as selectedIds">
            <div [@listAnimation]="multiSelect.isAnySelected$ | async">
                <ng-container *ngIf="multiSelect.isAnySelected$ | async">
                    <button
                        mat-icon-button
                        [matTooltip]="'tooltip.switch_to_manual_shop' | translate"
                        (click)="switchToManualShop(selectedIds)"
                    >
                        <mat-icon fontIcon="fa-wand-magic-sparkles"></mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        [matTooltip]="'tooltip.delete' | translate"
                        (click)="deleteShops(selectedIds)"
                    >
                        <mat-icon fontIcon="fa-trash"></mat-icon>
                    </button>
                </ng-container>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div>
                <button
                    mat-icon-button
                    [disabled]="disableReload$ | async"
                    [matTooltip]="'tooltip.reload_items' | translate"
                    (click)="reload$.next()"
                >
                    <mat-icon fontIcon="fa-rotate-right"></mat-icon>
                </button>
            </div>
        </ng-container>
    </mat-card>
    <div class="list-item-wrapper">
        <ng-container *ngIf="shopsLoading$ | async">
            <bb-loading-card *ngFor="let x of [0, 1, 2, 3, 4]"></bb-loading-card>
        </ng-container>
        <bb-new-list-item-card
            *ngIf="(shopsLoading$ | async) === false"
            label="label.add_shop"
            (onClick)="addNewShop()"
        ></bb-new-list-item-card>
        <bb-shop-card
            *ngFor="let shop of multiSelect.all$ | async; trackBy: trackBy"
            [shop]="shop"
            [loading]="((loadingIds$ | async) || []).includes(shop.Id)"
            (selectionChange)="onSelectionChange(shop.Id)"
            (clickShop)="onClickShopCard(shop)"
            (clickEdit)="editShop(shop)"
			(clickReAuth)="reAuthShop(shop)"
            (clickSwitchToManual)="switchToManualShop([shop.Id])"
            (clickDelete)="deleteShops([shop.Id])"
        ></bb-shop-card>
    </div>
</bb-data-page>
