<ng-container *ngIf="mwFieldType.options.expanded == false; else expandedTemplate">
    <mat-form-field [matTooltip]="mwFieldType.options.tooltip | translate"
                    [hidden]="!mwFieldType.options.visible">
        <mat-label>{{mwFieldType.options.label | translateLabel}}</mat-label>
        <input *ngIf="mwElement && mwFieldType.options.readonly"
               [name]="mwPath"
               [value]="(mwFieldType.options.options[mwElement.value] || '') | translate"
               matInput
               type="text"
               readonly>
        <mat-select *ngIf="!mwFieldType.options.readonly"
                    (openedChange)="$event ? checkOptions() : null"
                    [required]="mwFieldType.options.required == true"
                    [formControl]="mwElement"
                    (selectionChange)="onChange($event)"
                    [multiple]="mwFieldType.options.multiple"
                    [compareWith]="compareFn">
            <mat-option [value]="null" *ngIf="mwFieldType.options.required == false && mwFieldType.options.multiple != true"> -</mat-option>
            <mat-option *ngFor="let option of mwFieldType.options.options | keyvalue:trueCompare"
                        [disabled]="disabledOptions[option.key]"
                        [value]="option.key">{{ option.value | translate}}
            </mat-option>
        </mat-select>

        <mat-hint>{{ mwFieldType.options.hint | translate }}</mat-hint>
        <mat-error>
            <bb-form-errors [formField]="mwElement" [fieldType]="mwFieldType"></bb-form-errors>
        </mat-error>
    </mat-form-field>
</ng-container>

<ng-template #expandedTemplate>
    <mat-radio-group [matTooltip]="mwFieldType.options.hint | translate"
                     [required]="mwFieldType.options.required"
                     [formControl]="mwElement"
                     (change)="onChange($event)"
                     [hidden]="!mwFieldType.options.visible"
                     fxLayout="column">
        <mat-radio-button *ngFor="let option of mwFieldType.options.options | keyvalue : trueCompare"
                          [value]="option.key">{{ option.value | translate}}
        </mat-radio-button>
    </mat-radio-group>

    <mat-hint>{{ mwFieldType.options.hint | translate }}</mat-hint>
    <mat-error>
        <bb-form-errors [formField]="mwElement" [fieldType]="mwFieldType"></bb-form-errors>
    </mat-error>
</ng-template>
    