import {Constructor} from "@mintware-de/form-builder";
import {SwitchOptions} from "./switch.options";
import {SwitchComponent} from "./switch.component";
import {BaseType} from "../base/base.type";
import {ValidatorFn, Validators} from "@angular/forms";
import {LabelPart} from "@app/app/common/form/base/base.options";

export class Switch extends BaseType<SwitchOptions> {
    public readonly component: Constructor = SwitchComponent;

    constructor(options: SwitchOptions) {
        super(Object.assign({
            required: false,
            bottomPadding: "8px",
        } as SwitchOptions, options, {label: Array.isArray(options.label) ? LabelPart.combineHtml(options.label) : options.label}));
    }

    public buildValidators(): ValidatorFn[] {
        const validators = [];
        if (!this.options) {
            return validators;
        }
        if (this.options.required) {
            validators.push(Validators.requiredTrue);
        }
        return validators;
    }
}
