import {Inject, Injectable} from "@angular/core";
import {BB_PRESET_REPO, Preset, PresetRepository} from "@app/app/grid/core";
import {Message, MessagingService, TranslationService} from "@bb-core/service";
import {GeneralPresetPresenter} from "./general-preset.presenter";

export class SavePresetRequest {
    public preset: Preset<any> = null;
    public allPresets: Preset<any>[] = null;

    constructor(obj?: Partial<SavePresetRequest>) {
        ctor(this, obj);
    }
}

export abstract class SavePresetPresenter<T> extends GeneralPresetPresenter<T> {
}

@Injectable({providedIn: "any"})
export class SavePresetUseCase<T = any> implements IUseCase<SavePresetRequest, SavePresetPresenter<T>> {

    constructor(@Inject(BB_PRESET_REPO) private readonly repository: PresetRepository<T>,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
    ) {
    }

    public async execute(request: SavePresetRequest,
                         presenter?: SavePresetPresenter<T>,
    ): Promise<void> {

        const presetsToSave = [...request.allPresets];
        const index = request.allPresets.findIndex((p) => p.Id === request.preset.Id);
        presetsToSave[index] = request.preset;

        for (const preset of presetsToSave) {
            preset.Columns = preset.Columns.map(c => {
                const newCol = {
                    Name: c.Name,
                    Caption: c.Caption,
                    IsVisible: c.IsVisible,
                    Filter: c.Filter,
                    Sort: c.Sort,
                    VisibleIndex: c.VisibleIndex,
                    Width: c.Width,
                };
                if (!preset.RememberColumnFilters) {
                    delete newCol.Filter;
                }
                if (!preset.RememberOrder) {
                    delete newCol.Sort;
                }
                if (!preset.RememberColumns) {
                    delete newCol.VisibleIndex;
                    delete newCol.Width;
                }
                return newCol;
            }).filter(x => x.IsVisible);
        }

        await this.repository.savePresets(presetsToSave.filter(p => !p.IsSystemPreset));
        presenter?.displayPresets(presetsToSave);

        const successMessage = this.translator.translate("flash.preset_saved", {
            name: request.preset.Name,
        });
        await this.messaging.showMessage(Message.transient({message: successMessage}));
    }

}
