import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from "@angular/material/dialog";
import { BbSlideToggleComponent } from "@app/app/shared/ui/form/bb-slide-toggle/bb-slide-toggle.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    selector: "bb-in-app-help-options-dialog",
    templateUrl: "./in-app-help-options-dialog.component.html",
    styleUrls: ["./in-app-help-options-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatButton,
        BbSlideToggleComponent,
        TranslateModule,
        ReactiveFormsModule,
    ],
})
export class InAppHelpOptionsDialogComponent {
    protected readonly enableHelpscoutBeaconSetting = new FormControl(inject(MAT_DIALOG_DATA));
}
