import {Injectable} from "@angular/core";
import {OrderToShip, PackageType, ShippingProvider, WeightSet} from "../entity";
import {WeightCalculationService} from "../service";
import {OrderWeightType} from "../enum";

export class ApplyWeightRequest {
    public Order: OrderToShip = null;
    public Type: OrderWeightType = null;
    public PackageTypes: PackageType[] = [];
    public Providers: ShippingProvider[] = [];

    constructor(obj?: Partial<ApplyWeightRequest>) {
        ctor(this, obj, {
            Collections: {
                PackageTypes: PackageType,
                Providers: ShippingProvider,
            },
        });
    }
}

export abstract class ApplyWeightPresenter {
    public abstract displayNewWeights(order: OrderToShip,
    ): void;
}

@Injectable({providedIn: "root"})
export class ApplyWeightUseCase implements IUseCase<ApplyWeightRequest, ApplyWeightPresenter> {
    public async execute(request: ApplyWeightRequest, presenter?: ApplyWeightPresenter): Promise<void> {
        const {Order, Type, PackageTypes, Providers} = request;
        Order.DefaultWeightCalculation = Type;
        WeightCalculationService.recalculateWeight(Order, PackageTypes, Providers);

        Order.Weights = new WeightSet({
            OrderWeight: Order.OrderWeight,
            CalculatedWeight: WeightCalculationService.CalculatedWeight(Order, PackageTypes, Providers),
            ProviderWeight: WeightCalculationService.ProviderWeight(Order, Providers),
        });

        presenter?.displayNewWeights(Order);
    }
}
