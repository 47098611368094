export class CustomerListPermissions {
    public canCreate: boolean = false;
    public canEdit: boolean = false;
    public canDelete: boolean = false;
    public canImport: boolean = false;
    public canExport: boolean = false;
    public canMerge: boolean = false;
    public canList: boolean = false;
    public canCreateOrder: boolean = false;

    constructor(obj?: Partial<CustomerListPermissions>) {
        ctor(this, obj);
    }
}
