import {ShippingProductService} from "../shipping-product-service";

export class PreferredDayOfDeliveryService extends ShippingProductService {
    public DayOfDelivery: Date = null;

    constructor(obj?: Partial<PreferredDayOfDeliveryService>) {
        super(obj);
        ctor(this, obj, {
            DateFields: ["DayOfDelivery"],
        });
    }
}
