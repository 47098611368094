import {Injectable, inject} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ErrorHandlerService, InteractionService, MessagingService} from "@bb-core/service";
import {ComponentStore, OnStoreInit, tapResponse} from "@ngrx/component-store";
import {TranslateService} from "@ngx-translate/core";
import {EMPTY, Observable, of, pipe, zip} from "rxjs";
import {filter, map, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {ShopViewModelDto} from "../../../../partners/shops/data/shop-view-model.dto";
import {Shop} from "../../../../partners/shops/model/shop";
import {SHOPS} from "../../../../partners/shops/shops.token";
import {ShopsRepository} from "../../data/shop.repository";
import {DeleteShopDialogComponent} from "../../presentation/delete-shop-dialog/delete-shop-dialog.component";
import {SelectNewShopDialogComponent} from "../../presentation/select-new-shop-dialog/select-new-shop-dialog.component";

export interface ShopListState {
    shops: ShopViewModelDto[];
    shopsLoading: boolean;
    loadingIds: number[];
}

const initState: ShopListState = {
    shops: [],
    shopsLoading: true,
    loadingIds: [],
};

@Injectable()
export class ShopListStore extends ComponentStore<ShopListState> implements OnStoreInit {
    private readonly dialog = inject(MatDialog);
    private readonly messaging = inject(MessagingService);
    private readonly interaction = inject(InteractionService);
    private readonly errorHandler = inject(ErrorHandlerService);
    private readonly repository = inject(ShopsRepository);
    private readonly translate = inject(TranslateService);
    private readonly partnerShops = inject(SHOPS);

    public readonly shops$ = this.select(({shops}) => shops);
    public readonly shopsLoading$ = this.select(({shopsLoading}) => shopsLoading);
    public readonly loadingIds$ = this.select(({loadingIds}) => loadingIds);
    public readonly disableReload$ = this.select(({shopsLoading, loadingIds}) => loadingIds.length > 0 || shopsLoading);

    public readonly loadShops = this.effect((load$: Observable<void>) =>
        load$.pipe(
            tap((_) => this.patchState(initState)),
            switchMap((_) =>
                this.repository.getAllShops().pipe(
                    tapResponse(
                        (shops) => this.patchState({shops, shopsLoading: false}),
                        (err) => {
                            this.errorHandler.handleException(err, null, true);
                            this.patchState({shopsLoading: false});
                        },
                    ),
                ),
            ),
        ),
    );

    public readonly addNewShop = this.effect<void>(
        pipe(
            switchMap(() =>
                this.dialog.open(SelectNewShopDialogComponent, {width: "100%", maxWidth: "1400px"}).afterClosed(),
            ),
            filter((shop): shop is Shop => !!shop),
            switchMap((shop) => shop.add ? shop.add() : EMPTY),
        ),
    );

    public readonly reAuthShop = this.effect((shop$: Observable<ShopViewModelDto>) =>
        shop$.pipe(
            tap((shop) => this.setLoadingIds([shop.Id || 0])),
            switchMap((shop) => {
                const partner = this.partnerShops.find(({key}) => key === shop.Partner);
                return partner && partner.reAuth
                    ? partner.reAuth(shop).pipe(
                          tapResponse(
                              (newShop) => this.replaceShop(newShop),
                              (e) => this.errorHandler.handleException(e),
                              () => this.removeLoadingIds([shop.Id || 0]),
                          ),
                      )
                    : of(false).pipe(
                          tap(() => {
                              this.messaging.showSnackBar("flash.reauth_shop_not_supported");
                              this.removeLoadingIds([]);
                          }),
                      );
            }),
        ),
    );

    public readonly switchToManualShops = this.effect((shopIds$: Observable<number[]>) =>
        shopIds$.pipe(
            switchMap((shopIds) =>
                this.interaction
                    .confirm$(
                        this.translate.instant("title.switch_to_manual_shop"),
                        this.translate.instant("text.switch_to_manual_shop"),
                    )
                    .pipe(
                        filter((res) => !!res),
                        map(() => shopIds),
                    ),
            ),
            tap((ids) => this.setLoadingIds(ids)),
            switchMap((ids) =>
                zip(
                    ids.map((id) =>
                        this.repository.convertToManual(id).pipe(
                            tapResponse(
                                (shop) => {
                                    this.removeLoadingIds([id]);
                                    this.replaceShop(shop);
                                },
                                (e) => {
                                    this.removeLoadingIds([id]);
                                    this.errorHandler.handleException(e);
                                },
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    public readonly deleteShops = this.effect((shopIds$: Observable<number[]>) =>
        shopIds$.pipe(
            withLatestFrom(this.shops$),
            switchMap(([shopIds, allShops]) =>
                this.dialog
                    .open(DeleteShopDialogComponent, {
                        data: {
                            targetShopOptions: [
                                ...allShops
                                    .filter(({Partner, Id}) => Partner === "manual" && !shopIds.includes(Id || 0))
                                    .map((shop) => ({Key: shop.Name, Value: shop.Id})),
                                {Key: "label.create_new_manual_shop", Value: -1},
                            ],
                            allManualShops: !allShops
                                .filter(({Id}) => shopIds.includes(Id || 0))
                                .some(({Partner}) => Partner !== "manual"),
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter((res) => !!res),
                        map((req) => ({shopIds, req})),
                    ),
            ),
            switchMap(({shopIds, req}) =>
                shopIds.length > 1
                    ? this.interaction
                          .confirm$(
                              this.translate.instant("title.delete_shops"),
                              this.translate.instant("html.delete_shop_amount_security_question", {amount: shopIds.length}),
                              {isDangerousToProceed: true},
                          )
                          .pipe(
                              filter((res) => !!res),
                              map(() => ({shopIds, req})),
                          )
                    : of({shopIds, req}),
            ),
            tap(({shopIds}) => this.setLoadingIds(shopIds)),
            switchMap(({shopIds, req}) =>
                this.repository.deleteMultiple({...req, selectedShops: shopIds}).pipe(
                    tapResponse(
                        () => this.loadShops(),
                        (e) => {
                            this.errorHandler.handleException(e);
                            this.removeLoadingIds(shopIds);
                        },
                    ),
                ),
            ),
        ),
    );

    public ngrxOnStoreInit(): void {
        this.loadShops();
    }

    constructor() {
        super({
            shops: [],
            shopsLoading: true,
            loadingIds: [],
        });
    }

    private setLoadingIds = this.updater((state, ids: number[]) => ({
        ...state,
        loadingIds: [...state.loadingIds, ...ids],
    }));

    private removeLoadingIds = this.updater((state, ids: number[]) => ({
        ...state,
        loadingIds: [...state.loadingIds.filter((id) => !ids.includes(id))],
    }));

    private replaceShop = this.updater((state, shop: ShopViewModelDto) => ({
        ...state,
        shops: state.shops.map((oldShop) => (oldShop.Id === shop.Id ? shop : oldShop)),
    }));
}
