import { Message } from "./message";

export abstract class MessagingService {
    public abstract showError(message: Message, error?: Error): Promise<void>;

    public abstract showMessage(message: Message): Promise<void>;

    public abstract showSnackBar(
        message: string,
        params?: {[p: string]: string | number | Date},
        duration?: number,
    ): void;
}
