import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "@app/app/shared/presentation";
import { TranslationKey } from "@bb-core/entity";
import * as core from "@bb-core/service";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({providedIn: "root"})
export class InteractionService implements core.InteractionService {
    constructor(private readonly matDialog: MatDialog) {}

    public confirm(
        title: string,
        message: string,
        options?: {
            yesText?: TranslationKey;
            noText?: TranslationKey;
            isDangerousToProceed?: boolean;
        },
    ): Promise<boolean> {
        return this.confirm$(title, message, options).pipe(take(1)).toPromise();
    }

    public confirm$(
        title: string,
        message: string,
        options?: {
            yesText?: TranslationKey;
            noText?: TranslationKey;
            isDangerousToProceed?: boolean;
        },
    ): Observable<boolean> {
        return this.matDialog
            .open(ConfirmDialogComponent, {
                data: {
                    title,
                    message: message.replace(/\n/g, "<br>"),
					options,
				},
				autoFocus: false,
            })
            .afterClosed();
    }
}
