import {ChangeDetectionStrategy, Component} from "@angular/core";
import {AbstractFormFieldComponent, AbstractType} from "@mintware-de/form-builder";
import {TextAreaOptions} from "./text-area.options";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "bb-form-text-area",
    template: `
        <mat-form-field [matTooltip]="mwFieldType.options.tooltip" class="mat-block" [hidden]="!mwFieldType.options.visible">
           <textarea [placeholder]="mwFieldType.options.label | translateLabel"
                     matInput
                     [type]="mwFieldType.options.type"
                     [name]="mwPath"
                     [required]="mwFieldType.options.required == true"
                     [rows]="mwFieldType.options.rows"
                     [cols]="mwFieldType.options.cols"
                     [minlength]="mwFieldType.options.minLength"
                     [maxlength]="mwFieldType.options.maxLength"
                     [formControl]="mwElement"
                     [readonly]="mwFieldType.options.readonly"></textarea>
            <mat-hint>{{ mwFieldType.options.hint | translate }}</mat-hint>
            <mat-hint align="end" *ngIf="mwElement && mwElement.value != null && mwFieldType.options.maxLength > 0">
                {{ mwElement.value.length }} / {{ mwFieldType.options.maxLength }}
            </mat-hint>
            <mat-error>
                <bb-form-errors [formField]="mwElement" [fieldType]="mwFieldType"></bb-form-errors>
            </mat-error>
        </mat-form-field>
    `,
})
export class TextAreaComponent extends AbstractFormFieldComponent<AbstractType<TextAreaOptions>> {
}
