import {ShippingProductService} from "../shipping-product-service";
import {DeliveryTypeEnum} from "@app/app/shipping/core/entity/service/delivery-type.enum";

export class DhlDeliveryTypeService extends ShippingProductService {
    public deliveryType: DeliveryTypeEnum = DeliveryTypeEnum.Economy;

    constructor(obj?: Partial<DhlDeliveryTypeService>) {
        super(obj);
        ctor(this, obj);
    }

}
