<h2 mat-dialog-title>
    <img [src]="imageUrl" />
    {{ "title.add_shop" | translate }}
</h2>
<mat-dialog-content>
	<h6 *ngIf="data.hasError" class="mat-error">Die Verbindung konnte nicht hergestellt werden. Bitte überprüfe deine Eingaben und versuche es erneut.</h6>
    <form [formGroup]="form">
        <bb-input formControlName="Url" [label]="'label.complete_shop_url' | translate" required></bb-input>
        <bb-input formControlName="Key" [label]="'label.api_key' | translate" required></bb-input>
        <bb-input formControlName="Secret" [label]="'label.api_password' | translate" required></bb-input>
        <bb-select
            formControlName="ApiVersion"
            [label]="'label.api_version' | translate"
            [options]="versionOptions"
            required
        ></bb-select>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>{{ "button.cancel" | translate }}</button>
    <button mat-flat-button color="primary" (click)="save()">{{ "button.save" | translate }}</button>
</mat-dialog-actions>
