import {AbstractGroupType, Constructor, IGroupTypeOptions} from "@mintware-de/form-builder";
import {GroupComponent} from "./group.component";

export class Group<T> extends AbstractGroupType<IGroupTypeOptions<T>> {
    public readonly component: Constructor = GroupComponent;

    constructor(obj: IGroupTypeOptions<T>) {
        super(obj);
    }
}
