import {Column, PageResult} from "../entity";

export abstract class GridRepository<T> {
    public abstract queryPage(page: number,
                              pageSize: number,
                              visibleColumns: Column<T>[],
                              searchTerm: string,
                              additionalArguments?: any,
    ): Promise<PageResult<T>>;

    public abstract updateProperty(id: number, changes: Partial<T>): Promise<T>;
}
