import {Constructor, IGroupTypeOptions} from "@mintware-de/form-builder";
import {LayoutOptions} from "./flex-layout.options";
import {FlexLayoutComponent} from "./flex-layout.component";
import {FlexLayout} from "@app/app/common/form/layout/flex-layout/flex-layout.type";

export class ColumnLayout<T> extends FlexLayout<T> {
    public readonly component: Constructor = FlexLayoutComponent;

    constructor(options: LayoutOptions & IGroupTypeOptions<T>) {
        super({
            direction: "column",
            alignMainAxis: "start",
            alignCrossAxis: "stretch",
            debug: false,
            dense: false,
            ...options,
        });
    }
}
