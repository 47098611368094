<div class="page-wrapper">
    <div class="header-wrapper">
        <div class="header">
            @if (title) {   
                <h3 [matBadgeHidden]="!showBadge" [matBadge]="(multiSelect?.all$ | async)?.length" matBadgeOverlap="false">
                    {{ title }}
                </h3>
            } @else {
                <ng-content select="[headerLeft]"></ng-content>
            }
            <div>
                <ng-content select="[headerRight]"></ng-content>
            </div>
        </div>
    </div>
    <div class="header-wrapper">
        <ng-content select="[additionalHeader]"></ng-content>
    </div>

    <div class="content">
        <ng-content></ng-content>
    </div>
</div>
