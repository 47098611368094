import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "stripTags"})
export class StripTagsPipe implements PipeTransform {
    public transform(value: string) {
        const container = document.createElement("div");
        container.innerHTML = value.replace(/></g, "> <");
        value = container.textContent || container.innerText;
        return value;
    }
}
