import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {LegislativeTextsProvider} from "../model/legislative-texts-provider.enum";
import {LegislativeTextExistsDto} from "./legislative-text-exists.dto";
import {LegislativeTextDto} from "./legislative-text.dto";
import {LegislativeTextsTokenDto} from "./legislative-texts-token.dto";

@Injectable({
    providedIn: "root",
})
export class LegislativeTextsRepository {
    constructor(private readonly httpClient: HttpClient) {}

    public getProviderToken(provider: LegislativeTextsProvider): Observable<LegislativeTextsTokenDto> {
        return this.httpClient.get<LegislativeTextsTokenDto>(
            `${environment.baseUrl}/settings/setting?module=legislativetexts_${provider}`,
        );
    }

    public getProviderTexts(provider: LegislativeTextsProvider): Observable<LegislativeTextDto[]> {
        return this.httpClient.get<LegislativeTextDto[]>(
            `${environment.baseUrl}/legislativetext?providerName=${provider}`,
        );
    }

    public activateProvider(provider: LegislativeTextsProvider): Observable<LegislativeTextsTokenDto> {
        return this.httpClient.get<string>(`${environment.baseUrl}/settings/setting/generateuniquecode`).pipe(
            switchMap((token) =>
                this.httpClient
                    .put<void>(`${environment.baseUrl}/settings/setting?module=legislativetexts_${provider}`, {
                        Token: token,
                    })
                    .pipe(map(() => ({Token: token}))),
            ),
        );
    }

    public deactivateProvider(provider: LegislativeTextsProvider): Observable<void> {
        return this.httpClient.put<void>(
            `${environment.baseUrl}/settings/setting?module=legislativetexts_${provider}`,
            {Token: null},
        );
    }

    public existsByType(): Observable<LegislativeTextExistsDto> {
        return this.httpClient.get<LegislativeTextExistsDto>(`${environment.baseUrl}/legislativetext/existsbytype`);
    }
}
