import { ChangeDetectionStrategy, Component, forwardRef, Injector, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl, ValidationErrors } from "@angular/forms";
import { ErrorStateMatcher, MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { KeyValuePair } from "@bb-core/entity";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateModule } from "@ngx-translate/core";
import { BbFormErrorStateMatcher } from "../bb-form-error-state-matcher";
import { BbFormModule } from "../bb-form.module";

@UntilDestroy()
@Component({
    selector: "bb-select",
    templateUrl: "./bb-select.component.html",
    styleUrls: ["../bb-form.styles.scss"],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BbSelectComponent),
            multi: true,
        },
        {
            provide: ErrorStateMatcher,
            useClass: BbFormErrorStateMatcher,
        },
    ],
    standalone: true,
    imports: [BbFormModule, MatSelectModule, TranslateModule, MatOptionModule, MatTooltipModule],
})
export class BbSelectComponent<T> implements ControlValueAccessor, OnInit {
    @Input() label = "";
    @Input() tooltip = "";
    @Input() options: KeyValuePair<string, T>[] = [];
    @Input() multiple: boolean = false;
    @Input() required: boolean = false;
    @Input() customErrors: ValidationErrors[] = [];
    @Input() subscriptSizing: "dynamic" | "fixed" = "fixed";

    readonly control = new FormControl<T | null>(null);

    constructor(private readonly injector: Injector) {}

    ngOnInit() {
        this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
            this.onTouched();
            this.onChange(value);
        });

        this.control.setValidators(this.injector.get(NgControl).control?.validator || null);
    }

    writeValue(value: T) {
        this.control.setValue(value, { emitEvent: false });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    private onChange = (v: any) => {};
    private onTouched = () => {};
}
