import {
    Button,
    Card,
    ColumnLayout,
    ComponentWrapper,
    Divider,
    HiddenField,
    NumberField,
    PlainText,
    RowLayout,
    SelectField,
    TabLayout,
    TableCollection,
    Text,
    TextField,
    Toolbar,
} from "@app/app/common/form/types";
import {
    ArchiveStateEnum,
    Customer,
    CustomerAddress,
    CustomerComment,
    CustomerOrderListItem,
    CustomerPaymentListItem,
    CustomerType,
    Payment,
} from "@app/app/customer/core";
import { ApiLanguageRepository, ApiPriceGroupRepository } from "@app/app/customer/data/repository";
import { MailAddressesCollection } from "@app/app/customer/presentation/customer-form/form-type/mail-addresses-collection.type";
import { Column, GridOptions, GridRepository } from "@app/app/grid/core";
import { BbGridComponent } from "@app/app/grid/presentation/bb-grid.component";
import { AbstractLayoutType, FormModel } from "@mintware-de/form-builder";
import { CustomerAddressesCollection } from "./form-type/customer-addresses-collection.type";
import { CustomerSourceCollection } from "./form-type/customer-source-collection.type";
import { PhoneNumberCollection } from "./form-type/phone-number-collection.type";

export class CustomerFormViewModel {
    public customer: Customer = null;
    public formModel: FormModel<Customer>;

    public addressesField: CustomerAddressesCollection;
    public sourcesField: CustomerSourceCollection;
    public commentsField: TableCollection<CustomerComment>;
    public paymentsTab: any = { title: "title.payments", content: "_payments", hide: true };
    public ordersTab: any = { title: "title.orders", content: "_orders", hide: true };

    public addressesLoaded: boolean = false;
    public paymentGridOptions: GridOptions<Payment>;
    public orderGridOptions: GridOptions<CustomerOrderListItem>;
    public newCommentNameField: TextField;
    public newCommentTextField: TextField;
    public userName: string;
    public mergedAddresses: { [key: number]: number[] } = {};

    private phoneNumbers: PhoneNumberCollection = new PhoneNumberCollection();
    private mailAddresses: MailAddressesCollection = new MailAddressesCollection();

    constructor(private readonly paymentsRepo: GridRepository<any>,
        private readonly ordersRepo: GridRepository<any>,
        onClickCancel: () => void,
        addressesField: CustomerAddressesCollection,
        sourcesField: CustomerSourceCollection,
    ) {
        this.addressesField = addressesField;
        this.sourcesField = sourcesField;
        this.commentsField = new TableCollection<CustomerComment>({
            allowAdd: false,
            allowEdit: false,
            noDataText: "text.no_comments",
            entrySettings: {
                columns: [
                    {
                        // Hidden fields
                        content: new ColumnLayout<CustomerAddress>({
                            model: {
                                "CreatedBy": new HiddenField(),
                                "CreatedAt": new HiddenField(),
                                "Text": new HiddenField(),
                                "Id": new HiddenField(),
                                "IsInternalMessage": new HiddenField(),
                            },
                        }),
                        title: "",
                        isHidden: true,
                    },
                    {
                        title: "label.date",
                        field: "CreatedAt",
                        content: new Text({
                            builder: (val) => Intl.DateTimeFormat(navigator.language, {
                                month: "numeric",
                                year: "numeric",
                                second: "numeric",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                            }).format(val),
                        }),
                    },
                    {
                        title: "label.name",
                        field: "CreatedBy",
                        content: new Text({}),
                    },
                    {
                        title: "label.text",
                        field: "Text",
                        content: new Text({}),
                    },
                    {
                        title: "",
                        isActionsColumn: true,
                    },
                ],
            },
        });
        this.formModel = {
            _col: new ColumnLayout<Customer>({
                model: {
                    _tabs: new Card<Customer>({
                        footerModel: {
                            _footerRow: new RowLayout<Customer>({
                                alignMainAxis: "end",
                                alignCrossAxis: "center",
                                model: {
                                    _cancelButton: new Button({
                                        type: "button",
                                        appearance: "flat",
                                        text: "button.cancel",
                                        onClick: onClickCancel,
                                    }),
                                    _submitButton: new Button({
                                        type: "submit",
                                        appearance: "raised",
                                        color: "primary",
                                        text: "button.save",
                                    }),
                                },
                            }),
                        },
                        model: {
                            _tabs: new TabLayout<Customer>({
                                tabs: [
                                    { title: "title.general", content: "_general" },
                                    {
                                        title: "title.addresses",
                                        content: "_addresses",
                                        lazyLoad: true,
                                        onActivate: () => {
                                            if (this.addressesLoaded) {
                                                return;
                                            }
                                            this.addressesLoaded = true;
                                            this.addressesField.loadAddresses(this.customer.Id).catch(() => this.addressesLoaded = false);
                                        },
                                    },
                                    {
                                        title: "title.sources",
                                        content: "_sources",
                                    },
                                    this.paymentsTab,
                                    this.ordersTab,
                                    { title: "title.comments", content: "_comments" },
                                ],
                                model: {
                                    _general: this.buildGeneralTabModel(),
                                    _addresses: this.buildAddressesTabModel(),
                                    _sources: this.buildSourcesTabModel(),
                                    _payments: this.buildPaymentsTabModel(),
                                    _orders: this.buildOrdersTabModel(),
                                    _comments: this.buildCommentsTabModel(),
                                },
                            }),
                        },
                    }),
                },
            }),
        };
    }

    private buildAddressesTabModel(): AbstractLayoutType<any> {
        return new ColumnLayout<Customer>({
            model: {
                _toolbar: new Toolbar({
                    model: {
                        _addDeliveryAddress: new Button({
                            type: "button",
                            appearance: "icon",
                            tooltip: "button.new_address",
                            icon: "fa-plus",
                            onClick: () => this.addressesField.addAddress(),
                        }),
                        _mergeAddresses: new Button({
                            type: "button",
                            appearance: "icon",
                            icon: "fa-link",
                            tooltip: "tooltip.merge_addresses",
                            onClick: () => this.addressesField.mergeSelectedAddresses(),
                        }),
                        _deleteAddresses: new Button({
                            type: "button",
                            appearance: "icon",
                            icon: "fa-trash",
                            tooltip: "tooltip.delete_addresses",
                            onClick: () => this.addressesField.deleteSelectedAddresses(),
                        }),
                    },
                }),
                Addresses: this.addressesField,
            },
        });
    }

    private buildPaymentsTabModel(): AbstractLayoutType<any> {
        return new ColumnLayout<Customer>({
            model: {
                _payments: new ComponentWrapper<BbGridComponent>({
                    component: BbGridComponent,
                    inputs: {
                        repository: this.paymentsRepo,
                        options: this.paymentGridOptions = new GridOptions({
                            NoDataText: "text.no_payments_for_customer_available",
                            ShowHeaderFilter: false,
                            AllowColumnResizing: false,
                            Columns: [
                                new Column<Payment>({
                                    Name: "ExtId",
                                    Caption: "label.id",
                                    Type: "number",
                                    VisibleIndex: 0,
                                    Width: 180,
                                    CellTemplateName: "htmlCellTemplate",
                                    DisplayTransformer: (obj: CustomerPaymentListItem) => {
                                        const text = obj.ExtId ?? "";
                                        const extId = obj.ExtId.toString();
                                        const id = Number(extId.substr(Math.max(0, extId.length - 10), 10));
                                        return `<a href="#" onclick="window.ngRouter.navigateByUrl('/payments/incomes/${id}')">${text}</a>`;
                                    },
                                }),
                                new Column<Payment>({
                                    Name: "Created",
                                    Caption: "label.created_at",
                                    Type: "date",
                                    VisibleIndex: 1,
                                    Width: 150,
                                }),
                                new Column<Payment>({
                                    Name: "PayDate",
                                    Caption: "label.paid_at",
                                    Type: "date",
                                    VisibleIndex: 2,
                                    Width: 150,
                                }),
                                new Column<Payment>({
                                    Name: "PayValue",
                                    Caption: "label.pay_value",
                                    Type: "decimal",
                                    VisibleIndex: 3,
                                    Width: 100,
                                }),
                                new Column<Payment>({
                                    Name: "Fee",
                                    Caption: "label.fee",
                                    Type: "decimal",
                                    VisibleIndex: 4,
                                    Width: 100,
                                }),
                                new Column<Payment>({
                                    Name: "Order.ExternalReference",
                                    Caption: "label.order",
                                    Type: "string",
                                    VisibleIndex: 5,
                                    CellTemplateName: "htmlCellTemplate",
                                    DisplayTransformer: (obj: CustomerPaymentListItem) => {
                                        const text = obj["Order.ExternalReference"] ?? "";
                                        const extId = obj["Order.ExtId"].toString();
                                        const id = Number(extId.substr(Math.max(0, extId.length - 10), 10));
                                        return `<a href="#" onclick="window.ngRouter.navigateByUrl('/order?openOrder=${id}')">${text}</a>`;
                                    },
                                }),
                                new Column<Payment>({
                                    Name: "Order.ExtId",
                                    Caption: "label.order_id",
                                    Type: "number",
                                    VisibleIndex: 6,
                                    CellTemplateName: "htmlCellTemplate",
                                    DisplayTransformer: (obj: CustomerPaymentListItem) => {
                                        const text = obj["Order.ExtId"];
                                        const extId = obj["Order.ExtId"].toString();
                                        const id = Number(extId.substr(Math.max(0, extId.length - 10), 10));
                                        return `<a href="#" onclick="window.ngRouter.navigateByUrl('/order?openOrder=${id}')">${text}</a>`;
                                    },
                                }),
                            ],
                        }),
                    },
                }),
            },
        });
    }

    private buildOrdersTabModel(): AbstractLayoutType<any> {
        return new ColumnLayout<Customer>({
            model: {
                _orders: new ComponentWrapper<BbGridComponent>({
                    component: BbGridComponent,
                    inputs: {
                        repository: this.ordersRepo,
                        options: this.orderGridOptions = new GridOptions({
                            ShowHeaderFilter: false,
                            AllowColumnResizing: false,
                            NoDataText: "text.no_orders_for_customer_available",
                            Columns: [
                                new Column<CustomerOrderListItem>({
                                    Caption: "",
                                    VisibleIndex: 0,
                                    Name: "ArchiveState",
                                    Type: "string",
                                    AllowSorting: false,
                                    AllowFiltering: false,
                                    CellTemplateName: "htmlCellTemplate",
                                    DisplayTransformer: (value) => {
                                        if (value.ArchiveState == ArchiveStateEnum.RestoreRequested) {
                                            return "<i class=\"fal fa-fw fa-hourglass-half\" style=\"text-align: center\"></i>";
                                        }
                                        if (value.ArchiveState == ArchiveStateEnum.Archived) {
                                            return "<i class=\"fal fa-fw fa-lock\" style=\"text-align: center\"></i>";
                                        }
                                        return null;
                                    },
                                }),
                                new Column<CustomerOrderListItem>({
                                    Name: "ExtId",
                                    Caption: "label.id",
                                    Type: "number",
                                    VisibleIndex: 1,
                                    Width: 180,
                                    CellTemplateName: "htmlCellTemplate",
                                    DisplayTransformer: (val: CustomerOrderListItem) => {
                                        const extId = (val.ExtId ?? "").toString();
                                        const id = Number(extId.substr(Math.max(0, extId.length - 10), 10));
                                        return `<a href="#" onclick="window.ngRouter.navigateByUrl('/order?openOrder=${id}')">${val.ExtId}</a>`;
                                    },
                                }),
                                new Column<CustomerOrderListItem>({
                                    Name: "OrderDate",
                                    Caption: "label.order_date",
                                    Type: "date",
                                    VisibleIndex: 2,
                                    Width: 120,
                                }),
                                new Column<CustomerOrderListItem>({
                                    Name: "Platform",
                                    Caption: "label.platform",
                                    Type: "string",
                                    VisibleIndex: 3,
                                    Width: 180,
                                }),
                                new Column<CustomerOrderListItem>({
                                    Name: "ExternalReference",
                                    Caption: "label.external_reference",
                                    Type: "string",
                                    VisibleIndex: 4,
                                    CellTemplateName: "htmlCellTemplate",
                                    DisplayTransformer: (val: CustomerOrderListItem) => {
                                        const extId = (val.ExtId ?? "").toString();
                                        const id = Number(extId.substr(Math.max(0, extId.length - 10), 10));
                                        const displayText = val.ExternalReference ?? val.ExternalId ?? "";
                                        return `<a href="#" onclick="window.ngRouter.navigateByUrl('/order?openOrder=${id}')">${displayText}</a>`;
                                    },
                                }),
                                new Column<CustomerOrderListItem>({
                                    Name: "OrderState.Text",
                                    Caption: "label.order_state",
                                    Type: "string",
                                    VisibleIndex: 5,
                                    Width: 150,
                                }),
                                new Column<CustomerOrderListItem>({
                                    Name: "InvoiceTotal",
                                    Caption: "label.order_sum",
                                    Type: "decimal",
                                    VisibleIndex: 6,
                                    Width: 150,
                                    AllowSorting: false,
                                }),
                                new Column<CustomerOrderListItem>({
                                    Name: "CurrencyCode",
                                    Caption: "label.currency",
                                    Type: "string",
                                    VisibleIndex: 7,
                                    Width: 100,
                                }),
                            ],
                        }),
                    },
                }),
            },
        });
    }

    private buildCommentsTabModel(): AbstractLayoutType<any> {
        return new ColumnLayout<Customer>({
            model: {
            _y: new Card<Customer>({
            model: {
                _newCommentRow: new RowLayout({
                    alignCrossAxis: "baseline",
                    flex: ["none", "nogrow", "nogrow", "nogrow"],
                    model: {
                        _caption: new PlainText({ text: "label.new_comment" }),
                        _name: this.newCommentNameField = new TextField({
                            label: "label.name",
                            required: false,
                            onKeyUp: (e) => {
                                if (e.key === "Enter") {
                                    this.addNewComment();
                                    e.preventDefault();
                                    e.cancelBubble = true;
                                    e.stopPropagation();
                                }
                            },
                        }),
                        _text: this.newCommentTextField = new TextField({
                            label: "label.text",
                            required: false,
                            maxLength: 1000,
                            onKeyUp: (e) => {
                                if (e.key === "Enter") {
                                    this.addNewComment();
                                    e.preventDefault();
                                    e.cancelBubble = true;
                                    e.stopPropagation();
                                }
                            },
                        }),
                        _add: new Button({
                            type: "button",
                            text: "button.add_new_comment",
                            appearance: "raised",
                            onClick: this.addNewComment.bind(this),
                        }),
                    },
                }),
                _divider: new Divider({ withPadding: false }),
                Comments: this.commentsField,
            },
        })}});
    }

    private buildGeneralTabModel(): AbstractLayoutType<any> {
        return new RowLayout<Customer>({
            model: {
                _y: new Card<Customer>({
                    model: {
                        _content: new ColumnLayout({
                            model: {
                                _generalHeader: new PlainText({ text: "header.general_details" }),
                                Number: new NumberField({
                                    label: "label.customer_number",
                                }),
                                Name: new TextField({
                                    label: "label.name",
                                }),
                                Type: new SelectField({
                                    label: "label.customer_type",
                                    options: {
                                        [CustomerType.Customer]: "label.customer_type_customer",
                                        [CustomerType.Business]: "label.customer_type_business",
                                    },
                                }),
                                LanguageId: new SelectField({
                                    label: "label.language",
                                    options: {},
                                    dataProvider: ApiLanguageRepository,
                                    orderByText: true,
                                    required: false,
                                }),
                                PriceGroupId: new SelectField({
                                    label: "label.price_group",
                                    options: {},
                                    required: false,
                                    dataProvider: ApiPriceGroupRepository,
                                    orderByText: true,
                                }),
                                _divider: new Divider(),
                                _numbersHeader: new PlainText({ text: "header.more_numbers_and_ids" }),
                                VatId: new TextField({
                                    label: "label.vat_id",
                                    required: false,
                                }),
                                EoriNumber: new TextField({
                                    label: "label.eori_number",
                                    required: false,
                                }),
                            },
                        },
                        ),
                    },
                }),
                _contact: new Card<Customer>({
                    model: {
                        _ccc: this.buildContactRow(),
                    },
                }),
            },
        });
    }

    private buildSourcesTabModel(): AbstractLayoutType<any> {
        return new ColumnLayout<Customer>({
            model: {
                _sourcesToolbar: new Toolbar({
                    model: {
                        _addSource: new Button({
                            type: "button",
                            appearance: "icon",
                            icon: "fa-plus",
                            tooltip: "tooltip.new_source",
                            onClick: () => this.sourcesField.addSource(),
                        }),
                        _deleteSources: new Button({
                            type: "button",
                            appearance: "icon",
                            icon: "fa-trash",
                            tooltip: "tooltip.delete_sources",
                            onClick: () => this.sourcesField.deleteSelectedSources(),
                        }),
                    },
                }),
                Sources: this.sourcesField,
            },
        });
    }

    private buildContactRow(): RowLayout<Customer> {
        return new ColumnLayout<Customer>({
            model: {
                _mailCard: this.mailAddresses,
                _divider: new Divider(),
                _phoneCard: this.phoneNumbers,
            },
        });
    }

    public resetAuthorName(): void {
        this.newCommentNameField.control?.patchValue(this.userName);
    }

    private addNewComment(): void {
        const name = (this.newCommentNameField.control.value)?.trim() ?? "";
        const text = (this.newCommentTextField.control.value)?.trim() ?? "";
        if (name.length <= 0 || text.length <= 0) {
            return;
        }
        this.resetAuthorName();

        this.newCommentTextField.control.patchValue("");
        const rawValue = this.commentsField.control.getRawValue();
        this.commentsField.componentInstance.addEntry();
        this.commentsField.control.patchValue([new CustomerComment({
            CreatedAt: new Date(),
            CreatedBy: name,
            Text: text,
            IsInternalMessage: false,
        }), ...rawValue],
        );
    }
}
