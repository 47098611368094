import {ShippingProductService} from "../shipping-product-service";
import {EndorsementTypeEnum} from "@app/app/shipping/core/entity/service/endorsement-type.enum";

export class DhlEndorsementService extends ShippingProductService {
    public endorsementType: EndorsementTypeEnum = EndorsementTypeEnum.Sozu;

    constructor(obj?: Partial<DhlEndorsementService>) {
        super(obj);
        ctor(this, obj);
    }

}
