import {ComponentStore} from "@ngrx/component-store";
import {Observable, Subscription, combineLatest} from "rxjs";
import {filter, map, tap, withLatestFrom} from "rxjs/operators";

interface MultiSelectState<I> {
    selectedIds: I[];
}

export class MultiSelectService<T, I extends number | string> extends ComponentStore<MultiSelectState<I>> {
    public readonly selectedIds$ = combineLatest([this.select(({selectedIds}) => selectedIds), this.entities$]).pipe(
        map(([selectedIds, entities]) =>
            selectedIds.filter((selectedId) => entities.some((entity) => this.selectId(entity) === selectedId)),
        ),
    );

    private readonly count$ = this.select(this.selectedIds$, (selectedIds) => selectedIds.length);

    public readonly all$ = this.select(this.entities$, this.selectedIds$, (entities, selectedIds) =>
        entities.map((entity) => ({...entity, selected: selectedIds.includes(this.selectId(entity))})),
    );

    public readonly allSelected$ = this.all$.pipe(
        map(entities => entities.filter(entity => entity.selected)),
    );

    public readonly isNothingSelected$ = this.select(this.count$, (count) => count === 0);
    public readonly isOneSelected$ = this.select(this.count$, (count) => count === 1);
    public readonly isAnySelected$ = this.select(this.count$, (count) => count > 0);
    public readonly areManySelected$ = this.select(this.count$, (count) => count > 1);
    public readonly areAllSelected$ = this.select(
        this.count$,
        this.entities$,
        (count, entities) => count === entities.filter((entity) => !this.disabled(entity)).length,
    );

	public readonly toggle = this.updater((state, id: I) => {
        const filtered = state.selectedIds.filter((selectedId) => selectedId !== id);
        const selectedIds = filtered.length < state.selectedIds.length ? filtered : filtered.concat(id);
        return {...state, selectedIds};
    });

    public readonly reset = this.updater((state) => ({...state, selectedIds: []}));

    public readonly selectAll = this.effect((selectAll$) =>
        selectAll$.pipe(
            withLatestFrom(this.entities$, (_, entities) =>
                entities.filter((entity) => !this.disabled(entity)).map((entity) => this.selectId(entity)),
            ),
            tap((selectedIds: I[]) => this.patchState({selectedIds})),
        ),
    );

    constructor(
        private readonly entities$: Observable<T[]>,
        private readonly selectId: (entity: T) => I,
        private readonly disabled: (entity: T) => boolean = () => false,
    ) {
        super({selectedIds: []});
    }
}
