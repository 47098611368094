import {ShippingProductService} from "../shipping-product-service";
import {DeliveryOptionEnum} from "./delivery-option.enum";

export class TimeDefiniteService extends ShippingProductService {
    public SelectedDeliveryOption: DeliveryOptionEnum = DeliveryOptionEnum.d9;

    constructor(obj?: Partial<TimeDefiniteService>) {
        super(obj);
        ctor(this, obj);
    }
}
