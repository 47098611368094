import {AbstractFormFieldComponent, AbstractType} from "@mintware-de/form-builder";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit} from "@angular/core";
import {SelectFieldOptions} from "./select-field.options";
import {MatRadioChange} from "@angular/material/radio";
import {MatSelectChange} from "@angular/material/select";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "[bb-form-select]",
    templateUrl: "select-field.component.html",
})
export class SelectFieldComponent extends AbstractFormFieldComponent<AbstractType<SelectFieldOptions>> implements OnInit {
    public trueCompare = (a: any, b: any): number => {
        const callback = this.mwFieldType.options.sortCallback;
        if (callback){
            return callback(a.value,b.value);
        }
        if (this.mwFieldType.options.orderByText && a.value !== b.value) {
            return [a.value, b.value].sort().shift() === a.value ? -1 : 0;
        }
            return 0;
    };
    public compareFn: ((f1: any, f2: any) => boolean) | null = (f1: any, f2: any) => f1?.toString() === f2?.toString();

    public disabledOptions: { [key in string | number]: boolean } = {};

    constructor(private readonly injector: Injector,
                private readonly cdr: ChangeDetectorRef,
    ) {
        super();
    }

    public ngOnInit(): void {
        if (this.mwFieldType.options.dataProvider) {
            const dataProvider = this.injector.get(this.mwFieldType.options.dataProvider);
            dataProvider.fetchSelectOptions().then((data) => {
                (this.mwFieldType as any).reportChanges(data);
                this.cdr.detectChanges();
            });
        }
        (this.mwFieldType as any).componentInstance = this;
    }

    public onChange(event: MatRadioChange | MatSelectChange): void {
        if (this.mwFieldType.options.onChange != null) {
            this.mwFieldType.options.onChange(event, this.mwFieldType.builderInstance.group, this);
        }
    }

    public detectChanges(): void {
        this.cdr.detectChanges();
    }

    public checkOptions(): void {
        this.disabledOptions = {};
        if (this.mwFieldType.options.isOptionAvailable == null) {
            return;
        }
        
        for (const key of Object.keys(this.mwFieldType.options.options)){
            this.disabledOptions[key] = !this.mwFieldType.options.isOptionAvailable(this.mwIndex, key);
        }
    }
}
