import {ImportCustomerResultEnum} from "./import-customer-result.enum";

export class ImportedCustomer {
    public Number: number = null;
    public CustomerName: string = "";
    public Result: ImportCustomerResultEnum = null;
    public ResultText: string = "";

    constructor(obj?: Partial<ImportedCustomer>) {
        ctor(this, obj);
    }
}
