import {NgModule} from "@angular/core";

import * as coreService from "@bb-core/service";
import {SnackbarContainerComponent} from "../presentation";
import * as infraService from "./service";

@NgModule({
    declarations: [
        SnackbarContainerComponent,
    ],
    providers: [
        infraService.ActiveRouteService,
        infraService.LegacyAlertService,
        {provide: coreService.TranslationService, useExisting: infraService.TranslationService},
        {provide: coreService.MessagingService, useExisting: infraService.MessagingService},
        {provide: coreService.NavigationService, useExisting: infraService.RouterNavigationService},
        {provide: coreService.PlatformChannelService, useExisting: infraService.SignalRPlatformChannelService},
        {provide: coreService.EventsService, useExisting: infraService.AppEventsService},
        {provide: coreService.DownloadService, useExisting: infraService.BlobDownloadService},
        {provide: coreService.InteractionService, useExisting: infraService.InteractionService},
        {provide: coreService.AuthenticationService, useExisting: infraService.DefaultAuthenticationService},
        {provide: coreService.ClipboardService, useExisting: infraService.ClipboardService},
    ],
})
export class InfrastructureModule {
}
