<h2 mat-dialog-title>
    {{ "title.add_shop" | translate }}
</h2>
<mat-dialog-content>
    <bb-input [formControl]="search" [label]="'label.search' | translate" color="primary"></bb-input>
    <div class="scroll-container" *ngIf="filteredShops$ | async as filteredShops">
        <ng-container *ngIf="filteredShops.featured.length > 0">
            <bb-text-divider>{{ "label.most_famous_shops" | translate }}</bb-text-divider>
            <div class="wrapper">
                <ng-container *ngFor="let shop of filteredShops.featured">
                    <bb-select-shop-card
                        *ngIf="shop.add"
                        [shop]="shop"
                        (cardClicked)="clickShop(shop)"
                    ></bb-select-shop-card>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="filteredShops.others.length > 0">
            <bb-text-divider>{{ "label.other_shops" | translate }}</bb-text-divider>
            <div class="wrapper">
                <ng-container *ngFor="let shop of filteredShops.others">
                    <bb-select-shop-card
                        *ngIf="shop.add"
                        [shop]="shop"
                        (cardClicked)="clickShop(shop)"
                    ></bb-select-shop-card>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="filteredShops.featured.length + filteredShops.others.length === 0">
            {{ "text.shop_not_found" | translate }}
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>{{ "button.cancel" | translate }}</button>
</mat-dialog-actions>
