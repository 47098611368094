export class ArticleImageRelation {
    public Url: string = null;
    public Id: number = null;
    public ThumbPathExt: string = null;
    public ThumbUrl: string = null;
    public Position?: number = null;
    public IsDefault: boolean = false;
    public ImageId: number = null;

    constructor(obj?: Partial<ArticleImageRelation>) {
        ctor(this, obj);
    }
}
