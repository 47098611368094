import {ArchiveStateEnum} from "@app/app/customer/core";

export class CustomerOrderListItem {
    public Id: number = null;
    public Platform: string = null;
    public OrderDate: Date = null;
    public ExternalReference: string = null;
    public ExtId: string = null;
    public ExternalId: string = null;
    public "OrderState.Text": string = null;
    public InvoiceTotal: number = null;
    public CurrencyCode: string = null;
    public ArchiveState: ArchiveStateEnum = ArchiveStateEnum.NotArchived;


    constructor(obj?: Partial<CustomerOrderListItem>) {
        ctor(this, obj, {
            DateFields: ["OrderDate"],
        });
    }
}
