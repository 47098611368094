import {Injectable} from "@angular/core";
import * as model from "@app/app/common/model";
import * as ngx from "@ngx-resource/core";
import {ShopRepository} from "@app/app/customer/core";
import {Shop} from "@shared/models";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/settings/shop",
})
export class ShopsSettingsService extends ngx.Resource implements ShopRepository {

    @ngx.ResourceAction({
        path: "/",
        query: {"displayMode": 5},
    })
    private getShopList: ngx.IResourceMethod<void, { data: Shop[] }>;

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }

    public async getShopListSelectOptions(simpleIdValue: boolean = false): Promise<model.KeyValueOption[]> {
        return (await this.getShopList()).data.map(x => ({
            label: x.Name,
            value: simpleIdValue ? x.Id : `${x.Partner}#${x.Name}#${x.Id}`,
        }));
    }
}
