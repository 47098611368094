import {Injectable} from "@angular/core";
import {Column, GridOptions} from "../../entity";
import {GridRepository} from "@app/app/grid/core/repository";
import {ErrorHandlerService} from "@bb-core/service";

export class LoadGridDataRequest<T> {
    public Repository: GridRepository<T> = null;
    public Options: GridOptions = null;
    public Page: number = 1;
    public AdditionalArguments: any = null;

    constructor(obj?: Partial<LoadGridDataRequest<T>>) {
        ctor(this, obj, {
            Relations: {
                Options: GridOptions,
            },
        });
    }
}

export abstract class LoadGridDataPresenter<T> {

    /**
     * Displays the loaded data for the grid
     * @param data The data to display
     */
    public abstract displayData(data: T[]): void;

    /**
     * Displays paging details
     * @param page The current page
     * @param pageSize The current page size
     * @param totalCount The total number of records
     */
    public abstract displayPagingInfo(page: number,
                                      pageSize: number,
                                      totalCount: number,
    ): void ;
}

@Injectable({providedIn: "root"})
export class LoadGridDataUseCase implements IUseCase<LoadGridDataRequest<any>, LoadGridDataPresenter<any>> {

    constructor(private readonly errorHandler: ErrorHandlerService,
    ) {
    }

    public async execute<T>(request: LoadGridDataRequest<T>,
                            presenter?: LoadGridDataPresenter<T>,
    ): Promise<void> {
        if (!this.validateRequest(request)) {
            return;
        }

        const visibleColumns: Column<T>[] = request.Options.Columns.filter((c) => c.IsVisible);
        try {
            const page = await request.Repository.queryPage(
                request.Page,
                request.Options.PageSize,
                visibleColumns,
                request.Options.SearchTerm,
                request.AdditionalArguments,
            );
            presenter?.displayData(page.Data);
            presenter?.displayPagingInfo(page.Page, page.PageSize, page.TotalCount);
        } catch (e) {
            await this.errorHandler.handleException(e, null, true);
            presenter?.displayData([]);
            presenter?.displayPagingInfo(0, 100, 0);
        }
    }

    private validateRequest(request: LoadGridDataRequest<any>): boolean {
        if (!(request instanceof LoadGridDataRequest)) {
            throw new Error("Invalid request");
        }
        if (request.Options == null) {
            throw new Error("Missing Options");
        }
        if (request.Page == null) {
            throw new Error("Missing Page");
        }
        if (request.Options.PageSize == null) {
            throw new Error("Missing Options.PageSize");
        }
        if (request.Repository == null) {
            throw new Error("Missing Repository");
        }

        return true;
    }
}
