import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";


@Injectable({providedIn: "root"})
export class GoogleTranslationService {

    constructor(private readonly httpClient: HttpClient) {
    }

    /** @deprecated */
    public translate(sourceText: string,
                     sourceLanguage: string,
                     targetLanguage: string,
    ): Promise<string> {
        return this.translate$(sourceText, sourceLanguage, targetLanguage).toPromise();
    }

    public translate$(sourceText: string,
                      sourceLanguage: string,
                      targetLanguage: string,
    ): Observable<string> {
        return this.httpClient.post<string>(
            `/api/angular/article/translate/${sourceLanguage}/${targetLanguage}`,
            {text: sourceText},
        );
    }
}
