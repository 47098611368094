export enum CloudDeviceEnum {
    Unknown,
    GoogleCloudPrinter,
    GoogleDriveStorage,
    DropboxStorage,
    FtpsStorage,
    FtpMiddleware,
    WebDAVStorage,
    BoxStorage,
    AmazonS3Storage,
    OneDriveStorage,
    DruckerWolkePrinter,
}

export const DeviceTypeNames = {
    [CloudDeviceEnum.AmazonS3Storage]: "Amazon S3",
    [CloudDeviceEnum.BoxStorage]: "Box.com",
    [CloudDeviceEnum.DropboxStorage]: "Dropbox",
    [CloudDeviceEnum.DruckerWolkePrinter]: "Druckerwolke",
    [CloudDeviceEnum.FtpMiddleware]: "FTP(S) oder SFTP Server",
    [CloudDeviceEnum.FtpsStorage]: "FTP(S) oder SFTP Server",
    [CloudDeviceEnum.GoogleCloudPrinter]: "Google Cloud Printer",
    [CloudDeviceEnum.GoogleDriveStorage]: "Google Drive",
    [CloudDeviceEnum.OneDriveStorage]: "Onedrive",
    [CloudDeviceEnum.WebDAVStorage]: "WebDAV",
};

export function DeviceTypeName(type: CloudDeviceEnum): string {
    if (type in DeviceTypeNames){
        return DeviceTypeNames[type];
    }
    return "Unbekannt";
}
