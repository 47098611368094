import {ShippingProductService} from "../shipping-product-service";
import {TimeframeEnum} from "@app/app/shipping/core/entity/service/timeframe.enum";

export class DhlPreferredTimeFrameService extends ShippingProductService {
    public selectedTimeFrame: TimeframeEnum = null;

    constructor(obj?: Partial<DhlPreferredTimeFrameService>) {
        super(obj);
        ctor(this, obj);
    }
}
