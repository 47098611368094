import {
    BB_GRID_OPTIONS,
    BB_GRID_REPO, EditCellContentRequest, EditCellContentUseCase,
    GridOptions,
    GridRepository,
    LoadGridDataRequest,
    LoadGridDataUseCase,
} from "@app/app/grid/core";
import {Inject, Injectable} from "@angular/core";
import CustomStore from "devextreme/data/custom_store";
import {BehaviorSubject} from "rxjs";


@Injectable({providedIn: "any"})
export class BbApiStore extends CustomStore {

    public readonly totalCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public readonly isBusy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(@Inject(BB_GRID_REPO) private readonly repo: GridRepository<any>,
                @Inject(BB_GRID_OPTIONS) private readonly options: GridOptions,
                private readonly loadDataUseCase: LoadGridDataUseCase,
                private readonly editCellContentUseCase: EditCellContentUseCase,
    ) {
        super({
            key: "Id",
            load: (o) => this.loadImpl(o),
            update: (id, values) => this.updateImpl(id, values),
        });
    }

    private async loadImpl(options: any): Promise<any> {
        const offset = "skip" in options ? options.skip : 0;
        const pageSize = "take" in options ? options.take : 1;
        const page = Math.ceil(offset / pageSize + 1);
        const data = [];
        let totalCount = 0;

        this.isBusy$.next(true);
        await this.loadDataUseCase.execute(new LoadGridDataRequest({
            Repository: this.repo,
            Options: new GridOptions<any>({
                ...this.options,
                PageSize: pageSize,
            }),
            Page: page,
            AdditionalArguments: this.options.AdditionalArguments,
        }), {
            displayData(resultData: any[]): void {
                data.push(...resultData);
            },
            displayPagingInfo(_: number,
                              __: number,
                              count: number,
            ): void {
                totalCount = count;
            },
        });

        if (totalCount > 0 || page === 1) {
            this.totalCount$.next(totalCount);
        }
        this.isBusy$.next(false);

        return {
            data,
            totalCount,
        };
    }

    private async updateImpl(id: number, values: any): Promise<any> {
        await this.editCellContentUseCase.execute(new EditCellContentRequest<any>({
            Id: id,
            Changes: values,
        }));

    }
}
