export class Order {
    public Id: number = null;
    public OrderDate: Date = null;
    public ShippingDate: Date = null;
    public Platform: string = null;
    public ShippingAddress: string = null;
    public CustomerId: string = null;
    public Customer: string = null;
    public ExternalReference: string = null;
    public OrderNumber: number = null;
    public OrderNumberPrefix: string = null;
    public OrderNumberPostfix: string = null;
    public FullInvoiceNumber: string;
    public BillDate: Date = null;
    public Checked: boolean = false;

    constructor(obj?: Partial<Order>) {
        ctor(this, obj, {
            DateFields: ["OrderDate", "ShippingDate"],
        });
    }
}
