import {MessageTemplate} from "../models/message-template";

export abstract class MessageTemplateRepository {
    public abstract getAll(): Promise<MessageTemplate[]>;

    public abstract update(messageTemplate: MessageTemplate,
    ): Promise<MessageTemplate>;

    public abstract deleteMany(ids: number[],
    ): Promise<MessageTemplate[]>;

    public abstract getSingle(id: number,
    ): Promise<MessageTemplate>;
}
