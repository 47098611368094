import {KeyValuePair} from "./key-value-pair";

export class SelectableKeyValuePair<TKey = number, TValue = string> implements KeyValuePair<TKey, TValue> {

    public Key: TKey = null;
    public Value: TValue = null;
    public IsDefault: boolean = null;

    constructor(obj?: Partial<SelectableKeyValuePair<TKey, TValue>>) {
        ctor(this, obj);
    }
}
