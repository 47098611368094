import {InputField} from "./input.type";
import {TextFieldOptions} from "./text-field.options";
import {Constructor} from "@mintware-de/form-builder";
import {FileFieldComponent} from "./file-field.component";
import {InputOptions} from "./input.options";

export class FileField extends InputField<InputOptions> {

    public readonly component: Constructor = FileFieldComponent;

    constructor(options: TextFieldOptions) {
        super(Object.assign({
            required: true,
            type: "file",
        }, options));
    }

    public set required(required: boolean) {
        if (this.options.required !== required) {
            this.options.required = required;
            this.builderInstance.rebuildForm();
        }
    }

    public get required(): boolean {
        return this.options.required || false;
    }
}
