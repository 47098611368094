import {ChangeDetectionStrategy, Component} from "@angular/core";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantFinishTaskComponent} from "../finish/finish.component";
import {OnboardingAssistantSelectChannelTaskComponent} from "../select-channel/select-channel.component";

@Component({
    selector: "bb-onboarding-assistant-add-channels",
    templateUrl: "./add-channels.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantAddChannelsTaskComponent {
    
    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
    ) {}

	public next(addChannels: boolean): void {
		if (addChannels) {
			this.onboardingStore.nextTask(OnboardingAssistantSelectChannelTaskComponent);
		} else {
			this.onboardingStore.nextTask(OnboardingAssistantFinishTaskComponent);
		}
    }
}
