export class ArticleStock {
    public Id: number = null;
    public Name: string = null;
    public StockId: number = null;
    public IsDefault: boolean = false;
    public StockCurrent?: number = null;
    public StockCurrentCalculated?: number = null;
    public StockWarning?: number = null;
    public StockCode: string = null;
    public ChangeStock?: number = null;
    public ClearStock: boolean = false;
    public ChangeStockComment: string = null;
    public UnfulfilledAmount?: number = null;
    public StockDesired?: number = null;
    public RecalcMinStockOverDays?: number = null;
    public ReplenishmentTimeInDays?: number = null;
    public DesiredDaysOfStock?: number = null;
    public OrderedQuantity?: number = null;

    constructor(obj?: Partial<ArticleStock>) {
        ctor(this, obj);
    }
}
