import {Inject, Injectable} from "@angular/core";
import {BB_PRESET_REPO, Preset, PresetRepository} from "@app/app/grid/core";
import {GeneralPresetPresenter} from "./general-preset.presenter";
import {InteractionService, Message, MessagingService, TranslationService} from "@bb-core/service";

export class DeletePresetRequest {
    public preset: Preset<any> = null;
    public allPresets: Preset<any>[] = null;

    constructor(obj?: Partial<DeletePresetRequest>) {
        ctor(this, obj);
    }
}

export abstract class DeletePresetPresenter<T> extends GeneralPresetPresenter<T> {
}

@Injectable({providedIn: "any"})
export class DeletePresetUseCase<T = any> implements IUseCase<DeletePresetRequest, DeletePresetPresenter<T>> {

    constructor(@Inject(BB_PRESET_REPO) private readonly repository: PresetRepository<T>,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
                private readonly interaction: InteractionService,
    ) {
    }

    public async execute(request: DeletePresetRequest,
                         presenter?: DeletePresetPresenter<T>,
    ): Promise<void> {
        const presetToDeleteIndex = request.allPresets.findIndex(p => p.Id === request.preset.Id);
        const preset = request.allPresets[presetToDeleteIndex];
        if (preset == null) {
            const message = this.translator.translate("flash.preset_not_found");
            await this.messaging.showMessage(Message.blocking({message}));
            return;
        }

        if (preset.IsSystemPreset) {
            const title = this.translator.translate("title.system_preset");
            const message = this.translator.translate("text.can_not_delete_system_preset");
            await this.messaging.showMessage(Message.blocking({title, message}));
            return;
        }

        if (!(await this.confirmDelete(preset))) {
            return;
        }

        const newPresets = request.allPresets.filter(x => x.Id !== preset.Id);
        const presetsToSave = newPresets.filter(x => !x.IsSystemPreset);
        await this.repository.savePresets(presetsToSave);
        presenter?.displayPresets(newPresets);
        await this.showSuccessMessage(preset);
        presenter?.selectPreset(newPresets.find(_ => true));
    }

    private async showSuccessMessage(preset: Preset<any>): Promise<void> {
        const successMessage = this.translator.translate("flash.preset_was_deleted", {
            name: preset.Name,
        });
        await this.messaging.showMessage(Message.transient({message: successMessage}));
    }

    private confirmDelete(preset: Preset<any>): Promise<boolean> {
        const confirmTitle = this.translator.translate("title.delete_preset");
        const confirmMessage = this.translator.translate("text.confirm_delete_preset", {
            name: preset.Name,
        });
        return this.interaction.confirm(confirmTitle, confirmMessage);
    }
}
