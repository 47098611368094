import {Injectable} from "@angular/core";
import {CustomerInteractionService, ImportedCustomer} from "@app/app/customer/core";

export class ShowCustomerImportResultRequest {
    public Result: ImportedCustomer[] = [];

    constructor(obj?: Partial<ShowCustomerImportResultRequest>) {
        ctor(this, obj, {
            Collections: {
                Result: ImportedCustomer,
            },
        });
    }
}

@Injectable({providedIn: "root"})
export class ShowCustomerImportResultUseCase implements IUseCase<ShowCustomerImportResultRequest, void> {

    constructor(private readonly customerInteractionService: CustomerInteractionService,
    ) {
    }

    public async execute(request: ShowCustomerImportResultRequest,
                         presenter?: void,
    ): Promise<void> {
        this.customerInteractionService.showCustomerImportResult(request.Result);
    }
}
