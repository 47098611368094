import {SimpleCollection} from "@app/app/common/form/collection/simple-collection/simple-collection.type";
import {IGroupTypeOptions} from "@mintware-de/form-builder";
import {Customer, CustomerMetaInformation, CustomerMetaType} from "@app/app/customer/core";
import {
    Button,
    ColumnLayout,
    EmailField,
    FormGroup,
    Header,
    HiddenField,
    RowLayout,
    SelectField,
} from "@app/app/common/form/types";
import {UntypedFormArray} from "@angular/forms";
import {SelectFieldComponent} from "@app/app/common/form/select-field/select-field.component";

export class MailAddressesCollection extends ColumnLayout<Customer> {

    private defaultMapping: { [key: string]: number } = {};

    private readonly mailAddresses: SimpleCollection<any> = new SimpleCollection<IGroupTypeOptions<CustomerMetaInformation>>(FormGroup, {
        noDataText: "text.no_mail_addresses",
        showAddButton: false,
        customValidators: [
            (e: UntypedFormArray) => {
                let valid = e.controls.length === 0;
                for (const control of e.controls) {
                    const value = control.get("IsDefaultFor").value;
                    valid ||= Array.isArray(value) && value.includes("default");
                }
                const errors: any = {};
                if (!valid) {
                    errors.missing_default = true;
                }
                return errors;
            },
        ],
        afterInit: () => {
            for (let i = 0; i < this.mailAddresses.control.controls.length; i++) {
                const row = this.mailAddresses.control.controls[i];
                const defaults = (row as any).get("IsDefaultFor").value as string[];
                for (const d of defaults) {
                    if(!(d in this.defaultMapping)){
                        this.defaultMapping[d] = i;
                    }
                }
            }
        },
        validationMessages: {
            "missing_default": "error.missing_default_mail",
        },
        entrySettings: {
            model: {
                Id: new HiddenField(),
                Name: new HiddenField(),
                Type: new HiddenField(),
                _row: new RowLayout<CustomerMetaInformation>({
                    flex: ["auto", 45],
                    alignCrossAxis: "baseline",
                    model: {
                        Value: new EmailField({label: ""}),
                        IsDefaultFor: new SelectField({
                            label: "label.use_email_as",
                            multiple: true,
                            required: false,
                            options: {
                                default: "label.email_type_default",
                                commercial: "label.email_type_commercial",
                                status_updates: "label.email_type_status_updates",
                            },
                            isOptionAvailable: (index: number, option: string) =>
                                !(option in this.defaultMapping) || this.defaultMapping[option] === index,
                            onChange: (_, __, component) => this._updateDefault(component as any),
                        }),
                    },
                }),
            },
        },
    });

    constructor() {
        super({model: {}});
        Object.assign(this.options.model, {
            _mailTitle: new Header({
                icon: "fa-envelope",
                text: "label.mail_addresses",
            }),
            MailAddresses: this.mailAddresses,
            _addButton: new Button({
                text: "button.add_new_mail_address",
                appearance: "raised",
                onClick: () => {
                    this.mailAddresses.componentInstance.addEntry();
                    const lastIndex = this.mailAddresses.control.length - 1;
                    this.mailAddresses.control.get([lastIndex]).patchValue(new CustomerMetaInformation({
                        Type: CustomerMetaType.MailAddress,
                        SubType: "default",
                    }));
                },
                type: "button",
            }),
        });
    }

    private _updateDefault(component: SelectFieldComponent): void {
        for (const key of Object.keys(this.defaultMapping)) {
            if (this.defaultMapping[key] == component.mwIndex) {
                delete this.defaultMapping[key];
            }
        }

        for (const selection of component.mwElement.value) {
            if (!(selection in this.defaultMapping)) {
                this.defaultMapping[selection] = component.mwIndex;
            }
        }
    }

}
