import {BaseType} from "../base/base.type";
import {Constructor} from "@mintware-de/form-builder";
import {TextFieldComponent} from "./text-field.component";
import {InputOptions} from "./input.options";

export class InputField<T extends InputOptions> extends BaseType<T> {
    public readonly component: Constructor = TextFieldComponent;

    public get readonly(): boolean {
        return this.options.disabled || false;
    }

    public set readonly(value: boolean) {
        this.options.readonly = value;
    }

    public get updateOn(): "change" | "blur" | "submit" | null {
        return "change";
    }

    public set required(required: boolean) {
        if (this.options.required !== required) {
            this.options.required = required;
            this.builderInstance.rebuildForm();
        }
    }

    public get required(): boolean {
        return this.options.required || false;
    }
}
