<div class="description">
    <p [innerHTML]="'html.onboarding_thanks' | translate"></p>
    <table *ngIf="data$ | async as data">
        <tr>
            <th>{{ "label.company_based_in" | translate }}</th>
            <td>{{ "country_" + data.country | lowercase | translate }}</td>
        </tr>
        <tr *ngIf="data.vatId">
            <th>{{ "label.vat_id" | translate }}</th>
            <td>{{ data.vatId }}</td>
        </tr>
        <tr>
            <th>{{ "label.vat_mode" | translate }}</th>
            <td>{{ "label.vat_mode_" + data.vatMode | translate }}</td>
        </tr>
        <tr *ngIf="data.furtherVatIds.length > 0">
            <th>{{ "label.tax_liability_other_countries" | translate }}</th>
            <td>
                <div *ngFor="let furtherVatId of data.furtherVatIds">
                    {{ "country_" + furtherVatId.country | lowercase | translate }} ({{ furtherVatId.vatId }})
				</div>
            </td>
        </tr>
        <tr *ngIf="data.useTargetCountryTaxRate || data.useOss || data.detectThirdCountry || data.useIgl">
            <th>{{ "label.further_settings" | translate }}</th>
            <td>
                <div *ngIf="data.useTargetCountryTaxRate">
                    {{ "label.use_target_country_tax_rate" | translate }}
				</div>
                <div *ngIf="data.useOss">
                    {{ "label.participating_in_oss" | translate }}
                </div>
                <div *ngIf="data.detectThirdCountry">
                    {{ "label.net_invoice_overseas" | translate }}
                </div>
                <div *ngIf="data.useIgl">
                    {{ "label.using_igl" | translate }}
                </div>
            </td>
        </tr>
    </table>
	<br>
	<p>{{ "text.hint_2fa" | translate }}</p>
</div>
<button mat-stroked-button color="primary" mat-dialog-close [routerLink]="['/account/security']">
	{{ "title.2fa" | translate }}
</button>
<button mat-flat-button color="primary" mat-dialog-close>
    {{ "button.to_dashboard" | translate }}
</button>
