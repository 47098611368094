import {MessageTemplateRepository} from "@app/app/settings/automation/core/repository";
import {Injectable} from "@angular/core";
import * as ngx from "@ngx-resource/core";
import {MessageTemplate} from "@app/app/settings/automation/core";
import {ResourceRequestMethod} from "@ngx-resource/core";


@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/message-templates",
})
export class ApiMessageTemplateRepository
    extends ngx.Resource
    implements MessageTemplateRepository {

    @ngx.ResourceAction({
        method: ResourceRequestMethod.Post,
        path: "/delete-many",
    })
    private doDeleteMany: ngx.IResourceMethod<number[], MessageTemplate[]>;


    @ngx.ResourceAction({
        method: ResourceRequestMethod.Put,
        path: "/",
    })
    private doUpdate: ngx.IResourceMethod<MessageTemplate, MessageTemplate>;

    @ngx.ResourceAction({
        method: ResourceRequestMethod.Get,
        path: "",
    })
    private doGetAll: ngx.IResourceMethod<void, MessageTemplate[]>;
    @ngx.ResourceAction({
        method: ResourceRequestMethod.Get,
        path: "/{id}",
    })
    private doGetSingle: ngx.IResourceMethod<void, MessageTemplate>;

    public async deleteMany(ids: number[]): Promise<MessageTemplate[]> {
        return (await this.doDeleteMany(ids)).map(mt => new MessageTemplate(mt));
    }

    public async getAll(): Promise<MessageTemplate[]> {
        return (await this.doGetAll()).map(mt => new MessageTemplate(mt));
    }

    public async update(messageTemplate: MessageTemplate): Promise<MessageTemplate> {
        return new MessageTemplate(await this.doUpdate(messageTemplate));
    }

    public async getSingle(id: number): Promise<MessageTemplate> {
        return new MessageTemplate(await this.doGetSingle(null, null, {
            id,
        }));
    }


    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }
}
