import {Injectable} from "@angular/core";
import {TranslationKey} from "@bb-core/entity";
import {Message, MessagingService} from "@bb-core/service/messaging";
import {TranslationService} from "@bb-core/service/translation.service";

@Injectable({providedIn: "root"})
export class ErrorHandlerService {

    constructor(private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
    ) {
    }

    public handleException(e: any,
                           fallbackMessageId: Nullable<TranslationKey> = null,
                           isBlocking: boolean = false,
                           codes: { [code: string]: TranslationKey } = {},
                           titleTranslationKey: TranslationKey = null,
                           extractMessage: boolean = true,
                           showGenericErrorText: boolean = true,
    ): Promise<void> {
        const title = this.translator.translate(titleTranslationKey ?? "title.general_error");
        const fallback = fallbackMessageId != null ? this.translator.translate(fallbackMessageId) : "";

        e = this.unifyErrorObject(e);
        let msg = extractMessage ? this.extractMessageFromBody(e, fallback) : "";
        msg = this.translateMessageIfContainedInCodes(msg, codes);
        msg += this.extractErrorCodesFromModelState(e, codes);

        if (msg === "") {
            msg = this.extractMessageFromModelState(e);
        }

        let message = showGenericErrorText
            ? this.translator.translate("text.error_with_message", {"message": msg})
            : msg;
        message = message.replace(/\n/g, "<br>");
        
        return this.messaging.showError(new Message({title, message, isBlocking}));
    }

    private unifyErrorObject(e: any): any {

        e ??= {};
        e.body ??= {};

        if ("data" in e && "body" in e && typeof e.body == "object" && Object.keys(e.body).length == 0) {
            e.body = e.data;
            if ("Data" in e.body && "Message" in e.body.Data) {
                e.body.ErrorMessage = e.body.Data.Message;
            }
        }
        
        return e;
    }
    
    private extractMessageFromBody(e: any, fallback: string): any {
        let msg = e?.message;
        if ("body" in e && (msg == null || msg.trim() === "")) {
            if ("ExceptionMessage" in e.body) {
                msg = e.body.ExceptionMessage;
            } else if ("ErrorMessage" in e.body) {
                msg = e.body.ErrorMessage;
            } else if ("Message" in e.body) {
                msg = e.body.Message;
            }
        }
        if ((msg == null || msg.trim() === "") && "status" in e && e.status === 404) {
            msg = this.translator.translate("error.record_not_found");
        }

        if (msg == null || msg.trim() === "") {
            msg = fallback;
        }

        return msg;
    }

    private extractMessageFromModelState(e: any): string {
        if (!("body" in e) || !("ModelState" in e.body)) {
            return "";
        }

        const modelState = e.body.ModelState as {[key: string]: string[]};
        const messages = [];
        for (const field of Object.keys(modelState)) {
            for (const message of modelState[field]) {
                messages.push(message);
            }
        }

        return messages.join(" ");
    }

    private extractErrorCodesFromModelState(e: any, codes: { [p: string]: TranslationKey }): string {
        if (!("body" in e) || !("ModelState" in e.body)) {
            return "";
        }

        const errorCodes = [];
        const modelState = e.body.ModelState as {[key: string]: string[]};
        for (const field of Object.keys(modelState)) {
            for (const code of modelState[field].filter(c => c in codes)) {
                errorCodes.push(`${this.translator.translate(codes[code])}`);
            }
        }

        return errorCodes.length > 1
            ? errorCodes.map(_message => "<br>▪ " + _message).join(" ")
            : errorCodes.join()
        ;
    }
    
    private translateMessageIfContainedInCodes(message: string, codes: { [p: string]: TranslationKey }): string {
        if (!(message in codes)) {
            return message;
        }
        return this.translator.translate(codes[message]);
    }
}
