export enum VatDetectionFlagsEnum {
    IsDirty = 1 << 0,
    ThirdPartyCountry = 1 << 1,
    SrcCountryIsEqualToDstCountry = 1 << 2,
    CustomerHasVatId = 1 << 3,
    EuDeliveryThresholdExceeded = 1 << 4,
    OssEnabled = 1 << 5,
    SellerIsRegisteredInDstCountry = 1 << 6,
    OrderDistributionCountryIsEmpty = 1 << 8,
    UserProfileCountryIsEmpty = 1 << 9,
}

export function hasFlag(self: VatDetectionFlagsEnum | number, flagToCheck: VatDetectionFlagsEnum | number) {
    return (self & flagToCheck) === flagToCheck;
}
