import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CustomerAddress } from "@app/app/customer/core";
import { EditCustomerAddressViewModel } from "./edit-customer-address.view-model";

@Component({
    selector: "bb-edit-customer-address-dialog",
    template: `
        <h4 mat-dialog-title>{{ (viewModel.address.Id == null ? 'title.add_address' : 'title.edit_address') | translate }}</h4>
        <mat-dialog-content>
            <mw-form-builder #form
                             [mwFormModel]="viewModel.formModel"
                             [mwFormData]="viewModel.address"
                             (mwFormSubmit)="submit($event)">
            </mw-form-builder>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button type="button" mat-button [mat-dialog-close]="null">{{ 'button.cancel' | translate }}</button>
            <button type="button" mat-raised-button color="primary" (click)="form.submit()">
                {{ 'button.save' | translate }}
            </button>
        </mat-dialog-actions>
    `,
})
export class EditCustomerAddressComponent {
    public readonly viewModel: EditCustomerAddressViewModel;

    public constructor(@Inject(MAT_DIALOG_DATA) private readonly data: { address: CustomerAddress },
                       private readonly dialogRef: MatDialogRef<EditCustomerAddressComponent>,
    ) {
        this.viewModel = new EditCustomerAddressViewModel(data.address.Id == null || data.address.Id === 0);
        this.viewModel.address = data.address;
    }

    public submit(address: CustomerAddress): void {
        this.dialogRef.close(new CustomerAddress(Object.assign({}, this.viewModel.address, address)));
    }
}
