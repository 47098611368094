import {Inject, Injectable} from "@angular/core";
import {BB_PRESET_REPO, Preset, PresetRepository} from "@app/app/grid/core";
import {ClientStorageService, ErrorHandlerService} from "@bb-core/service";
import {GeneralPresetPresenter} from "./general-preset.presenter";

export abstract class InitPresetSectionPresenter<T> extends GeneralPresetPresenter<T> {
}

@Injectable({providedIn: "any"})
export class InitPresetSectionUseCase<T = any> implements IUseCase<void, InitPresetSectionPresenter<T>> {

    constructor(@Inject(BB_PRESET_REPO) private readonly repository: PresetRepository<T>,
                private readonly errorHandler: ErrorHandlerService,
                private readonly clientStorage: ClientStorageService,
    ) {
    }

    public async execute(request: void,
                         presenter?: InitPresetSectionPresenter<T>,
    ): Promise<void> {
        await this.loadAndDisplayPresets(presenter);
    }

    private async loadAndDisplayPresets(presenter?: InitPresetSectionPresenter<T>): Promise<void> {
        try {
            const presets = await this.repository.getPresets();
            presenter?.displayPresets(presets.sort(x => x.IsSystemPreset ? -1 : 1));
            this.selectPreset(presets, presenter);
            this.setPresetPanelVisibility(presenter);
        } catch (e) {
            await this.errorHandler.handleException(e, "flash.failed_to_load_presets", true);
            throw e;
        }
    }

    private selectPreset(presets: Preset<T>[],
                         presenter?: InitPresetSectionPresenter<T>,
    ): void {
        const selectedFilterId = this.clientStorage.get(`grid.preset.${this.repository.uniqueIdentifier}.selected`);
        let selectedIndex = presets.findIndex(p => p.Id === selectedFilterId);
        selectedIndex ??= presets.findIndex(_ => true);
        selectedIndex = Math.max(0, selectedIndex);

        presenter?.selectPreset(presets[selectedIndex]);
    }

    private setPresetPanelVisibility(presenter?: InitPresetSectionPresenter<T>,
    ): void {
        const isVisible = this.clientStorage.get(`grid.preset.${this.repository.uniqueIdentifier}.visible`, true, Boolean);
        presenter?.setFilterPanelVisibility(isVisible.valueOf());
    }
}
