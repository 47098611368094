import {HttpClient} from "@angular/common/http";
import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {GeneralSettingsService} from "@app/app/common/service/da/general-settings.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {zip} from "rxjs";
import {delay, switchMap} from "rxjs/operators";
import {AccountService} from "../../../common/service/da/account.service";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantAddChannelsTaskComponent} from "../add-channels/add-channels.component";

@UntilDestroy()
@Component({
    selector: "bb-onboarding-assistant-saving",
    templateUrl: "./saving.component.html",
    styleUrls: ["../tasks.scss", "./saving.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantSavingTaskComponent implements OnInit {
    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
        private readonly generalSettings: GeneralSettingsService,
		private readonly httpClient: HttpClient,
		private readonly accountService: AccountService,
    ) {}

    public ngOnInit(): void {
        zip(
            this.generalSettings.getVatSettings(),
            this.httpClient.get("/api/angular/settings/setting?module=vat_countrysettings"),
            this.onboardingStore.data$,
        )
            .pipe(
                delay(2000),
                switchMap(([vatSettings, countryVatSettings, data]) => {
                    const furtherVatIds =
                        data.vatId && data.vatIdValidFrom
                            ? [
                                  {country: data.country, vatId: data.vatId, validFrom: data.vatIdValidFrom},
                                  ...data.furtherVatIds,
                              ]
                            : data.furtherVatIds;

                    return zip(
                        this.generalSettings.saveVatSettings({...vatSettings, DefaultVatMode: data.vatMode}),
                        this.httpClient.put("/api/angular/settings/setting?module=vat_countrysettings", {
                            ...countryVatSettings,
                            RecognizeThirdPartyCountry: data.detectThirdCountry,
                            CalcNetFromGrossOnThirdParty: data.thirdCountryNetCorrect,
                            CountryVatConfigurations: furtherVatIds.map((vatIds) => ({
                                CountryCode: vatIds.country,
                                VatId: vatIds.vatId,
                                ValidFrom: vatIds.validFrom.toISOString(),
                            })),
                            OSSEnabled: data.useOss,
                            AddTagForDistanceSelling: data.useOss,
                            SetIglWhenVatIdAvailable: data.useIgl,
                            SetIglWhenExistingCustomerHasVatId: data.useIgl,
                            IglVatMode: data.iglVatMode || "0",
                            TaxationAboveEUDeliveryThreshold: data.useTargetCountryTaxRate,
                        }),
                        this.accountService.updateInvoiceAddressCountry({country: data.country}),
                    );
                }),
                untilDestroyed(this),
            )
            .subscribe(() => this.onboardingStore.nextTask(OnboardingAssistantAddChannelsTaskComponent));
    }
}
