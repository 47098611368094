import {OrderToShip} from "./order-to-ship";
import {OrderState} from "@app/app/order";

export class BulkShipContainer {
    public Orders: OrderToShip[] = [];
    public ShippingDate: Date = new Date();
    public SortOrder: string = "OrderDate ASC";
    public MarkWithState: OrderState = OrderState.shipped;
    public ShippingProductId: number = null;
    public ShippingPackageId: number = null;

    constructor(obj?: Partial<BulkShipContainer>) {
        ctor(this, obj, {
            Collections: {
                Orders: OrderToShip,
            },
            DateFields: ["ShippingDate"],
        });
    }
}
