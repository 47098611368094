import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {ShopViewModelDto} from "../../../partners/shops/data/shop-view-model.dto";
import {DeleteRequestDto} from "./delete-request.dto";

@Injectable()
export class ShopsRepository {
    private readonly baseUrl = `${environment.baseUrl}/settings/shop`;

    constructor(private readonly httpClient: HttpClient) {}

    public getAllShops(): Observable<ShopViewModelDto[]> {
        return this.httpClient
            .get<{data: ShopViewModelDto[]}>(`${this.baseUrl}`, {params: {displayMode: 5}})
            .pipe(map(({data}) => data));
    }

    public convertToManual(id: number): Observable<ShopViewModelDto> {
        return this.httpClient.get<ShopViewModelDto>(`${this.baseUrl}/${id}/convert-to-manual`);
    }

    public deleteMultiple(deleteReq: DeleteRequestDto): Observable<void> {
        return this.httpClient.post<void>(`${this.baseUrl}/delete-multiple`, deleteReq);
    }
}
