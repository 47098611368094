import {TextArea, TextField, HtmlInput} from "@app/app/common/form/types";
import {LocalizedMessageTemplate, MessageTemplate} from "@app/app/settings/automation/core";
import {FormModel} from "@mintware-de/form-builder";
import {AvailableLanguages} from "@shared/models";

export class MessageTemplateFormViewModel {
    public messageTemplate: MessageTemplate;
    public localizedMessageTemplate: LocalizedMessageTemplate;
    public currentLanguage: string = "DE";
    public htmlField: HtmlInput = new HtmlInput({
        label: "E-Mail Nachricht",
        rows: 9,
    });

    public formModel: FormModel<LocalizedMessageTemplate> = {
        Name: new TextField({
            label: "Name",
        }),
        Comment: new TextArea({
            label: "Beschreibung",
        }),
        Subject: new TextField({
            label: "E-Mail Betreff",
        }),
        Body: this.htmlField,
    };
    public availableLanguages$: Promise<AvailableLanguages>;
    public allLanguages: string[] = [];
}
