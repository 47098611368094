import {Button, RowLayout, TextField} from "../../../../common/form/types";
import {CustomerAddress} from "../../../core";
import {CustomerAddressFormModel} from "../edit-customer-address/customer-address.form-model";
import {BaseOptions} from "@app/app/common/form/base/base.options";
import {BaseType} from "@app/app/common/form/base/base.type";
import {ThemePalette} from "@angular/material/core";

export class CustomerAddressMergeFormModel {
    public CompanyRow: RowLayout<CustomerAddress> = null;
    public SalutationRow: RowLayout<CustomerAddress> = null;
    public Name2Row: RowLayout<CustomerAddress> = null;
    public TitleRow: RowLayout<CustomerAddress> = null;
    public NameRow: RowLayout<CustomerAddress> = null;
    public StreetRow: RowLayout<CustomerAddress> = null;
    public AddressAdditionRow: RowLayout<CustomerAddress> = null;
    public CityRow: RowLayout<CustomerAddress> = null;
    public State: RowLayout<CustomerAddress> = null;
    public CountryCode: RowLayout<CustomerAddress> = null;
}

export class CustomerAddressMergeFormModelHelper {
    public formModel: CustomerAddressMergeFormModel = new CustomerAddressMergeFormModel();
    public buttons: { [key: string]: Button } = {};

    constructor(private buttonColor: ThemePalette, 
                onClickUseValue: (fieldPath: string) => void) {
        const originalModel = new CustomerAddressFormModel();
        this.formModel.CompanyRow = this.wrap("Company", originalModel.Company, onClickUseValue);
        this.formModel.SalutationRow = this.wrap("Salutation", originalModel.Salutation, onClickUseValue);
        this.formModel.TitleRow = this.wrap("Title", originalModel.Title, onClickUseValue);
        this.formModel.NameRow = new RowLayout({
            model: {
                FirstName: this.wrap("FirstName", originalModel.nameRow.options.model.FirstName as TextField, onClickUseValue),
                LastName: this.wrap("LastName", originalModel.nameRow.options.model.LastName as TextField, onClickUseValue),
            },
        });
        this.formModel.Name2Row = this.wrap("Name2", originalModel.Name2, onClickUseValue);
        this.formModel.StreetRow = new RowLayout({
            flex: ["noshrink", "none"],
            model: {
                Street: this.wrap("Street", originalModel.streetRow.options.model.Street as TextField, onClickUseValue),
                Housenumber: this.wrap("Housenumber", originalModel.streetRow.options.model.Housenumber as TextField, onClickUseValue),
            },
        });
        this.formModel.AddressAdditionRow = this.wrap("AddressAddition", originalModel.AddressAddition, onClickUseValue);
        this.formModel.CityRow = new RowLayout({
            flex: ["noshrink", "none"],
            model: {
                Zip: this.wrap("Zip", originalModel.cityRow.options.model.Zip as TextField, onClickUseValue),
                City: this.wrap("City", originalModel.cityRow.options.model.City as TextField, onClickUseValue),
            },
        });
        this.formModel.State = this.wrap("State", originalModel.State, onClickUseValue);
        this.formModel.CountryCode = this.wrap("CountryCode", originalModel.CountryCode, onClickUseValue);
    }

    private wrap(key: keyof CustomerAddress,
                 type: BaseType<BaseOptions>, onClickUseValue: (fieldPath: string) => void,
    ): RowLayout<CustomerAddress> {
        type.options.readonly = true;
        type.options.required = false;
        return new RowLayout({
            dense: true,
            flex: ["noshrink", "none"],
            model: {
                [key]: type,
                [`button${key}`]: this.buttons[key] = new Button({
                    appearance: "icon",
                    color: this.buttonColor,
                    icon: "fa-check",
                    onClick: () => onClickUseValue(key),
                    tooltip: "tooltip.use_value",
                }),
            },
        });
    }
}
