export class CustomerSource {
    public Id: number = null;
    public Source: string = null;
    public SourceId: string = null;
    public ApiAccountId: number = null;
    public ApiAccountName: string = null;

    constructor(obj?: Partial<CustomerSource>) {
        ctor(this, obj);
    }
}
