import {Injectable, Injector} from "@angular/core";
import {FormControl, FormGroupDirective, NgControl, NgForm} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";

@Injectable()
export class BbFormErrorStateMatcher implements ErrorStateMatcher {
    constructor(private readonly injector: Injector) {}

	public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const injectedControl = this.injector.get(NgControl);

		if (!injectedControl) {
            return (control?.touched || false) && (control?.invalid || false);
        }

        return (injectedControl.control?.touched || false) && (injectedControl.control?.invalid || false);
    }
}
