import {ChangeDetectorRef, Component, Inject, Injector, OnDestroy, OnInit, Optional, ViewChild} from "@angular/core";
import {CustomerFormViewModel} from "./customer-form.view-model";
import {
    Customer,
    CustomerOrderRepository,
    CustomerPaymentRepository,
    LoadCustomerPresenter,
    LoadCustomerRequest,
    LoadCustomerUseCase,
    SaveCustomerRequest,
    SaveCustomerUseCase,
} from "../../core";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {ComponentWithForm} from "@bb-infra/route-guard";
import {FormBuilderComponent} from "@mintware-de/form-builder";
import {UntypedFormGroup} from "@angular/forms";
import {NavigationService} from "@bb-core/service";
import {CustomerAddressesCollection} from "./form-type/customer-addresses-collection.type";
import {CustomerSourceCollection} from "./form-type/customer-source-collection.type";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: "bb-customer-form",
    templateUrl: "customer-form.component.html",
    styleUrls: ["./customer-form.component.scss"],
})
export class CustomerFormComponent implements OnInit,
    OnDestroy,
    LoadCustomerPresenter,
    ComponentWithForm {

    public readonly viewModel: CustomerFormViewModel;

    @ViewChild("customerForm")
    public customerForm: FormBuilderComponent;

    private readonly subscriptions: Subscription[] = [];
    private readonly fromDialog: boolean = false;

    constructor(private readonly loadCustomerUseCase: LoadCustomerUseCase,
                private readonly route: ActivatedRoute,
                private readonly paymentsRepository: CustomerPaymentRepository,
                private readonly ordersRepository: CustomerOrderRepository,
                @Inject("authService") private readonly auth: AuthService,
                private readonly cdr: ChangeDetectorRef,
                private readonly saveCustomerUseCase: SaveCustomerUseCase,
                private readonly nav: NavigationService,
                private readonly injector: Injector,
                @Optional() private readonly dialogRef: MatDialogRef<CustomerFormComponent>,
                private readonly navigator: NavigationService,
    ) {
        this.fromDialog = dialogRef != null;

        this.viewModel = new CustomerFormViewModel(
            paymentsRepository,
            ordersRepository,
            () => this.goBack(),
            new CustomerAddressesCollection(injector, (targetId, sourceIds) => {
                sourceIds = sourceIds.filter(id => id != null && id !== targetId);
                if (!(targetId in this.viewModel.mergedAddresses)) {
                    this.viewModel.mergedAddresses[targetId] = [];
                }
                for (const sourceId of sourceIds) {
                    if (sourceId in this.viewModel.mergedAddresses) {
                        this.viewModel.mergedAddresses[targetId].push(...this.viewModel.mergedAddresses[sourceId]);
                        delete this.viewModel.mergedAddresses[sourceId];
                    }
                    this.viewModel.mergedAddresses[targetId].push(sourceId);
                }
            }),
            new CustomerSourceCollection(injector),
        );

    }

    // region Life cycle hooks

    public ngOnInit(): void {

        this.auth.userPromise.then((a) => this.viewModel.userName = a.userName);

        this.subscriptions.push(this.route.params.subscribe((params) => {
            const customerId = "id" in params ? params.id : null;
            this.loadCustomerUseCase.execute(new LoadCustomerRequest({
                CustomerId: customerId,
            }), this).then();
        }));

    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    // endregion

    // region LoadCustomerPresenter
    public displayCustomer(customer: Customer): void {
        this.viewModel.customer = customer;
        if (customer.Id !== null) {
            this.viewModel.paymentsTab.hide = false;
            this.viewModel.ordersTab.hide = false;
        } else {
            this.viewModel.addressesField.addressRequired = true;
        }
        this.viewModel.paymentGridOptions.AdditionalArguments = {customerId: customer.Id};
        this.viewModel.orderGridOptions.AdditionalArguments = {customerId: customer.Id};
        this.cdr.detectChanges();
        this.viewModel.resetAuthorName();
        this.cdr.detectChanges();
    }


    // endregion

    public async onSubmit(formData: Customer): Promise<void> {
        const dataToSubmit = new Customer(Object.assign({}, this.viewModel.customer, formData));

        this.customerForm.group.disable();
        try {
            let savedCustomer: Customer = null;
            await this.saveCustomerUseCase.execute(new SaveCustomerRequest({
                Customer: dataToSubmit,
                UpdateAddresses: this.viewModel.addressesLoaded,
                MergedAddresses: this.viewModel.mergedAddresses,
            }), {
                displaySavedCustomer(customer: Customer): void {
                    savedCustomer = customer;
                },
            });
            await this.goBack(savedCustomer);
        } finally {
            this.customerForm.group.enable();
        }
    }

    public getForm(): UntypedFormGroup {
        return this.customerForm?.group ?? new UntypedFormGroup({});
    }

    private async goBack(newCustomer?: Customer): Promise<void> {
        if (this.fromDialog) {
            this.dialogRef.close(newCustomer);
        } else {
            await this.navigator.navigateToCustomerList();
        }
    }
}
