import {Address, RecipientDetails, RecipientOptions} from "../entity";

/** This class describes a service for user interaction in the shipping context */
export abstract class ShippingInteractionService {

    /**
     * Let the user edit the details about the recipient
     * @param recipientDetails The details to edit.
     * @param options DropDown values
     */
    abstract editRecipientDetails(recipientDetails: RecipientDetails,
                                  options: RecipientOptions,
    ): Promise<RecipientDetails>;

    /**
     * Let the user pick an address for a customer.
     * @param customerId The id of the customer.
     * @param addresses The currently known addresses of the customer.
     * @param orderId The order id
     * @param currentAddressId? The id of the current selected address.
     */
    abstract pickAddress(customerId: number,
                         addresses: Address[],
                         orderId: number,
                         currentAddressId?: number,
    ): Promise<Address>;
}
