import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import { baseEnvironment } from "@app/app/environments/environment.base";

@Injectable({
    providedIn: "root",
})

export class RefinerService {
    constructor() {
    }

    public showChurnForm(): Observable<boolean> {
        if ("_refiner" in window) {
            const churnSurveyId = baseEnvironment.churnSurveys[Math.floor(Math.random() * baseEnvironment.churnSurveys.length * 10)  % baseEnvironment.churnSurveys.length];
            (window as any)._refiner("showForm", churnSurveyId, true);
            return new Observable<boolean>(subscriber => {
                (window as any)._refiner("onClose", function(formId) {
                    if (formId === churnSurveyId) {
                        subscriber.next(true);
                        subscriber.complete();
                    }
                });
            });
        } else {
            console.error("Refiner is not available");
            return of(true);
        }
    }
}
