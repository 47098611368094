/* eslint-disable */
import {Injectable} from "@angular/core";
import {Customer, LegacyCustomerRepository} from "@app/app/customer/core";
import {ErrorHandlerService, Message, MessagingService, NavigationService, TranslationService} from "@bb-core/service";

export class LoadCustomerRequest {
    public CustomerId?: number = null;

    constructor(obj?: Partial<LoadCustomerRequest>) {
        ctor(this, obj);
    }
}

export abstract class LoadCustomerPresenter {

    /** Display the loaded customer */
    public abstract displayCustomer(customer: Customer): void;
}

/**
 * @deprecated
 */
@Injectable({providedIn: "root"})
export class LoadCustomerUseCase implements IUseCase<LoadCustomerRequest, LoadCustomerPresenter> {

    constructor(private readonly repository: LegacyCustomerRepository,
                private readonly messaging: MessagingService,
                private readonly translator: TranslationService,
                private readonly navigator: NavigationService,
                private readonly errorHandler: ErrorHandlerService,
    ) {
    }

    public async execute(
        request: LoadCustomerRequest,
        presenter?: LoadCustomerPresenter,
    ): Promise<void> {
        const customer = await this.loadCustomer(request.CustomerId);
        if (customer == null) {
            await this.navigator.navigateToCustomerList();
            return;
        }
        presenter?.displayCustomer(customer);
    }


    private async loadCustomer(customerId?: number): Promise<Customer> {
        let customer = new Customer();
        if (customerId != null) {
            try {
                customer = await this.repository.getCustomerById(customerId);
            } catch (e) {
                await this.errorHandler.handleException(e, "text.failed_to_load_the_customer", true);
                return null;
            }
        }

        if (customer == null) {
            const title = this.translator.translate("title.error");
            const message = this.translator.translate("text.customer_not_found");
            await this.messaging.showError(Message.blocking({title, message}));
            return null;
        }

        return customer;
    }

}
