import {ChangeDetectionStrategy, Component} from "@angular/core";
import {take} from "rxjs/operators";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantBeforeSavingTaskComponent} from "../before-saving/before-saving.component";
import {OnboardingAssistantOverseasInvoiceNetComponent} from "../overseas-invoice-net/overseas-invoice-net.component";
import {OnboardingAssistantUseOssTaskComponent} from "../use-oss/use-oss.component";

@Component({
    selector: "bb-onboarding-assistant-tax-rate-target-country",
    templateUrl: "./tax-rate-target-country.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantTaxRateTargetCountryTaskComponent {
    
    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
    ) {}

	public next(useTargetCountryTaxRate: boolean): void {
		this.onboardingStore.setData({useTargetCountryTaxRate});

		if (useTargetCountryTaxRate) {
			this.onboardingStore.nextTask(OnboardingAssistantUseOssTaskComponent);
			return;
		} else {
			this.onboardingStore.data$.pipe(
				take(1),
			).subscribe(({vatMode}) => {
				if (vatMode === 0) {
					this.onboardingStore.nextTask(OnboardingAssistantOverseasInvoiceNetComponent);
				} else {
					this.onboardingStore.nextTask(OnboardingAssistantBeforeSavingTaskComponent);
				}
			});
		}
    }
}
