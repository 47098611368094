import {FormModel} from "@mintware-de/form-builder";
import {CustomerExportSettings} from "@app/app/customer/core";
import {SelectField} from "@app/app/common/form/types";

export class ExportCustomersViewModel {
    public fileFormatField: SelectField = null;
    public selectionField: SelectField = null;

    public formModel: FormModel<CustomerExportSettings> = {
        Selection: this.selectionField = new SelectField({
            label: "label.selection",
            options: {},
        }),
        FileFormat: this.fileFormatField = new SelectField({
            label: "label.format",
            options: {},
        }),
    };

    public exportSettings: CustomerExportSettings = new CustomerExportSettings();
}
