<h2 mat-dialog-title>{{ "title.add_kaufland_shop" | translate }}</h2>
<mat-dialog-content>
	<form [formGroup]="form">
		<bb-input [label]="'label.api_key' | translate" formControlName="kauflandApiKey" required></bb-input>
		<bb-input [label]="'label.api_password' | translate" formControlName="kauflandApiSecret" required></bb-input>
	</form>
    <mat-hint [innerHtml]="'text.add_kaufland_shop_hint' | translate"> </mat-hint>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
        {{ "button.cancel" | translate }}
    </button>
    <button
        mat-flat-button
        color="primary"
		(click)="continue()"
    >
        {{ "button.next" | translate }}
    </button>
</mat-dialog-actions>
