/* eslint-disable */
import {Injectable} from "@angular/core";
import {ErrorHandlerService, InteractionService, Message, MessagingService, TranslationService} from "@bb-core/service";
import {LegacyCustomerRepository} from "@app/app/customer/core";

export class DeleteCustomerRequest {
    public SelectedIds: number[] = [];

    constructor(obj?: Partial<DeleteCustomerRequest>) {
        ctor(this, obj);
    }
}

export abstract class DeleteCustomersPresenter {
    public abstract removeDeletedCustomers(customerIds: number[]): void;
}

/**
 * @deprecated
 */
@Injectable({providedIn: "root"})
export class DeleteCustomerUseCase implements IUseCase<DeleteCustomerRequest, DeleteCustomersPresenter> {
    constructor(private readonly customerRepository: LegacyCustomerRepository,
                private readonly messaging: MessagingService,
                private readonly translator: TranslationService,
                private readonly interactionService: InteractionService,
                private readonly errorHandler: ErrorHandlerService,
    ) {
    }

    public async execute(request: DeleteCustomerRequest, presenter?: DeleteCustomersPresenter): Promise<void> {
        if (!(await this.confirmDelete())) {
            return;
        }

        await this.deleteCustomers(request.SelectedIds);
        presenter?.removeDeletedCustomers(request.SelectedIds);
    }

    private async deleteCustomers(selectedIds: number[]): Promise<void> {
        try {
            await this.customerRepository.deleteCustomers(selectedIds);
            const message = this.translator.translate("flash.customers_deleted");
            await this.messaging.showMessage(Message.transient({message}));
        } catch (e) {
            await this.errorHandler.handleException(e, "flash.delete_customer_failed", true);
        }
    }

    private confirmDelete(): Promise<boolean> {
        const title = this.translator.translate("title.delete_customers");
        const message = this.translator.translate("text.confirm_delete_customers");
        return this.interactionService.confirm(title, message);
    }
}
