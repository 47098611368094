export const connectionTypes = [
    {
        value       : "shop",
        displayText : "text.amazon_scpn_auth_dialog.create_connection.shop_connection",
        description : "text.amazon_scpn_auth_dialog.create_connection.shop_connection.description",
    },
    {
        value       : "fulfillment_fba",
        displayText : "text.amazon_scpn_auth_dialog.create_connection.fulfillment_connection_fba",
        description : "text.amazon_scpn_auth_dialog.create_connection.fulfillment_connection_fba.description",
    },
    {
        value       : "fulfillment_sfp",
        displayText : "text.amazon_scpn_auth_dialog.create_connection.fulfillment_connection_sfp",
        description : "text.amazon_scpn_auth_dialog.create_connection.fulfillment_connection_sfp.description",
    },
    {
        value       : "payment_import",
        displayText : "text.amazon_scpn_auth_dialog.create_connection.payment_import_connection",
        description : "text.amazon_scpn_auth_dialog.create_connection.payment_import_connection.description",
    },
];

export interface AmazonMarketplace {
    id: string;
    displayName: string;
}

export class AmazonMarketplaces {
    
    private static _amazonMarketplaces: AmazonMarketplace[] = [
        { id : "A1PA6795UKMFR9", displayName: "DE - A1PA6795UKMFR9"},
        { id : "A1RKKUPIHCS9HS", displayName: "ES - A1RKKUPIHCS9HS"},
        { id : "A13V1IB3VIYZZH", displayName: "FR - A13V1IB3VIYZZH"},
        { id : "A1805IZSGTT6HS", displayName: "NL - A1805IZSGTT6HS"},
        { id : "AMEN7PMS3EDWL" , displayName: "BE - AMEN7PMS3EDWL"},
        { id : "A21TJRUUN4KGV" , displayName: "IN - A21TJRUUN4KGV"},
        { id : "APJ6JRA9NG5V4" , displayName: "IT - APJ6JRA9NG5V4"},
        { id : "A1F83G8C2ARO7P", displayName: "CO.UK - A1F83G8C2ARO7P"},
        { id : "A2EUQ1WTGCTBG2", displayName: "CA - A2EUQ1WTGCTBG2"},
        { id : "ATVPDKIKX0DER" , displayName: "US - ATVPDKIKX0DER"},
        { id : "A1AM78C64UM0Y8", displayName: "MX - A1AM78C64UM0Y8"},
        { id : "A1VC38T7YXB528", displayName: "JP - A1VC38T7YXB528"},
        { id : "AAHKV2X7AFYLW" , displayName: "CN - AAHKV2X7AFYLW"},
    ];
    
    public static getList(): AmazonMarketplace[] {
        return this._amazonMarketplaces;
    }
}

export interface SellerCentral {
    displayName: string;
    region: string;
    url: string;
}

export class SellerCentrals {
    
    private static _sellerCentrals: SellerCentral[]  = [
        {displayName:"Canada",  region: "NA", url:"https://sellercentral.amazon.ca"},
        {displayName:"US",  region: "NA", url:"https://sellercentral.amazon.com"},
        {displayName:"Mexico",  region: "NA", url:"https://sellercentral.amazon.com.mx"},
        {displayName:"Brazil",  region: "NA", url:"https://sellercentral.amazon.com.br"},
        {displayName:"Spain",  region: "EU", url:"https://sellercentral-europe.amazon.com"},
        {displayName:"UK",  region: "EU", url:"https://sellercentral-europe.amazon.com"},
        {displayName:"France",  region: "EU", url:"https://sellercentral-europe.amazon.com"},
        {displayName:"Netherlands",  region: "EU", url:"https://sellercentral.amazon.nl"},
        {displayName:"Germany",  region: "EU", url:"https://sellercentral-europe.amazon.com"},
        {displayName:"Italy",  region: "EU", url:"https://sellercentral-europe.amazon.com"},
        {displayName:"Sweden",  region: "EU", url:"https://sellercentral.amazon.se"},
        {displayName:"Poland",  region: "EU", url:"https://sellercentral.amazon.pl"},
        {displayName:"Egypt",  region: "EU", url:"https://sellercentral.amazon.eg"},
        {displayName:"Turkey",  region: "EU", url:"https://sellercentral.amazon.com.tr"},
        {displayName:"Saudi Arabia",  region: "EU",	url:"https://sellercentral.amazon.com.sa"},
        {displayName:"U.A.E.",  region: "EU", url:"https://sellercentral.amazon.ae"},
        {displayName:"India",  region: "EU", url:"https://sellercentral.amazon.in"},
        {displayName:"Singapore",  region: "FE", url:"https://sellercentral.amazon.sg"},
        {displayName:"Australia",  region: "FE", url:"https://sellercentral.amazon.com.au"},
        {displayName:"Japan",  region: "FE", url:"https://sellercentral.amazon.co.jp"},
    ];
    
    public static getList(): SellerCentral[] {
        return this._sellerCentrals;
    }
    
    public static getSellerCentralByHostname(hostname: string) {
        for (const sellerCentral of this._sellerCentrals) {
            const domain = new URL(sellerCentral.url);
            const hostname2 = domain.hostname;
            if (hostname === hostname2) {
                return sellerCentral.region;
            }
        }
        return "EU";
    }
}

export enum States {
    START,
    REAUTHORIZE,
    REAUTHORIZE_IN_PROGRESS,
    REAUTHORIZE_RESULT,
    CREATE_CONNECTION,
    SELECT_MARKETPLACE,
}

export enum ReautResultStates {
    SUCCESS,
    NO_CONNECTIONS,
    FAILED,
}




