import {ColumnLayout, HiddenField, TableCollection, Text} from "@app/app/common/form/types";
import {CustomerSource, EditCustomerSourceRequest, EditCustomerSourceUseCase} from "@app/app/customer/core";
import {InteractionService, Message, MessagingService, TranslationService} from "@bb-core/service";
import {Injector} from "@angular/core";

export class CustomerSourceCollection extends TableCollection<CustomerSource> {

    private selectedSources: CustomerSource[] = [];
    private selectedSourceIndexes: number[] = [];

    private readonly editCustomerSourceUseCase: EditCustomerSourceUseCase;
    private readonly translator: TranslationService;
    private readonly messaging: MessagingService;
    private readonly interaction: InteractionService;

    constructor(injector: Injector) {
        super({
            allowAdd: false,
            allowDelete: false,
            allowEdit: true,
            onClickEdit: (index, data) => this.editSource(index, new CustomerSource(data)),
            noDataText: "text.no_customer_sources",
            selectMode: "multiple",
            selectionChanged: (indexes, rows) => {
                this.selectedSourceIndexes = indexes;
                this.selectedSources = rows.map((x) => new CustomerSource(x));
            },
            entrySettings: {
                columns: [
                    {
                        content: new ColumnLayout<CustomerSource>({
                            model: {
                                "Id": new HiddenField(),
                            },
                        }),
                        title: "",
                        isHidden: true,
                    },
                    {
                        field: "Source",
                        content: new Text({}),
                        title: "label.source",
                    },
                    {
                        field: "ApiAccountName",
                        content: new Text({}),
                        title: "label.name",
                    },
                    {
                        field: "ApiAccountId",
                        content: new Text({}),
                        title: "label.id",
                    },
                    {
                        field: "SourceId",
                        content: new Text({}),
                        title: "label.source_id",
                    },
                    {
                        title: "",
                        isActionsColumn: true,
                    },
                ],
            },
        });
        this.editCustomerSourceUseCase = injector.get(EditCustomerSourceUseCase);
        this.translator = injector.get(TranslationService);
        this.messaging = injector.get(MessagingService);
        this.interaction = injector.get(InteractionService);
    }

    public async addSource() {
        await this.editSource(this.control.controls.length, new CustomerSource());
    }

    private async editSource(index: number, address: CustomerSource): Promise<void> {
        const updated = await this.editCustomerSource(address);
        if (updated == null) {
            return;
        }

        if (this.control.controls.length <= index) {
            this.componentInstance.addEntry();
        }
        this.control.get([index]).patchValue(updated);
    }

    public async deleteSelectedSources(): Promise<void> {
        if (!(await this.confirmDeleteSources(this.selectedSources))) {
            return;
        }
        this.removeSelectedRows();
    }

    private removeSelectedRows(): void {
        const indexesToRemove = [...this.selectedSourceIndexes].map(Number).sort().reverse();
        indexesToRemove.forEach(i => this.componentInstance.removeEntry(i, true));
        this.componentInstance.resetSelection();
    }

    private async editCustomerSource(source: CustomerSource): Promise<CustomerSource> {
        let updatedSource = null;
        await this.editCustomerSourceUseCase.execute(new EditCustomerSourceRequest({
            Source: source,
        }), {
            displayNewSource(newSource: CustomerSource): void {
                updatedSource = newSource;
            },
        });

        return updatedSource;
    }

    private confirmDeleteSources(sources: CustomerSource[]): Promise<boolean> {
        if (sources.length === 0) {
            this.messaging.showMessage(Message.blocking({
                title: this.translator.translate("title.select_sources"),
                message: this.translator.translate("text.select_at_least_one_source_to_delete"),
            })).then();
            return;
        }

        const title = this.translator.translate("title.delete_sources");
        const count = sources.length;
        const message = this.translator.translate(
            count === 1 ? "text.delete_single_source" : "text.delete_multiple_sources",
            {"count": count},
        );

        return this.interaction.confirm(title, message);
    }

}
