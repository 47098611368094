import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
    providedIn: "root",
})
export class BulkEditLegacyService {
	public reload$ = new Subject<void>();
	
	public reloadList(): void {
		this.reload$.next();
	}
}
