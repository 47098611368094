import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ShopViewModelDto } from "../../data/shop-view-model.dto";
import { ShopKey } from "../../model/shop-key";
import { ShopsService } from "../shops.service";

@Injectable({
    providedIn: "root",
})
export class GetOAuthViewModelCommand {
    private readonly shopsService = inject(ShopsService);

    execute(
        key: ShopKey,
        logo: string,
        name: string,
        url: string,
        earliestStartDate?: Date,
    ): Observable<ShopViewModelDto> {
        return this.shopsService
            .getOauthDetails(logo, url)
            .pipe(
                switchMap(oauth =>
                    (earliestStartDate
                        ? this.shopsService.getBaseDetails(key, name, logo, earliestStartDate)
                        : this.shopsService.getBaseDetails(key, name, logo)
                    ).pipe(map(viewModel => ({ ...viewModel, OAuthAccessToken: oauth }))),
                ),
            );
    }
}
