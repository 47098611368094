import {Injectable, inject} from "@angular/core";
import {Observable, from} from "rxjs";
import {ShopsRepository} from "../../data/shops.repository";
import {Shop} from "../../model/shop";
import {ShopType} from "../../model/shop-type.enum";
import {OauthShopAddCommand} from "../../service/command/oauth-shop-add.command";

@Injectable({
    providedIn: "root",
})
export class EcwidShop implements Shop {
    public readonly key = "ecwid";
    public readonly name = "Ecwid";
    public readonly type = ShopType.Shop;
    public readonly logo = `assets/images/logo/shops/${this.key}.jpg`;

    private readonly repository = inject(ShopsRepository);
    private readonly addCommand = inject(OauthShopAddCommand);

    add(): Observable<never> {
        return this.addCommand.execute(from(this.repository.getAuthorizeUrl(this.key)), this.key, this.logo);
    }
}
