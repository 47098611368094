export class PackageType {
    public Id: number = null;
    public Name: string = "";
    public HeightCm: number = 0;
    public LengthCm: number = 0;
    public WidthCm: number = 0;
    public WeightGram?: number = null;
    public MaximumUsageWeightGram: number = null;
    public Priority: number = 0;

    constructor(obj?: Partial<PackageType>) {
        ctor(this, obj, {});
    }

    public toString(): string {
        return this.Name;
    }
}
