<h2 mat-dialog-title>{{ "title.choose_amazon_region" | translate }}</h2>
<mat-dialog-content>
    <bb-select
        [formControl]="regionFormControl"
        [label]="'label.region' | translate"
        [options]="options"
        required
    ></bb-select>
    <mat-hint [innerHtml]="'text.add_amazon_shop_hint' | translate"> </mat-hint>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
        {{ "button.cancel" | translate }}
    </button>
    <button
        mat-flat-button
        color="primary"
		(click)="chooseRegion()"
    >
        {{ "button.next" | translate }}
    </button>
</mat-dialog-actions>
