import {Injectable} from "@angular/core";
import * as ngx from "@ngx-resource/core";
import {AvailableLanguages, LanguageDefinition} from "@shared/models";
import {BehaviorSubject} from "rxjs";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/language",
})
export class LanguageService extends ngx.Resource {
    public readonly selectedLang$ = new BehaviorSubject<string | null>(null);

    @ngx.ResourceAction({
        path: "/",
    })
    public query: ngx.IResourceMethod<void, AvailableLanguages>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/",
    })
    public addNewLanguage: ngx.IResourceMethod<{language: string}, void>;

    @ngx.ResourceAction({
        path: "/getall",
    })
    public queryAll: ngx.IResourceMethod<void, LanguageDefinition[]>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/delete",
    })
    public deleteMany: ngx.IResourceMethod<string[], void>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/changedefault",
    })
	public setDefault: ngx.IResourceMethod<any, void>;
	
	public selectLanguage(lang: string): void {
		this.selectedLang$.next(lang);
	}

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }
}
