import {Component, Inject} from "@angular/core";
import {FormModel} from "@mintware-de/form-builder";
import {Address, RecipientDetails, RecipientOptions} from "@app/app/shipping/core/entity";
import {Button, CountryField, FormGroup, RowLayout, SelectField, TextField} from "@app/app/common/form/types";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
    EditCustomerMetaDataRequest,
    EditCustomerMetaDataUseCase,
} from "@app/app/customer/core/use-case/edit-customer-meta-data.use-case";
import {CustomerMetaInformation, CustomerMetaType} from "@app/app/customer/core";

/**
 * @deprecated
 */
@Component({
    selector: "bb-edit-address-dialog",
    template: `
        <h4 mat-dialog-title>{{ 'title.edit_recipient' | translate }}</h4>
        <mat-dialog-content>
            <mw-form-builder #form
                             [mwFormModel]="formModel"
                             [mwFormData]="data.recipientDetails"
                             (mwFormSubmit)="submit($event)">
            </mw-form-builder>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button type="button" mat-flat-button (click)="cancel()">
                {{ 'button.cancel' | translate }}
            </button>
            <button type="button"
                    mat-raised-button
                    color="primary"
                    (click)="form.submit()">
                {{ 'button.save' | translate }}
            </button>
        </mat-dialog-actions>
    `,
    styles: [],
})
export class EditAddressDialogComponent {

    public formModel: FormModel<RecipientDetails & RecipientOptions> = {};
    public metaDataEdited: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public readonly data: {
                    recipientDetails: RecipientDetails & RecipientOptions,
                    options: RecipientOptions,
                },
                private readonly dialogRef: MatDialogRef<EditAddressDialogComponent>,
                private readonly editMetaDataUseCase: EditCustomerMetaDataUseCase,
    ) {
        this.formModel = {
            Address: new FormGroup<Omit<Address, "toString">>({
                model: {
                    Company: new TextField({
                        label: "label.company",
                        tooltip: "tooltip.company",
                        required: false,
                    }),
                    _nameRow: new RowLayout({
                        model: {
                            FirstName: new TextField({
                                label: "label.first_name",
                                tooltip: "tooltip.recipient_first_name",
                                required: false,
                            }),
                            LastName: new TextField({
                                label: "label.last_name",
								tooltip: "tooltip.recipient_last_name",
								required: false,
                            }),
                        },
                    }),
                    Name2: new TextField({
                        label: "label.name2",
                        tooltip: "tooltip.recipient_name2",
                        required: false,
                    }),
                    _streetRow: new RowLayout({
                        flex: [70, 30],
                        model: {
                            Street: new TextField({
                                label: "label.street",
                                tooltip: "tooltip.recipient_street",
                            }),
                            Housenumber: new TextField({
                                label: "label.house_number",
                                tooltip: "tooltip.recipient_house_number",
                                required: false,
                            }),
                        },
                    }),
                    AddressAddition: new TextField({
                        label: "label.address_addition",
                        tooltip: "tooltip.recipient_address_addition",
                        required: false,
                    }),
                    _cityRow: new RowLayout({
                        flex: [30, 70],
                        model: {
                            Zip: new TextField({
                                label: "label.zip_code",
                                tooltip: "tooltip.recipient_zip_code",
                                required: false,
                            }),
                            City: new TextField({
                                label: "label.city",
                                tooltip: "tooltip.recipient_city",
                            }),
                        },
                    }),
                    State: new TextField({
                        label: "label.state",
                        tooltip: "tooltip.recipient_state",
                        required: false,
                    }),
                    CountryCode: new CountryField({
                        label: "label.country",
                        tooltip: "tooltip.recipient_country",
                    }),
                },
            }),
            _defaultMailRow: this._wrapMetaRow("DefaultMailAddressId", new SelectField({
                label: "label.email_default",
                required: false,
                options: data.options.DefaultMailAddresses.reduce((res, o) => ({...res, [o.Key]: o.Value}), {}),
            }), CustomerMetaType.MailAddress),
            _commercialMailRow: this._wrapMetaRow("CommercialMailAddressId", new SelectField({
                label: "label.email_commercial",
                required: false,
                options: data.options.CommercialMailAddresses.reduce((res, o) => ({...res, [o.Key]: o.Value}), {}),
            }), CustomerMetaType.MailAddress),
            _statusUpdatesMailRow: this._wrapMetaRow("StatusUpdatesMailAddressId", new SelectField({
                label: "label.email_status_updates",
                required: false,
                options: data.options.StatusUpdatesMailAddresses.reduce((res, o) => ({...res, [o.Key]: o.Value}), {}),
            }), CustomerMetaType.MailAddress),
            _phone1Row: this._wrapMetaRow("Phone1Id", new SelectField({
                label: "label.phone1",
                required: false,
                options: data.options.Phone1Numbers.reduce((res, o) => ({...res, [o.Key]: o.Value}), {}),
            }), CustomerMetaType.PhoneNumber),
            _phone2Row: this._wrapMetaRow("Phone2Id", new SelectField({
                label: "label.phone2",
                required: false,
                options: data.options.Phone2Numbers.reduce((res, o) => ({...res, [o.Key]: o.Value}), {}),
            }), CustomerMetaType.PhoneNumber),
            _faxRow: this._wrapMetaRow("FaxId", new SelectField({
                label: "label.fax",
                required: false,
                options: data.options.FaxNumbers.reduce((res, o) => ({...res, [o.Key]: o.Value}), {}),
            }), CustomerMetaType.PhoneNumber),
        };
    }

    private _wrapMetaRow(key: string,
                         child: SelectField,
                         type: CustomerMetaType,
    ): RowLayout<RecipientDetails & RecipientOptions> {
        return new RowLayout({
            flex: ["flex", 0],
            dense: true,
            model: {
                [key]: child,
                _editButton: new Button({
                    appearance: "icon",
                    icon: "fa-pencil",
                    onClick: () => {
                        const id = child.componentInstance.mwElement.value;
                        if (id == null) {
                            return;
                        }
                        return this.editMetaDataUseCase.execute(new EditCustomerMetaDataRequest({
                            MetaData: new CustomerMetaInformation({
                                Id: id,
                                Value: child.options.options[id],
                                Type: type,
                            }),
                        }), {
                            displayUpdatedMetaData: (metaData: CustomerMetaInformation) => {
                                this.metaDataEdited = true;
                                const current = child.options.options;
                                current[metaData.Id] = metaData.Value;
                                child.reportChanges(Object.keys(current).map(k => ({
                                    value: Number(k),
                                    text: current[k],
                                })));
                            },
                        });
                    },
                }),
            },
        });
    }

    public submit(formData: RecipientDetails & RecipientOptions): void {
        const {Address: address} = this.data.recipientDetails;
        const newAddress = new Address(Object.assign({}, address, formData.Address));
        if (!this.metaDataEdited && newAddress.equals(address) && this.metaDataEquals(formData)) {
            this.dialogRef.close(null);
            return;
        }

        this.dialogRef.close(new RecipientDetails({
            ...formData,
            Address: newAddress,
        }));
    }

    public cancel(): void {
        if (!this.metaDataEdited) {
            this.dialogRef.close(null);
            return;
        }
        this.dialogRef.close(new RecipientDetails(this.data.recipientDetails));
    }

    private metaDataEquals(formData: RecipientDetails): boolean {
        return this.data.recipientDetails.DefaultMailAddressId === formData.DefaultMailAddressId
            && this.data.recipientDetails.CommercialMailAddressId === formData.CommercialMailAddressId
            && this.data.recipientDetails.StatusUpdatesMailAddressId === formData.StatusUpdatesMailAddressId
            && this.data.recipientDetails.Phone1Id === formData.Phone1Id
            && this.data.recipientDetails.Phone2Id === formData.Phone2Id
            && this.data.recipientDetails.FaxId === formData.FaxId
            ;
    }
}
