<div class="description">
    {{ "text.which_shop" | translate }}
</div>
<div class="choose-button-wrapper">
    <div
        *ngFor="let shopData of shops$ | async"
        [matTooltipDisabled]="!shopData.disabled"
        [matTooltip]="'tooltip.shop_already_connected' | translate"
    >
        <button mat-stroked-button color="primary" [disabled]="shopData.disabled" (click)="add(shopData.shop)">
            <img [src]="shopData.shop.logo" />
        </button>
    </div>
</div>
<p class="info"><mat-icon fontIcon="fa-info"></mat-icon> {{ "text.other_shops_in_settings" | translate }}</p>
<button mat-flat-button color="primary" (click)="next()" *ngIf="{value: hasAddedShops$ | async} as hasAddedShops">
    {{ (hasAddedShops.value ? "button.next" : "button.skip") | translate }}
</button>
