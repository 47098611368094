<ng-container *ngIf="user && user.ExternalLogins && user.ExternalLogins.length; else noExternalLogins">
    <table class="material smaller">
        <thead>
        <tr>
            <th>{{"Id" | translate}}</th>
            <th>{{"Anbieter" | translate}}</th>
            <th>{{"Externer Benutzername" | translate}}</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let login of user.ExternalLogins; index as index">
            <td>{{login.Id}}</td>
            <td>{{providerNames[login.Provider]}}</td>
            <td>{{login.ExternalUserName}}</td>
            <td>
                <button type="button" mat-icon-button (click)="confirmDeleteExternalLogin(index)"
                        class="mat-elevation-z1">
                    <mat-icon fontIcon="fa-trash"></mat-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</ng-container>
<ng-template #noExternalLogins>
    <bb-meta-card icon="fa-info-circle">
        {{ 'Dieser Benutzer hat keine externen Logins.' | translate }}
    </bb-meta-card>
</ng-template>
