import {ShippingProviderEnum, ShippingProviderStateEnum} from "../enum";
import {ShippingProviderProduct} from "./shipping-provider-product";

export class ShippingProvider {
    public Id: number = null;
    public Name: string = "";
    public API: string = "";
    public ApiAsEnum: ShippingProviderEnum = null;
    public AttachedProducts: ShippingProviderProduct[] = [];
    public PackageWeightGram?: number = null;
    public DefaultWeightGram?: number = null;
    public PrinterId?: number = null;
    public PrinterIdForExportDocs?: number = null;
    public PrinterIdForRetoureLabels?: number = null;
    public State: ShippingProviderStateEnum = ShippingProviderStateEnum.Ready;
    public StateText?: string = null;

    constructor(obj?: Partial<ShippingProvider>) {
        ctor(this, obj, {
            Collections: {
                AttachedProducts: ShippingProviderProduct,
            },
        });
    }

    public toString(): string {
        return this.Name;
    }
}
