<h3 mat-dialog-title>{{ "title.add_versacommerce_shop" | translate }}</h3>
<mat-dialog-content>
    <bb-input
        [formControl]="shopName"
        [label]="'label.shopname' | translate"
        required
    ></bb-input>
    <mat-hint [innerHtml]="'text.add_versacommerce_shopname_hint' | translate"> </mat-hint>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
        {{ "button.cancel" | translate }}
    </button>
    <button
        mat-flat-button
        color="primary"
		[disabled]="shopName.invalid"
		[mat-dialog-close]="shopName.value"
    >
        {{ "button.next" | translate }}
    </button>
</mat-dialog-actions>
