import { Pipe, PipeTransform } from "@angular/core";
import { ShopViewModelDto } from "@app/app/partners/shops/data/shop-view-model.dto";
import { ShopSyncState } from "../../model/shop-sync-state.enum";

@Pipe({
    name: "bbIconFromSyncState",
    standalone: true,
})
export class IconFromSyncStatePipe implements PipeTransform {
    transform(shop: ShopViewModelDto): string {
        if (!shop.DownloadOrders) {
            return "fa-pause";
        }

        if (
            shop.LastSyncState === ShopSyncState.ReauthRequired ||
            shop.LastSyncState === ShopSyncState.SyncFailedReconnectRequired
        ) {
            return "fa-xmark";
        }

        return "fa-play";
    }
}
