import {AbstractLayoutType, Constructor, IGroupTypeOptions} from "@mintware-de/form-builder";
import {ComponentWrapperOptions} from "./component-wrapper.options";
import {ComponentWrapperComponent} from "./component-wrapper.component";

export class ComponentWrapper<T> extends AbstractLayoutType<IGroupTypeOptions> {
    public readonly component: Constructor = ComponentWrapperComponent;

    constructor(opts: Omit<ComponentWrapperOptions<T>, "model">) {
        super({
            ...opts,
            model: {},
        });
    }
}
