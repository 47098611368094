export abstract class DownloadService {

    /** Downloads a file by its key */
    public abstract downloadFileByKey(fileKey: string): Promise<void>;

    /** Downloads a file by a url */
    public abstract downloadFileByUrl(url: string): Promise<void>;

    /** Downloads a blob */
    public abstract downloadBlob(blob: Blob): Promise<void>;
}
