import {Inject, Injectable} from "@angular/core";
import {BB_PRESET_REPO, GeneralPresetPresenter, PresetRepository} from "@app/app/grid/core";
import {ClientStorageService} from "@bb-core/service";

@Injectable({providedIn: "any"})
export class SetPresetPanelVisibilityUseCase<T = any> implements IUseCase<boolean, GeneralPresetPresenter<T>> {

    constructor(@Inject(BB_PRESET_REPO) private readonly repository: PresetRepository<T>,
                private readonly clientStorage: ClientStorageService,
    ) {
    }

    public async execute(isVisible: boolean,
                         presenter?: GeneralPresetPresenter<T>,
    ): Promise<void> {
        this.clientStorage.set(`grid.preset.${this.repository.uniqueIdentifier}.visible`, isVisible);
        presenter?.setFilterPanelVisibility(isVisible);
    }
}
