import {InputField} from "./input.type";
import {TextFieldOptions} from "./text-field.options";
import {Constructor} from "@mintware-de/form-builder";
import {TextFieldComponent} from "./text-field.component";
import {ValidatorFn, Validators} from "@angular/forms";

export class EmailField extends InputField<TextFieldOptions> {

    public readonly component: Constructor = TextFieldComponent;

    constructor(options: TextFieldOptions) {
        super(Object.assign({
            required: true,
            type: "email",
        }, options));
    }

    protected buildValidators(): ValidatorFn[] {
        return [Validators.email];
    }
}
