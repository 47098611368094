import {Injectable} from "@angular/core";
import * as ngx from "@ngx-resource/core";
import {PreSaveOrderRequest, PreSaveOrderResponse} from "@app/app/order";
import {InconsistentVatDetailsCheckModeEnum} from "@app/app/common/model";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/order",
})
export class OrderRepository extends ngx.Resource {

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/pre-save-order-check",
    })
    private doPreSaveOrderCheck: ngx.IResourceMethod<PreSaveOrderRequest, PreSaveOrderResponse>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Get,
        path: "/inconsistent-vat-details-check-mode",
    })
    private doGetInconsistentVatCheckConfig: ngx.IResourceMethod<void, { Mode: InconsistentVatDetailsCheckModeEnum }>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/inconsistent-vat-details-check-mode",
    })
    private doSetInconsistentVatCheckMode: ngx.IResourceMethod<InconsistentVatDetailsCheckModeEnum, void>;

    public preSaveOrderCheck(request: PreSaveOrderRequest): Promise<PreSaveOrderResponse> {
        return this.doPreSaveOrderCheck(request).then(r => new PreSaveOrderResponse(r));
    }

    public static cachedVatCheckDetails: { Mode: InconsistentVatDetailsCheckModeEnum } = null;

    public async getInconsistentVatCheckConfig(): Promise<{ Mode: InconsistentVatDetailsCheckModeEnum }> {
        return OrderRepository.cachedVatCheckDetails
            ?? (OrderRepository.cachedVatCheckDetails = (await this.doGetInconsistentVatCheckConfig()));
    }

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }

    public async setInconsistentVatCheckMode(mode: InconsistentVatDetailsCheckModeEnum): Promise<void> {
        await this.doSetInconsistentVatCheckMode(mode);
        OrderRepository.cachedVatCheckDetails = null;
    }
}
