import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from "@angular/core";
import {AbstractFormFieldComponent, AbstractType} from "@mintware-de/form-builder";
import {InputOptions} from "./input.options";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "bb-form-file-field",
    template: `
        <input #realInput
               [type]="mwFieldType.options.type"
               [name]="mwPath"
               type="file"
               (change)="onFileChange($event)"
               hidden>
        <mat-form-field>
            <mat-label
                *ngIf="mwFieldType.options.label">{{ mwFieldType.options.label | translateLabel }}</mat-label>
            <input type="text" readonly disabled matInput [value]="realInput.files[0]?.name">

            <button matSuffix
                    mat-icon-button
                    type="button"
                    (click)="realInput.click()">
                <mat-icon fontIcon="fa-file-upload"></mat-icon>
            </button>
        </mat-form-field>
    `,
})
export class FileFieldComponent extends AbstractFormFieldComponent<AbstractType<InputOptions>> {

    constructor(private readonly cdr: ChangeDetectorRef) {
        super();
    }

    public onFileChange(event: any): void {
        const [file] = event.target.files;
        this.mwElement.patchValue(file);
    }
}

