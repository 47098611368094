import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BusyService } from "@app/app/common/service/busy.service";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class IndicatorInterceptor implements HttpInterceptor {
    constructor(private readonly busyService: BusyService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.busyService.increase();
        return next.handle(req).pipe(finalize(() => this.busyService.decrease()));
    }
}