import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BbCheckboxComponent } from "@app/app/shared/ui/form/bb-checkbox/bb-checkbox.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateModule } from "@ngx-translate/core";

@UntilDestroy()
@Component({
    selector: "bb-enter-reset-account-settings-dialog",
    templateUrl: "./enter-reset-account-settings-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        ReactiveFormsModule,
        BbCheckboxComponent,
        MatTooltipModule,
        MatButtonModule,
    ],
})
export class EnterResetAccountSettingsDialogComponent implements OnInit {
    public readonly formGroup = new FormGroup({
        DeleteOrders: new FormControl(false),
        DeleteCustomers: new FormControl(false),
        DeleteProducts: new FormControl(false),
        DeletePayments: new FormControl(false),
        ResetRules: new FormControl(false),
    });

    public ngOnInit(): void {
        this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            const value = this.formGroup.getRawValue();

            if (value.DeleteCustomers) {
                this.formGroup.controls.DeleteOrders.setValue(true, { emitEvent: false });
                this.formGroup.controls.DeleteOrders.disable({ emitEvent: false });
            } else {
                this.formGroup.controls.DeleteOrders.enable({ emitEvent: false });
            }

            if (value.DeleteOrders || value.DeleteCustomers) {
                this.formGroup.controls.DeletePayments.setValue(true, { emitEvent: false });
                this.formGroup.controls.DeletePayments.disable({ emitEvent: false });
            } else {
                this.formGroup.controls.DeletePayments.enable({ emitEvent: false });
            }
        });
    }
}
