import {AbstractLayoutType, Constructor} from "@mintware-de/form-builder";
import {ExpansionPanelOptions} from "./expansion-panel.options";
import {ExpansionPanelComponent} from "./expansion-panel.component";

export class ExpansionPanel extends AbstractLayoutType<ExpansionPanelOptions> {
    public readonly component: Constructor = ExpansionPanelComponent;
    constructor(opt: ExpansionPanelOptions) {
        super({
            expanded: false,
            ...opt,
        });
    }
}
