import {AfterContentInit, Component} from "@angular/core";
import {AbstractControl, UntypedFormControl} from "@angular/forms";
import {
    AbstractGroupType,
    AbstractLayoutComponent,
    AbstractLayoutType,
    AbstractType,
    IGroupTypeOptions,
} from "@mintware-de/form-builder";
import {TabLayoutOptions} from "./tab-layout.options";

@Component({
    selector: "bb-form-tab-layout",
    template: `
        <mat-tab-group (selectedTabChange)="tabActivated($event.index)">
            <ng-container *ngFor="let tab of mwFieldType.options.tabs; index as index">
                <mat-tab *ngIf="!tab.hide">
                    <ng-template mat-tab-label>
                        <mat-icon
                            [fontIcon]="isTabValid[index] ? '' : 'fa-exclamation-circle'"
                            [inline]="true"
                            color="warn"
                            style="margin-right: 4px"
                        ></mat-icon>
                        {{ tab.title | translate }}
                    </ng-template>

                    <ng-template matTabContent>
                        <ng-container
                            mwFormField
                            [mwFormGroup]="mwFormGroup"
                            [mwElement]="mwElement"
                            [mwFieldType]="mwFieldType.options.model[tab.content]"
                            [mwPath]="fieldPaths[tab.content]"
                            [mwIndex]="indexFromParent"
                        ></ng-container>
                    </ng-template>
                </mat-tab>
            </ng-container>
        </mat-tab-group>
    `,
})
export class TabLayoutComponent
    extends AbstractLayoutComponent<AbstractLayoutType<TabLayoutOptions>>
    implements AfterContentInit
{
    public isTabValid: boolean[] = [];

    public tabActivated(index: number): void {
        const onActivate = this.mwFieldType.options.tabs[index]?.onActivate;
        if (onActivate) {
            onActivate(this.mwFieldType.builderInstance);
        }
    }

    public ngAfterContentInit(): void {
        //@ts-ignore
        super.ngAfterContentInit();
        this.tabActivated(0);
        this.createValidationSubscriptions();
    }

    private createValidationSubscriptions(): void {
        const model = this.mwFieldType.options.model;
        for (let tabIndex = 0; tabIndex < this.mwFieldType.options.tabs.length; tabIndex++) {
            const tab = this.mwFieldType.options.tabs[tabIndex];
            if (!(tab.content in model)) {
                continue;
            }
            const modelElement = model[tab.content];
            const controls = [];
            if (modelElement instanceof AbstractLayoutType) {
                controls.push(...this.getTopLevelFormControls(modelElement));
            } else if (modelElement instanceof AbstractType) {
                controls.push(modelElement.control);
            }
            this.createSubscriptionForSingleTab(tabIndex, controls);
        }
    }

    private createSubscriptionForSingleTab(tabIndex: number, controls: AbstractControl[]): void {
        this.isTabValid[tabIndex] = controls.reduce((res, ctrl) => res && ctrl.valid, true);
        for (const control of controls) {
            control.statusChanges.subscribe(() => {
                this.isTabValid[tabIndex] = controls.reduce((res, ctrl) => res && (ctrl.valid || ctrl.untouched), true);
            });
            this.mwFormGroup.statusChanges.subscribe(() => {
                this.isTabValid[tabIndex] = controls.reduce(
                    (res, ctrl) => res && (ctrl.valid || this.mwFormGroup.untouched),
                    true,
                );
            });
        }
    }

    private getTopLevelFormControls(group: AbstractGroupType<IGroupTypeOptions<any>>): UntypedFormControl[] {
        const controls = [];

        for (const key of Object.keys(group.options.model)) {
            const modelElement = group.options.model[key];
            if (modelElement instanceof AbstractLayoutType) {
                controls.push(...this.getTopLevelFormControls(modelElement));
            } else if (modelElement instanceof AbstractType) {
                controls.push(modelElement.control);
            }
        }

        return controls;
    }
}
