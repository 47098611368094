import {AuthenticationService} from "@bb-core/service";
import {Inject, Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class DefaultAuthenticationService implements AuthenticationService {
    constructor(@Inject("authService") private auth: AuthService,
    ) {
    }

    public async getRoles(): Promise<string[]> {
        const user = await this.auth.userPromise;
        return user.roles;
    }
}
