import {NgIf} from "@angular/common";
import {ChangeDetectionStrategy, Component, OnInit, inject} from "@angular/core";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from "@angular/material/dialog";
import {TranslateModule} from "@ngx-translate/core";
import {BbInputComponent} from "../../../../../shared/ui/form/bb-input/bb-input.component";
import {BbSelectComponent} from "../../../../../shared/ui/form/bb-select/bb-select.component";
import {ShopViewModelDto} from "../../../data/shop-view-model.dto";

@Component({
    selector: "bb-woo-connection-details-dialog",
    templateUrl: "./connection-details-dialog.component.html",
    styleUrls: ["./connection-details-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        ReactiveFormsModule,
        MatButtonModule,
        NgIf,
        BbInputComponent,
        BbSelectComponent,
    ],
})
export class WooCommerceConnectionDetailsDialogComponent implements OnInit {
    public readonly imageUrl = `assets/images/logo/shops/woocommercerest.png`;

    public readonly versionOptions: KeyValuePair<string, number>[] = [
        {Key: "V2", Value: 2},
        {Key: "V3", Value: 3},
    ];

    public readonly form = new FormGroup({
        Url: new FormControl("", Validators.required),
        Key: new FormControl("", Validators.required),
        Secret: new FormControl("", Validators.required),
        ApiVersion: new FormControl(2, Validators.required),
    });

    public readonly data: {hasError: boolean; viewModel: ShopViewModelDto} = inject(MAT_DIALOG_DATA);
    private readonly ref: MatDialogRef<WooCommerceConnectionDetailsDialogComponent> = inject(MatDialogRef);

    ngOnInit(): void {
        this.form.patchValue(this.data.viewModel);
    }

    public save(): void {
        if (this.form.invalid) {
            return;
        }

        this.ref.close(this.form.value);
    }
}
