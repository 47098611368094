import {ShippingProductService} from "../shipping-product-service";
import {RegulatorySetEnum} from "@app/app/shipping/core/entity/service/regulatory-set.enum";

export class DryIceService extends ShippingProductService {
    public IceWeightKg: number = null;
    public regulatorySet: RegulatorySetEnum = RegulatorySetEnum.CFR;
    public medicalProducts: boolean = false;

    constructor(obj?: Partial<DryIceService>) {
        super(obj);
        ctor(this, obj);
    }
}
