import {FormModel} from "@mintware-de/form-builder";
import {CustomerAddress} from "@app/app/customer/core";
import {CustomerAddressFormModel} from "./customer-address.form-model";

export class EditCustomerAddressViewModel {
    public formModel: FormModel<CustomerAddress>;
    public address: CustomerAddress = new CustomerAddress();

    constructor(showAddressTypeField: boolean = false) {
        this.formModel = new CustomerAddressFormModel(showAddressTypeField);
    }
}
