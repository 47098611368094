import {ComponentStatus} from "@app/app/common/service/system-status";

export class SystemStatusComponent {
    public id: string = null;
    public name: string = null;
    public status: ComponentStatus = null;
    public created_at: Date = null;
    public updated_at: Date = null;
    public position: number = null;
    public description: string = null;
    public showcase: boolean = false;
    public start_date: Date = null;
    public group_id: string = null;
    public page_id: string = null;
    public group: boolean = false;
    public only_show_if_degraded: boolean = false;
    public components: string[];

    constructor(obj?: Partial<SystemStatusComponent>) {
        ctor(this, obj, {
            DateFields: ["created_at", "updated_at", "start_date"],
        });
    }
}
