import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: "root"})
export class ChannelSettingsRepository {
	private readonly baseUrl = `${environment.baseUrl}/settings/shop`;

    constructor(private readonly httpClient: HttpClient) {
    }

    public deleteSources(channelId: number): Observable<void> {
		return this.httpClient.post<void>(`${this.baseUrl}/${channelId}/delete-sources`, {});
    }

    public disableSync(channelId: number): Observable<void> {
        return this.httpClient.post<void>(`${this.baseUrl}/${channelId}/disable-sync`, {});
    }
}
