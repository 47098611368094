import {Component, Input} from "@angular/core";
import {TranslationKey} from "@bb-core/entity";

@Component({
    selector: "bb-no-data-container",
    template: `
        <div fxFlex fxLayout="column" fxLayoutAlign="center center">
<!--            <img src="assets/vector/folder_with_files.svg" style="max-width: 300px; width: 100%">-->
<!--            <br>-->
            <div>
                <ng-container>
                    {{ noDataText | translate }}
                </ng-container>
            </div>
        </div>
    `,
})
export class NoDataContainerComponent {
    @Input()
    public noDataText: TranslationKey;
}
