import {LocalizedString, Order} from "@shared/models";

export class MessageTemplateBase {
    public Id: number = null;
    public Created: Date = null;
    public Name?: string = null;
    public Comment?: string = null;
    public IsHtml: boolean = false;
    public ShowEditor: boolean = false;

    constructor(obj?: Partial<MessageTemplateBase>) {
        ctor(this, obj, {
            DateFields: ["Created"],
        });
    }
}

export class MessageTemplate extends MessageTemplateBase {
    public Subject: LocalizedString[] = [];
    public Body: LocalizedString[] = [];

    constructor(obj?: Partial<MessageTemplate>) {
        super(obj);
        ctor(this, obj, {
            Collections: {
                Subject: LocalizedString,
                Body: LocalizedString,
            },
        });
    }
}

export class LocalizedMessageTemplate extends MessageTemplateBase {
    public Subject: string = null;
    public Body: string = null;

    constructor(obj?: Partial<LocalizedMessageTemplate>) {
        super(obj);
        ctor(this, obj);
    }
}
