<div class="description">
	{{ "text.is_overseas_invoice_net_correct" | translate }}
</div>
<div class="choose-button-wrapper">
    <button mat-stroked-button color="primary" (click)="next(true)">
		{{ "button.net_correct" | translate }}
	</button>
    <button mat-stroked-button color="primary" (click)="next(false)">
		{{ "button.billbee_subtract_tax" | translate }}
	</button>
</div>