import {AfterViewInit, Component} from "@angular/core";
import {SystemStatusService} from "@app/app/common/service/system-status";
import {HelpLinkDirective} from "@app/app/common/directive/help-link.directive";

@Component({
    selector: "bb-nav-status-item",
    templateUrl: "./status-item.component.html",
})
export class StatusItemComponent implements AfterViewInit {
    public isLoaded: boolean = false;

    constructor(public readonly systemStatusService: SystemStatusService) {
    }

    public ngAfterViewInit(): void {
        window.setTimeout(() => this.isLoaded = true, 200);
    }

    public openStatusPage(): void {
        window.open(HelpLinkDirective.linkMapping.status_page);
    }
}
