import {VatDetectionFlagsEnum} from "./vat-detection-flags.enum";
import {VatRates} from "./vat-rates";

export class PreSaveOrderResponse {
    public VatDetectionFlags: VatDetectionFlagsEnum = VatDetectionFlagsEnum.IsDirty;
    public CustomerVatId: string = null;
    public MerchantVatId: string = null;
    public VatRates: VatRates = null;

    constructor(obj?: Partial<PreSaveOrderResponse>) {
        ctor(this, obj, {
            Relations: {
                VatRates: VatRates,
            },
        });
    }
}
