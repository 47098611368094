import {ClientStorage} from "@bb-core/storage";
import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class LocalStorageStorage implements ClientStorage {
    public get(key: string): string {
        const item = localStorage.getItem(`ls.${key}`);
        if (item == null || item === "undefined") {
            return null;
        }
        return item;
    }

    public set(key: string, value: string): void {
        const storageKey = `ls.${key}`;
        if (value == null || value === "undefined") {
            localStorage.removeItem(storageKey);
        } else {
            localStorage.setItem(storageKey, value);
        }
    }
}
