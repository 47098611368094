import {Injectable, inject} from "@angular/core";
import {EMPTY, Observable} from "rxjs";
import {Shop} from "../../model/shop";
import {ShopType} from "../../model/shop-type.enum";
import {DefaultShopAddCommand} from "../../service/command/default-shop-add.command";

@Injectable({
    providedIn: "root",
})
export class FlowShop implements Shop {
    public readonly key = "flow";
    public readonly name = "Flow";
	public readonly type = ShopType.Shop;
	public readonly logo = `assets/images/logo/shops/${this.key}.png`;

    private readonly addCommand = inject(DefaultShopAddCommand);

    add(): Observable<never> {
		this.addCommand.execute(this.key);
		return EMPTY;
    }
}
