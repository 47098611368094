import {ArchiveStateEnum} from "@app/app/shipping/core";

export class SearchOrderResult {
    public OrderId: number = null;
    public CustomerNumber: number = null;
    public CustomerName: string = "";
    public ShippingAddress: string = "";
    public ExternalReference: string = "";
    public OrderDate: Date = null;
    public ShipDate?: Date = null;
    public SalesChannel: string = "";
    public ArchiveState: ArchiveStateEnum = ArchiveStateEnum.NotArchived;

    constructor(obj?: Partial<SearchOrderResult>) {
        ctor(this, obj, {
            DateFields: ["OrderDate", "ShipDate"],
            NumberFields: ["OrderId"],
        });
    }
}

