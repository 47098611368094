<mat-toolbar *ngIf="isLoaded" color="primary" fxFlex="none" style="width: 64px; border-left: 1px solid white"
             [ngClass]="(systemStatusService.statusColorClass$ | async)">
    <mat-toolbar-row style="padding: 0" fxLayout="row" fxLayoutAlign="center center">
        <button mat-icon-button
                type="button"
                [matTooltip]="'Status' | translate"
                [matMenuTriggerFor]="statusMenu">
            <mat-icon [fontIcon]="systemStatusService.statusIcon$ | async"></mat-icon>
        </button>
        <mat-menu #statusMenu="matMenu">
            <mat-list style="padding-top: 0 !important;" (click)="$event.stopPropagation()">
                <ng-container *ngIf="(systemStatusService.statusAsString$ | async) == 'ok'; else statusList">
                    <mat-list-item>
                        <mat-icon mat-list-icon fontIcon="fa-smile"></mat-icon>
                        <h4 mat-line>{{ 'Alle Systeme laufen auf Hochtouren.' | translate }}</h4>
                    </mat-list-item>
                </ng-container>
                <mat-divider></mat-divider>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" class="margin-half">
                    <button (click)="openStatusPage()" type="button"
                            mat-raised-button>{{ 'Weitere Informationen' | translate}}</button>
                </div>
            </mat-list>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>

<ng-template #statusList>
    <ng-container *ngFor="let incident of (systemStatusService.incidents$ | async)">
        <mat-list-item *ngFor="let component of incident.components" [ngClass]="
            incident.impact === 'maintenance' ? 'fill-info-light' 
            : incident.impact === 'minor' ? 'fill-warning-light'
            : (incident.impact === 'major' || incident.impact === 'critical') ? 'fill-error-light'
            : ''
">
            <mat-icon mat-list-icon [matTooltip]="incident.name" [fontIcon]="
            (component.status === 'maintenance' || component.status === 'under_maintenance') ? 'fa-tools' 
            : (component.status === 'partial_outage' || component.status === 'degraded_performance') ? 'fa-exclamation-circle'
            : component.status === 'major_outage' ? 'fa-times-circle'
            : ''
        "></mat-icon>
            <h4 mat-line>{{ component.name | translate }}</h4>
            <span mat-line>{{ component.description | translate }}</span>
            <span mat-line>{{
                ((component.status === 'maintenance' || component.status === 'under_maintenance') ? 'Wartung'
                        : component.status === 'partial_outage' ? 'Teilweiser Ausfall'
                            : (component.status === 'degraded_performance') ? 'Performance Probleme'
                                : component.status === 'major_outage' ? 'Vollständiger Ausfall'
                                    : ''
                ) | translate }}</span>
        </mat-list-item>
        <div style="height: 4px"></div>
    </ng-container>
</ng-template>