import {NgModule} from "@angular/core";
import {BbModule} from "@app/app/common/module/bb.module";
import {SharedModule} from "@app/app/common/module/shared.module";
import {DataPageComponent} from "../../shared/ui/data-page/data-page.component";
import {BbSelectComponent} from "../../shared/ui/form/bb-select/bb-select.component";
import {ListItemCardComponent} from "../../shared/ui/list-item-card/list-item-card.component";
import {ShopsRepository} from "./data/shop.repository";
import {ShopSettingsOutletComponent} from "./presentation/shop-settings-outlet.component";
import {ShopifyInitDialogComponent} from "./presentation/shopify-init-dialog/shopify-init-dialog.component";
import {ShopSettingsRouterModule} from "./shop-settings-router.module";

@NgModule({
    declarations: [
        ShopSettingsOutletComponent,
        ShopifyInitDialogComponent,
    ],
    imports: [
        BbModule,
        SharedModule,
        ShopSettingsRouterModule,
		BbSelectComponent,
		DataPageComponent,
		ListItemCardComponent,
	],
	providers: [ShopsRepository],
})
export class ShopSettingsModule {
}
