import {AbstractType, Constructor} from "@mintware-de/form-builder";
import {TextComponent} from "./text.component";
import {TextOptions} from "./text.options";

export class Text<T> extends AbstractType<TextOptions> {
    public readonly component: Constructor = TextComponent;

    constructor(opts: TextOptions) {
        super({
            builder: (x) => x,
            ...opts,
        });
    }
}
