import {Constructor} from "@mintware-de/form-builder";
import {TagsFieldOptions} from "./tags-field.options";
import {TagsFieldComponent} from "./tags-field.component";
import {BaseType} from "@app/app/common/form/base/base.type";

export class TagsField extends BaseType<TagsFieldOptions> {
    public readonly component: Constructor = TagsFieldComponent;

    constructor(obj: TagsFieldOptions) {
        super({
            required: false,
            ...obj,
        });
    }
}
