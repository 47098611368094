import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { ControlContainer, FormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { formViewProvider } from "@app/app/shared/provider/form-view.provider";
import { BbCheckboxComponent } from "@app/app/shared/ui/form/bb-checkbox/bb-checkbox.component";
import { BbInputComponent } from "@app/app/shared/ui/form/bb-input/bb-input.component";
import { BbSelectComponent } from "@app/app/shared/ui/form/bb-select/bb-select.component";
import { TranslateModule } from "@ngx-translate/core";
import { filter, map, of, startWith, switchMap, take } from "rxjs";
import { ShopifyRepository } from "../shopify.repository";

@Component({
    selector: "bb-shopify-general-form",
    templateUrl: "./shopify-general-form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [formViewProvider],
    standalone: true,
    imports: [FormsModule, TranslateModule, BbInputComponent, MatTooltipModule, BbCheckboxComponent, BbSelectComponent],
})
export class ShopifyGeneralFormComponent {
    private readonly controlContainer = inject(ControlContainer);
    private readonly shopifyRepository = inject(ShopifyRepository);

    readonly stockLocationOptions = toSignal(
        this.controlContainer.control?.valueChanges.pipe(
            map(() => this.controlContainer.control?.getRawValue()),
            startWith(this.controlContainer.control?.getRawValue()),
            filter(({ ShopName, ShopifyAccessToken }) => !!ShopName && !!ShopifyAccessToken),
            take(1),
            switchMap(({ ShopName, ShopifyAccessToken }) =>
                this.shopifyRepository.getStockLocations(ShopifyAccessToken, ShopName),
            ),
            map(items => [{ Value: null, Key: "-" }, ...items.map(({ Id, name }) => ({ Value: Id, Key: name }))]),
        ) ?? of([]),
    );
}
