import {Inject, Injectable} from "@angular/core";
import {saveAs} from "file-saver";


@Injectable({providedIn: "root"})
export class PDFHelperService {
    constructor(@Inject("$alert") private Alert: AlertService,
                @Inject("$translate") private $translate: ng.translate.ITranslateService) {
    }

    /**
     * downloads a pdf file from server via http get and opens download dialog on the users device.
     */
    public DownloadFileAsBlob(url: string): void {
        if (url === null) {
            const title = this.$translate.instant("Datei kann nicht heruntergeladen werden.");
            const message = this.$translate.instant("Es wurde keine Url angegeben.");
            return this.Alert.failure(title, message);
        }

        saveAs(url);
    }
}
