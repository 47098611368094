<button
        mat-icon-button
        type="button"
        aria-label="Benachrichtigungen"
        [matMenuTriggerFor]="notificationMenu">
    <span class="bubble"></span>
    <mat-icon [fontIcon]="bellIcon"></mat-icon>
</button>
<mat-menu #notificationMenu="matMenu">
    <mat-list style="padding-top: 0 !important;" (click)="$event.stopPropagation()">
        <ng-container *ngIf="!hasNotifications; else notificationList">
            <mat-list-item>
                <mat-icon mat-list-icon fontIcon="fa-smile"></mat-icon>
                <h4 mat-line>{{ 'Hier gibt es gerade nichts zu tun' | translate }}</h4>
            </mat-list-item>
        </ng-container>
    </mat-list>
</mat-menu>
<ng-template #notificationList>
    <ng-container *ngIf="newNotifications.length > 0">
        <div mat-subheader class="no-bottom-padding" fxLayout="row">
            <span fxFlex="nogrow">{{ 'Neu' | translate }}</span>
            <span fxFlex></span>

            <button mat-icon-button (click)="markAllAsRead()" fxFlex="nogrow"
                    [matTooltip]="'Alle als gelesen markieren' |translate">
                <mat-icon fontIcon="fa-check-double"></mat-icon>
            </button>

            <ng-container *ngTemplateOutlet="sysEventsButton"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="listItemTemplate; context: {'notifications': newNotifications, 'hasMore': numberOfMoreNewNotifications}">
        </ng-container>
    </ng-container>
    <ng-container *ngIf="sysNotifications.length > 0">
        <div mat-subheader class="no-bottom-padding" fxLayout="row">
            <span fxFlex="nogrow">{{ 'System' | translate }}</span>
            <span fxFlex></span>
            <ng-container *ngIf="newNotifications.length == 0">
                <ng-container *ngTemplateOutlet="sysEventsButton"></ng-container>
            </ng-container>
        </div>
        <ng-container *ngTemplateOutlet="listItemTemplate; context: {'notifications': sysNotifications}">
        </ng-container>
    </ng-container>
    <ng-container *ngIf="oldNotifications.length > 0">
        <div mat-subheader class="no-bottom-padding" fxLayout="row">
            <span fxFlex="nogrow">{{ 'Gelesen' | translate }}</span>
            <span fxFlex></span>
            <ng-container *ngIf="newNotifications.length == 0 && sysNotifications.length == 0">
                <ng-container *ngTemplateOutlet="sysEventsButton"></ng-container>
            </ng-container>
        </div>
        <ng-container *ngTemplateOutlet="listItemTemplate; context: {'notifications': oldNotifications, 'hasMore': numberOfMoreOldNotifications}">
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #listItemTemplate let-notifications="notifications" let-hasMore="hasMore">
    <div class="margin"
         fxLayout="row"
         fxLayoutAlign="start stretch"
         *ngFor="let n of notifications; index as index">

        <div class="mat-icon-button">
            <div class="mat-button-wrapper">
                <mat-icon [fontIcon]="(n.Type == 1 || n.Type == 3 || n.Type == 4 ? 'fa-exclamation-circle' : 'fa-info-circle')">
                </mat-icon>
            </div>
        </div>

        <mat-list-item class="no-wrap" fxFlex>
            <h4 mat-line [innerHTML]="n.Title"></h4>
            <p class="highlight-links" mat-line [innerHTML]="n.Message"></p>
        </mat-list-item>

        <div fxLayout="column" fxLayoutAlign="space-between end">
            <div class="notification-tile-date">{{ n.Created | date:'dd.MM.yy'}}</div>
            <button mat-icon-button color="primary" *ngIf="!n.Viewed && (n.Type == 0 || n.Type == 1)" (click)="markAsRead(n.Id)">
                <mat-icon fontIcon="fa-check"></mat-icon>
            </button>
        </div>
    </div>
    <ng-container *ngIf="hasMore > 0">
        <div mat-subheader class="no-bottom-padding" fxLayout="row">
            <span>{{ 'text.has_more_notifications' | translate:{count: hasMore} }}</span>
        </div>

    </ng-container>
</ng-template>

<ng-template #sysEventsButton>
    <button mat-icon-button routerLink="/report/events" fxFlex="nogrow"
            [matTooltip]="'Alle Ereignisse' |translate">
        <mat-icon fontIcon="fa-list-alt"></mat-icon>
    </button>
</ng-template>
