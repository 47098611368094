import {AbstractLayoutType, Constructor, IGroupTypeOptions} from "@mintware-de/form-builder";
import {SubheadingOptions} from "./subheading.options";
import {SubheadingComponent} from "./subheading.component";

export class Subheading extends AbstractLayoutType<SubheadingOptions & IGroupTypeOptions> {
    public readonly component: Constructor = SubheadingComponent;

    constructor(opts: SubheadingOptions) {
        super({
            model: {},
            ...opts,
        });
    }
}
