import {Constructor} from "@mintware-de/form-builder";
import {HtmlInputComponent} from "./html-input.component";
import {HtmlInputOptions} from "./html-input.options";
import {BaseType} from "@app/app/common/form/base/base.type";

export class HtmlInput extends BaseType<HtmlInputOptions> {
    public readonly component: Constructor = HtmlInputComponent;

    constructor(opts: HtmlInputOptions) {
        super({
            showPlaceholdersLink: false,
            useHtml: false,
            useEditor: false,
            ...opts,
        });
    }
}
