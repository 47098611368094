import {DownloadService} from "@bb-core/service";
import {Injectable} from "@angular/core";
import {saveAs} from "file-saver";

@Injectable({providedIn: "root"})
export class BlobDownloadService implements DownloadService {

    public downloadFileByKey(fileKey: string): Promise<void> {
        return this.downloadFileByUrl(`/Application/Download?key=${fileKey}`);
    }

    public async downloadBlob(blob: Blob): Promise<void> {
        saveAs(blob);
    }

    public async downloadFileByUrl(url: string): Promise<void> {
        saveAs(url);
    }
}
