<h3 mat-dialog-title>{{ 'title.authorize_access_to_amazon' | translate }}</h3>
<button class="close-icon" [mat-dialog-close]="true">
    <i class="fas fa-times"></i>
</button>

<div *ngIf="state == States.START">
    <mat-dialog-content>
        <p [innerHtml]="'text.amazon_scpn_auth_dialog.start' | translate">
        </p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button
                (click)="changeState(States.REAUTHORIZE)">{{"button.amazon_scpn_auth_dialog.re_authorization"|translate}}</button>
        <button mat-stroked-button
                (click)="changeState(States.CREATE_CONNECTION)">{{"button.amazon_scpn_auth_dialog.new_connection"|translate}}</button>
    </mat-dialog-actions>
</div>

<div *ngIf="state == States.REAUTHORIZE">
    <mat-dialog-content>
        <p [innerHtml]="'text.amazon_scpn_auth_dialog.reauthorize' | translate : { merchantId : merchantId }">
        </p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button (click)="changeState(States.START)">{{"button.back"|translate}}</button>
        <button mat-flat-button color="primary" (click)="reauthorize()">{{"button.go"|translate}}</button>
    </mat-dialog-actions>
</div>

<div *ngIf="state == States.REAUTHORIZE_IN_PROGRESS" class="reauthorize_in_progress">
    <mat-dialog-content>
        <p [innerHtml]="'text.amazon_scpn_auth_dialog.reauthorizing_connections' | translate"></p>
        <div>
            <mat-progress-spinner mode="indeterminate" [diameter]="50"></mat-progress-spinner>
        </div>
        <p>{{reauthProgressMessage}}</p>
    </mat-dialog-content>
</div>

<div *ngIf="state == States.REAUTHORIZE_RESULT" class="reauthorize_result">
    <mat-dialog-content>
        <ng-container *ngIf="reauthResultState == ReautResultStates.NO_CONNECTIONS">
            <p  [innerHtml]="'text.amazon_scpn_auth_dialog.reauthorize_result.no_connections' | translate"></p>
        </ng-container>
        <ng-container *ngIf="reauthResultState == ReautResultStates.SUCCESS">
            <p  [innerHtml]="'text.amazon_scpn_auth_dialog.reauthorize_result.success' | translate"></p>
            <p><strong>{{"text.amazon_scpn_auth_dialog.reauthorize_result.shop_connections" | translate}}:</strong></p>
            <ul>
                <li *ngFor="let connectionName of this.reauthResult.ReauthorizedShopConnections">
                    {{connectionName}}
                </li>
                <li *ngIf="0 >= this.reauthResult.ReauthorizedShopConnections.length">({{"text.amazon_scpn_auth_dialog.reauthorize_result.none" | translate}})</li>
            </ul>
            <p><strong>{{"text.amazon_scpn_auth_dialog.reauthorize_result.fulfillment_connections" | translate}}:</strong></p>
            <ul>
                <li *ngFor="let connectionName of this.reauthResult.ReauthorizedFulfillmentConnections">
                    {{connectionName}}
                </li>
                <li *ngIf="0 >= this.reauthResult.ReauthorizedFulfillmentConnections.length">({{"text.amazon_scpn_auth_dialog.reauthorize_result.none" | translate}})</li>
            </ul>
            <p><strong>{{"text.amazon_scpn_auth_dialog.reauthorize_result.payment_connections" | translate }}:</strong></p>
            <ul>
                <li *ngFor="let connectionName of this.reauthResult.ReauthorizedPaymentImportConnections">
                    {{connectionName}}
                </li>
                <li *ngIf="0 >= this.reauthResult.ReauthorizedPaymentImportConnections.length">({{"text.amazon_scpn_auth_dialog.reauthorize_result.none" | translate}})</li>
            </ul>
        </ng-container>
        <ng-container *ngIf="reauthResultState == ReautResultStates.FAILED">
            <p [innerHtml]="'text.amazon_scpn_auth_dialog.reauthorize_result.failed' | translate"></p>
        </ng-container>

    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-flat-button color="primary" (click)="close()">{{ "button.ok" | translate}}</button>
    </mat-dialog-actions>
</div>

<div *ngIf="state == States.CREATE_CONNECTION" class="create_connection">
    <mat-dialog-content>
        <p [innerHtml]="'text.amazon_scpn_auth_dialog.create_connection' | translate">
        </p>
        <mat-radio-group [(ngModel)]="connectionType">
            <ng-container *ngFor="let connectionType of connectionTypes">
                <mat-radio-button [value]="connectionType.value">
                    <strong>{{connectionType.displayText | translate }}</strong>
                </mat-radio-button>
                <p>{{connectionType.description | translate }}</p>
            </ng-container>
        </mat-radio-group>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button (click)="changeState(States.START)">{{"button.back"|translate}}</button>
        <button mat-flat-button color="primary" (click)="createConnectionAfterConnectionTypeSelection()">{{"button.go"|translate}}</button>
    </mat-dialog-actions>
</div>

<div *ngIf="state == States.SELECT_MARKETPLACE" class="select_marketplace">
    <mat-dialog-content>
        <p [innerHtml]="'text.amazon_scpn_auth_dialog.select_marketplace' | translate">
        </p>
        <mat-form-field appearance="fill">
            <mat-label>Amazon Marktplatz</mat-label>
            <mat-select [(ngModel)]="amazonMarketplace">
                <mat-option *ngFor="let amazonMarketplace of amazonMarketplaces" [value]="amazonMarketplace.id">
                    {{amazonMarketplace.displayName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button (click)="changeState(States.START)">{{"button.back"|translate}}</button>
        <button mat-flat-button color="primary" (click)="createConnectionAfterMarketplaceSelection()">{{"button.go"|translate}}</button>
    </mat-dialog-actions>
</div>

