<h2 mat-dialog-title class="">{{"Willkommen bei Billbee!"|translate}}</h2>
<mat-dialog-content><p>Um dir den Start mit Billbee so einfach wie möglich zu gestalten, hier ein paar Tipps zum Einstieg:</p>
    <ul>
        <li>In unserem <a helpLink="help" class="primary">Hilfe-Bereich</a> findest du Anleitungen zu vielen Themen.</li>
        <li>Um den Schutz deines Kontos zu erhöhen empfehlen wir dir die <a routerLink="/account/security" class="primary" mat-dialog-close="true">Zwei-Faktor-Authentifizierung</a>
            zu aktivieren.
        </li>
    </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ 'Schließen' | translate }}</button>
</mat-dialog-actions>