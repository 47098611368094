import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CustomerImportSettings, FileFormat } from "@app/app/customer/core";
import { FormBuilderComponent } from "@mintware-de/form-builder";
import { ImportCustomersViewModel } from "./import-customers.view-model";

@Component({
    selector: "bb-import-customers-dialog",
    template: `
        <h4 mat-dialog-title>{{ 'title.import_customers' | translate }}</h4>
        <mat-dialog-content>
            <p>
                <mw-form-builder #importForm
                                 [mwFormModel]="viewModel.formModel"
                                 [mwFormData]="viewModel.importSettings"
                                 (mwFormSubmit)="closeDialog($event)"
                >
                </mw-form-builder>
            </p>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button type="button" mat-flat-button
                    [mat-dialog-close]="null">{{ 'button.cancel' | translate }}</button>
            <button type="button" mat-raised-button color="primary"
                    (click)="importForm.submit()">{{ 'button.import' | translate }}</button>
        </mat-dialog-actions>
    `,
})
export class ImportCustomersComponent {
    @ViewChild("importForm", {static: true})
    public form: FormBuilderComponent = null;

    public readonly viewModel: ImportCustomersViewModel = new ImportCustomersViewModel();

    constructor(private readonly dialogRef: MatDialogRef<ImportCustomersComponent>,
                @Inject(MAT_DIALOG_DATA) private readonly data: {
                    currentSettings: CustomerImportSettings,
                    fileFormats: FileFormat[],
                    showAppliedFilterOption: boolean,
                    showSelectedCustomersOption: boolean,
                }) {
        this.viewModel.importSettings = data.currentSettings;
        this.viewModel.fileFormatField.reportChanges(data.fileFormats.map(f => ({text: f.Name, value: f.Value})));
    }

    public closeDialog(data: CustomerImportSettings): void {
        this.dialogRef.close(new CustomerImportSettings(data));
    }
}
