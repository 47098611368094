import {Injectable} from "@angular/core";
import {AddressRepository} from "../repository";
import {ShippingInteractionService} from "../service";
import {ErrorHandlerService} from "@bb-core/service";
import {Address} from "../entity";

export class PickAddressRequest {
    public CustomerId: number = null;
    public CurrentAddressId: number = null;
    public OrderId?: number = null;

    constructor(obj?: Partial<PickAddressRequest>) {
        ctor(this, obj);
    }
}

export abstract class PickAddressPresenter {
    /// Is called when the address was updated.
    public abstract displayPickedAddress(address: Address): void;
}

@Injectable({providedIn: "root"})
export class PickAddressUseCase implements IUseCase<PickAddressRequest, PickAddressPresenter> {

    constructor(private readonly addressRepository: AddressRepository,
                private readonly interactionService: ShippingInteractionService,
                private readonly errorHandler: ErrorHandlerService,
    ) {
    }

    public async execute(request: PickAddressRequest,
                         presenter?: PickAddressPresenter,
    ): Promise<void> {
        const customerId = request.CustomerId;
        if (customerId == null) {
            return;
        }

        const addresses = await this.getCustomerAddresses(customerId);
        const address = await this.interactionService.pickAddress(
            customerId,
            addresses,
            request.OrderId,
            request.CurrentAddressId,
        );
        if (address == null) {
            return;
        }
        presenter?.displayPickedAddress(address);
    }

    private async getCustomerAddresses(customerId: number): Promise<Address[]> {
        try {
            return await this.addressRepository.getForCustomer(customerId);
        } catch (e) {
            await this.errorHandler.handleException(e, "text.error_loading_addresses", true);
            throw e;
        }
    }
}
