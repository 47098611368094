<h3 mat-dialog-title>{{ 'title.add_shopify_shop' | translate }}</h3>
<mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="16px">
        <button type="button" mat-flat-button color="primary" (click)="openLink(appLink)">
            {{ 'button.add_existing_shopify_shop' | translate }}
        </button>
        <button type="button" mat-flat-button color="primary" (click)="openLink(affiliateLink)">
            {{ 'button.add_new_shopify_shop' | translate }}
        </button>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
    <button type="button" mat-flat-button [matDialogClose]="null">
        {{ 'button.cancel' | translate }}
    </button>
</mat-dialog-actions>