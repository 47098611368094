import {Injectable} from "@angular/core";
import {Preset, SavePresetPresenter, SavePresetRequest, SavePresetUseCase} from "@app/app/grid/core";

@Injectable({providedIn: "any"})
export class SaveAsNewPresetUseCase<T = any> implements IUseCase<SavePresetRequest, SavePresetPresenter<T>> {

    constructor(private readonly savePresetUseCase: SavePresetUseCase<T>,
    ) {
    }

    public async execute(request: SavePresetRequest,
                         presenter?: SavePresetPresenter<T>,
    ): Promise<void> {
        const preset = new Preset({
            ...JSON.parse(JSON.stringify(request.preset)),
            Id: (Math.random() + 1).toString(36).substring(7),
            IsSystemPreset: false,
        });
        const allPresets = [...request.allPresets, preset];
        await this.savePresetUseCase.execute(new SavePresetRequest({preset, allPresets}), presenter);
    }
}
