import { createReducer, on } from "@ngrx/store";
import { deleteAccountSuccess, loadAccountSuccess, loadHelpscoutBeaconsSettingSuccess, setHelpscoutBeaconsSettingSuccess } from "./account.actions";

export interface AccountState {
	Id: string;
	ProfileId: number;
    UserName: string;
	FullName: string;
    IsNewCustomer: boolean;
	MailAddress: string;
    IsMasterUser: boolean;
    MasterUserId: string | undefined;
    InstanceId: string;
    Roles: string[];
    HelpscoutBeaconsEnabled: boolean;
}

export const initialAccountState: AccountState = {
	Id: "",
	ProfileId: 0,
    UserName: "",
	FullName: "",
	IsNewCustomer: false,
	MailAddress: "",
    IsMasterUser: false,
    MasterUserId: undefined,
    InstanceId: "",
    Roles: [],
    HelpscoutBeaconsEnabled: true,
};

export const accountReducer = createReducer(
    initialAccountState,

    on(loadAccountSuccess, (state, {account}) => ({
		...state,
		Id: account.userId,
		ProfileId: account.profileId,
		UserName: account.userName,
		FullName: account.fullName,
		IsNewCustomer: account.isNewCustomer,
		MailAddress: account.email,
        IsMasterUser: account.isMasterUser,
        MasterUserId: account.masterUserId,
        InstanceId: account.instanceName,
		Roles: account.roles,
    })),
    
    on(loadHelpscoutBeaconsSettingSuccess, setHelpscoutBeaconsSettingSuccess, (state, { isEnabled }) => ({
        ...state,
        HelpscoutBeaconsEnabled: isEnabled
    })),

    on(deleteAccountSuccess, () => Object.assign({}, initialAccountState)),
);
