import {Injectable} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject, Subscription} from "rxjs";

export class ActiveRouteState extends Subscription {

    public active$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    protected subscriptions: Subscription[] = [];

    public queryParams: any = {};

    constructor(private route: ActivatedRoute,
                private router: Router,
    ) {
        super(() => this.subscriptions.forEach((s) => s.unsubscribe()));
        this.subscriptions.push(router.events.subscribe(() => {
            const originalUrl = "/" + route.snapshot.pathFromRoot.map(x => x.url.toString()).filter(x => x).join("/").toString();
            const currentUrl = this.router.url.toString().split("?")[0].toString();
            this.active$.next(originalUrl === currentUrl);
        }));

        this.subscriptions.push(route.queryParams.subscribe((params) => {
            this.queryParams = params || {};
        }));
    }
}

@Injectable({providedIn: "root"})
export class ActiveRouteService {
    constructor(private readonly router: Router) {
    }
    public getState(route: ActivatedRoute): ActiveRouteState {
        return new ActiveRouteState(route, this.router);
    }
}
