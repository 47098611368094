import {Component, Inject, OnInit, Optional, ViewChild} from "@angular/core";
import {MessageTemplateFormViewModel} from "@app/app/settings/automation/presentation/message-templates/form/form.view-model";
import {ActivatedRoute, Router} from "@angular/router";
import {MessageTemplateRepository} from "@app/app/settings/automation/core/repository";
import {LocalizedMessageTemplate, MessageTemplate} from "@app/app/settings/automation/core";
import {FormBuilderComponent} from "@mintware-de/form-builder";
import {LocalizedString} from "@shared/models";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LanguageService} from "@app/app/common/service/da/language.service";
import {MessageTemplateListComponent} from "../list/list.component";
import {ErrorHandlerService, Message, MessagingService} from "@bb-core/service";

@Component({
    selector: "bb-message-template-form",
    templateUrl: "./form.component.html",
})
export class MessageTemplateFormComponent implements OnInit {
    public readonly viewModel: MessageTemplateFormViewModel = new MessageTemplateFormViewModel();
    public readonly fromDialog: boolean = false;
    private messageTemplateId: number = null;

    @ViewChild("messageTemplateForm")
    public messageTemplateForm: FormBuilderComponent;

    constructor(private readonly route: ActivatedRoute,
                private readonly router: Router,
                private readonly messageTemplateRepository: MessageTemplateRepository,
                private readonly languageService: LanguageService,
                @Inject("$translate") private $translate: ng.translate.ITranslateService,
                @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: { id: number },
                @Optional() private dialogRef: MatDialogRef<MessageTemplateFormComponent>,
                private readonly messaging: MessagingService,
                private readonly errorHandlerService: ErrorHandlerService,

    ) {
        this.fromDialog = dialogData != null;
        this.messageTemplateId = dialogData?.id;
        this.viewModel.availableLanguages$ = languageService.query().then((res) => {
            this.viewModel.allLanguages = res.AllLanguages ?? [];
            return res;
        });
    }

    public ngOnInit(): void {
        let isCopy = false;
        this.route.queryParams.subscribe(async (params) => {
            if ("copyfrom" in params) {
                this.messageTemplateId = params.copyfrom;
                isCopy = true;
            }
        });

        this.route.params.subscribe(async (params) => {
            if ("id" in params) {
                this.messageTemplateId = params.id;
            }
            if (this.messageTemplateId == null) {
                this.viewModel.messageTemplate = new MessageTemplate();
            } else {
                this.viewModel.messageTemplate = await this.messageTemplateRepository.getSingle(this.messageTemplateId);
            }
            if (isCopy) {
                this.viewModel.messageTemplate.Id = null;
            }
            this.viewModel.htmlField.options.useHtml = this.viewModel.messageTemplate.IsHtml;
            this.viewModel.htmlField.options.useEditor = this.viewModel.messageTemplate.ShowEditor;

            const languages = await this.viewModel.availableLanguages$;
            this.flipLanguage((languages.DefaultLanguage ?? "DE").toUpperCase());
        });
    }

    public flipLanguage(language: string) {
        const isInitialFlip = this.viewModel.localizedMessageTemplate == null;

        if (!isInitialFlip) {
            const localizedMessageTemplate = new LocalizedMessageTemplate(this.messageTemplateForm.group.value);
            this.updateFromLocalizedMessageTemplate(this.viewModel.currentLanguage, localizedMessageTemplate);
        }

        this.viewModel.localizedMessageTemplate = new LocalizedMessageTemplate({
            ...this.viewModel.messageTemplate,
            Subject: this.viewModel.messageTemplate.Subject.find(e => e.LanguageCode === language)?.Text,
            Body: this.viewModel.messageTemplate.Body.find(e => e.LanguageCode === language)?.Text,
        });

        this.viewModel.currentLanguage = language;
    }

    private updateFromLocalizedMessageTemplate(language: string, template: LocalizedMessageTemplate): void {
        let currentSubject = this.viewModel.messageTemplate.Subject.find(e => e.LanguageCode === language);
        if (currentSubject == null) {
            currentSubject = new LocalizedString({
                LanguageCode: language,
            });
            this.viewModel.messageTemplate.Subject.push(currentSubject);
        }

        let currentBody = this.viewModel.messageTemplate.Body.find(e => e.LanguageCode === language);
        if (currentBody == null) {
            currentBody = new LocalizedString({
                LanguageCode: language,
            });
            this.viewModel.messageTemplate.Body.push(currentBody);
        }

        this.viewModel.messageTemplate.Name = template.Name;
        this.viewModel.messageTemplate.Comment = template.Comment;
        currentSubject.Text = template.Subject;
        currentBody.Text = template.Body;
    }

    public async submit(formData: LocalizedMessageTemplate): Promise<void> {
        if (this.messageTemplateForm.group.disabled) {
            return;
        }
        const localizedMessageTemplate = new LocalizedMessageTemplate(formData);
        this.updateFromLocalizedMessageTemplate(this.viewModel.currentLanguage, localizedMessageTemplate);
        this.viewModel.messageTemplate.IsHtml = this.viewModel.htmlField.options.useHtml;

        try {
            this.messageTemplateForm.group.disable();
            await this.messageTemplateRepository.update(this.viewModel.messageTemplate);
            this.messaging.showMessage(new Message({
                title: this.$translate.instant("Erfolgreich gespeichert"),
                message: this.$translate.instant("Der Benachrichtigungstext wurde gespeichert"),
                isBlocking: false,
            }));

            MessageTemplateListComponent.refreshListEmitter.emit();
            this.cancel().then();
        } catch (e) {

            // Custom error output for HTML sanitization errors
            if ("ErrorMessage" in e.body
                && e.body.ErrorMessage !== null
                && typeof e.body.ErrorMessage === "string"
                && e.body.ErrorMessage.includes("unerlaubte HTML-Elemente"))
            {
                this.errorHandlerService.handleException(e, null, true, {}, "title.validation_of_email_template_failed", true, false);
                return;
            }

            this.errorHandlerService.handleException(e);

        } finally {
            this.messageTemplateForm.group.enable();

        }


    }

    public async cancel(): Promise<void> {
        if (this.fromDialog) {
            this.dialogRef.close();
        } else {
            await this.router.navigateByUrl("/settings/automation/message-templates");
        }
    }

    public preSendTestMail(): { template: MessageTemplate, currentLanguage: string } {
        const localizedMessageTemplate = new LocalizedMessageTemplate(this.messageTemplateForm.group.value);
        this.updateFromLocalizedMessageTemplate(this.viewModel.currentLanguage, localizedMessageTemplate);
        return {template: this.viewModel.messageTemplate, currentLanguage: this.viewModel.currentLanguage};
    }
}
