import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { AbstractFormFieldComponent, AbstractType } from "@mintware-de/form-builder";
import { Subscription } from "rxjs";
import { SwitchOptions } from "./switch.options";

@Component({
    selector: "bb-form-switch",
    template: `
        <div class="mat-form-field"
             [hidden]="!mwFieldType.options.visible"
             [ngStyle]="{paddingBottom: mwFieldType.options.bottomPadding}">
            <mat-slide-toggle color="primary"
                              [name]="mwPath"
                              [formControl]="mwElement"
                              [matTooltip]="mwFieldType.options.tooltip | translate"
                              (change)="mwFieldType.options.onChange ? mwFieldType.options.onChange($event, mwFieldType.builderInstance.group, this) : null">
                <span [innerHTML]="mwFieldType.options.label | translateLabel"></span>
            </mat-slide-toggle>
            <mat-hint>{{ mwFieldType.options.hint | translate }}</mat-hint>
            <mat-error [hidden]="statusChanges <= 1 || !mwElement.errors || !mwElement.errors.required">
                {{'Bitte diesen Schalter aktivieren.' | translate }}
            </mat-error>
        </div>
    `,
})
export class SwitchComponent extends AbstractFormFieldComponent<AbstractType<SwitchOptions>>
    implements AfterViewInit, OnDestroy {
    private readonly subscriptions: Subscription[] = [];

    public statusChanges: number = 0;

    constructor(private readonly cdr: ChangeDetectorRef) {
        super();
    }
F
    public ngAfterViewInit(): void {
        this.subscriptions.push(this.mwElement.statusChanges.subscribe((_) => {
            // This hack is ugly. The mat-slide-toggle validates immediately after the view rendered.
            this.statusChanges += 1;
            this.cdr.detectChanges();
        }));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
