export abstract class NavigationService {

    /// Navigates to shipping
    public abstract navigateToShipping(): Promise<boolean>;

    /// Navigates to ship single.
    public abstract navigateToShipSingle(orderId: number): Promise<boolean>;

    /// Navigates to the list of cloud storages.
    public abstract navigateToCloudStorages(): Promise<boolean>;

    /// Navigates to the list of customers.
    public abstract navigateToCustomerList(): Promise<boolean>;

    /// Navigates to the edit customer form.
    public abstract navigateToEditCustomer(customerId: number): Promise<boolean> ;

    /// Navigates to the account deleted landing page.
    public abstract navigateToBillbeeAccountDeleted(hintText: string): Promise<boolean>;
}
