import {LayoutOptions} from "./flex-layout.options";
import {FlexLayout} from "@app/app/common/form/layout/flex-layout/flex-layout.type";
import {IGroupTypeOptions} from "@mintware-de/form-builder";

export class RowLayout<T> extends FlexLayout<T> {
    constructor(options: IGroupTypeOptions<T> & LayoutOptions) {
        super({
            direction: "row",
            alignMainAxis: "start",
            alignCrossAxis: "stretch",
            debug: false,
            dense: false,
            ...options,
        });
    }
}
