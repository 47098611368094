import {inject, Injectable} from "@angular/core";
import {OAuthService} from "@app/app/oauth/core";
import {from, Observable} from "rxjs";
import {switchMap} from "rxjs/operators";
import {ShopViewModelDto} from "../../data/shop-view-model.dto";
import {ShopsRepository} from "../../data/shops.repository";
import {Shop} from "../../model/shop";
import {ShopType} from "../../model/shop-type.enum";
import {GetOAuthViewModelCommand} from "../../service/command/get-oauth-view-model.command";
import {OauthShopAddCommand} from "../../service/command/oauth-shop-add.command";
import {ReauthShopCommand} from "../../service/command/reauth-shop.command";
import {ShopsService} from "../../service/shops.service";

@Injectable({
    providedIn: "root",
})
export class EtsyShop implements Shop {
    public readonly key = "etsy_v3";
    public readonly name = "Etsy";
    public readonly type = ShopType.Marketplace;
	public readonly featured = true;
	public readonly logo = `assets/images/logo/shops/${this.key}.svg`;

    private readonly shopsRepository = inject(ShopsRepository);
    private readonly oauthService = inject(OAuthService);
    private readonly getOauthViewModel = inject(GetOAuthViewModelCommand);
    private readonly shopsService = inject(ShopsService);
	private readonly addCommand = inject(OauthShopAddCommand);
	private readonly reauthCommand = inject(ReauthShopCommand);

    add(): Observable<never> {
        return this.addCommand.execute(from(this.oauthService.getChannelAuthorizeUrl(this.key)), this.key, this.logo);
    }

    reAuth(shop: ShopViewModelDto): Observable<ShopViewModelDto> {
        const url$ = from(
            this.oauthService.getChannelAuthorizeUrl(this.key, shop.Id || 0),
        );
        return this.reauthCommand.execute(url$, this.key, this.logo, shop);
    }

    saveShopWithNecessarySettings(): Observable<ShopViewModelDto> {
        return from(this.oauthService.getChannelAuthorizeUrl(this.key)).pipe(
            switchMap((url) => this.getOauthViewModel.execute(this.key,this.logo, this.name, url)),
            switchMap((model) =>
                this.shopsService.showShopLoadingDialogForRequest(
                    this.shopsRepository.saveShop(model),
                    this.logo,
                    "title.saving",
                    "text.saving_shop",
                ),
            ),
        );
    }
}
