import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService } from "@app/app/oauth/core";
import { ErrorHandlerService, MessagingService } from "@bb-core/service";
import { NEVER, Observable, from } from "rxjs";
import { catchError, ignoreElements, map, switchMap, tap } from "rxjs/operators";
import { PopupType } from "../../../../shared/model/popup-type.enum";
import { PopupService } from "../../../../shared/service/popup.service";
import { ShopViewModelDto } from "../../data/shop-view-model.dto";
import { ShopsRepository } from "../../data/shops.repository";
import { Shop } from "../../model/shop";
import { ShopFeatureFlag } from "../../model/shop-feature-flag";
import { ShopType } from "../../model/shop-type.enum";
import { ReauthShopCommand } from "../../service/command/reauth-shop.command";
import { ShopsService } from "../../service/shops.service";
import { ShopifyConnectionFormComponent } from "./shopify-connection-form/shopify-connection-form.component";
import { ShopifyGeneralFormComponent } from "./shopify-general-form/shopify-general-form.component";

@Injectable({
    providedIn: "root",
})
export class ShopifyShop implements Shop {
    private readonly shopsRepository = inject(ShopsRepository);
    private readonly shopsService = inject(ShopsService);
    private readonly popupService = inject(PopupService);
    private readonly reauthCommand = inject(ReauthShopCommand);
    private readonly oauthService = inject(OAuthService);
    private readonly router = inject(Router);
    private readonly messagingService = inject(MessagingService);
    private readonly errorHandlerService = inject(ErrorHandlerService);

    readonly key = "shopify";
    readonly name = "Shopify";
    readonly type = ShopType.Shop;
    readonly logo = `assets/images/logo/shops/${this.key}.svg`;
    readonly featured = true;
    readonly featureFlags = [ShopFeatureFlag.HasNewShopDetailPage];
    readonly connectionFormComponent = ShopifyConnectionFormComponent;
    readonly generalFormComponent = ShopifyGeneralFormComponent;

    private readonly authUrl = "https://apps.shopify.com/billbee-de";

    add(): Observable<never> {
        return this._saveNewShopifyShop(false).pipe(
            tap(({ Id }) =>
                this.router.navigate([`/settings/shops/details/${Id}`], { queryParams: { newShopConnection: true } }),
            ),
            ignoreElements(),
        );
    }

    reAuth(shop: ShopViewModelDto): Observable<ShopViewModelDto> {
        const url$ = from(
            this.oauthService.getChannelAuthorizeUrl(this.key, shop.Id || 0, {
                ShopName: shop.ShopName || "",
            }),
        );
        return this.reauthCommand.execute(url$, this.key, this.logo, shop);
    }

    saveShopWithNecessarySettings(): Observable<ShopViewModelDto> {
        return this._saveNewShopifyShop(true);
    }

    private _saveNewShopifyShop(isOnboarding: boolean): Observable<ShopViewModelDto> {
        return this._fetchShopName().pipe(
            //Check and catch if shopname already registered in Billbee
            switchMap(shopName =>
                this.shopsRepository.shopifyShopCheck(shopName).pipe(
                    map(({ ShopExists }) => {
                        if (ShopExists) {
                            this.messagingService.showSnackBar("flash.shop_already_registered");
                            throw new Error("Shop already exists");
                        } else {
                            return shopName;
                        }
                    }),
                    catchError(e => {
                        this.errorHandlerService.handleException(e);
                        return NEVER;
                    }),
                ),
            ),
            switchMap(shopName =>
                this.shopsService.getOauthDetails(
                    this.logo,
                    `/sync/shopifyauth?shop=${shopName}.myshopify.com&connectionType=channel`,
                ),
            ),
            switchMap(oauth =>
                (isOnboarding
                    ? this.shopsService.getBaseDetails(this.key, this.name, this.logo)
                    : this.shopsService.getBaseDetails(this.key, this.name, this.logo, undefined, true)
                ).pipe(
                    map(viewModel => ({
                        ...viewModel,
                        ShopName: oauth.shop?.replace(".myshopify.com", "") || "",
                        ShopifyAccessToken: oauth.Token,
                        OAuthAccessToken: { ...oauth, shop: oauth.shop?.replace(".myshopify.com", "") || "" },
                    })),
                ),
            ),
            switchMap(model =>
                this.shopsService.showShopLoadingDialogForRequest(
                    this.shopsRepository.saveShop(isOnboarding ? model : { ...model, DownloadOrders: false }),
                    this.logo,
                    "title.saving",
                    "text.saving_shop",
                ),
            ),
        );
    }

    private _fetchShopName(): Observable<string> {
        return this.shopsService
            .showShopLoadingDialogForRequest(
                this.popupService.getMessage<{ shop: string }>(this.authUrl, PopupType.ShopifyShopName),
                this.logo,
                "title.add_shop",
                "text.add_shop_description",
            )
            .pipe(map(({ shop }) => shop.replace(".myshopify.com", "")));
    }
}
