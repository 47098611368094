import {Component} from "@angular/core";
import {AbstractFormGroupComponent, AbstractGroupType, IGroupTypeOptions} from "@mintware-de/form-builder";

@Component({
    selector: "bb-form-group",
    template: `
        <ng-content></ng-content>
        <ng-container *ngFor="let field of mwFieldType.options.model | keyvalue:orderAsGiven">
            <ng-container mwFormField
                          [mwFormGroup]="mwElement"
                          [mwElement]="elements[field.key]"
                          [mwFieldType]="field.value"
                          [mwPath]="fieldPaths[field.key]"
                          [mwIndex]="indexFromParent">
            </ng-container>
        </ng-container>
    `,
    styles: [],
})
export class FormGroupComponent extends AbstractFormGroupComponent<AbstractGroupType<IGroupTypeOptions>> {


}
