export class ArticleComment {
    public CreatedAt: Date = new Date();
    public CreatedBy: string = null;
    public Text: string = null;

    constructor(obj?: Partial<ArticleComment>) {
        ctor(this, obj, {
            DateFields: ["CreatedAt"],
        });
    }
}
