import {Component, Input} from "@angular/core";

@Component({
    selector: "bb-meta-card",
    template: `
        <mat-card class="meta-card" fxLayout="row" fxLayoutGap="4px">
            <mat-icon [inline]="true" [fontIcon]="icon"></mat-icon>
            <div fxFlex="grow">
                <ng-content></ng-content>
            </div>
        </mat-card>`,
})
export class MetaCardComponent {
    @Input() public icon: string;
}
