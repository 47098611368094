import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: "select-font",
    templateUrl: "selectFont.component.html",
})
export class SelectFontComponent {
    @Input() public font: any;
    @Input() public userFonts: any;
    @Output() public onChange = new EventEmitter();

    public Changed() {
        this.onChange.emit(null);
    }
}
