import {
    OrderToShip,
    ShippingProductService,
    ShippingProvider,
    ShippingProviderProduct,
    ShippingResult,
} from "../entity";
import {Injectable} from "@angular/core";
import {ClientStorageService, TranslationService} from "@bb-core/service";
import {
    SelectShippingProductPresenter,
    SelectShippingProductRequest,
    SelectShippingProductUseCase,
} from "./select-shipping-product.use-case";

export class SelectShippingProviderRequest {
    public Order: OrderToShip = null;
    public Provider: ShippingProvider = null;
    public WithPreselect: boolean = false;
    public DefaultShippingProductId: number = null;

    constructor(obj?: Partial<SelectShippingProviderRequest>) {
        ctor(this, obj);
    }
}

export abstract class SelectShippingProviderPresenter implements SelectShippingProductPresenter {
    /** Display the available shipping products */
    public abstract displayAvailableShippingProviderProducts(products: ShippingProviderProduct[],
    ): void;

    public abstract preselectCloudStorage(cloudStorageId: number,
    ): void;

    public abstract preselectCloudStorageForExportDocs(cloudStorageIdForExportDocs: number,
    ): void;

    public abstract preselectCloudStorageForRetoureLabels(cloudStorageIdForRetoureLabels: number,
    ): void;

    public abstract displayAvailableShippingProductServices(order: OrderToShip,
                                                            services: ShippingProductService[],
    ): void;

    public abstract preselectShippingProduct(product: ShippingProviderProduct,
    ): void;
}

@Injectable({providedIn: "root"})
export class SelectShippingProviderUseCase implements IUseCase<SelectShippingProviderRequest, SelectShippingProviderPresenter> {

    constructor(private readonly clientStorageService: ClientStorageService,
                private readonly selectShippingProductUseCase: SelectShippingProductUseCase,
                private readonly translator: TranslationService,
    ) {
    }

    public async execute(request: SelectShippingProviderRequest,
                         presenter?: SelectShippingProviderPresenter,
    ): Promise<void> {
        const provider = request.Provider;
        const shippingProducts = provider?.AttachedProducts ?? [];
        presenter?.displayAvailableShippingProviderProducts(shippingProducts);

        const currentProduct = shippingProducts.find(x => x.Id === request.Order?.ShippingProduct?.Id)
            ?? shippingProducts.find((p) => p.Id === request.Order?.ShippingProductId);

        const lastUsedShippingProductId = this.clientStorageService.get<number>("shipping.defaultProductId");
        const product = currentProduct // Assigned to the order
            ?? shippingProducts.find(x => x.Id === request.DefaultShippingProductId) // default shipping product id
            ?? shippingProducts.find(x => x.Id === lastUsedShippingProductId) // Last used product
            ?? shippingProducts.find(_ => true); // First product

        if (request.Order != null) {
            request.Order.ShippingProviderId = product?.ShippingProviderId;
            request.Order.CloudStorageId = provider?.PrinterId ?? request.Order.CloudStorageId;
            request.Order.CloudStorageIdForExportDocs = provider?.PrinterIdForExportDocs ?? request.Order.CloudStorageIdForExportDocs;
            request.Order.CloudStorageIdForRetoureLabels = provider?.PrinterIdForRetoureLabels ?? request.Order.CloudStorageIdForRetoureLabels;
        }

        await this.selectShippingProductUseCase.execute(new SelectShippingProductRequest({
            Product: product,
            Order: request.Order,
            WithPreselect: request.WithPreselect,
        }), presenter);

        if (currentProduct === null && request.Order?.ShippingProductId != null) {
            request.Order.Result = new ShippingResult({
                Message: this.translator.translate("text.shipping_product_was_set_automatically"),
            });
        }

        if (request.WithPreselect && provider?.PrinterId != null) {
            presenter?.preselectCloudStorage(provider.PrinterId);
        }

        if (request.WithPreselect && provider?.PrinterIdForExportDocs != null) {
            presenter?.preselectCloudStorageForExportDocs(provider.PrinterIdForExportDocs);
        }

        if (request.WithPreselect && provider?.PrinterIdForRetoureLabels != null) {
            presenter?.preselectCloudStorageForRetoureLabels(provider.PrinterIdForRetoureLabels);
        }
    }
}
