import {Injectable} from "@angular/core";
import {CustomerAddress, CustomerInteractionService} from "@app/app/customer/core";
import {Message, MessagingService, TranslationService} from "@bb-core/service";

export class EditCustomerAddressRequest {
    public Address: CustomerAddress = null;

    constructor(obj?: Partial<EditCustomerAddressRequest>) {
        ctor(this, obj);
    }
}

export abstract class EditCustomerAddressPresenter {
    public abstract displayNewAddress(address: CustomerAddress): void;

    public abstract displayUpdatedAddress(address: CustomerAddress): void;
}

@Injectable({providedIn: "root"})
export class EditCustomerAddressUseCase implements IUseCase<EditCustomerAddressRequest, EditCustomerAddressPresenter> {

    constructor(private readonly customerInteractionService: CustomerInteractionService,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
    ) {
    }

    public async execute(request: EditCustomerAddressRequest,
                         presenter?: EditCustomerAddressPresenter,
    ): Promise<void> {
        const isArchived = request.Address.IsArchived;
        
        if(isArchived){
            const title = this.translator.translate("title.error");
            const message = this.translator.translate("error.customer_addresses.can_not_edit_archived_customer_address");
            await this.messaging.showMessage(Message.transient({title, message}));
            return;
        }
        
        const isUsed = request.Address.IsUsedInOrders;
        const newAddress = await this.customerInteractionService.editAddress(new CustomerAddress(request.Address));
        if (newAddress == null) {
            return;
        }

        if (isUsed) {
            const title = this.translator.translate("title.addresses_are_in_use");
            const message = this.translator.translate("text.new_address_created");
            await this.messaging.showMessage(Message.transient({title, message}));
        }

        if (isUsed || newAddress.Id == null) {
            newAddress.Id = 0;
            newAddress.IsUsedInOrders = false;
            presenter?.displayNewAddress(newAddress);
        } else {
            presenter?.displayUpdatedAddress(newAddress);
        }
    }
}
