<div class="mat-elevation-z1" *ngIf="isListVisible">
    <mat-toolbar>
        <mat-toolbar-row>
            <button type="button" mat-icon-button [matMenuTriggerFor]="selectionMenu">
                <mat-icon fontIcon="fa-check-square"></mat-icon>
            </button>
            <mat-menu #selectionMenu="matMenu">
                <button mat-menu-item
                        (click)="multiSelect.selectAll(viewModel.templates)">{{'Alle auswählen'|translate}}</button>
                <button mat-menu-item (click)="multiSelect.resetSelection()">{{'Keine auswählen'|translate}}</button>
            </mat-menu>
            <button type="button" mat-button routerLink="./create">
                <mat-icon fontIcon="fa-plus"></mat-icon>
                {{ 'Neu' | translate }}
            </button>
            <button type="button" mat-icon-button [disabled]="!multiSelect.onlyOneItemSelected()"
                    routerLink="./create" [queryParams]="{'copyfrom': multiSelect.getIdOfOnlySelectedItem()}">
                <mat-icon fontIcon="fa-copy"></mat-icon>
            </button>
            <button type="button" mat-icon-button [disabled]="!multiSelect.anyItemSelected()"
                    (click)="confirmDeleteSelected()">
                <mat-icon fontIcon="fa-trash"></mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <table class="material smaller" *ngIf="viewModel.templates != null && viewModel.templates.length > 0; else noData">
        <thead>
        <tr>
            <th></th>
            <th></th>
            <th>{{ 'Name' | translate }}</th>
            <th>{{ 'Subjekt' | translate }}</th>
            <th>{{ 'Text' | translate }}</th>
            <th>{{ 'Bemerkung' | translate }}</th>
            <th>{{ 'Text/HTML' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let template of viewModel.templates; index as $index">
            <td style="width: 48px">
                <mat-checkbox [(ngModel)]="multiSelect.selectedItems[$index]"
                              (change)="multiSelect.selectUnselectNew($index, template.Id)">
                </mat-checkbox>
            </td>
            <td style="width: 48px">
                <button mat-icon-button class="mat-elevation-z1" [routerLink]="['./', template.Id]"
                        matTooltip="{{'Diesen Text bearbeiten öffnen' | translate}}">
                    <mat-icon fontIcon="fa-pencil"></mat-icon>
                </button>
            </td>
            <td>{{ template.Name }}</td>
            <td>{{ template.Subject | defaultLanguage }}</td>
            <td>
                <div [innerHTML]="template.Body | defaultLanguage | stripTags"></div>
            </td>
            <td>{{ template.Comment }}</td>
            <td>
                <mat-icon [fontIcon]="template.IsHtml ? 'fa-code' : 'fa-font'"></mat-icon>
            </td>
        </tr>
        </tbody>
    </table>
</div>
<router-outlet></router-outlet>
<ng-template #noData>
    <bb-meta-card icon="fa-info-circle">
        <p>
            Hier kannst du Benachrichtigungstexte erfassen.
        </p>
        <p>
            Diese Texte können über Regeln bei bestimmten Ereignissen an Kunden versendet werden.
            Beispiele dafür sind:
        </p>
        <ul>
            <li>Versandbenachrichtigung, wenn eine Bestellung versendet wurde</li>
            <li>Information über einen Zahlungseingang, wenn eine Bestellung bezahlt wurde</li>
            <li>Hinweis auf verfügbare Downloads, wenn eine Bestellung bezahlt wurde</li>
        </ul>
    </bb-meta-card>
</ng-template>