import {ShippingProductService} from "../shipping-product-service";

export class DhlRetoureLocationsService extends ShippingProductService {
    public location?: string = null;

    constructor(obj?: Partial<DhlRetoureLocationsService>) {
        super(obj);
        ctor(this, obj);
    }

}
