import {Preset} from "@app/app/grid/core";
import {FormModel} from "@mintware-de/form-builder";
import {HiddenField} from "@app/app/common/form/text-field/hidden-field.type";
import {TextField} from "@app/app/common/form/text-field/text-field.type";
import {Switch} from "@app/app/common/form/switch/switch.type";
import {BehaviorSubject} from "rxjs";

export class BbGridSidebarViewModel<T> {
    public preset: Preset<T> = null;

    public readonly expandFilterPanel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readonly expandColumnsPanel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public formModel: FormModel<Preset<T>> = {
        Id: new HiddenField(),
        Name: new TextField({
            label: "label.name",
        }),
        RememberColumns: new Switch({
            label: "label.remember_columns",
        }),
        RememberColumnFilters: new Switch({
            label: "label.remember_column_filters",
        }),
        RememberOrder: new Switch({
            label: "label.remember_sort_order",
        }),
        RememberSearchTerm: new Switch({
            label: "label.remember_search_term",
        }),
    };
}
