<div class="description">
    {{ "text.want_to_add_vat_id" | translate }}
</div>

<bb-input [formControl]="vatId" [label]="'label.vat_id' | translate"></bb-input>
<bb-datepicker [formControl]="vatIdValidFrom" [label]="'label.valid_from' | translate"></bb-datepicker>

<button *ngIf="!vatId.value || !vatIdValidFrom.value" mat-stroked-button color="primary" (click)="next(false)">
    {{ "button.skip" | translate }}
</button>
<button *ngIf="vatId.value !== '' && vatIdValidFrom.value" mat-flat-button color="primary" (click)="next(true)">
	{{ "button.next" | translate }}
</button>
