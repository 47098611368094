import {AbstractCollectionType, Constructor} from "@mintware-de/form-builder";
import {SimpleCollectionComponent} from "./simple-collection.component";
import {SimpleCollectionOptions} from "@app/app/common/form/collection/simple-collection/simple-collection.options";
import {ConstructorOf1} from "@mintware-de/form-builder/lib/types";
import {AbstractType} from "@mintware-de/form-builder/lib/form-type/abstract-type";
import {ValidatorFn, Validators} from "@angular/forms";

export class SimpleCollection<TConfig> extends AbstractCollectionType<TConfig, SimpleCollectionOptions<TConfig>> {
    readonly component: Constructor = SimpleCollectionComponent;

    public componentInstance: SimpleCollectionComponent;

    constructor(childType: ConstructorOf1<TConfig, AbstractType<TConfig>>, options: SimpleCollectionOptions<TConfig>) {
        super(childType, {
            showAddButton: true,
            allowDelete: true,
            required: false,
            customValidators: [],
            ...options,
        } as SimpleCollectionOptions<TConfig>);
    }

    public get validators(): ValidatorFn[] {
        const validators = this.options.customValidators;
        if (this.options.required) {
            validators.push(Validators.required);
        }
        return validators;
    }
}
