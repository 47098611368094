import {EuDeliveryThreshold} from "./eu-delivery-threshold";
import {CountryVatConfiguration} from "./country-vat-configuration";

export class CountrySettings {
    public RecognizeThirdPartyCountry: boolean = false;
    public CalcNetFromGrossOnThirdParty: boolean = false;
    public MOSSEnabled: boolean = true;
    public SetIglWhenVatIdAvailable: boolean = false;
    public IglVatMode: number = 0;
    public SetIglWhenExistingCustomerHasVatId: boolean = false;
    public EuDeliveryThresholds: EuDeliveryThreshold[] = [];
    public OSSEnabled: boolean = true;
    public TaxationAboveEUDeliveryThreshold: boolean = false;
    public EUDeliveryThresholdExceededAt?: Date = null;
    public AddTagForDistanceSelling: boolean = true;
    public CountryVatConfigurations: CountryVatConfiguration[] = [];

    constructor(obj?: Partial<CountrySettings>) {
        ctor(this, obj, {
            Collections: {
                CountryVatConfigurations: CountryVatConfiguration,
                EuDeliveryThresholds: EuDeliveryThreshold,
            },
            DateFields: ["EUDeliveryThresholdExceededAt"],
        });
    }
}
