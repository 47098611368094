import {ChangeDetectionStrategy, Component} from "@angular/core";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantSavingTaskComponent} from "../saving/saving.component";

@Component({
    selector: "bb-onboarding-assistant-before-saving",
    templateUrl: "./before-saving.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantBeforeSavingTaskComponent {
    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
    ) {}

	public next(): void {		
		this.onboardingStore.nextTask(OnboardingAssistantSavingTaskComponent);
    }
}
