import {ChangeDetectorRef, Component, Inject} from "@angular/core";
import {Notification} from "@shared/models";


@Component({
    selector: "bb-nav-notifications",
    templateUrl: "./notifications-item.component.html",
    styles: [`
        .notification-tile-date {
            color: #999;
        }`],
})
export class NotificationsItemComponent {
    public bellIcon: string = "fa-bell";
    public allNotifications: { [key: number]: Notification } = {};
    public newNotifications: Notification[] = [];
    public sysNotifications: Notification[] = [];
    public oldNotifications: Notification[] = [];
    public hasNotifications: boolean = false;
    public numberOfMoreOldNotifications: number = 0;
    public numberOfMoreNewNotifications: number = 0;
    private static maxStackSize: number = 5;

    constructor(private readonly cdr: ChangeDetectorRef,
                @Inject("SignalRService") private readonly sigR: SignalRService,
                @Inject("NotificationResource") private readonly notificationResource: INotificationResource,
    ) {

        sigR.on("notificationhub", "NotifyUser", (...args) => {
            this._queryNotifications("fa-bell-on");
        });
        sigR.on("notificationhub", "notify", (count: number, withWarning: boolean) => {
            if (count > 0) {
                const bellIcon = withWarning ? "fa-bell-exclamation" : "fa-bell-on";
                this._queryNotifications(bellIcon);
            } else {
                this.bellIcon = "fa-bell";
            }
            this.cdr.detectChanges();
        });
    }

    private _queryNotifications(bellIcon: string): void {
        this.notificationResource.query2((res: { Notifications: Notification[] }) => {
            this.allNotifications = res.Notifications.reduce((all, notification) => {
                if (notification.Id === 0) {
                    notification.Id = new Date().getMilliseconds();
                }
                all[notification.Id] = notification;
                return all;
            }, {});
            this._updateLists();
            this.hasNotifications = res.Notifications.length > 0;
            this.bellIcon = bellIcon;
        }, (): any => this.bellIcon = bellIcon);
    }

    public markAllAsRead(): void {
        Object.values(this.allNotifications).forEach((n) => n.Viewed = true);
        this.bellIcon = "fa-bell";
        this._updateLists();
        this.notificationResource.markAsRead({id: "all"});
    }

    public markAsRead(index: number): void {
        const notification = this.allNotifications[index];
        notification.Viewed = true;
        this._updateLists();
        this.notificationResource.markAsRead({id: notification.Id}, () => null, () => {
            notification.Viewed = false;
            this._updateLists();
        });
    }

    private _updateLists() {
        this.oldNotifications.splice(0);
        this.newNotifications.splice(0);
        this.sysNotifications.splice(0);
        this.numberOfMoreNewNotifications = 0;
        this.numberOfMoreOldNotifications = 0;
        for (const n of Object.values(this.allNotifications)) {
            const {Type: type, Viewed: viewed} = n;
            if (type === 0 || type === 1) {
                if (viewed) {
                    if (this.numberOfMoreOldNotifications === 0
                        && this.oldNotifications.length < NotificationsItemComponent.maxStackSize) {
                        this.oldNotifications.push(n);
                    } else {
                        this.numberOfMoreOldNotifications++;
                    }
                } else if (!viewed) {
                    if (this.numberOfMoreNewNotifications === 0
                        && this.newNotifications.length < NotificationsItemComponent.maxStackSize) {
                        this.newNotifications.push(n);
                    } else {
                        this.numberOfMoreNewNotifications++;
                    }
                }
            } else {
                this.sysNotifications.push(n);
            }
        }
    }
}
