import {inject, Injectable} from "@angular/core";
import {OAuthService} from "@app/app/oauth/core";
import {from, Observable} from "rxjs";
import {ShopViewModelDto} from "../../data/shop-view-model.dto";
import {Shop} from "../../model/shop";
import {ShopType} from "../../model/shop-type.enum";
import {OauthShopAddCommand} from "../../service/command/oauth-shop-add.command";
import {ReauthShopCommand} from "../../service/command/reauth-shop.command";

@Injectable({
    providedIn: "root",
})
export class WishShop implements Shop {
    public readonly key = "wish";
    public readonly name = "wish";
    public readonly type = ShopType.Marketplace;
    public readonly logo = `assets/images/logo/shops/${this.key}.jpg`;

    private readonly oauthService = inject(OAuthService);
    private readonly addCommand = inject(OauthShopAddCommand);
    private readonly reauthCommand = inject(ReauthShopCommand);

    add(): Observable<never> {
        return this.addCommand.execute(from(this.oauthService.getChannelAuthorizeUrl(this.key)), this.key, this.logo);
    }

    reAuth(shop: ShopViewModelDto): Observable<ShopViewModelDto> {
        const url$ = from(
            this.oauthService.getChannelAuthorizeUrl(this.key, shop.Id || 0, {
                ShopName: shop.ShopName || "",
            }),
        );
        return this.reauthCommand.execute(url$, this.key, this.logo, shop);
    }
}
