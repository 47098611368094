<div class="description">
    {{ "text.which_vat_mode" | translate }}
</div>
<div class="choose-button-wrapper">
    <button mat-stroked-button color="primary" (click)="next(0)">
		{{ "label.vat_mode_0" | translate }}
	</button>
    <button mat-stroked-button color="primary" (click)="next(1)">
		{{ "label.vat_mode_1" | translate }}
	</button>
    <button mat-stroked-button color="primary" (click)="next(4)">
		{{ "label.vat_mode_4" | translate }}
	</button>
</div>
