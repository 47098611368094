import {ChangeDetectionStrategy, Component} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {TranslateModule} from "@ngx-translate/core";

@Component({
	templateUrl: "./epages-hint-dialog.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatDialogModule, MatButtonModule, TranslateModule],
})
export class EpagesHintDialogComponent {}
