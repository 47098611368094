import {Injectable} from "@angular/core";
import {PackageType, ShippingProvider} from "@app/app/shipping/core/entity";
import {Message, MessagingService, TranslationService} from "@bb-core/service";
import {ShipmentRepository} from "@app/app/shipping/core/repository";

@Injectable({providedIn: "root"})
export class ShippingDependenciesService {
    constructor(private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
                private readonly shippingRepo: ShipmentRepository,
    ) {
    }

    /** Loads all shipping providers and returns the assigned ShippingProduct. */
    public async loadShippingProviders(): Promise<ShippingProvider[]> {
        let providers: ShippingProvider[];
        try {
            providers = (await this.shippingRepo.getShippingProviders()).filter(p => p.AttachedProducts?.length > 0);
        } catch (e) {
            const message = this.translator.translate("flash.load_shipping_providers_failed");
            this.messaging.showMessage(Message.transient({message})).then();
            throw e;
        }
        return providers;
    }

    /** Loads all package types and returns the assigned package type. */
    public async loadPackageTypes(): Promise<PackageType[]> {
        try {
            return await this.shippingRepo.getPackageTypes();
        } catch (e) {
            const message = this.translator.translate("flash.load_package_types_failed");
            this.messaging.showMessage(Message.transient({message})).then();
            throw e;
        }
    }
}
