import {AbstractFormFieldComponent, AbstractType} from "@mintware-de/form-builder";
import {AddressGroupOptions} from "./address-group.options";
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy} from "@angular/core";
import {Address} from "@app/app/shipping/core/entity";
import {
    EditAddressPresenter,
    EditAddressRequest,
    EditAddressUseCase,
    PickAddressPresenter,
    PickAddressRequest,
    PickAddressUseCase,
} from "@app/app/shipping/core/use-case";
import {Subscription} from "rxjs";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "[bb-form-address-group]",
    template: `
        <div fxLayout="row" style="margin-bottom: 8px">
            <div fxLayout="column" fxLayoutGap="4px" fxFlex="grow">
                <div fxLayout="row">
                    <div class="addr-panel-field2" *ngIf="address?.Company">{{address?.Company}}</div>
                </div>
                <div fxLayout="row">
                    <div class="addr-panel-field2">{{address?.FirstName}}</div>
                    <div class="addr-panel-field2">{{address?.LastName}}</div>
                </div>
                <div fxLayout="row" *ngIf="address?.Name2">
                    <div class="addr-panel-field2">{{address?.Name2}}</div>
                </div>
                <div fxLayout="row">
                    <div class="addr-panel-field2">{{address?.Street}}</div>
                    <div class="addr-panel-field2">{{address?.Housenumber}}</div>
                </div>
                <div fxLayout="row" *ngIf="address?.AddressAddition">
                    <div class="addr-panel-field2">{{address?.AddressAddition}}</div>
                </div>
                <div fxLayout="row">
                    <div class="addr-panel-field2">{{address?.Zip}}</div>
                    <div class="addr-panel-field2">{{address?.City}}</div>
                </div>
                <div fxLayout="row" *ngIf="address?.State">
                    <div class="addr-panel-field2">{{address?.State}}</div>
                </div>
                <div fxLayout="row">
                    <div [ngStyle]="{fontWeight: !isOwnCountry ? 'bold' : 'normal' }" class="addr-panel-field2">
                        {{ countryLabel |translate}}
                    </div>
                    <mat-icon *ngIf="!isOwnCountry"
                              [inline]="true"
                              fxFlex="none"
                              fontIcon="fa-exclamation-triangle"
                              [matTooltip]="'tooltip.international_shipment' | translate"></mat-icon>
                </div>
                <div fxLayout="row" *ngIf="address?.Email">
                    <div class="addr-panel-field2">{{address?.Email}}</div>
                </div>
            </div>
            <div fxFlex="none" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px">
                <button type="button" [disabled]="disabled" (click)="EditAddress()" mat-flat-button>
                    <mat-icon fontIcon="fa-pencil"></mat-icon>
                </button>
                <button type="button" [disabled]="disabled" (click)="SelectAddress()" mat-flat-button>
                    <mat-icon fontIcon="fa-exchange"></mat-icon>
                </button>
            </div>
        </div>

    `,
    styles: [
        `.addr-panel-field2 {
            margin-right: 2px;
            padding: 1px 2px;
        }`,
    ],
})
export class AddressGroupComponent extends AbstractFormFieldComponent<AbstractType<AddressGroupOptions>>
    implements AfterViewInit, OnDestroy, EditAddressPresenter, PickAddressPresenter {
    private readonly subscriptions: Subscription[] = [];

    public address: Address = null;
    public isOwnCountry: boolean = true;
    public countryLabel: string;
    public disabled: boolean = true;

    constructor(@Inject("authService") private user: AuthService,
                private readonly editAddressUseCase: EditAddressUseCase,
                private readonly pickAddressUseCase: PickAddressUseCase,
                private readonly cdr: ChangeDetectorRef,
    ) {
        super();
    }

    // region Life cycle hooks
    public ngAfterViewInit(): void {
        this.subscriptions.push(this.mwElement.valueChanges.subscribe((data) => {
            this.address = new Address(data);
            this.isOwnCountry = this.address.CountryCode && this.user.getCountryCode() === this.address.CountryCode;
            this.countryLabel = "country_" + this.address.CountryCode?.toLocaleLowerCase();
            this.cdr.markForCheck();
        }));
        this.subscriptions.push(this.mwElement.statusChanges.subscribe(() => {
            this.disabled = this.mwElement.disabled;
            this.cdr.markForCheck();
        }));
    }


    public ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    // endregion

    public EditAddress(): void {
        this.editAddressUseCase.execute(new EditAddressRequest({
            Address: this.address,
            OrderId: this.mwFieldType.options.OrderId,
        }), this).then();
    }

    public SelectAddress(): void {
        this.pickAddressUseCase.execute(new PickAddressRequest({
            CustomerId: this.address.CustomerId,
            CurrentAddressId: this.address.Id,
            OrderId: this.mwFieldType.options.OrderId,
        }), this).then();
    }

    public displayUpdatedAddress(address: Address): void {
        this._changeAddress(address);
    }

    public displayPickedAddress(address: Address): void {
        this._changeAddress(address);
    }

    private _changeAddress(address: Address): void {
        this.mwElement.patchValue(address);
    }
}
