import {ShippingProductService} from "../shipping-product-service";
import {AgeCheckEnum} from "@app/app/shipping/core/entity/service/age-check.enum";

export class VisualAgeCheckService extends ShippingProductService {
    public SelectedAgeValue: AgeCheckEnum = AgeCheckEnum.A16;

    constructor(obj?: Partial<VisualAgeCheckService>) {
        super(obj);
        ctor(this, obj);
    }
}
