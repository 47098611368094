import {Directive, Input, OnInit, ElementRef} from "@angular/core";

@Directive({
    selector: "[helpLink]",
})
export class HelpLinkDirective implements OnInit {
    @Input("helpLink")
    public alias: string = null;
    public static linkMapping: { [key: string]: string } = {
        "help": "https://hilfe.billbee.io",
        "datev.general": "https://hilfe.billbee.io/article/371-datev-erloeseexport-generieren",
        "youtube": "https://www.youtube.com/channel/UC2y_i7TIhAJpWCMm8fcs_xw",
        "blog": "https://www.billbee.io/news/blog/",
        "privacy": "https://www.billbee.io/datenschutz/",
        "imprint": "https://www.billbee.io/impressum/",
        "terms": "https://www.billbee.io/agb/",
        "account.security.2fa": "https://hilfe.billbee.io/article/841-zwei-faktor-authentifizierung-2fa",
        "placeholders": "https://hilfe.billbee.io/article/357-platzhalter",
        "status_page": "https://status.billbee.io",
        "shops.shopify.add_affiliate": "https://shopify.pxf.io/e4Gdkj",
        "shops.shopify.billbee_app": "https://apps.shopify.com/billbee-de?locale=de",
        "products.merge_products": "https://hilfe.billbee.io/article/756-artikel-zusammenfassen",
        "products.import_recognition": "https://hilfe.billbee.io/article/404-artikel-importieren#artikelerkennungbeimeinlesen",
    };

    private _getLink(alias: string): string {
        if (alias in HelpLinkDirective.linkMapping) {
            return HelpLinkDirective.linkMapping[alias];
        }
        throw new Error(`Link for alias ${alias} not found.`);
    }

    constructor(private el: ElementRef) {

    }

    public ngOnInit(): void {
        const nativeElement = this.el.nativeElement as HTMLElement;
        const helpLink = this._getLink(this.alias);

        if (nativeElement.tagName === "a") {
            this.el.nativeElement.target = "_BLANK";
            this.el.nativeElement.href = helpLink;
        } else {
            const oldOnClick = nativeElement.onclick?.bind(nativeElement);
            nativeElement.onclick = () => {
                window.open(helpLink);
                if (oldOnClick != null) {
                    oldOnClick();
                }
            };
        }
    }
}
