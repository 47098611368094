import {AbstractFormFieldComponent, AbstractType} from "@mintware-de/form-builder";
import {Component} from "@angular/core";
import {CheckboxOptions} from "./checkbox.options";

@Component({
    selector: "[bb-form-checkbox]",
    template: `
        <div class="mat-form-field"
             [hidden]="!mwFieldType.options.visible">
            <mat-checkbox color="primary"
                          [name]="mwPath"
                          [required]="mwFieldType.options.required == true"
                          [formControl]="mwElement"
                          [matTooltip]="mwFieldType.options.tooltip | translate"
                          (change)="mwFieldType.options.onChange ? mwFieldType.options.onChange($event, this, mwFieldType.builderInstance.group) : null">
                {{mwFieldType.options.label | translateLabel}}
            </mat-checkbox>
            <mat-hint>{{ mwFieldType.options.hint | translate }}</mat-hint>
        </div>

    `,
})
export class CheckboxComponent extends AbstractFormFieldComponent<AbstractType<CheckboxOptions>> {

}
