import {QuillConfig} from "ngx-quill/config";

const Quill = require("quill");
const icons = Quill.import("ui/icons");
icons.undo = "<i class=\"fal fa-undo\"></i>";
icons.redo = "<i class=\"fal fa-redo\"></i>";
icons.bold = "<i class=\"fal fa-bold\"></i>";
icons.italic = "<i class=\"fal fa-italic\"></i>";
icons.underline = "<i class=\"fal fa-underline\"></i>";
icons.list.ordered = "<i class=\"fal fa-list-ol\"></i>";
icons.list.bullet = "<i class=\"fal fa-list-ul\"></i>";
icons.list.check = "<i class=\"fal fa-tasks\"></i>";
icons.header[1] = "<i class=\"fal fa-h1\"></i>";
icons.header[2] = "<i class=\"fal fa-h2\"></i>";
icons.header[3] = "<i class=\"fal fa-h3\"></i>";

export function undo() {
    this.quill.history.undo();
}

export function redo() {
    this.quill.history.redo();
}


export const config: QuillConfig = {
    modules: {
        toolbar: {
            container: [
                [{"header": 1}, {"header": 2}, {"header": 3}],               // custom button values
                ["bold", "italic", "underline"],        // toggled buttons
                [{"list": "bullet"}, {"list": "ordered"}],
                ["undo", "redo"],
            ],
            handlers: {
                "undo": undo,
                "redo": redo,
            },
        },
        history: true,
    },
};
