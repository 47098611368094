import {Injectable} from "@angular/core";
import {CustomerAddress, CustomerInteractionService} from "@app/app/customer/core";
import {InteractionService, Message, MessagingService, TranslationService} from "@bb-core/service";

export class MergeAddressesRequest {
    public AddressesToMerge: CustomerAddress [] = [];

    constructor(obj?: Partial<MergeAddressesRequest>) {
        ctor(this, obj, {
            Collections: {
                AddressesToMerge: CustomerAddress,
            },
        });
    }
}

export abstract class MergeAddressesPresenter {

    /// Is called after the user merged the addresses.
    public abstract displayMergedAddress(address: CustomerAddress, sourceAddresses: CustomerAddress[]): void;
}

@Injectable({providedIn: "root"})
export class MergeAddressesUseCase implements IUseCase<MergeAddressesRequest, MergeAddressesPresenter> {

    constructor(private readonly customerInteractionService: CustomerInteractionService,
                private readonly interactionService: InteractionService,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
    ) {
    }

    public async execute(request: MergeAddressesRequest,
                         presenter?: MergeAddressesPresenter,
    ): Promise<void> {
        if (!(await this.verifyRequest(request))) {
            return;
        }

        const newAddress = await this.customerInteractionService.mergeAddresses(request.AddressesToMerge);
        if (newAddress == null) {
            return;
        }

        presenter?.displayMergedAddress(newAddress, request.AddressesToMerge);
    }

    private  async verifyRequest(request: MergeAddressesRequest): Promise<boolean> {
        console.log(request?.AddressesToMerge);
        if (request == null || request.AddressesToMerge.length < 2) {
            const title = this.translator.translate("title.select_addresses");
            const message = this.translator.translate("text.select_at_least_two_addresses_to_merge");
            this.messaging.showMessage(Message.blocking({title, message})).then();
            return false;
        }

        if (Array.from(new Set([...request.AddressesToMerge.map(x => x.AddressType)])).length > 1) {
            const title = this.translator.translate("title.different_address_types");
            const message = this.translator.translate("text.can_not_merge_different_address_types");
            this.messaging.showMessage(Message.blocking({title, message})).then();
            return false;
        }

        if (request.AddressesToMerge.find(a => a.IsUsedInOrders) != null) {
            const title = this.translator.translate("title.addresses_are_in_use");
            const message = this.translator.translate("text.orders_with_used_addresses_will_be_updated");
            return await this.interactionService.confirm(title, message);
        }
        return true;
    }
}
