import * as ngx from "@ngx-resource/core";

export class RestService extends ngx.Resource {

    protected $handleErrorResponse(options: ngx.IResourceActionInner,
                                   resp: ngx.IResourceResponse,
    ): any {
        if ("body" in resp && "ErrorMessage" in resp.body) {
            (resp as any).message = resp.body.ErrorMessage;
        }
        return super.$handleErrorResponse(options, resp);
    }
}
