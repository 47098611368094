import {ChangeDetectionStrategy, Component} from "@angular/core";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantBeforeSavingTaskComponent} from "../before-saving/before-saving.component";
import {OnboardingAssistantIglNetCorrectTaskComponent} from "../igl-net-correct/igl-net-correct.component";

@Component({
    selector: "bb-onboarding-assistant-use-igl",
    templateUrl: "./use-igl.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantUseIglTaskComponent {
    
    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
    ) {}

	public next(useIgl: boolean): void {
		this.onboardingStore.setData({useIgl});
		
		if (useIgl) {
			this.onboardingStore.nextTask(OnboardingAssistantIglNetCorrectTaskComponent);
		} else {
			this.onboardingStore.nextTask(OnboardingAssistantBeforeSavingTaskComponent);
		}
    }
}
