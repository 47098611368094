import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { OkDialogComponent } from "@app/app/shared/presentation";
import { TranslationKey } from "@bb-core/entity";
import { ErrorHandlerService, InteractionService, Message, MessagingService } from "@bb-core/service";
import { take } from "rxjs/operators";

/**
 * @deprecated Use the specific services directly
 */
@Injectable({providedIn: "root"})
export class LegacyAlertService implements AlertService {
    constructor(private readonly messaging: MessagingService,
                private readonly matDialog: MatDialog,
                private readonly interaction: InteractionService,
                private readonly errorHandler: ErrorHandlerService,
    ) {
    }

    /** @deprecated Use MessagingService.showError instead */
    public failure(title: string,
                   message?: string,
                   modal?: boolean,
                   onClose?: Function,
    ): void {
        this.messaging.showError(new Message({
            title, message,
            isBlocking: modal,
        })).then(() => onClose == null ? null : onClose());
    }

    /** @deprecated Use MessagingService.showMessage instead */
    public success(title: string, message?: string, modal: boolean = false): void {
        this.messaging.showMessage(new Message({
            title, message,
            isBlocking: modal,
        }));
    }

    /** @deprecated Use MessagingService.showMessage instead */
    public notice(title: string, message?: string, modal?: boolean, onClose?: Function): void {
        this.messaging.showMessage(new Message({
            title, message,
            isBlocking: modal,
        })).then(() => onClose == null ? null : onClose());
    }

    /** @deprecated Use MessagingService.showMessage instead */
    public warning(title: string, message?: string, modal?: boolean, onClose?: Function): void {
        this.messaging.showMessage(new Message({
            title, message,
            isBlocking: modal,
        })).then(() => onClose == null ? null : onClose());
    }

    /** @deprecated Use InteractionService.confirm instead */
    public askOptions(message: string,
                      title: string,
                      option1: string,
                      option2: string,
                      option1CallBack: Function,
                      option2CallBack: Function,
                      $theme?: string,
                      $event?: MouseEvent,
    ): void {
        this.interaction.confirm(title, message, {
            yesText: option1 as TranslationKey,
            noText: option2 as TranslationKey,
        })
            .then(confirmed => {
                const callback = confirmed ? option1CallBack : option2CallBack;
                if (callback) {
                    callback();
                }
            });
    }

    /** @deprecated Use InteractionService.confirm instead */
    public askYesNo(message: string, title: string, yesCallBack?: any, noCallBack?: any): void {
        this.interaction.confirm(title, message)
            .then(confirmed => {
                const callback = confirmed ? yesCallBack : noCallBack;
                if (callback) {
                    callback();
                }
            });
    }

    /** @deprecated Use InteractionService.confirm instead */
    public confirm(message: string, title: string, yesCallBack?: any, noCallBack?: any, $theme?: string, $event?: MouseEvent): void {
        this.askYesNo(message, title, yesCallBack, noCallBack);
    }

    /** @deprecated Use ErrorHandlerService.handleException instead */
    public failureFromHttpResult(result: any, title: string, asModal?: boolean): void {
        this.errorHandler.handleException(result, null, asModal ?? false);
    }

    /** @deprecated Use MatDialog instead */
    public overlay(title: string, message: string, type: string) {
        this.matDialog.open(OkDialogComponent, {
            data: {
                title,
                message,
                showActions: false,
            },
            disableClose: true,
            closeOnNavigation: false,
        }).afterClosed().pipe(take(1));
    }

}
