import {ChangeDetectorRef, NgModule} from "@angular/core";
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FormBuilderComponent, FormBuilderModule, FormBuilderOptions} from "@mintware-de/form-builder";
import * as Fields from "./components";
import {SharedModule} from "../module/shared.module";
import {FormErrorsComponent} from "./form-errors.component";
import {CommonModule} from "@angular/common";
import {MaterialModule} from "../module/material.module";
import {QuillModule} from "ngx-quill";
import {AccountService} from "@app/app/common/service/da/account.service";

@NgModule({
    declarations: [
        FormErrorsComponent,
        Fields.ExpansionPanelComponent,
        Fields.SwitchComponent,
        Fields.TextAreaComponent,
        Fields.TextFieldComponent,
        Fields.SelectFieldComponent,
        Fields.DateFieldComponent,
        Fields.FlexLayoutComponent,
        Fields.TableCollectionComponent,
        Fields.TableRowComponent,
        Fields.SubheadingComponent,
        Fields.DividerComponent,
        Fields.PlainTextComponent,
        Fields.ButtonComponent,
        Fields.TextComponent,
        Fields.CardComponent,
        Fields.RawTextComponent,
        Fields.HtmlInputComponent,
        Fields.AddressGroupComponent,
        Fields.TagsFieldComponent,
        Fields.FormGroupComponent,
        Fields.SimpleCollectionComponent,
        Fields.HiddenFieldComponent,
        Fields.CheckboxComponent,
        Fields.GroupComponent,
        Fields.TabLayoutComponent,
        Fields.ComponentWrapperComponent,
        Fields.HeaderComponent,
        Fields.FileFieldComponent,
        Fields.ToolbarComponent,
        Fields.IconCheckboxComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormBuilderModule,
        SharedModule,
        MaterialModule,
        QuillModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        FormBuilderModule,
    ],
})
export class FormModule {

    constructor(private readonly accountService: AccountService) {
        FormBuilderOptions.preFormSubmit = (builder: FormBuilderComponent) => {
            this.validateAllFormFields(builder.group);
        };
        if (Fields.CountryField.options == null) {
            Fields.CountryField.options = {};
            new Promise<void>(async (resolve) => {
                const countries = await accountService.getCountryList();
                Fields.CountryField.options = countries.reduce((all, c) => ({...all, [c.Value]: c.Text}), {});
                delete Fields.CountryField.options[""];
                resolve(null);
            }).then();
        }
    }

    private validateAllFormFields(formGroup: UntypedFormGroup): void {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl || control instanceof UntypedFormArray) {
                control.markAsTouched();
                control.markAsDirty();
                control.updateValueAndValidity();
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }
}
