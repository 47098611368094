import {Injectable} from "@angular/core";
import {ShipmentRepository} from "../../repository";
import {ClientStorageService, ErrorHandlerService, MessagingService, TranslationService} from "@bb-core/service";
import {
    BulkShipContainer,
    OrderToShip,
    PackageType,
    ShippingOptions, ShippingProductService,
    ShippingProvider, ShippingProviderProduct,
} from "../../entity";
import {ShippingDependenciesService} from "../../service";
import {ApplyWeightPresenter, ApplyWeightUseCase, ApplyWeightRequest} from "../apply-weight.use-case";
import {SortOrdersPresenter, SortOrdersUseCase, SortOrdersRequest} from "./sort-orders.use-case";
import {
    SelectShippingProviderUseCase,
    SelectShippingProviderRequest,
    SelectShippingProviderPresenter,
} from "../select-shipping-provider.use-case";
import {SelectShippingProductPresenter} from "@app/app/shipping/core/use-case";

export enum LoadOrdersType {
    readyForShip,
    selectedOrders,
}

export class LoadOrdersRequest {
    public Type: LoadOrdersType = LoadOrdersType.selectedOrders;

    constructor(obj?: Partial<LoadOrdersRequest>) {
        ctor(this, obj);
    }
}

export abstract class LoadOrdersPresenter implements ApplyWeightPresenter, SelectShippingProductPresenter, SelectShippingProviderPresenter {

    /* Display the orders to ship */
    public abstract displayOrders(container: BulkShipContainer): void;

    /** Display the available shipping providers */
    public abstract displayProvidersAndPackageTypes(providers: ShippingProvider[], packageTypes: PackageType[]): void ;

    public abstract displayNewWeights(order: OrderToShip,
    ): void;

    public abstract displayAvailableShippingProductServices(order: OrderToShip,
                                                            services: ShippingProductService[],
    ): void;

    public abstract preselectShippingProduct(product: ShippingProviderProduct): void;

    public abstract displayAvailableShippingProviderProducts(products: ShippingProviderProduct[]): void;

    public abstract preselectCloudStorage(cloudStorageId: number): void;
    
    public abstract preselectCloudStorageForExportDocs(cloudStorageIdForExportDocs: number): void;
    
    public abstract preselectCloudStorageForRetoureLabels(cloudStorageIdForRetoureLabels: number): void;
}

class InMemoryPresenter implements SortOrdersPresenter {
    public sortedOrders: OrderToShip[] = [];

    public displaySortedOrders(orders: OrderToShip[]): void {
        this.sortedOrders = orders;
    }
}

@Injectable({providedIn: "root"})
export class LoadOrdersUseCase implements IUseCase<LoadOrdersRequest, LoadOrdersPresenter> {

    constructor(private readonly shipmentRepository: ShipmentRepository,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
                private readonly errorHandler: ErrorHandlerService,
                private readonly clientStorage: ClientStorageService,
                private readonly shippingDependenciesService: ShippingDependenciesService,
                private readonly selectShippingProviderUseCase: SelectShippingProviderUseCase,
                private readonly applyWeightUseCase: ApplyWeightUseCase,
                private readonly sortOrdersUseCase: SortOrdersUseCase,
    ) {
    }

    public async execute(request: LoadOrdersRequest, presenter?: LoadOrdersPresenter): Promise<void> {
        const orders = await this.getOrders(request.Type);

        if (orders.length === 0) {
            // Todo: Info, dass keine Bestellungen gefunden wurden?
            return;
        }

        const [shippingProviders, packageTypes] = await this._fetchData();

        const options: ShippingOptions = await this.shipmentRepository.getShippingOptions();
        await this.updateDefaults(orders, options, packageTypes, shippingProviders, presenter);

        const sortOrdersBy = this.getSortOrdersBy();
        const inMemoryPresenter = new InMemoryPresenter();
        await this.sortOrdersUseCase.execute(new SortOrdersRequest({
            Orders: orders,
            By: sortOrdersBy,
        }), inMemoryPresenter);

        const container = new BulkShipContainer();
        container.ShippingDate = LoadOrdersUseCase.getShippingDate();
        container.Orders = inMemoryPresenter.sortedOrders;
        container.SortOrder = sortOrdersBy;
        container.MarkWithState = options.OrderState;
        console.log(container);

        presenter?.displayOrders(container);
        presenter?.displayProvidersAndPackageTypes(shippingProviders, packageTypes);
    }

    private async _fetchData(): Promise<[ShippingProvider[], PackageType[]]> {
        return await Promise.all([
            await this.shippingDependenciesService.loadShippingProviders(),
            await this.shippingDependenciesService.loadPackageTypes(),
        ]) as [ShippingProvider[], PackageType[]];
    }

    private async getOrders(type: LoadOrdersType): Promise<OrderToShip[]> {
        try {
            if (type === LoadOrdersType.selectedOrders) {
                const orderIds = this.clientStorage.get<number[]>("shipping.OrderIdsForShipping", []);
                return await this.shipmentRepository.getOrdersForBulkShip(orderIds);
            } else if (type === LoadOrdersType.readyForShip) {
                return await this.shipmentRepository.getReadyForShip();
            }
        } catch (e) {
            await this.errorHandler.handleException(e, "text.failed_to_load_orders", true);
        }
    }

    private static getShippingDate(): Date {
        const sendingDate = new Date();
        sendingDate.setHours(sendingDate.getHours() + 1);
        return sendingDate;
    }

    private async updateDefaults(orders: OrderToShip[],
                                 options: ShippingOptions,
                                 packageTypes: PackageType[],
                                 shippingProviders: ShippingProvider[],
                                 presenter?: LoadOrdersPresenter,
    ): Promise<void> {
        const defaultShippingPackageId = this.clientStorage.get<number>("shipping.defaultPackageId", null);
        for (const order of orders) {
            order.ShippingPackageId = packageTypes.find((p) => p.Id === order.ShippingPackageId)?.Id
                ?? packageTypes.find((p) => p.Id === defaultShippingPackageId)?.Id
                ?? packageTypes.find((_) => true)?.Id;

            const provider = shippingProviders.find((p) => p.Id === order.ShippingProviderId) ??
                shippingProviders.find((_) => true);

            await this.selectShippingProviderUseCase.execute(new SelectShippingProviderRequest({
                Order: order,
                Provider: provider,
                DefaultShippingProductId: options.DefaultShippingProductId,
            }), presenter);

            await this.applyWeightUseCase.execute(new ApplyWeightRequest({
                Order: order,
                PackageTypes: packageTypes,
                Providers: shippingProviders,
                Type: order.DefaultWeightCalculation,
            }), presenter);
        }
    }

    private getSortOrdersBy(): string {
        let orderBy = this.clientStorage.get<string>("shipping.BulkShipSortOrder", null);
        if (orderBy == null || orderBy === "") {
            orderBy = "OrderDate ASC";
        }
        return orderBy;
    }
}
