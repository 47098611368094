import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {FormControl} from "@angular/forms";
import {take} from "rxjs/operators";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantShippingOverseasTaskComponent} from "../shipping-overseas/shipping-overseas.component";
import {OnboardingAssistantVatModeTaskComponent} from "../vat-mode/vat-mode.component";

@Component({
    selector: "bb-onboarding-assistant-vat-id",
    templateUrl: "./vat-id.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantVatIdTaskComponent implements OnInit {
	public readonly vatId = new FormControl("");
	public readonly vatIdValidFrom = new FormControl(new Date());

    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
	) {}
	
	public ngOnInit(): void {
		 this.onboardingStore.data$
             .pipe(
                 take(1),
             )
             .subscribe(({vatId, vatIdValidFrom}) => {
				 this.vatId.setValue(vatId);
				 this.vatIdValidFrom.setValue(vatIdValidFrom || this.vatIdValidFrom.value);
             });
	}

	public next(saveVatId: boolean): void {
		if (saveVatId) {
			this.onboardingStore.setData({vatId: this.vatId.value, vatIdValidFrom: this.vatIdValidFrom.value});
		}
		this.onboardingStore.data$.pipe(take(1)).subscribe(({country}) => {
			if (country === "DE") {
				this.onboardingStore.nextTask(OnboardingAssistantVatModeTaskComponent);
			} else {
				this.onboardingStore.setData({vatMode: 0});
				this.onboardingStore.nextTask(OnboardingAssistantShippingOverseasTaskComponent);
			}
		});
    }
}
