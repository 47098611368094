import {Component, EventEmitter, Inject} from "@angular/core";
import {MessageTemplateRepository} from "@app/app/settings/automation/core/repository";
import {MessageTemplateListViewModel} from "./list.view-model";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
    selector: "bb-message-template-list",
    templateUrl: "list.component.html",
})
export class MessageTemplateListComponent {
    public static refreshListEmitter = new EventEmitter();

    public readonly viewModel: MessageTemplateListViewModel = new MessageTemplateListViewModel();

    public isListVisible: boolean = false;

    constructor(private readonly messageTemplateRepository: MessageTemplateRepository,
                private readonly router: Router,
                @Inject("multiSelectService") public readonly multiSelect: MultiSelectService,
                @Inject("$alert") private alert: AlertService,
                @Inject("$translate") private $translate: ng.translate.ITranslateService,
    ) {
        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).forEach((e: NavigationEnd) => {
            this.isListVisible = e.url.endsWith("message-templates");
        });
        MessageTemplateListComponent.refreshListEmitter.subscribe((_) => this.fetchData().then());
        this.fetchData().then();
    }

    private async fetchData(): Promise<void> {
        this.viewModel.templates = await this.messageTemplateRepository.getAll();
    }

    public confirmDeleteSelected(): void {
        this.alert.askYesNo(this.$translate.instant("Sollen die ausgewählten Benachrichtigungstexte gelöscht werden?"),
            this.$translate.instant("Benachrichtigungstexte löschen"),
            this.doDeleteSelected.bind(this),
            () => null,
        );
    }

    public async doDeleteSelected(): Promise<void> {
        try {
            const selectedIds = this.multiSelect.getSelectedItemsIdArray();
            this.viewModel.templates = await this.messageTemplateRepository.deleteMany(selectedIds);
            this.multiSelect.resetSelection();
        } catch (e) {
            this.alert.failureFromHttpResult(e, this.$translate.instant("Fehler beim Abruf der Daten"));
        }
    }

}
