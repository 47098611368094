import {AbstractLayoutComponent, AbstractLayoutType, IGroupTypeOptions} from "@mintware-de/form-builder";
import {ButtonOptions} from "@app/app/common/form/button/button.options";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";

@Component({
    selector: "bb-form-button",
    template: `
        <ng-container [ngSwitch]="mwFieldType.options.appearance">
            <button *ngSwitchCase="'raised'"
                    (click)="mwFieldType.options.onClick && onClick($event)"
                    [disabled]="disabled"
                    [color]="mwFieldType.options.color"
                    [ngStyle]="{fontWeight: mwFieldType.options.fontWeight, textDecoration: mwFieldType.options.textDecoration}"
                    [matTooltip]="mwFieldType.options.tooltip | translate"
                    [hidden]="!mwFieldType.options.visible"
                    mat-raised-button
                    [type]="mwFieldType.options.type">
                <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
            </button>
            <button *ngSwitchCase="'flat'"
                    (click)="mwFieldType.options.onClick && onClick($event)"
                    [disabled]="disabled"
                    [color]="mwFieldType.options.color"
                    [ngStyle]="{fontWeight: mwFieldType.options.fontWeight, textDecoration: mwFieldType.options.textDecoration}"
                    [matTooltip]="mwFieldType.options.tooltip | translate"
                    [hidden]="!mwFieldType.options.visible"
                    mat-flat-button
                    [type]="mwFieldType.options.type">
                <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
            </button>
            <button *ngSwitchCase="'icon'"
                    (click)="mwFieldType.options.onClick && onClick($event)"
                    [disabled]="disabled"
                    [color]="mwFieldType.options.color"
                    [ngStyle]="{fontWeight: mwFieldType.options.fontWeight, textDecoration: mwFieldType.options.textDecoration}"
                    [matTooltip]="mwFieldType.options.tooltip | translate"
                    [hidden]="!mwFieldType.options.visible"
                    mat-icon-button
                    [type]="mwFieldType.options.type">
                <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
            </button>
            <button *ngSwitchDefault
                    (click)="mwFieldType.options.onClick && onClick($event)"
                    [disabled]="disabled"
                    [color]="mwFieldType.options.color"
                    [ngStyle]="{fontWeight: mwFieldType.options.fontWeight, textDecoration: mwFieldType.options.textDecoration}"
                    [matTooltip]="mwFieldType.options.tooltip | translate"
                    [hidden]="!mwFieldType.options.visible"
                    mat-button
                    [type]="mwFieldType.options.type">
                <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
            </button>
        </ng-container>
        <ng-template #buttonContentTemplate>
            <mat-icon *ngIf="mwFieldType.options.icon" [fontIcon]="mwFieldType.options.icon"></mat-icon>
            {{ mwFieldType.options.text | translate  }}
        </ng-template>
        <!-- Fake submit button -->
        <button style="display: none" *ngIf="mwFieldType.options.type != 'submit'" type="submit"></button>
    `,
})
export class ButtonComponent extends AbstractLayoutComponent<AbstractLayoutType<ButtonOptions & IGroupTypeOptions>>
    implements OnInit, OnDestroy {

    private readonly subscriptions: Subscription[] = [];
    public disabled: boolean = false;

    public onClick(event: MouseEvent): void {
        return this.mwFieldType.options.onClick(event, this.mwFieldType.builderInstance);
    }

    public ngOnInit(): void {
        this.subscriptions.push(this.mwElement.statusChanges.subscribe(() => {
            this.disabled = this.mwElement.disabled;
        }));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
