<div class="description">
	{{ "text.is_igl_invoice_net_correct" | translate }}
</div>
<div class="choose-button-wrapper">
    <button mat-stroked-button color="primary" (click)="next('0')">
		{{ "button.net_correct" | translate }}
	</button>
    <button mat-stroked-button color="primary" (click)="next('1')">
		{{ "button.billbee_correction_is_country" | translate }}
	</button>
	<button mat-stroked-button color="primary" (click)="next('2')">
		{{ "button.billbee_correction_target_country" | translate }}
	</button>
</div>