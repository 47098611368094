import {inject, Injectable} from "@angular/core";
import {EMPTY, fromEvent, Observable, race, timer} from "rxjs";
import {filter, finalize, map, take, tap} from "rxjs/operators";
import {MessagingService} from "../core/service";
import {PopupData} from "../model/popup-data";
import {PopupType} from "../model/popup-type.enum";

@Injectable({
    providedIn: "root",
})
export class PopupService {
    private popups: {[key: string]: Window} = {};

    private readonly messageService = inject(MessagingService);

    getMessage<T>(url: string, type: PopupType, width = 640, height = 480): Observable<T> {
        if (this.popups[type]) {
            this.popups[type].close();
        }

        const popup: Window | null = window.open(url, type, `popup=true,width=${width},height=${height}`);

        if (!popup) {
            this.messageService.showSnackBar("flash.could_not_open_popup", {}, 8000);
            return EMPTY;
        }

        this.popups[type] = popup;

        const channel = new BroadcastChannel(type);
		return fromEvent(channel, "message").pipe(
			take(1),
            filter((message): message is MessageEvent<PopupData<T>> => !!message),
            tap(() => channel.postMessage({received: true})),
            map(({data}) => data.data),
            finalize(() => {
                popup?.close();
                channel.close();
            }),
        );
    }

    emitMessage(type: PopupType, data: unknown, fallback?: () => void): void {
        const channel = new BroadcastChannel(type);
        race([
            fromEvent<MessageEvent<{received: boolean}>>(channel, "message").pipe(
                filter(({data}) => !!data.received),
                tap(() => {
                    channel.close();
                    window.close();
                }),
            ),
            timer(100).pipe(
				tap(() => {
                    channel.close();
                    fallback?.();
                }),
            ),
        ])
            .pipe(take(1))
            .subscribe();
        channel.postMessage({type, data});
    }
}
