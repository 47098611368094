<div class="hint-wrapper">
    <mat-icon fontIcon="fa-circle-info"></mat-icon>
    <span [innerHTML]="'text.html_shopify_connect_hint' | translate"></span>
</div>
<bb-input ngModel name="Id" disabled hidden></bb-input>
<div class="api-token-wrapper">
    <bb-input
        ngModel
        name="ShopifyAccessToken"
        [label]="'label.api_token' | translate"
        [type]="apiTokenFieldType()"
        disabled
    ></bb-input>
    <button mat-icon-button (click)="toggleApiTokenFieldType()">
        <mat-icon [fontIcon]="apiTokenFieldType() === 'password' ? 'fa-eye' : 'fa-eye-slash'"></mat-icon>
    </button>
</div>
<bb-input ngModel name="ShopName" [label]="'label.shopname' | translate" disabled></bb-input>

<button mat-stroked-button color="primary" (click)="onClickReAuth()">{{ "title.reauth_shop" | translate }}</button>
