import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {TranslateModule} from "@ngx-translate/core";
import {BbInputComponent} from "../../../../../shared/ui/form/bb-input/bb-input.component";

@Component({
    selector: "bb-kaufland-api-key",
    templateUrl: "./kaufland-api-key-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        BbInputComponent,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
    ],
})
export class KauflandApiKeyDialogComponent {
    public readonly form = new FormGroup({
        kauflandApiKey: new FormControl("", Validators.required),
        kauflandApiSecret: new FormControl("", Validators.required),
    });

    private readonly dialogRef = inject(MatDialogRef);

    public continue(): void {
        if (this.form.invalid) {
            return;
        }

        this.dialogRef.close(this.form.value);
    }
}
