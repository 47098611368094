import {Injectable, inject} from "@angular/core";
import {Router} from "@angular/router";
import {Observable, ignoreElements, switchMap, tap} from "rxjs";
import {PopupType} from "../../../../shared/model/popup-type.enum";
import {PopupService} from "../../../../shared/service/popup.service";
import {ShopKey} from "../../model/shop-key";
import {ShopOauthPopupMessage} from "../../model/shop-oauth-popup-message";
import {ShopsService} from "../shops.service";

@Injectable({
    providedIn: "root",
})
export class OauthShopAddCommand {
    private readonly router = inject(Router);
    private readonly popupService = inject(PopupService);
    private readonly shopsService = inject(ShopsService);

    public execute(url$: Observable<string>, key: ShopKey, logo: string, altFecthingUrlText?: string): Observable<never> {
        return this.shopsService
            .showShopLoadingDialogForRequest(
                url$,
                logo,
                "title.add_shop",
                altFecthingUrlText || "text.fetching_partner_url",
            )
            .pipe(
                switchMap((url) =>
                    this.shopsService.showShopLoadingDialogForRequest(
                        this.popupService.getMessage<ShopOauthPopupMessage>(url, PopupType.ConnectShopOauth),
                        logo,
                        "title.add_shop",
                        "text.add_shop_description",
                    ),
                ),
                tap(({code, state, shop, t, shopId, SessionId, username}) =>
                    this.router.navigate([`settings/shops/oauth/add/${key}`], {
                        queryParams: {code, state, shop, t, shopId, SessionId, username},
                    }),
                ),
                ignoreElements(),
            );
    }
}
