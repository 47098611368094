export class CustomFieldValue {
    public Id?: number = null;
    public DefinitionId: number = null;
    public ArticleId?: number = null;
    public Value: any = null;

    constructor(obj?: Partial<CustomFieldValue>) {
        ctor(this, obj);
    }
}
