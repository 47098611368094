import {ChangeDetectionStrategy, Component} from "@angular/core";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantBeforeSavingTaskComponent} from "../before-saving/before-saving.component";

@Component({
    selector: "bb-onboarding-assistant-igl-net-correct",
    templateUrl: "./igl-net-correct.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantIglNetCorrectTaskComponent {
    
    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
    ) {}

	public next(iglVatMode: string): void {
		this.onboardingStore.setData({iglVatMode});
		
		this.onboardingStore.nextTask(OnboardingAssistantBeforeSavingTaskComponent);
    }
}
