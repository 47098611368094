import {BaseType} from "../base/base.type";
import {DateFieldOptions} from "./date-field.options";
import {Constructor} from "@mintware-de/form-builder";
import {DateFieldComponent} from "./date-field.component";

export class DateField extends BaseType<DateFieldOptions> {
    public readonly component: Constructor = DateFieldComponent;

    constructor(options: DateFieldOptions) {
        super(Object.assign({
            startView: "month",
            withTime: false,
        } as DateFieldOptions, options));
    }
}
