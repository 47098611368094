import {ChangeDetectionStrategy, Component} from "@angular/core";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";

@Component({
    selector: "bb-onboarding-assistant-finish",
    templateUrl: "./finish.component.html",
    styleUrls: ["../tasks.scss", "./finish.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantFinishTaskComponent {
    public readonly data$ = this.onboardingStore.data$;

    constructor(private readonly onboardingStore: OnboardingAssistantComponentStore) {}
}
