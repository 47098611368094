import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AutofocusDirective } from "@app/app/common/directive/autofocus.directive";
import { HelpLinkDirective } from "@app/app/common/directive/help-link.directive";
import { MetaCardComponent } from "@app/app/common/directive/meta-card.component";
import { MaterialModule } from "@app/app/common/module/material.module";
import { DefaultLanguagePipe } from "@app/app/common/pipe/default-language.pipe";
import { SafeContentPipe } from "@app/app/common/pipe/safe-content.pipe";
import { StripTagsPipe } from "@app/app/common/pipe/strip-tags.pipe";
import { TranslateLabelPipe } from "@app/app/common/pipe/translate-label.pipe";
import { MessageTemplateRepository } from "@app/app/settings/automation/core/repository";
import { ApiMessageTemplateRepository } from "@app/app/settings/automation/data";
import {
    BbCardComponent,
    BbColumnComponent,
    BbIconBadgeDirective,
    BbRowComponent,
    ConfirmDialogComponent,
    NoDataContainerComponent,
    OkDialogComponent,
} from "@app/app/shared/presentation";
import { TranslateModule } from "@ngx-translate/core";
import { BbLoadingCardComponent } from "../../ui/bb-loading-card/bb-loading-card.component";
import { DebugPipe } from "../pipe/debug.pipe";
import { TranslatePipe } from "../pipe/translate.pipe";

@NgModule({
    declarations: [
        DebugPipe,
        TranslateLabelPipe,
        MetaCardComponent,
        SafeContentPipe,
        AutofocusDirective,
        DefaultLanguagePipe,
        StripTagsPipe,
        HelpLinkDirective,
        TranslatePipe,
        NoDataContainerComponent,
        ConfirmDialogComponent,
        OkDialogComponent,
        BbCardComponent,
        BbRowComponent,
        BbColumnComponent,
        BbIconBadgeDirective,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MaterialModule,
        BbLoadingCardComponent,
    ],
    exports: [
        DebugPipe,
        TranslateLabelPipe,
        TranslatePipe,
        CommonModule,
        TranslateModule,
        MetaCardComponent,
        MaterialModule,
        SafeContentPipe,
        AutofocusDirective,
        NoDataContainerComponent,
        DefaultLanguagePipe,
        StripTagsPipe,
        HelpLinkDirective,
        BbCardComponent,
        BbRowComponent,
        BbColumnComponent,
        BbIconBadgeDirective,
        OkDialogComponent,
    ],
    providers: [
		{provide: MessageTemplateRepository, useClass: ApiMessageTemplateRepository},
	],
})
export class SharedModule {}
