export class SignInRequest {
    public UserName: string = "";
    public Password: string = "";
    public RememberMe: boolean = false;
    public Otp?: string = null;

    public constructor(obj?: Partial<SignInRequest>) {
        if (!obj) {
            return;
        }
        Object.keys(this).forEach(k => {
            if (k in obj) {
                this[k] = obj[k];
            }
        });
    }
}
