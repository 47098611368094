import {ChangeDetectionStrategy, Component} from "@angular/core";
import {OnboardingAssistantComponentStore} from "../../container/onboarding-assistant-dialog/onboarding-assistant.store";
import {OnboardingAssistantBeforeSavingTaskComponent} from "../before-saving/before-saving.component";
import {OnboardingAssistantFurtherCountriesVatIdsTaskComponent} from "../further-country-vat-ids/further-country-vat-ids.component";

@Component({
    selector: "bb-onboarding-assistant-shipping-overseas",
    templateUrl: "./shipping-overseas.component.html",
    styleUrls: ["../tasks.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantShippingOverseasTaskComponent {
    
    constructor(
        private readonly onboardingStore: OnboardingAssistantComponentStore,
    ) {}

	public next(shippingOverseas: boolean): void {
		if (shippingOverseas) {
			this.onboardingStore.nextTask(OnboardingAssistantFurtherCountriesVatIdsTaskComponent);
		} else {
			this.onboardingStore.nextTask(OnboardingAssistantBeforeSavingTaskComponent);
		}
    }
}
