import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {AmazonScpnAuthDialogComponent} from "@app/app/oauth/extensions/amazon";
import {switchMap, take} from "rxjs/operators";
import {Observable} from "rxjs";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector : "bb-amazon-callback",
    template : "",
})
export class AmazonScpnAuthComponent implements OnInit {

    constructor(
        private readonly  router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialog: MatDialog,
    ) {}

    public ngOnInit(): void {
        this.route.queryParams.pipe(
            take(1),switchMap(queryParams => this.amazonScpnAuth(queryParams))).subscribe();
        // setTimout required. Execution in same event loop iteration destroys layout for some reason.
        setTimeout(() => this.router.navigateByUrl(`/settings`));
    }

    public amazonScpnAuth(amazonScpnAuthData: object): Observable<any> {
        return this.dialog.open(AmazonScpnAuthDialogComponent, {
            panelClass : "bb-amazon-scpn-auth-dialog",
            data : amazonScpnAuthData,
        }).afterClosed();
    }
}
