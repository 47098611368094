import { Injectable } from "@angular/core";
import { JavascriptSnippetService } from "./da/javascript-snippet.service";
import { JavascriptSnippet } from "@app/app/common/model/javascript-snippet";
import { JavascriptSnippetContainer } from "@app/app/common/model/javascript-snippet";

@Injectable({
    providedIn: "root",
})

export class JavascriptSnippetHelperService {

    private cachedJsSnippets: JavascriptSnippetContainer;

    constructor(private javascriptSnippetService: JavascriptSnippetService) {
    }

    public async loadAndExecuteJsSnippets(currentUrl: string, executePageLoadScripts: boolean): Promise<void> {

        if (this.cachedJsSnippets == null) {
            this.cachedJsSnippets = await this.javascriptSnippetService.getJsSnippets({});
        }

        if (executePageLoadScripts) {
            console.log("Executing page load snippets");
            this.attachJs(this.cachedJsSnippets.pageload, currentUrl);
        }

        console.log("Executing nav snippets");
        this.attachJs(this.cachedJsSnippets.nav, currentUrl);
    }

    private attachJs(jsSnippets: JavascriptSnippet[], url: string): void {

        for (const jsSnippet of jsSnippets) {
            if (url.match(jsSnippet.UrlFilter)) {

                const id = "jss-" + jsSnippet.Id;
                let element = document.getElementById(id);

                if (element != null && jsSnippet.LoadOnce === false) {
                    console.log("removing snippet " + jsSnippet.Name);
                    element.parentNode.removeChild(element);
                    element = null;
                }

                if (element == null) {
                    console.log("loading snippet " + jsSnippet.Name);

                    const node = document.createElement("script");
                    node.async = false;
                    node.setAttribute("id", id);
                    node.type = "text/javascript";
                    node.charset = "utf-8";
                    node.appendChild(document.createTextNode(jsSnippet.Content));
                    document.head.appendChild(node);
                }
            }
        }
    }
}
