/* eslint-disable */
import {Injectable} from "@angular/core";

import {
    Customer,
    CustomerExportSelection,
    CustomerExportSettings,
    CustomerInteractionService,
    LegacyCustomerRepository,
    DirectionEnum,
    FileFormat,
} from "@app/app/customer/core";
import {ClientStorageService, Message, MessagingService, TranslationService} from "@bb-core/service";
import {Column} from "@app/app/grid/core";

/**
 * @deprecated
 */
export class ExportCustomersRequest {

    public SelectedIds: number[] = null;
    public Columns: Column<Customer>[] = null;

    constructor(obj?: Partial<ExportCustomersRequest>) {
        ctor(this, obj);
    }
}

export abstract class ExportCustomersPresenter {
}

@Injectable({providedIn: "root"})
export class ExportCustomersUseCase implements IUseCase<ExportCustomersRequest, ExportCustomersPresenter> {

    constructor(private readonly customerInteractionService: CustomerInteractionService,
                private readonly customerRepository: LegacyCustomerRepository,
                private readonly clientStorage: ClientStorageService,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
    ) {
    }

    public async execute(request: ExportCustomersRequest): Promise<void> {
        const exportFormats = await this.getExportFormats();
        const hasFilter = request.Columns?.find(x => x.Filter != null) != null;
        let exportSettings = this.getInitialExportSettings(request, exportFormats, hasFilter);
        const hasSelectedIds = request.SelectedIds != null && request.SelectedIds.length > 0;
        exportSettings = await this.customerInteractionService.enterExportSettings(
            exportSettings,
            exportFormats,
            hasFilter,
            hasSelectedIds,
        );

        if (exportSettings == null) {
            return;
        }

        exportSettings.SelectedIds = exportSettings.Selection === CustomerExportSelection.selected && hasSelectedIds
            ? request.SelectedIds
            : null;

        exportSettings.Columns = exportSettings.Selection === CustomerExportSelection.filtered && hasFilter
            ? request.Columns
            : null;

        await this.startExport(exportSettings);
    }

    private async startExport(exportSettings: CustomerExportSettings): Promise<void> {
        const message = this.translator.translate("flash.customer_export_started");
        await this.messaging.showMessage(Message.transient({message}));
        await this.customerRepository.startExport(exportSettings);
    }

    private async getExportFormats(): Promise<FileFormat[]> {
        const fileFormats = await this.customerRepository.getFileFormats();
        return fileFormats.filter((f) => f.FileFormatDirection === DirectionEnum.Export);
    }

    private getInitialExportSettings(request: ExportCustomersRequest,
                                     exportFormats: FileFormat[],
                                     hasFilter: boolean,
    ): CustomerExportSettings {
        let selection = request.SelectedIds == null || request.SelectedIds.length === 0
            ? CustomerExportSelection.all
            : CustomerExportSelection.selected;

        if (hasFilter && selection !== CustomerExportSelection.selected) {
            selection = CustomerExportSelection.filtered;
        }

        let exportFormat = this.clientStorage.get<string>("customer.export_format");
        if (!exportFormats.find(f => f.Value === exportFormat)) {
            exportFormat = exportFormats.find((_) => true)?.Value;
        }

        return new CustomerExportSettings({
            Selection: selection,
            FileFormat: exportFormat,
        });
    }
}
