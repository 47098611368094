import {AbstractLayoutType, Constructor} from "@mintware-de/form-builder";
import {ToolbarOptions} from "./toolbar.options";
import {ToolbarComponent} from "./toolbar.component";

export class Toolbar extends AbstractLayoutType<ToolbarOptions> {
    public readonly component: Constructor = ToolbarComponent;

    constructor(options: ToolbarOptions) {
        super({
            ...options,
        });
    }
}
