import {Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {MessageTemplateFormComponent} from "@app/app/settings/automation/presentation";

@Injectable({providedIn: "root"})
export class MessageTemplateService {
    constructor(
        private readonly matDialog: MatDialog,
    ) {
    }

    public async editMessageTemplate(id: number): Promise<void> {
        const dialogRef = this.matDialog.open(MessageTemplateFormComponent, {
            data: {id},
        });

        return new Promise(resolve => {
            dialogRef.afterClosed().subscribe(_ => resolve());
        });
    }
}
