import {Address} from "@app/app/shipping/core";

export class SingleOrderShippedEvent {
    public OrderId: number = null;
    public Success: boolean = null;
    public Message: string = null;
    public InfoMessages: string[] = null;
    public BatchId: string = null;
    public ProviderId: number = null;
    public ProviderName: string = null;
    public LabelCount: number = null;
    public ExportDocCount: number = null;
    public RetoureLabelCount: number = null;
    public FileTypeText: string = null;
    public ShippingId: string = null;
    public OrderRef: string = null;
    public OrderNumber: string = null;
    public Created: Date = new Date();
    public ShippingAddress: Address = new Address();

    constructor(obj?: Partial<SingleOrderShippedEvent>) {
        ctor(this, obj, {
            DateFields: ["Created"],
            NumberFields: ["OrderId", "ProviderId", "LabelCount", "ExportDocCount", "RetoureLabelCount"],
            Relations: {
                ShippingAddress: Address,
            },
        });
    }
}
