import {AbstractType, Constructor} from "@mintware-de/form-builder";
import {CheckboxOptions} from "./checkbox.options";
import {CheckboxComponent} from "@app/app/common/form/checkbox/checkbox.component";

export class Checkbox extends AbstractType<CheckboxOptions> {
    public readonly component: Constructor = CheckboxComponent;
    constructor(options: CheckboxOptions) {
        super(Object.assign({
            required: false,
            visible: true,
        }, options));
    }
}
