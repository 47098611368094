import {ColumnLayout, CountryField, HiddenField, RawText, TableCollection, Text} from "@app/app/common/form/types";
import {AddressTypeEnum} from "@app/app/shipping/core";
import {
    CustomerAddress,
    EditCustomerAddressRequest,
    EditCustomerAddressUseCase,
    LoadAddressesPresenter,
    LoadAddressesRequest,
    LoadAddressesUseCase,
    MergeAddressesPresenter,
    MergeAddressesRequest,
    MergeAddressesUseCase,
} from "@app/app/customer/core";
import {Injector} from "@angular/core";
import {InteractionService, Message, MessagingService, TranslationService} from "@bb-core/service";
import {AbstractControl, UntypedFormArray, ValidatorFn} from "@angular/forms";

export class CustomerAddressesCollection extends TableCollection<CustomerAddress>
    implements MergeAddressesPresenter, LoadAddressesPresenter {

    private selectedAddresses: CustomerAddress[] = [];
    private selectedAddressIndexes: number[] = [];
    private readonly editCustomerAddressUseCase: EditCustomerAddressUseCase;
    private readonly mergeAddressesUseCase: MergeAddressesUseCase;
    private readonly loadAddressesUseCase: LoadAddressesUseCase;
    private readonly translator: TranslationService;
    private readonly messaging: MessagingService;
    private readonly interaction: InteractionService;
    public addressRequired: boolean = false;

    constructor(injector: Injector,
                private readonly onAddressesMerged: (targetId: number, sourceIds: number[]) => void) {
        super({
            allowAdd: false,
            allowDelete: false,
            allowEdit: true,
            required: true,
            onClickEdit: (index, data) => this.editAddress(index, new CustomerAddress(data)),
            noDataText: "text.no_customer_addresses",
            selectMode: "multiple",
            selectionChanged: (indexes, rows) => {
                this.selectedAddressIndexes = indexes;
                this.selectedAddresses = rows.map((x) => new CustomerAddress(x));
            },
            entrySettings: {
                columns: [
                    {
                        // Hidden fields
                        content: new ColumnLayout<CustomerAddress>({
                            model: {
                                "Id": new HiddenField(),
                                "CustomerId": new HiddenField(),
                                "FirstName": new HiddenField(),
                                "LastName": new HiddenField(),
                                "Street": new HiddenField(),
                                "Housenumber": new HiddenField(),
                                "Title": new HiddenField(),
                                "Salutation": new HiddenField(),
                                "PhoneNumbers": new HiddenField(),
                                "MailAddresses": new HiddenField(),
                                "IsUsedInOrders": new HiddenField(),
                                "IsArchived": new HiddenField(),
                            },
                        }),
                        title: "",
                        isHidden: true,
                    },
                    {
                        field: "AddressType",
                        content: new Text({
                            builder: (x) => `label.address_type_${AddressTypeEnum[Number(x)].toString().toLowerCase()}`,
                        }),
                        title: "label.address_type",
                    },
                    {
                        field: "Company",
                        content: new Text({}),
                        title: "label.company",
                    },
                    {
                        field: "FirstName",
                        content: new Text({}),
                        title: "label.first_name",
                    },
                    {
                        field: "LastName",
                        content: new Text({}),
                        title: "label.last_name",
                    },
                    {
                        field: "Name2",
                        content: new Text({}),
                        title: "label.name2",
                    },
                    {
                        field: "FullStreet",
                        content: new RawText({
                            builder: (_, component) => [
                                component.mwElement.value.Street,
                                component.mwElement.value.Housenumber,
                            ].join(" ").trim(),
                        }),
                        title: "label.street",
                    },
                    {
                        field: "AddressAddition",
                        content: new Text({}),
                        title: "label.address_addition",
                    },
                    {
                        field: "Zip",
                        content: new Text({}),
                        title: "label.zip_code",
                    },
                    {
                        field: "City",
                        content: new Text({}),
                        title: "label.city",
                    },
                    {
                        field: "State",
                        content: new Text({}),
                        title: "label.state",
                    },
                    {
                        field: "CountryCode",
                        content: new Text({
                            builder: (countryCode) => CountryField.options[countryCode],
                        }),
                        title: "label.country",
                    },
                    {
                        field: "IsUsedInOrders",
                        content: new Text({
                            builder: (x) => x ? "label.yes" : "label.no",
                        }),
                        title: "label.is_used_in_orders",
                    },
                    {
                        title: "",
                        isActionsColumn: true,
                    },
                ],
            },
        });
        this.editCustomerAddressUseCase = injector.get(EditCustomerAddressUseCase);
        this.mergeAddressesUseCase = injector.get(MergeAddressesUseCase);
        this.loadAddressesUseCase = injector.get(LoadAddressesUseCase);
        this.translator = injector.get(TranslationService);
        this.messaging = injector.get(MessagingService);
        this.interaction = injector.get(InteractionService);
    }

    public async addAddress() {
        await this.editAddress(this.control.controls.length, new CustomerAddress({
            AddressType: this.control.controls.length === 0 ? AddressTypeEnum.Invoice : AddressTypeEnum.Ship,
        }));
    }

    private async editAddress(index: number, address: CustomerAddress): Promise<void> {
        let updated: CustomerAddress;
        let created: CustomerAddress;

        await this.editCustomerAddressUseCase.execute(new EditCustomerAddressRequest({
            Address: address,
        }), {
            displayUpdatedAddress(updatedAddress: CustomerAddress): void {
                updated = updatedAddress;
            },
            displayNewAddress(newAddress: CustomerAddress): void {
                created = newAddress;
            },
        });

        if (updated != null) {
            this.control.get([index]).patchValue(updated);
        } else if (created != null) {
            this.componentInstance.addEntry();
            this.control.get([this.control.length - 1]).patchValue(created);
        }

    }

    public async deleteSelectedAddresses(): Promise<void> {
        if (!(await this.confirmDeleteAddresses(this.selectedAddresses))) {
            return;
        }
        this.removeSelectedRows();
    }

    public async mergeSelectedAddresses(): Promise<void> {
        await this.mergeAddressesUseCase.execute(new MergeAddressesRequest({
            AddressesToMerge: this.selectedAddresses,
        }), this);
    }

    public displayMergedAddress(address: CustomerAddress, sourceAddresses: CustomerAddress[]): void {
        this.removeSelectedRows();
        this.componentInstance.addEntry();
        this.control.get([this.control.controls.length - 1]).patchValue(address);
        this.onAddressesMerged?.call(this.onAddressesMerged, address.Id, sourceAddresses.map(x => x.Id));
    }

    private removeSelectedRows(): void {
        const indexesToRemove = [...this.selectedAddressIndexes].map(Number).sort().reverse();
        indexesToRemove.forEach(i => this.componentInstance.removeEntry(i, true));
        this.componentInstance.resetSelection();
    }

    private async confirmDeleteAddresses(addresses: CustomerAddress[]): Promise<boolean> {
        if (addresses.filter(a => a.IsUsedInOrders).length > 0) {
            await this.messaging.showMessage(Message.blocking({
                title: this.translator.translate("title.addresses_are_in_use"),
                message: this.translator.translate("text.used_addresses_can_not_be_deleted"),
            }));
            return false;
        }

        if (addresses.length === 0) {
            this.messaging.showMessage(Message.blocking({
                title: this.translator.translate("title.select_addresses"),
                message: this.translator.translate("text.select_at_least_one_address_to_delete"),
            })).then();
            return false;
        }

        const title = this.translator.translate("title.delete_addresses");
        const count = addresses.length;
        const message = this.translator.translate(
            count === 1 ? "text.delete_single_address" : "text.delete_multiple_addresses",
            {"count": count},
        );

        return await this.interaction.confirm(title, message);
    }

    public async loadAddresses(customerId: number): Promise<void> {
        this.addressRequired = true;
        if (customerId == null) {
            return;
        }
        await this.loadAddressesUseCase.execute(new LoadAddressesRequest({
            CustomerId: customerId,
        }), this);
    }

    // region LoadAddressesPresenter

    public displayAddresses(addresses: CustomerAddress[]): void {
        this.control.clear();
        for (const address of addresses) {
            this.componentInstance.addEntry();
        }
        this.control.patchValue(addresses);
        this.control.markAsPristine();
    }

    // endregion

    public get validators(): ValidatorFn[] {
        const validators: ValidatorFn[] = [];
        validators.push((control: AbstractControl) => {
            const validationErrors: any = {};
            if (control instanceof UntypedFormArray && this.addressRequired) {
                if (control.controls.length === 0) {
                    validationErrors.required = true;
                } else if (control.controls.filter(x => x.get("AddressType").value == 1).length === 0) {
                    validationErrors.custom = "error.add_invoice_address";
                }
            }
            return validationErrors;
        });
        return validators;
    }
}
