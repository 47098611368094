import {Payment} from "./payment";

export class CustomerPaymentListItem extends Payment {
    public "Order.ExternalReference": string = null;
    public "Order.ExternalId": string = null;
    public "Order.ExtId": number = null;

    constructor(obj: Partial<CustomerPaymentListItem>) {
        super(obj);
        ctor(this, obj, {
            DateFields: ["Created", "PayDate"],
            NumberFields: ["Id", "PayValue", "OrderId", "PaymentType", "Fee"],
        });
    }
}
