import {AbstractLayoutType, Constructor, IGroupTypeOptions} from "@mintware-de/form-builder";
import {ButtonOptions} from "@app/app/common/form/button/button.options";
import {ButtonComponent} from "@app/app/common/form/button/button.component";

export class Button extends AbstractLayoutType<ButtonOptions & IGroupTypeOptions> {
    public readonly component: Constructor = ButtonComponent;

    constructor(options: ButtonOptions) {
        super({
            model: {},
            fontWeight: 500,
            textDecoration: "none",
            type: "button",
            visible: true,
            ...options,
        } as ButtonOptions & IGroupTypeOptions);
    }

    public get visible(): boolean {
        return this.options.visible ?? true;
    }

    public set visible(value: boolean) {
        if (this.options.visible !== value) {
            this.options.visible = value;
        }
    }

    public get text(): string {
        return this.options.text;
    }

    public set text(value: string) {
        if (this.options.text !== value) {
            this.options.text = value;
        }
    }

}
