export enum OrderState {
    ordered = 1,
    confirmed = 2,
    paid = 3,
    shipped = 4,
    complained = 5,
    deleted = 6,
    completed = 7,
    canceled = 8,
    archived = 9,
    rated = 10,
    warning = 11,
    warning2 = 12,
    packed = 13,
    offered = 14,
    reminder = 15,
    inFulfillment = 16,    
    retoure = 17,
}
