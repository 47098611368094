export class AvailableLanguages {
    public DefaultLanguage: string = null;
    public OtherLanguages: string[] = [];
    public AllLanguages: string[] = [];

    constructor(obj?: Partial<AvailableLanguages>) {
        ctor(this, obj);
    }
}

