import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {ArticleSelection} from "./model/article-selection.model";

@Injectable({providedIn: "root"})
export class ArticleService {
	private readonly _articleSelection$ = new BehaviorSubject<ArticleSelection | null>(null);

    public get articleSelection$(): Observable<ArticleSelection | null> {
        return this._articleSelection$.asObservable();
    }

    public setArticleSelection(selection: ArticleSelection) {
        this._articleSelection$.next(selection);
    }
}
