export class CountryVatConfiguration {
    public CountryCode: string = null;
    public VatId: string = null;
    public ValidFrom: Date = new Date();

    constructor(obj?: Partial<CountryVatConfiguration>) {
        ctor(this, obj, {
            DateFields: ["ValidFrom"],
        });
    }
}
