import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {AbstractType} from "@mintware-de/form-builder";

@Component({
    selector: "bb-form-errors",
    template: `
        <ng-template #template>
            <ng-container *ngIf="formField && formField.errors">
                <mat-error [hidden]="!formField.errors.required || !formField.touched">
                    {{'error.required' | translate }}
                </mat-error>
                <mat-error *ngIf="formField.errors.email">
                    {{'error.email' | translate }}
                </mat-error>
                <mat-error *ngIf="formField.errors.min">
                    {{ 'error.min' | translate:fieldType.options }}
                </mat-error>
                <mat-error *ngIf="formField.errors.max">
                    {{ 'error.max' | translate:fieldType.options }}
                </mat-error>
                <mat-error *ngIf="formField.errors.minlength != null && formField.errors.minlength.requiredLength">
                    {{ 'error.min_length' | translate:fieldType.options }}
                </mat-error>
                <mat-error *ngIf="formField.errors.notZero != null">{{ 'error.not_zero' | translate }}</mat-error>
                <mat-error *ngIf="formField.errors.server != null">{{ formField.errors.server }}</mat-error>
            </ng-container>
        </ng-template>
    `,
})
export class FormErrorsComponent implements AfterViewInit {
    @Input()
    public formField: UntypedFormControl | UntypedFormArray | UntypedFormGroup | AbstractControl;

    @Input()
    public fieldType: AbstractType<any>;

    @ViewChild("template")
    public template: TemplateRef<any>;

    constructor(private readonly viewRef: ViewContainerRef,
                private readonly elRef: ElementRef<HTMLElement>) {
    }

    public ngAfterViewInit(): void {
        this.viewRef.createEmbeddedView(this.template);
        this.elRef.nativeElement.parentElement.removeChild(this.elRef.nativeElement);
    }

}
