import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { EmptyComponent } from "../../common/component/empty.component";
import { ShopListComponent } from "./container/shop-list/shop-list.component";

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: "",
                pathMatch: "full",
                component: ShopListComponent,
                data: {
                    contentPadding: false,
                },
            },
            {
                path: "details",
                children: [
                    {
                        path: ":id",
                        loadComponent: () =>
                            import("./container/shop-details/shop-details.component").then(m => m.ShopDetailsComponent),
                        data: { contentPadding: false },
                    },
                ],
            },
            {
                path: "",
                children: [
                    { component: EmptyComponent, data: { title: "Neuer Kanal" }, path: "add/:type" },
                    { component: EmptyComponent, data: { title: "Kanal bearbeiten" }, path: ":id" },
                    { component: EmptyComponent, data: { title: "Neuer Kanal" }, path: "oauth/add/:type" },
                ],
            },
        ]),
    ],
    exports: [RouterModule],
})
export class ShopSettingsRouterModule {}
