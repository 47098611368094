import {NgModule} from "@angular/core";

import * as coreStorage from "@bb-core/storage";
import * as dataStorage from "./storage";

@NgModule({
    providers: [
        {provide: coreStorage.ClientStorage, useExisting: dataStorage.LocalStorageStorage},
    ],
})
export class DataModule {
}
