import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SelectField} from "@app/app/common/form/select-field/select-field.type";
import {Address} from "@app/app/shipping/core/entity";
import {EditAddressPresenter, EditAddressRequest, EditAddressUseCase} from "@app/app/shipping/core/use-case";
import {FormModel} from "@mintware-de/form-builder";

@Component({
    selector: "bb-pick-address-dialog",
    template: `
        <h4 mat-dialog-title>{{ 'title.pick_address' | translate }}</h4>

        <mat-dialog-content>
            <mw-form-builder #form
                             [mwFormModel]="formModel"
                             [mwFormData]="formData"
                             (mwFormSubmit)="submit($event)">
            </mw-form-builder>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button type="button" mat-flat-button (click)="createNewAddress()">
                {{ 'button.new_address' | translate}}
            </button>
            <div style="flex-grow: 1"></div>
            <button type="button" mat-flat-button [mat-dialog-close]="null">
                {{ 'button.cancel' | translate }}
            </button>
            <button type="button"
                    mat-raised-button
                    color="primary"
                    (click)="form.submit()">
                {{ 'button.pick' | translate }}
            </button>
        </mat-dialog-actions>
    `,
})
export class PickAddressDialogComponent implements EditAddressPresenter {

    public formModel: FormModel<{ addressId: number }>;
    public formData: { addressId: number };

    constructor(@Inject(MAT_DIALOG_DATA) public readonly data: {
                    customerId: number,
                    currentAddressId?: number,
                    addresses: Address[],
                    orderId?: number,
                },
                private readonly dialogRef: MatDialogRef<PickAddressDialogComponent>,
                private readonly editAddressUseCase: EditAddressUseCase,
    ) {
        this.formModel = {
            addressId: new SelectField({
                label: "label.address",
                options: data.addresses.reduce((all, a) => ({
                    ...all,
                    [a.Id]: a.toString(),
                }), {}),
            }),
        };

        this.formData = {addressId: data.currentAddressId};
    }


    public submit(formData: { addressId: number }): void {
        this.dialogRef.close(this.data.addresses.find((a) => a.Id === Number(formData.addressId)));
    }

    public createNewAddress(): void {
        this.editAddressUseCase.execute(new EditAddressRequest({
            Address: new Address({CustomerId: this.data.customerId}),
            OrderId: this.data.orderId,
        }), this).then();
    }

    public displayUpdatedAddress(address: Address): void {
        this.data.addresses.push(address);
        (this.formModel.addressId as SelectField).reportChanges(this.data.addresses.map((a) => ({
            text: a.toString(),
            value: a.Id,
        })));
        this.formData = Object.assign({}, this.formData, {addressId: address.Id});
    }
}
