import {ShippingProductService} from "../shipping-product-service";

export class ShopDeliveryService extends ShippingProductService {
    public ShopId: string = null;
    public ShipToNextShop: boolean = true;

    constructor(obj?: Partial<ShopDeliveryService>) {
        super(obj);
        ctor(this, obj);
    }
}
