<h2 mat-dialog-title>{{ 'Bestandsabgleich manuell auslösen' | translate }}</h2>
<ng-container *ngIf="isLoaded; else isLoading">
    <mat-dialog-content>
        <mw-form-builder #form
                         [mwFormModel]="formModel"
                         [mwFormData]="formData"
                         (mwFormSubmit)="submit($event)">
        </mw-form-builder>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" mat-button mat-dialog-close>{{ 'Abbrechen' | translate }}</button>
        <button type="button" mat-raised-button color="primary"
                (click)="form.submit()"> {{ 'Bestandsabgleich starten' | translate }}</button>
    </mat-dialog-actions>
</ng-container>
<ng-template #isLoading>
    {{ 'Artikeldaten werden geladen.' | translate}}
</ng-template>