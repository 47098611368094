import {ShippingProductService} from "../shipping-product-service";

export class DhlIndividualTeilnahmeService extends ShippingProductService {
    public IndividualTeilnahme: string = null;

    constructor(obj?: Partial<DhlIndividualTeilnahmeService>) {
        super(obj);
        ctor(this, obj);
    }
}
