import {FormModel} from "@mintware-de/form-builder";
import {Customer} from "@app/app/customer/core";
import {TextField} from "@app/app/common/form/text-field/text-field.type";
import {NumberField} from "@app/app/common/form/text-field/number-field.type";

export class MergeCustomersViewModel {

    public formModel: FormModel<Customer> = {
        Name: new TextField({
            label: "label.name",
        }),
        Number: new NumberField({
            label: "label.customer_number",
        }),
    };

    public readonlyFormModels: FormModel<Customer>[];

    public customers: Customer[] = [];
    public targetCustomer: Customer = new Customer();
    public selectedTargetCustomer: number = 0;
}
