import {AbstractLayoutComponent, AbstractLayoutType, IGroupTypeOptions} from "@mintware-de/form-builder";
import {PlainTextOptions} from "./plain-text.options";
import {Component} from "@angular/core";

@Component({
    selector: "bb-form-plain-text",
    template: `<span [innerHTML]="mwFieldType.options.text | translate:mwFieldType.options.interpolationParams"></span>`,
})
export class PlainTextComponent
    extends AbstractLayoutComponent<AbstractLayoutType<PlainTextOptions & IGroupTypeOptions>> {
}
