import {
    Customer,
    CustomerAddress,
    CustomerExportSettings,
    CustomerImportSettings, CustomerMetaInformation,
    CustomerSource,
    FileFormat, ImportedCustomer,
} from "@app/app/customer/core";
import {KeyValueOption} from "@shared/models";

export abstract class CustomerInteractionService {

    /** Display a form to the user for editing an address */
    public abstract editAddress(customerAddress: CustomerAddress): Promise<CustomerAddress>;

    /** Display a form to the user for editing a source */
    public abstract editSource(customerSource: CustomerSource, shops: KeyValueOption[]): Promise<CustomerSource>;

    /** Display a form to the user for merging multiple customer addresses */
    public abstract mergeAddresses(addressesToMerge: CustomerAddress[]): Promise<CustomerAddress>;

    /** Display a form to the user for exporting customers */
    public abstract enterExportSettings(currentSettings: CustomerExportSettings,
                                        fileFormats: FileFormat[],
                                        showAppliedFilterOption: boolean,
                                        showSelectedCustomersOption: boolean,
    ): Promise<CustomerExportSettings>;

    /** Display a form to the user for importing customers */
    public abstract enterImportSettings(currentSettings: CustomerImportSettings,
                                        fileFormats: FileFormat[],
    ): Promise<CustomerImportSettings> ;

    /** Display a form to the user for merging multiple customers */
    public abstract mergeCustomers(customersToMerge: Customer[],
    ): Promise<Customer>;

    /** Display the result of a customer import to the user */
    public abstract showCustomerImportResult(result: ImportedCustomer[]): void;

    /** Display a form to the user for editing the meta data */
    public abstract editMetaData(metaData: CustomerMetaInformation,
    ): Promise<CustomerMetaInformation | null>;
}
