import {CustomerOrderListItem, CustomerOrderRepository} from "@app/app/customer/core";
import {Injectable} from "@angular/core";
import {Column, PageResult} from "@app/app/grid/core";
import * as ngx from "@ngx-resource/core";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/customer",
})
export class ApiCustomerOrderRepository extends ngx.Resource implements CustomerOrderRepository {

    @ngx.ResourceAction({
        path: "/{!customerId}/orders",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doQueryPage: ngx.IResourceMethod<any, { Data: CustomerOrderListItem[], TotalCount: number }>;

    public async queryPage(page: number,
                           pageSize: number,
                           visibleColumns: Column<CustomerOrderListItem>[],
                           searchTerm: string,
                           additionalArguments: any = null,
    ): Promise<PageResult<CustomerOrderListItem>> {
        pageSize = Math.min(pageSize, 99);
        const data = await this.doQueryPage({
            Page: page,
            PageSize: pageSize + 1,
            VisibleColumns: visibleColumns,
        }, null, additionalArguments);

        return new PageResult<CustomerOrderListItem>({
            Data: data.Data.slice(0, pageSize).map(x => new CustomerOrderListItem(x)),
            Page: page,
            PageSize: pageSize,
            TotalCount: data.TotalCount,
        });
    }

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }

    public updateProperty(id: number, changes: Partial<CustomerOrderListItem>): Promise<CustomerOrderListItem> {
        // noop
        return Promise.resolve(null);
    }
}
