import {Component} from "@angular/core";
import {HelpLinkDirective} from "@app/app/common/directive/help-link.directive";

@Component({
    selector: "bb-shopify-init",
    templateUrl: "shopify-init-dialog.component.html",
})
export class ShopifyInitDialogComponent {
    public readonly affiliateLink: string = HelpLinkDirective.linkMapping["shops.shopify.add_affiliate"];
    public readonly appLink: string = HelpLinkDirective.linkMapping["shops.shopify.billbee_app"];

    public openLink(link: string): void {
        document.location.href = link;
    }
}
