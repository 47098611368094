export class ShopSyncSettings {
    public I0003: number = 0;
    public I0306: number = 0;
    public I0609: number = 1;
    public I0912: number = 1;
    public I1215: number = 1;
    public I1518: number = 0;
    public I1821: number = 0;
    public I2124: number = 0;

    constructor(obj?: Partial<ShopSyncSettings>) {
        ctor(this, obj);
    }
}
