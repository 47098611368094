import {ChangeDetectionStrategy, Component} from "@angular/core";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {TranslateModule} from "@ngx-translate/core";
import {BbInputComponent} from "../../../../../shared/ui/form/bb-input/bb-input.component";

@Component({
	templateUrl: "./versacommerce-shop-name-dialog.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        BbInputComponent,
        MatButtonModule,
		MatFormFieldModule,
		ReactiveFormsModule,
    ],
})
export class VersacommerceShopNameDialogComponent {
	public readonly shopName = new FormControl("", Validators.required);
}
