import {ShippingProductService} from "../shipping-product-service";

export class PinAuthenticationService extends ShippingProductService {
    public Pin: string = null;

    constructor(obj?: Partial<PinAuthenticationService>) {
        super(obj);
        ctor(this, obj);
    }
}
