export class CloudStorage {
    public Id: number = null;
    public Name: string = "";

    constructor(obj?: Partial<CloudStorage>) {
        ctor(this, obj, {});
    }

    public toString(): string {
        return this.Name ?? `Cloud #${this.Id}`;
    }
}
