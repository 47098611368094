import {inject, Injectable} from "@angular/core";
import {from, map, Observable, of} from "rxjs";
import {environment} from "../../../../environments/environment";
import {OAuthService} from "../../../../oauth/core/service";
import {ShopViewModelDto} from "../../data/shop-view-model.dto";
import {Shop} from "../../model/shop";
import {ShopType} from "../../model/shop-type.enum";
import {OauthShopAddCommand} from "../../service/command/oauth-shop-add.command";
import {ReauthShopCommand} from "../../service/command/reauth-shop.command";

@Injectable({
    providedIn: "root",
})
export class OttoShop implements Shop {
    public readonly key = "otto";
    public readonly name = "OTTO Market";
	public readonly type = ShopType.Marketplace;
	public readonly logo = `assets/images/logo/shops/${this.key}.png`;
    public readonly featured = true;

    private readonly addCommand = inject(OauthShopAddCommand);
	private readonly reauthCommand = inject(ReauthShopCommand);
	private readonly oauthService = inject(OAuthService);

    add(): Observable<never> {
        return this.addCommand.execute(of(this.getOttoUrl()), this.key, this.logo);
    }

	reAuth(shop: ShopViewModelDto): Observable<ShopViewModelDto> {
		const url$ = from(this.oauthService.getChannelAuthorizeUrl(this.key, shop.Id || 0)).pipe(
			map(url => {
				const state = new URL(url).searchParams.get("state");
				return `${this.getOttoUrl()}?state=${state}`;
			}),
		);
        return this.reauthCommand.execute(url$, this.key, this.logo, shop);
    }

    private getOttoUrl(): string {
        return environment.production
            ? "https://portal.otto.market/apps/billbee"
            : "https://portal.otto.market/apps/sandbox/billbee-sandbox";
    }
}
